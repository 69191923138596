import { render, staticRenderFns } from "./all-costs-table.vue?vue&type=template&id=17b8a0b6&scoped=true"
import script from "./all-costs-table.vue?vue&type=script&lang=ts"
export * from "./all-costs-table.vue?vue&type=script&lang=ts"
import style0 from "./all-costs-table.vue?vue&type=style&index=0&id=17b8a0b6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b8a0b6",
  null
  
)

export default component.exports