<template>
    <div class="scenario-actions-cell-wrapper">
        <pui-button
            icon="edit"
            state="secondary"
            :small="true"
            @click="openSecondaryKpiLightbox"
        >
            {{ $t('setup.scenario.formulaStep.actions.edit') }}
        </pui-button>
        <pui-button
            icon="delete"
            state="secondary"
            :small="true"
            @click="deleteClicked"
        >
            {{ $t('setup.scenario.formulaStep.actions.delete') }}
        </pui-button>
        <secondary-kpi-delete-lightbox
            :kpi-details="kpiDetails"
            :lightbox-open="isDeleteLightboxOpen"
            @close-lightbox="handleCloseDeleteLightbox"
            @trigger-delete="deleteSecondaryKpi"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ValueFormatterParams } from 'ag-grid-community';
import SecondaryKpiDeleteLightbox from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-delete-lightbox.vue';
import { SecondaryKpi } from '@/config/formulaStep';

@Component({
    components: {
        SecondaryKpiDeleteLightbox,
    }
})
export default class SecondaryKpiActionsCellComponent extends Vue {
    private params?: ValueFormatterParams<SecondaryKpi>;
    private isDeleteLightboxOpen = false;

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get kpiDetails(): { kpiNumber: number | undefined, kpiId: string | undefined } {
        return {
            kpiNumber: this.params?.data?.kpiNumber,
            kpiId: this.params?.data?.kpiId,
        }
    }

    private get kpiRunByEngine(): boolean {
        return this.params?.data?.runByEngine ?? false;
    }

    private get kpiDependencies(): string[] | undefined {
        return this.params?.data?.kpiUsedBy.kpisDependent;
    }

    private get kpiDependenciesList(): string {
        return this.kpiDependencies ? this.kpiDependencies.join(', ') : '';
    }

    private openSecondaryKpiLightbox(): void {
        this.params?.context.openSecondaryKpiLightbox(this.params?.data);
    }

    private handleCloseDeleteLightbox(): void {
        this.isDeleteLightboxOpen = false;
    }

    private handleOpenDeleteLightbox(): void {
        this.isDeleteLightboxOpen = true;
    }

    private deleteClicked(): void {
        if (this.kpiDependencies && this.kpiDependencies.length > 0) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('setup.scenario.formulaStep.toastMessages.deleteKpiDependent.title'),
                copy: this.$t('setup.scenario.formulaStep.toastMessages.deleteKpiDependent.copy', { list: this.kpiDependenciesList }),
            });

            return;
        }
        
        this.kpiRunByEngine ? this.handleOpenDeleteLightbox() : this.deleteSecondaryKpi();
    }

    private async deleteSecondaryKpi(): Promise<void> {
        const kpiNumber = this.params?.data?.kpiNumber;
        const kpiId = this.params?.data?.kpiId;
        const scenarioId = this.scenarioId;
        try {
            this.params?.context.handleShowOverlay(true);
            this.handleCloseDeleteLightbox();
            await this.$store.dispatch('scenario/formulaStep/deleteKpi', { kpiNumber, kpiId, scenarioId });
            this.params?.context.refreshTable();
            this.params?.context.reloadSummary();
        } catch(e: any) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('setup.scenario.formulaStepForm.toastMessages.deleteKpiError.title'),
                copy: e.errors[0],
            });
        } finally {
            this.params?.context.handleShowOverlay(false);
        }
    }
}
</script>

<style scoped lang="scss">
.scenario-actions-cell-wrapper {
    height: 100%;
    display: flex;
    gap: 10px;
}
</style>
