<template>
    <div :class="['donut-card', { 'donut-card--active': hoverable && !selected }, { 'donut-card--selected': selected }]">
        <spinner 
            :promise="promise"
            size="35px"
            min-height="100%"
            :is-inline-error="wide"
        >
            <div :class="['donut-card__donut-container', { 'donut-card__donut-container--wide' : wide }]">
                <div :class="['donut-card__donut-container__donut', { 'donut-card__donut-container--wide__donut' : wide }]">
                    <pui-donut
                        title=""
                        :segment-width="segmentWidth"
                        :values="segments"
                    />
                </div>
                <div :class="['donut-card__donut-container__additional-info', { 'donut-card__donut-container--wide__additional-info' : wide }]">
                    <pui-headline
                        type="h4"
                        :class="['donut-card__donut-container__additional-info__count', { 'donut-card__donut-container--wide__additional-info__count' : wide }]"
                    >
                        {{ count }}
                    </pui-headline>
                    <span :class="['donut-card__donut-container__additional-info__title', { 'donut-card__donut-container__additional-info__title--selected': selected }]">
                        {{ title }}
                    </span>
                </div>
            </div>
        </spinner>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Spinner from '@/components/spinner/spinner.vue';
import { DonutCardSegment } from '@/models/interfaces';

enum SegmentWidth {
    Bold = 45,
    Normal = 25,
}

@Component({
    components: {
        Spinner
    }
})
export default class DonutCardComponent extends Vue {
    private DONUT_BACKGROUND = '#F2F6FC';

    /**
     * This prop is used to know the card type.
     */
    @Prop(Boolean)
    private wide!: boolean;
    /**
     * This prop is used for the spinner.
     */
    @Prop()
    private promise!: Promise<void>;
    /**
     * This prop is required to know the title.
     */
    @Prop()
    private title!: string;
    /**
     * This prop is required to know the color of the donut.
     */
    @Prop()
    private color!: string;
    /**
     * This prop is required to know the number.
     */
    @Prop()
    private count!: number;
    /**
     * This prop is required to know the total number.
     */
    @Prop()
    private total!: number;
    /**
     * This prop is required to know if the card is hoverable.
     */
    @Prop({ default: false })
    private hoverable!: boolean;
    /**
     * This prop is required to know if the card is selected.
     */
    @Prop({ default: false })
    private selected!: boolean;

    private get segmentWidth(): SegmentWidth {
        return this.wide ? SegmentWidth.Bold : SegmentWidth.Normal;
    }

    private get segments(): DonutCardSegment[] {
        return [
            {
                color: this.color,
                count: this.count,
            },
            {
                color: this.DONUT_BACKGROUND,
                count: this.total - this.count,
            }
        ];
    }

}
</script>

<style scoped lang="scss">
.donut-card {
    background-color: $white;
    margin: 10px 0;
    padding: 20px;
    width: 100%;
    min-height: 100px;
    min-width: 100px;
    @include pui-box();

    &--active:hover {
        cursor: pointer;
        background: transparentize($uniper-blue, 0.9);
    }

    &--selected {
        cursor: pointer;
        background: transparentize($uniper-blue, 0.7);
    }

    &__donut-container {
        &__additional-info {
            text-align: center;

            &__count {
                color: $dark-grey;
                font-size: 24px;
                font-weight: 700;
                @include rem(margin-top, pui-spacing(xs));
            }  

            &__title {
                color: $warm-grey;
                font-weight: 500;

                &--selected {
                    color: $dark-grey;
                }
            } 
        }
        
        &__donut {
            height: 60px;
            width: 60px;
            margin: 0 auto;
        }

        &--wide {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            &__additional-info {
                display: flex;
                flex-direction: column-reverse;
                text-align: left;
                
                &__count {
                    @include rem(margin-top, 0);
                }  
            }
            
            &__donut {
                margin: 0;
            }
        }
    }

}
</style>