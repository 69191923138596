var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "control-card"
  }, [_c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [!_vm.isEngineRunning ? _c('engine-run-start-card') : _c('engine-run-running-card')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }