<template>
    <div class="status-cell-wrapper">
        <div 
            class="status-cell-wrapper__dot" 
            :style="{ backgroundColor: statusColor }"
        />
        <div>{{ statusName }}</div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SCENARIO_STATUS, SCENARIO_STATUS_DETAILS } from '@/config/status';
import { ValueFormatterParams } from 'ag-grid-community';
import { IScenarioList } from '@/service-proxies/service-proxies.g';

@Component({})
export default class ScenarioStatusCellComponent extends Vue {
    private params?: ValueFormatterParams<IScenarioList>;

    private get statusId(): string | undefined {
        return this.params?.value.toString();
    }

    private get statusPercent(): string | undefined {
        return this.params?.data?.budgetFlaggedPercent?.toString();
    }

    private get statusName(): string {
        if (this.statusId && SCENARIO_STATUS_DETAILS[this.statusId]) {
            if (this.statusId === SCENARIO_STATUS.IN_REVIEW) {
                return `${this.$t(SCENARIO_STATUS_DETAILS[this.statusId].NAME)} (${this.statusPercent}%)`
            }

            return this.$t(SCENARIO_STATUS_DETAILS[this.statusId].NAME);
        }

        return '-';
    }

    private get statusColor(): string {
        return this.statusId && SCENARIO_STATUS_DETAILS[this.statusId] ? SCENARIO_STATUS_DETAILS[this.statusId].COLOR : '';
    }
}
</script>

<style scoped lang="scss">
.status-cell-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>