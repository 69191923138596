<template>
    <div
        id="app"
        v-if="isSecurityLoaded"
    >
        <header-wrapper />
        <navbar />
        <router-view />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Navbar from '@/components/navbar/navbar.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import { APP_SCOPE } from '@/utils/coode-sdk-config';
import './main.scss';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Navbar,
        Snackbar,
        Loader,
    },
})
export default class App extends Vue {
    private isSecurityLoaded = false;

    private async mounted(): Promise<void> {
        await this.setupCoodeSdk();
    }

    private async setupCoodeSdk(): Promise<void> {
        await this.$sdk.$authenticator?.loadAuthModule();
        await this.$sdk.$authenticator?.attemptSsoSilent('loginRedirect');

        await this.$sdk.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);

        await this.$sdk.$http?.$generic.request.api.setTokenCallback(
            async () => this.$sdk.$authenticator?.getToken(APP_SCOPE)
        );

        const isSignedIn = await this.$sdk.$authenticator?.isAuthenticated() as boolean;

        if (isSignedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            await this.$sdk.$permissions?.setPermissions(useCaseId);

            this.isSecurityLoaded = true;
        }
    }
}
</script>

<style lang="scss">
</style>
