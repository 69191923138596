<template>
    <div class="kpi-type-tab">
        <table-header
            :search-term="tableHeaderFilters.searchTerm"
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
            @search-triggered="onTriggerSearch"
            @clear-search-term="onClearSearchTerm"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
        />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import {
    GridApi,
    GridOptions,
    GridSizeChangedEvent,
    IServerSideGetRowsParams,
    ValueSetterParams
} from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import {
    DeleteKpiTypeRequest,
    EditKpiTypeDto,
    EditKpiTypeRequest,
    KpiTypeServiceProxy
} from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import { Debouncer } from '@/mixins/debouncer';
import { KpiTypeRow } from '@/models/admin/kpi-type';
import AdminActionCell from '@/components/admin/table-cells/delete-action-cell.vue';

@Component({
    components: {
        TableHeader,
        AgGridVue,
        AdminActionCell,
    }
})
export default class AdminKpiTypeTab extends mixins(Debouncer) {
    private readonly kpiTypeService = new KpiTypeServiceProxy();

    private kpiTypeNameChangelist = new Map<number, string>();
    private kpiTypeDeletelist = new Set<number>();

    private tableHeaderFilters = {
        pageSize: 20,
        searchTerm: '',
    };

    private gridApi: GridApi<KpiTypeRow> | null = null;

    private gridOptions: GridOptions<KpiTypeRow> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const pageNumber = this.pageNumber(params.request.endRow);
                const { searchTerm, pageSize } = this.tableHeaderFilters;

                this.kpiTypeService.listKpiTypes(pageNumber, pageSize, searchTerm, undefined)
                    .then(response => {
                        const rowData: KpiTypeRow[] = response.result.kpiTypes.items?.map(item => ({
                            id: item.id,
                            kpiType: item.name ?? '-',
                            canBeDeleted: item.canBeDeleted,
                        })) ?? [];

                        params.success({ rowData, rowCount: response.result.kpiTypes.total });
                    })
                    .catch(() => params.fail());
            },
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('admin.modal.table.id'),
                field: 'id',
                maxWidth: 200,
            },
            {
                headerName: this.$t('admin.modal.table.kpiType'),
                field: 'kpiType',
                editable: true,
                valueSetter: (params: ValueSetterParams<KpiTypeRow>): boolean => {
                    if (params.oldValue.toString() === params.newValue.toString() || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const newName = params.newValue.toString();

                    this.scheduleKpiTypeNameChange(id, newName);
                    params.data.kpiType = newName;

                    return true;
                },
            },
            {
                headerName: this.$t('admin.modal.table.actions'),
                maxWidth: 150,
                cellRenderer: 'AdminActionCell',
            }
        ],
        defaultColDef: {
            menuTabs: [],
        },
        getRowId: (params) => {
            return params.data.id.toString();
        },
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event: GridSizeChangedEvent<KpiTypeRow>) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
        context: {
            scheduleEntityDeletion: this.scheduleKpiTypeDeletion,
            onRefreshTable: this.onRefreshTable,
        }
    };

    private scheduleKpiTypeNameChange(id: number, newKpiTypeName: string): void {
        this.kpiTypeNameChangelist.set(id, newKpiTypeName);

        this.$store.dispatch('prevention/registerPrevention', 'adminKpiType');
        this.debounce('kpiTypeSaveChanges', this.saveChanges, 2000);
    }

    private scheduleKpiTypeDeletion(id: number): void {
        this.kpiTypeDeletelist.add(id);
        this.kpiTypeNameChangelist.delete(id);

        this.$store.dispatch('prevention/registerPrevention', 'adminKpiType');

        this.clearDebouncer('kpiTypeSaveChanges');
        this.gridApi?.showLoadingOverlay();
        this.saveChanges().finally(() => {
            this.gridApi?.hideOverlay();
            this.gridApi?.refreshServerSide({ purge: true });
        });
    }

    private async saveChanges(): Promise<void> {
        if (this.kpiTypeDeletelist.size !== 0) {
            const deleteList: number[] = [];

            for (const id of this.kpiTypeDeletelist.values()) {
                deleteList.push(id);
            }

            this.kpiTypeDeletelist.clear();

            try {
                await this.kpiTypeService.deleteKpiType(new DeleteKpiTypeRequest({ kpiTypeIds: deleteList }));
            } catch {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('admin.modal.toast.kpiType.delete.title'),
                    copy: this.$t('admin.modal.toast.kpiType.delete.copy'),
                });

                deleteList.forEach(entry => this.kpiTypeDeletelist.add(entry));
            }
        }

        if (this.kpiTypeNameChangelist.size !== 0) {
            const changeList: EditKpiTypeDto[] = [];

            for (const [id, name] of this.kpiTypeNameChangelist.entries()) {
                changeList.push(new EditKpiTypeDto({ id, name }));
            }

            this.kpiTypeNameChangelist.clear();

            try {
                await this.kpiTypeService.editKpiType(new EditKpiTypeRequest({ kpiTypes: changeList, }));
            } catch {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('admin.modal.toast.kpiType.edit.title'),
                    copy: this.$t('admin.modal.toast.kpiType.edit.copy'),
                });

                changeList.forEach(entry => this.kpiTypeNameChangelist.set(entry.id, entry.name ?? '-'));
            }
        }

        await this.$store.dispatch('prevention/unregisterPrevention', 'adminKpiType');
    }

    private onGridReady(params: AgGridCommon<KpiTypeRow>): void {
        params.api.sizeColumnsToFit();
        this.gridApi = params.api;
    }

    public async onRefreshTable(): Promise<void> {
        this.clearDebouncer('kpiTypeSaveChanges');
        await this.saveChanges();

        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableHeaderFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableHeaderFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize)
    }
}
</script>

<style scoped lang="scss">
.kpi-type-tab {
    @include rem(margin, pui-spacing(s) 0);
    @include pui-box();

    width: 100%;
    background-color: $white;
}
</style>
