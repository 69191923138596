<template>
    <div class="checkbox-wrapper">
        <pui-form-checkbox
            label
            :checked="isChecked"
            @change="onChange"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IKpiDto } from '@/service-proxies/service-proxies.g';
import { ValueFormatterParams } from 'ag-grid-community';

@Component({})
export default class CheckboxCellComponent extends Vue {
    private params?: ValueFormatterParams<IKpiDto>;

    private get isChecked(): boolean | undefined {
        return this.params?.data?.kpiActive;
    }

    private onChange(value: boolean): void {
        const colId = this.params?.column.getColId();
        if(colId) {
            this.params?.node?.setDataValue(colId, value);
        }
    }
}
</script>

<style scoped lang="scss">
  .checkbox-wrapper {
    height: 100%;
    display: flex;
    @include rem(margin-top, pui-spacing(xxxs));
  }
</style>