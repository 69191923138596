<template>
    <pui-loader :promise="infinitePromise" />
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class LoadingOverlayComponent extends Vue {
    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
}
</script>