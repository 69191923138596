<template>
    <div class="kpi-summary-card">
        <spinner 
            :promise="summaryPromise"
            :is-inline-error="false"
            min-height="60px"
        >
            <pui-headline
                class="kpi-summary-card__headline"
                type="h5"
            >
                {{ $t('setup.scenario.formulaStep.summary.title') }}
            </pui-headline>
            <pui-headline
                class="kpi-summary-card__content kpi-summary-card__content__item kpi-summary-card__content__item--bold"
                type="h6"
            >
                {{ $t('setup.scenario.formulaStep.summary.kpisSelected', { count: summary.selected }) }}
            </pui-headline>
            <div :class="['kpi-summary-card__content--inline', {'kpi-summary-card__content' : !isPrimary}]">
                <pui-headline type="h6">
                    {{ $t('setup.scenario.formulaStep.summary.unitKpis', { count: summary.unit }) }}
                </pui-headline>
                <pui-headline type="h6">
                    {{ $t('setup.scenario.formulaStep.summary.unit0Kpis', { count: summary.unit0 }) }}
                </pui-headline>
                <pui-headline type="h6">
                    {{ $t('setup.scenario.formulaStep.summary.unitPlusUnit0Kpis', { count: summary.unitPlusUnit0 }) }}
                </pui-headline>
            </div>
            <pui-headline
                v-if="!isPrimary"
                class="kpi-summary-card__content__item--highlight"
                type="h6"
            >
                {{ $t('setup.scenario.formulaStep.summary.activeKpis', { count: summary.active }) }}
            </pui-headline>
        </spinner>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Spinner from '@/components/spinner/spinner.vue';

type Summary = {
    selected: number,
    unit: number,
    unit0: number,
    unitPlusUnit0: number,
    active: number,
};

@Component({
    components: {
        Spinner
    }
})
export default class KpiSummaryCardComponent extends Vue {
    private summaryPromise: Promise<void> | null = null;
    private summary: Summary = {
        selected: 0,
        unit: 0,
        unit0: 0,
        unitPlusUnit0: 0,
        active: 0,
    };
    
    /**
     * This prop is required to know if the kpi is primary.
     */
    @Prop(Boolean)
    private isPrimary!: boolean;


    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private mounted(): void {
        this.fetchSummary();
    }

    public fetchSummary(): void {
        const payload = {
            scenarioId: this.scenarioId,
            isPrimary: this.isPrimary,
        }
        this.summaryPromise = this.$store.dispatch('scenario/formulaStep/fetchSummary', payload)
            .then((result) => {
                this.summary = result;
            });
    }
}
</script>

<style lang="scss" scoped>
.kpi-summary-card {
  @include pui-box();
  @include rem(padding, pui-spacing(m));
  @include rem(margin-top, pui-spacing(m));
  
    &__headline {
      color: $warm-grey;
      @include rem(margin-bottom, pui-spacing(s));
    }

    &__content {
      @include rem(margin-bottom, pui-spacing(s));

      &--inline {
        display: flex;
        @include rem(gap, pui-spacing(m));
      }

      &__item {  
        &--bold {
          font-weight: bold;
        }
  
        &--highlight {
          color: #28A745;
        }
  
        &--no-margin {
          @include rem(margin-bottom, 0);
        }
      }
    }
}
</style>