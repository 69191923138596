var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isSecurityLoaded ? _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('header-wrapper'), _c('navbar'), _c('router-view')], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }