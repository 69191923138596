<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :default-header-label="headerText"
        :close-on-e-s-c="false"
        :close-on-overlay-click="true"
    >
        <kpi-analysis-display
            :scenario-id="scenarioId"
            :kpi-id="kpiId"
            :year="year"
            :unit-sid="unitSid"
        />
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import KpiAnalysisDisplay from '@/components/results/kpi-analysis/kpi-analysis-display.vue';
import { KpiAnalysisLightboxPayload } from '@/models/kpi-analysis/kpi-analysis-lightbox';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({
    components: { KpiAnalysisDisplay }
})
export default class KpiAnalysisLightbox extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private scenarioId: number | null = null;
    private kpiId: string | null = null;
    private year: number | null = null;
    private unitSid: number | null = null;
    private unitUi: string | null = null;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]?: PuiLightbox,
    };

    private get headerText(): string {
        if (!this.scenarioId || !this.kpiId || !this.year || !this.unitSid) {
            return this.$t('kpiAnalysisLightbox.loadingHeader');
        }

        return this.$t('kpiAnalysisLightbox.header', {
            kpiId: this.kpiId,
            year: this.year,
            unitUi: this.unitUi,
            unitSid: this.unitSid,
        });
    }

    public open({ scenarioId, kpiId, year, unitSid, unitUi }: KpiAnalysisLightboxPayload): void {
        this.scenarioId = scenarioId;
        this.kpiId = kpiId;
        this.year = year;
        this.unitSid = unitSid;
        this.unitUi = unitUi;

        this.$refs.lightbox?.open();
    }

    public close(): void {
        this.$refs.lightbox?.close();
    }
}
</script>
