var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scenario-compare"
  }, [_c('div', {
    staticClass: "scenario-compare__selectors"
  }, [_c('scenario-compare-linked-scenario-selector', {
    on: {
      "change:scenario": _vm.onFirstScenarioChange
    }
  }), _c('scenario-compare-scenario-selector', {
    on: {
      "change:scenario": _vm.onSecondScenarioChange
    }
  })], 1), _c('div', {
    staticClass: "scenario-compare__summary"
  }, [_c('scenario-compare-summary')], 1), _c('div', {
    staticClass: "scenario-compare__tabs"
  }, [_c('scenario-compare-tabs')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }