<template>
    <div
        v-if="overviewData"
        class="sc-table-overview"
    >
        <div
            class="sc-table-overview__item"
            @click="statusFilterClicked(ScenarioCompareStatus.GREEN)"
        >
            <div class="sc-table-overview__item__chip sc-table-overview__item__chip--complete" />
            <div class="sc-table-overview__item__text">
                {{ $t('results.scenarioCompare.overview.complete', { count: overviewData.numberOfGreenCells }) }}
            </div>
        </div>
        <div
            class="sc-table-overview__item"
            @click="statusFilterClicked(ScenarioCompareStatus.RED)"
        >
            <div class="sc-table-overview__item__chip sc-table-overview__item__chip--missing" />
            <div class="sc-table-overview__item__text">
                {{ $t('results.scenarioCompare.overview.missing', { count: overviewData.numberOfRedCells }) }}
            </div>
        </div>
        <div
            class="sc-table-overview__item"
            @click="statusFilterClicked(ScenarioCompareStatus.ORANGE)"
        >
            <div class="sc-table-overview__item__chip sc-table-overview__item__chip--partially-missing" />
            <div class="sc-table-overview__item__text">
                {{ $t('results.scenarioCompare.overview.partiallyMissing', { count: overviewData.numberOfOrangeCells }) }}
            </div>
        </div>
        <div
            class="sc-table-overview__item"
            @click="statusFilterClicked(ScenarioCompareStatus.GREY)"
        >
            <div class="sc-table-overview__item__chip sc-table-overview__item__chip--not-applicable" />
            <div class="sc-table-overview__item__text">
                {{ $t('results.scenarioCompare.overview.notApplicable', { count: overviewData.numberOfGreyCells }) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ScenarioCompareOverviewDto } from '@/service-proxies/service-proxies.g';
import { ScenarioCompareStatus } from '@/config/scenario-compare';

@Component({})
export default class ScenarioCompareTableOverview extends Vue {
    private readonly ScenarioCompareStatus = ScenarioCompareStatus;

    @Prop()
    private overviewData?: ScenarioCompareOverviewDto;

    private statusFilterClicked(status: ScenarioCompareStatus): void {
        this.$store.dispatch('results/scenarioCompare/setFilterOptions', {
            ...this.$store.getters['results/scenarioCompare/getFilterOptions'],
            status: [status],
        });
    }
}
</script>

<style scoped lang="scss">
.sc-table-overview {
    @include rem(padding, pui-spacing(m));
    @include rem(gap, pui-spacing(s));
    display: flex;
    align-items: center;

    &__item {
        @include rem(gap, pui-spacing(xxs));
        display: flex;
        align-items: center;
        cursor: pointer;

        &__chip {
            height: 1rem;
            width: 1rem;
            border-radius: 50%;

            &--complete {
                background-color: #19C142;
            }

            &--missing {
                background-color: #CD0000;
            }

            &--partially-missing {
                background-color: #FFAB00;
            }

            &--not-applicable {
                background-color: #ADADAD;
            }
        }

        &__text {
            font-size: 1.2rem;
        }
    }
}
</style>
