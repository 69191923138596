<template>
    <div class="badge-wrapper">
        <pui-badge :background-color="backgroundColor">
            {{ text }}
        </pui-badge>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface BadgeCell extends ICellRendererParams {
    text: string;
    backgroundColor: string;
}

@Component({})
export default class BadgeCellComponent extends Vue {
    private params?: BadgeCell;

    private get text(): string {
        return this.params?.text ?? '-';
    }

    private get backgroundColor(): string | undefined {
        return this.params?.backgroundColor;
    }
}
</script>
