<template>
    <div class="badge-wrapper">
        <pui-badge :background-color="BADGE_COLOR">
            {{ $t('results.scenarioCompare.details.table.cells.notCalculated') }}
        </pui-badge>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PUICOLOR_DARK_BLUE } from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({})
export default class ScenarioCompareDetailsNotCalculatedCell extends Vue {
    private readonly BADGE_COLOR = PUICOLOR_DARK_BLUE;
}
</script>
