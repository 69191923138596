<template>
    <div class="scenario-compare">
        <div class="scenario-compare__selectors">
            <scenario-compare-linked-scenario-selector @change:scenario="onFirstScenarioChange" />
            <scenario-compare-scenario-selector @change:scenario="onSecondScenarioChange" />
        </div>
        <div class="scenario-compare__summary">
            <scenario-compare-summary />
        </div>
        <div class="scenario-compare__tabs">
            <scenario-compare-tabs />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ScenarioCompareScenarioSelector
    from '@/components/results/scenario-compare/scenario-compare-scenario-selector.vue';
import { ScenarioCompareScenarioSelectOption } from '@/models/results/scenario-compare';
import ScenarioCompareSummary from '@/components/results/scenario-compare/sections/scenario-compare-summary.vue';
import ScenarioCompareTabs from '@/components/results/scenario-compare/sections/scenario-compare-tabs.vue';
import ScenarioCompareLinkedScenarioSelector
    from '@/components/results/scenario-compare/scenario-compare-linked-scenario-selector.vue';

@Component({
    components: {
        ScenarioCompareLinkedScenarioSelector,
        ScenarioCompareTabs,
        ScenarioCompareSummary,
        ScenarioCompareScenarioSelector,
    }
})
export default class ResultsScenarioCompare extends Vue {
    created(): void {
        this.$store.dispatch('results/scenarioCompare/clearScenarioCompare');
    }

    private onFirstScenarioChange(scenario: ScenarioCompareScenarioSelectOption | null): void {
        this.$store.dispatch('results/scenarioCompare/setFirstScenario', scenario?.extraData);
    }

    private onSecondScenarioChange(scenario: ScenarioCompareScenarioSelectOption | null): void {
        this.$store.dispatch('results/scenarioCompare/setSecondScenario', scenario?.extraData);
    }
}
</script>

<style scoped lang="scss">
.scenario-compare {
    @include rem(padding, pui-spacing(m) 0);
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: column;

    &__selectors {
        @include rem(gap, pui-spacing(m));

        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
