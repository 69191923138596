<template>
    <div
        class="spinner"
        :style="[{ 'min-height': minHeight }]"
    >
        <pui-loader-spinner
            v-if="isLoading"
            :size="size"
            :title="title"
            :min-height="minHeight"
        />
        <template v-else>
            <slot v-if="isResolved" />
            <slot
                v-if="isError"
                name="error-state"
            >
                <div :class="['spinner__error-state', { 'spinner__error-state--inline': isInlineError }]">
                    <div :class="['spinner__error-icon', { 'spinner__error-icon--inline': isInlineError }]">
                        <pui-icon
                            :icon-color="ERROR_STATE_ICON_COLOR"
                            icon-name="data-unavailable"
                            size="24px"
                        />
                    </div>
                    <div :class="['spinner__error-message', { 'spinner__error-icon--inline': isInlineError }]">
                        {{ $t('dataUnavailable') }}
                    </div>
                </div>
            </slot>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PUICOLOR_WARM_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';

@Component({})
export default class SpinnerComponent extends Vue {
    /**
     * This prop is required to know the title.
     */
    @Prop()
    private promise!: Promise<void>;

    private isError = false;
    private isResolved = false;
    private isLoading = false;
    private localPromise = this.promise;
    private ERROR_STATE_ICON_COLOR = PUICOLOR_WARM_GREY;

    /**
     * This prop is required to know the title.
     */
    @Prop()
    private title!: string;
    /**
     * This prop is required to know the size.
     */
    @Prop({default: '80px'})
    private size!: string;
    /**
     * This prop is required to know the title.
     */
    @Prop({default: '200px'})
    private minHeight!: string;
    /**
     * This prop is required to know the title.
     */
    @Prop()
    private isInlineError!: boolean;

    @Watch('promise')
    onPromiseChanged(promise: Promise<void>): void {
        this.localPromise = promise;
    }

    @Watch('localPromise', { immediate: true })
    async onLocalPromiseChanged(promise: Promise<any>): Promise<void> {
        if (promise) {
            this.resetStates();
            this.startLoader();

            const isFinalPromiseResult = (): boolean => {
                return this.localPromise === promise;
            };

            try {
                await promise;
                if (isFinalPromiseResult()) {
                    this.isResolved = true;
                    this.stopLoader();
                }
            } catch {
                this.stopLoader();
                this.isError = true;
            }
        } else {
            this.resetStates();
            this.stopLoader();
        }
    }

    private resetStates(): void {
        this.isResolved = false;
        this.isError = false;
    }

    private startLoader(): void {
        this.isLoading = true;
    }

    private stopLoader(): void {
        this.isLoading = false;
    }

}
</script>

<style scoped lang="scss">
.spinner {
    width: 100%;
    height: 100%;
    position: relative;

    &__error-state {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &--inline {
        flex-direction: row;
        }
    }

    &__error-icon {
        &--inline {
            align-self: center;
            @include rem(padding-right, pui-spacing(xxs));
        }
    }

    &__error-message {
        color: $warm-grey;
        font-size: 14px;
        text-align: center;
        @include rem(margin-top, pui-spacing(xxxs));

        &--inline {
            margin-top: 0;
        }
    }
}
</style>