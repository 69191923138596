var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ce-step"
  }, [_c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [_vm.hasMtpYear ? [_c('pui-tabs', {
    staticClass: "ce-step__tabs",
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.ceForm.tabs.all')
    }
  }), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.ceForm.tabs.flagged')
    }
  })], 1), _c('all-costs-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.COST_EXCLUSION_TAB.ALL,
      expression: "selectedTabIndex === COST_EXCLUSION_TAB.ALL"
    }]
  }), _c('flagged-costs-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.COST_EXCLUSION_TAB.FLAGGED,
      expression: "selectedTabIndex === COST_EXCLUSION_TAB.FLAGGED"
    }],
    ref: _vm.FLAGGED_TABLE_REF
  })] : _c('pui-form-inline-notification', {
    attrs: {
      "title": _vm.$t('setup.scenario.ceForm.noMtpNotification.title'),
      "text": _vm.$t('setup.scenario.ceForm.noMtpNotification.text'),
      "type": "info"
    }
  })], 2), _c('div', {
    staticClass: "ce-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.next')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }