<template>
    <div class="inflation-step">
        <pui-tabs
            :selected="selectedTabIndex"
            class="inflation-step__tabs"
            @changed="onTabChanged"
        >
            <pui-tab :title="$t('setup.scenario.inflationForm.tabs.nominated')" />
            <pui-tab :title="$t('setup.scenario.inflationForm.tabs.yearly')" />
        </pui-tabs>
        <pui-loader
            v-if="isFormSubmitting"
            :promise="infinitePromise"
            :title="$t('setup.scenario.inflationForm.loader.savingYearly.title')"
            :message="$t('setup.scenario.inflationForm.loader.savingYearly.message')"
        />
        <nominated-inflation-table
            v-show="!isFormSubmitting && selectedTabIndex === INFLATION_STEP_TAB.NOMINATED"
            :ref="NOMINATED_TABLE_REF"
        />
        <yearly-inflation-table
            v-show="!isFormSubmitting && selectedTabIndex === INFLATION_STEP_TAB.YEARLY"
            :ref="YEARLY_TABLE_REF"
        />
        <div class="inflation-step__buttons">
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.next') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import NominatedInflationTable
    from '@/components/inflation-step/nominated-inflation-table/nominated-inflation-table.vue';
import YearlyInflationTable from '@/components/inflation-step/yearly-inflation-table/yearly-inflation-table.vue';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

enum INFLATION_STEP_TAB {
    NOMINATED = 0,
    YEARLY = 1,
}

const NOMINATED_TABLE_REF = 'nominatedTable' as const;
const YEARLY_TABLE_REF = 'yearlyTable' as const;

@Component({
    components: {
        YearlyInflationTable,
        NominatedInflationTable,
    },
    beforeRouteLeave(to, from, next) {
        (this as InflationScenarioStep).saveYearlyTable(true);
        next();
    }
})
export default class InflationScenarioStep extends mixins(ScenarioStepNavigation) {
    private readonly INFLATION_STEP_TAB = INFLATION_STEP_TAB;
    private readonly NOMINATED_TABLE_REF = NOMINATED_TABLE_REF;
    private readonly YEARLY_TABLE_REF = YEARLY_TABLE_REF;

    private isFormSubmitting = false;
    private selectedTabIndex = INFLATION_STEP_TAB.NOMINATED;

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    $refs!: {
        [NOMINATED_TABLE_REF]: NominatedInflationTable,
        [YEARLY_TABLE_REF]: YearlyInflationTable,
    };

    private async onTabChanged(newTabIndex: number): Promise<void> {
        this.selectedTabIndex = newTabIndex;

        if (newTabIndex === INFLATION_STEP_TAB.NOMINATED) {
            await this.saveYearlyTable(true, true);
            this.$refs.nominatedTable.refreshData();
        }

        if (newTabIndex === INFLATION_STEP_TAB.YEARLY) {
            this.$refs.yearlyTable.refreshData();
        }
    }

    private async saveYearlyTable(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        this.isFormSubmitting = true;
        const hasSubmitted = await this.$refs.yearlyTable.submitForm(saveAsDraft, automaticSubmit);
        this.isFormSubmitting = false;

        return hasSubmitted;
    }

    private async backButtonClicked(): Promise<void> {
        if (await this.saveYearlyTable(true)) {
            await this.pushToStep(SCENARIO_FORM_STEP.FORMULA);
        }
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (await this.saveYearlyTable(true)) {
            await this.pushToScenarioOverview();
        }
    }

    private async nextButtonClicked(): Promise<void> {
        if (await this.saveYearlyTable(false)) {
            await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.MTC);
            await this.pushToStep(SCENARIO_FORM_STEP.MTC);
        }
    }
}
</script>

<style scoped lang="scss">
.inflation-step {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    &__tabs {
        margin-bottom: -1rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }
}
</style>
