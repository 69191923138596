var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-analysis-selector"
  }, [_c('kpi-analysis-scenario-selector', {
    staticClass: "kpi-analysis-selector__scenario",
    on: {
      "change:scenario-id": _vm.onScenarioIdChange,
      "change:years": _vm.onYearRangeChange
    }
  }), _vm.scenarioId && _vm.scenarioFilters ? _c('div', {
    staticClass: "kpi-analysis-selector__opt-selectors"
  }, [_c('kpi-analysis-kpi-selectors', {
    attrs: {
      "scenario-id": _vm.scenarioId,
      "scenario-filters": _vm.scenarioFilters
    },
    on: {
      "change:kpi-id": _vm.onKpiIdChange
    }
  }), _c('div', {
    staticClass: "kpi-analysis-selector__opt-selectors__spacer"
  }), _c('kpi-analysis-unit-selectors', {
    attrs: {
      "start-year": _vm.startYear,
      "end-year": _vm.endYear,
      "scenario-filters": _vm.scenarioFilters
    },
    on: {
      "change:unit": _vm.onUnitChange,
      "change:year": _vm.onYearChange
    }
  })], 1) : _vm.scenarioId && !_vm.scenarioFilters ? _c('div', {
    staticClass: "kpi-analysis-selector__loader"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "size": "24px",
      "min-height": "0"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }