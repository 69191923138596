var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.overviewData ? _c('div', {
    staticClass: "sc-table-overview"
  }, [_c('div', {
    staticClass: "sc-table-overview__item",
    on: {
      "click": function ($event) {
        return _vm.statusFilterClicked(_vm.ScenarioCompareStatus.GREEN);
      }
    }
  }, [_c('div', {
    staticClass: "sc-table-overview__item__chip sc-table-overview__item__chip--complete"
  }), _c('div', {
    staticClass: "sc-table-overview__item__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.overview.complete', {
    count: _vm.overviewData.numberOfGreenCells
  })) + " ")])]), _c('div', {
    staticClass: "sc-table-overview__item",
    on: {
      "click": function ($event) {
        return _vm.statusFilterClicked(_vm.ScenarioCompareStatus.RED);
      }
    }
  }, [_c('div', {
    staticClass: "sc-table-overview__item__chip sc-table-overview__item__chip--missing"
  }), _c('div', {
    staticClass: "sc-table-overview__item__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.overview.missing', {
    count: _vm.overviewData.numberOfRedCells
  })) + " ")])]), _c('div', {
    staticClass: "sc-table-overview__item",
    on: {
      "click": function ($event) {
        return _vm.statusFilterClicked(_vm.ScenarioCompareStatus.ORANGE);
      }
    }
  }, [_c('div', {
    staticClass: "sc-table-overview__item__chip sc-table-overview__item__chip--partially-missing"
  }), _c('div', {
    staticClass: "sc-table-overview__item__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.overview.partiallyMissing', {
    count: _vm.overviewData.numberOfOrangeCells
  })) + " ")])]), _c('div', {
    staticClass: "sc-table-overview__item",
    on: {
      "click": function ($event) {
        return _vm.statusFilterClicked(_vm.ScenarioCompareStatus.GREY);
      }
    }
  }, [_c('div', {
    staticClass: "sc-table-overview__item__chip sc-table-overview__item__chip--not-applicable"
  }), _c('div', {
    staticClass: "sc-table-overview__item__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.overview.notApplicable', {
    count: _vm.overviewData.numberOfGreyCells
  })) + " ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }