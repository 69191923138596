var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "badge-wrapper"
  }, [_c('pui-badge', {
    attrs: {
      "background-color": _vm.backgroundColor
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }