import { Vue } from 'vue-property-decorator';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';
import Component from 'vue-class-component';

@Component({})
export default class ScenarioStepNavigation extends Vue {
    protected async pushToScenarioOverview(): Promise<void> {
        await this.$router.push({
            name: 'SetupOverview',
        });
    }

    protected async pushToStep(step: SCENARIO_FORM_STEP, scenarioId?: number): Promise<void> {
        const params = scenarioId ? { id: scenarioId.toString() } : undefined;

        try {
            await this.$router.push({
                name: SCENARIO_FORM_STEP_ROUTE_MAP[step],
                params,
            });
        } catch {
            console.debug('Avoided redundant navigation.');
        }
    }

    protected async replaceToStep(step: SCENARIO_FORM_STEP, scenarioId?: number): Promise<void> {
        const params = scenarioId ? { id: scenarioId.toString() } : undefined;

        try {
            await this.$router.replace({
                name: SCENARIO_FORM_STEP_ROUTE_MAP[step],
                params,
            });
        } catch {
            console.debug('Avoided redundant navigation.');
        }
    }
}
