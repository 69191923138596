var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pk-step"
  }, [_c('pui-loader', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isSaving,
      expression: "isSaving"
    }],
    attrs: {
      "promise": _vm.infinitePromise,
      "title": _vm.$t('setup.scenario.primaryKpiStep.loader.savingChanges.title'),
      "message": _vm.$t('setup.scenario.primaryKpiStep.loader.savingChanges.message')
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSaving,
      expression: "!isSaving"
    }]
  }, [_c('summary-card', {
    on: {
      "on-handle-saving": _vm.handleSaving
    }
  }), _c('primary-kpi-table', {
    ref: _vm.PRIMARY_KPI_TABLE_REF
  })], 1), _c('div', {
    staticClass: "pk-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isSaving,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isSaving,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isSaving,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.complete')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }