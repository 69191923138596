var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "setup-create-scenario"
  }, [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onActiveRouteChanged
    }
  }), _c('div', {
    staticClass: "setup-create-scenario__card"
  }, [!_vm.isCurrentLoaderStep ? [_c('div', {
    staticClass: "setup-create-scenario__card__step-display"
  }, [_c('step-display', {
    attrs: {
      "steps": _vm.steps
    }
  })], 1), _c('div', {
    staticClass: "setup-create-scenario__card__separator"
  })] : _vm._e(), _c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [_c('router-view')], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }