<template>
    <div
        class="tooltip"
        role="tooltip"
    >
        <div class="tooltip-inner">
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.ceForm.tooltips.nameLocalLanguage', { value: params.data?.name ?? '-' }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.ceForm.tooltips.forecast', { value: params.data?.forecast ?? '-' }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.ceForm.tooltips.new1', { value: params.data?.new1 ?? '-' }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.ceForm.tooltips.new2', { value: params.data?.new2 ?? '-' }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.ceForm.tooltips.new3', { value: params.data?.new3 ?? '-' }) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ITooltipParams } from 'ag-grid-community';

@Component({})
export default class CostsTooltip extends Vue {
    private params?: ITooltipParams;
}
</script>

<style scoped lang="scss">
.tooltip {
    .tooltip-inner {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        &__text {
            color: $white;
            font-size: 1.4rem;
        }
    }
}
</style>
