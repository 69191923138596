<template>
    <div class="dm-table">
        <div class="dm-table__table">
            <div class="dm-table__table__extra-header">
                <pui-headline
                    class="dm-table__table__extra-header__title"
                    type="h6"
                >
                    {{ title }}
                </pui-headline>
                <pui-button
                    v-if="selectedMessage === BM_LOG_LEVEL.ERRORS"
                    class="dm-table__table__extra-header__button"
                    icon="check"
                    state="primary"
                    small
                    :disabled="isMarkingDisabled"
                    @click="markAsAcceptableErrors"
                >
                    {{ $t('engineRun.summary.summaryTable.actions.markAsacceptableErrors') }}
                </pui-button>
                <pui-toggle
                    v-show="showIsAcceptableColumn"
                    @change="handleShowOnlyAcceptableErrors"
                >
                    <template #puiToggleRight>
                        {{ $t('engineRun.summary.summaryTable.actions.showOnlyAcceptableErrors') }}
                    </template>
                </pui-toggle>
            </div>
            <table-header
                :search-term="tableExtraFilters.searchTerm"
                :page-size="tableExtraFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
                @search-triggered="onTriggerSearch"
                @clear-search-term="onClearSearchTerm"
            />
            <pui-loader
                v-if="showLoadingOverlay"
                :promise="infinitePromise"
                :title="$t('engineRun.summary.summaryTable.loader.title')"
                :message="$t('engineRun.summary.summaryTable.loader.message')"
            />
            <ag-grid-vue
                v-show="!showLoadingOverlay"
                class="ag-theme-alpine"
                :grid-options="gridOptions"
                @grid-ready="onGridReady"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, ITooltipParams, ModelUpdatedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { EngineRunServiceProxy, IEngineLogDto, IMarkErrorRequest } from '@/service-proxies/service-proxies.g';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import BadgeCell from '@/components/benchmarking-table/cell-types/badge-cell.vue';
import ERUnitsListCell from '@/components/engine-run-summary/engine-run-summary-table/er-units-list-cell.vue';
import NoRowsOverlay from '@/components/benchmarking-table/overlay-types/no-rows-overlay.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import { ACCEPTABLE_COLOR, BM_LOG_LEVEL, BM_LOG_LEVEL_DETAILS, BM_LOG_LEVEL_ID } from '@/config/log-level';
import { KpiAnalysisLightboxPayload } from '@/models/kpi-analysis/kpi-analysis-lightbox';

@Component({
    components: {
        AgGridVue,
        TableHeader,
        BadgeCell,
        ERUnitsListCell,
        NoRowsOverlay,
        SimpleTooltip,
    }
})
export default class EngineRunSummaryTableComponent extends Vue {
    private engineRunService = new EngineRunServiceProxy();
    private gridApi: GridApi<IEngineLogDto> | null = null;
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
        showOnlyAcceptableErrors: false,
    }

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
    private showLoadingOverlay = false;

    private selectedMessageLogIds: number[] = [];
    private isMarkingInProgress = false;
    private BM_LOG_LEVEL = BM_LOG_LEVEL;

    /**
     * This prop is required to know the selected status.
     */
    @Prop()
    private selectedMessage!: BM_LOG_LEVEL | null;

    private gridOptions: GridOptions<IEngineLogDto> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                this.handleLoadingOverlay(true);
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;
                const messageLevel = this.selectedMessageLevelId(this.selectedMessage);

                const payload = {
                    scenarioId: this.scenarioId,
                    pageNumber,
                    pageSize,
                    searchTerm,
                    messageLevel,
                }

                this.engineRunService.viewDetails(payload.scenarioId, payload.messageLevel, payload.pageNumber, payload.pageSize, payload.searchTerm, undefined)
                    .then((response) => {
                        params.success({ rowData: response.result.items ?? [], rowCount: response.result.count });
                        if (response.result.count === 0) {
                            params.api.showNoRowsOverlay();
                        }
                    })
                    .catch(() => params.fail())
                    .finally(() => {
                        this.handleLoadingOverlay(false);
                    });
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: '',
                field: 'markAsAcceptable',
                checkboxSelection: (params): boolean => params.node?.level === 0,
                valueFormatter: (): string => '',
                minWidth: 50,
                maxWidth: 50,
                pinned: 'left',
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.logLevel'),
                field: 'logLevel',
                cellRenderer: 'BadgeCell',
                cellRendererParams: (params: ICellRendererParams): { text: string, backgroundColor: string } => ({
                    text: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL_ID[params.value]].NAME),
                    backgroundColor: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL_ID[params.value]].COLOR,
                }),
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.message'),
                field: 'message',
                resizable: true,
                minWidth: 400,
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.kpiId'),
                field: 'kpiId',
                valueFormatter: (params): string => params.value ?? '-',
                tooltipField: 'kpiId',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<IEngineLogDto>): { show: boolean, content?: string } => ({
                    show: !!params.data?.kpiDescription,
                    content: params.data?.kpiDescription,
                }),
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.year'),
                field: 'year',
                valueFormatter: (params): string => params.value && params.value !== 0 ? params.value : '-',
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.units'),
                field: 'units',
                cellRenderer: 'ERUnitsListCell',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: this.$t('engineRun.summary.summaryTable.headers.isAcceptable'),
                field: 'isAcceptable',
                valueFormatter: (params): string => params.value ? this.$t('engineRun.summary.summaryTable.cell.acceptableError') : '',
                cellStyle: { color: ACCEPTABLE_COLOR },
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: false,
            menuTabs: [],
            minWidth: 150,
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        pagination: true,
        paginateChildRows: false,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        noRowsOverlayComponent: 'NoRowsOverlay',
        getRowId: (params) => params.data?.id.toString() ?? '',
        onSelectionChanged: this.onSelectionChanged,
        onModelUpdated: this.onModelUpdated,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        context: {
            handleUnitClick: this.handleUnitClick,
        }
    }

    @Watch('selectedMessage')
    onSelectedMessageChanged(): void {
        this.handleColumnVisibility();
        this.gridApi?.sizeColumnsToFit();
        this.refreshTable();
    }

    private handleColumnVisibility(): void {
        this.gridOptions.columnApi?.setColumnVisible('markAsAcceptable', this.selectedMessage === BM_LOG_LEVEL.ERRORS);
        this.gridOptions.columnApi?.setColumnVisible('isAcceptable', this.showIsAcceptableColumn);
    }

    private get showIsAcceptableColumn(): boolean {
        return !(this.selectedMessage && this.selectedMessage !== BM_LOG_LEVEL.TOTAL);
    }

    private get scenarioId(): number {
        return this.$store.getters['engineRun/summary/getScenario'].id;
    }

    private get isMarkingDisabled(): boolean {
        return this.isMarkingInProgress || !this.selectedMessageLogIds.length;
    }

    private get title(): string {
        return this.selectedMessage
            ? this.$t(`engineRun.summary.summaryTable.title.${this.selectedMessage}`)
            : this.$t('engineRun.summary.summaryTable.title.total');
    }

    private handleUnitClick(payload: { kpiId: string, year: number, unitSid: number, unitUi: string }): void {
        const lightboxPayload: KpiAnalysisLightboxPayload = {
            scenarioId: this.scenarioId,
            kpiId: payload.kpiId,
            year: payload.year,
            unitSid: payload.unitSid,
            unitUi: payload.unitUi,
        };

        this.$emit('open:kpi-analysis-lightbox', lightboxPayload);
    }

    private handleLoadingOverlay(value: boolean): void {
        if (value) {
            this.gridApi?.hideOverlay();
        }
        this.showLoadingOverlay = value;
    }

    private showNoRowsOverlay(): void {
        this.gridApi?.showNoRowsOverlay();
    }

    private onGridReady(params: AgGridCommon<IEngineLogDto>): void {
        this.gridApi = params.api;
        this.handleColumnVisibility();
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private handleShowOnlyAcceptableErrors(value: boolean): void {
        this.tableExtraFilters.showOnlyAcceptableErrors = value;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private selectedMessageLevelId(messageLevel: BM_LOG_LEVEL | null): number[] | undefined {
        const messageLogKey: Record<BM_LOG_LEVEL, number[] | undefined> = {
            [BM_LOG_LEVEL.TOTAL]: undefined,
            [BM_LOG_LEVEL.WARNINGS]: [1],
            [BM_LOG_LEVEL.ERRORS]: [2],
            [BM_LOG_LEVEL.COMPLETED]: [3],
            [BM_LOG_LEVEL.DEBUG]: [4],
            [BM_LOG_LEVEL.ACCEPTABLE]: [5],
        };

        if ((messageLevel === BM_LOG_LEVEL.TOTAL || messageLevel === null) && this.tableExtraFilters.showOnlyAcceptableErrors) {
            return messageLogKey[BM_LOG_LEVEL.ACCEPTABLE];
        }

        return messageLevel ? messageLogKey[messageLevel] : undefined;
    }

    private selectedMessageColor(messageLevel: number | undefined): string | undefined {
        const messageColor: Record<number, string> = {
            1: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.WARNINGS].COLOR,
            2: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.ERRORS].COLOR,
            3: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.COMPLETED].COLOR,
            4: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.DEBUG].COLOR,
            5: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.ACCEPTABLE].COLOR,
        };

        return messageLevel ? messageColor[messageLevel] : undefined;
    }

    private onSelectionChanged(event: SelectionChangedEvent<IEngineLogDto>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.isSelected()) {
                if (node.data.id && !this.selectedMessageLogIds.includes(node.data.id)) {
                    this.selectedMessageLogIds = [...this.selectedMessageLogIds, node.data.id];
                }
            } else {
                this.selectedMessageLogIds = this.selectedMessageLogIds.filter(e => e !== node.data?.id);
            }
        });
    }

    private onModelUpdated(event: ModelUpdatedEvent<IEngineLogDto>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.data.id && this.selectedMessageLogIds.includes(node.data.id)) {
                node.setSelected(true, false, true);
            } else {
                node.setSelected(false, false, true);
            }
        });
    }

    private async markAsAcceptableErrors(): Promise<void> {
        if (this.selectedMessageLogIds.length === 0) {
            return;
        }

        this.isMarkingInProgress = true;

        const payload: IMarkErrorRequest = {
            errors: this.selectedMessageLogIds,
        }

        try {
            await this.$store.dispatch('engineRun/summary/markErrorsAsAcceptable', payload);
            this.selectedMessageLogIds = [];
            this.$pui.toast({
                type: 'success',
                title: this.$t('engineRun.summary.summaryTable.toastMessage.markSuccess.title'),
                copy: this.$t('engineRun.summary.summaryTable.toastMessage.markSuccess.copy'),
            });
            this.$emit('reload-summary');
            this.refreshTable();
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('engineRun.summary.summaryTable.toastMessage.markFail.title'),
                copy: this.$t('engineRun.summary.summaryTable.toastMessage.markFail.copy'),
            });
        } finally {
            this.isMarkingInProgress = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.dm-table {
  @include rem(margin-top, pui-spacing(m));

  &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 3rem;

      &__text {
          font-size: 1.4rem;
          font-weight: bold;
          color: $dark-blue-lighter;
      }
  }

  &__table {
      width: 100%;
      background-color: $white;
      @include pui-box();
      @include rem(margin, pui-spacing(l) 0);

      &__extra-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $warm-grey-25;
          @include rem(padding, pui-spacing(xs) pui-spacing(s));
          @include rem(min-height, 45px);

          &__title {
              color: $dark-grey;
              font-weight: bold;
          }

          &__button {
              @include rem(padding, 0);
          }

      }
  }
}
</style>
