var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-col profile-container"
  }, [_c('div', {
    staticClass: "profile-details"
  }, [_c('div', {
    staticClass: "pb-1"
  }, [_c('button', {
    staticClass: "copy-token-button left-align",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.copyToken();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('copyToken')) + " "), _c('font-awesome-icon', {
    attrs: {
      "icon": "copy"
    }
  })], 1)]), _c('div', {
    staticClass: "pb-1"
  }, [_c('a', {
    staticClass: "swagger-link",
    attrs: {
      "href": _vm.swaggerURL,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('openSwagger')) + " ")])]), _c('div', {
    staticClass: "flex-col flex-3 pb-1"
  }, [_c('div', {
    staticClass: "version"
  }, [_vm._v(" " + _vm._s(_vm.$t('version')) + " " + _vm._s(_vm.version) + " ")]), _c('div', {
    staticClass: "version"
  }, [_vm._v(" " + _vm._s(_vm.$t('apiUrl')) + "  "), _c('a', {
    staticClass: "swagger-link",
    attrs: {
      "href": _vm.apiUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.apiUrl) + " ")])])]), _c('div', {
    staticClass: "send-feedback-wrapper"
  }, [_c('button', {
    staticClass: "send-feedback-wrapper__button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.sendToFeedbackForm();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sendFeedback')) + " ")])]), _c('div', {
    staticClass: "report-incident-wrapper"
  }, [_c('button', {
    staticClass: "report-incident-wrapper__button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.sendToIncidentReporting();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reportIncident')) + " ")])]), _c('div', [_c('button', {
    staticClass: "logout-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.logOut();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('signOut')) + " ")])])]), _c('input', {
    staticClass: "copy-text",
    attrs: {
      "type": "text",
      "id": "copyText"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }