var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "engine-run-running-card"
  }, [_c('div', {
    staticClass: "engine-run-running-card__indicator"
  }, [_c('pui-headline', {
    staticClass: "engine-run-running-card__indicator__text",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.engineRunning')) + " ")]), _c('loading-bar', {
    staticClass: "engine-run-running-card__indicator__loading-bar"
  })], 1), _c('div', {
    staticClass: "engine-run-running-card__horizontal-separator"
  }), _c('div', {
    staticClass: "engine-run-running-card__actions"
  }, [_c('div', {
    staticClass: "engine-run-running-card__actions__button"
  }, [_c('pui-button', {
    attrs: {
      "disabled": !_vm.isEngineRunStoppable,
      "state": "secondary"
    },
    on: {
      "click": _vm.stopEngineRunClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.engineRunCount === 1 ? _vm.$t('engineRun.engineControl.stopEngineSingle') : _vm.$t('engineRun.engineControl.stopEngineMultiple')) + " ")])], 1), _vm.engineRunCount ? [_c('div', {
    staticClass: "engine-run-running-card__actions__vertical-separator"
  }), _c('div', {
    staticClass: "engine-run-running-card__actions__text-content"
  }, [_c('pui-headline', {
    staticClass: "engine-run-running-card__actions__text-content__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.numberOfEngines')) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-running-card__actions__text-content__text",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.engineRunCount) + " ")])], 1)] : _vm._e(), _vm.latestStartedAt ? [_c('div', {
    staticClass: "engine-run-running-card__actions__vertical-separator"
  }), _c('div', {
    staticClass: "engine-run-running-card__actions__text-content"
  }, [_c('pui-headline', {
    staticClass: "engine-run-running-card__actions__text-content__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.started')) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-running-card__actions__text-content__text",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.latestStartedAt)) + " ")])], 1)] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }