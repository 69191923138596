var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "formula-step"
  }, [_c('pui-tabs', {
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.formulaStep.tabs.primaryKpi')
    }
  }), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.formulaStep.tabs.secondaryKpi')
    }
  })], 1), _c('primary-kpi-tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.FORMULA_STEP_TAB.PRIMARY_KPI,
      expression: "selectedTabIndex === FORMULA_STEP_TAB.PRIMARY_KPI"
    }]
  }), _c('secondary-kpi-tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.FORMULA_STEP_TAB.SECONDARY_KPI,
      expression: "selectedTabIndex === FORMULA_STEP_TAB.SECONDARY_KPI"
    }],
    ref: _vm.SECONDARY_KPI_REF
  }), _c('div', {
    staticClass: "formula-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isSecondaryKpiSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isSecondaryKpiSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isSecondaryKpiSubmitting,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.next')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }