var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "loader-container"
  }, [_c('div', {
    staticClass: "loader-backdrop"
  }), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loader center-align"
  }, [_c('div', {
    staticClass: "bar-container"
  }, [_c('div', {
    staticClass: "bar"
  })])]);

}]

export { render, staticRenderFns }