var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showButtons ? _c('div', {
    staticClass: "fc-actions-cell-wrapper"
  }, [_c('pui-button', {
    attrs: {
      "icon": "edit",
      "state": "secondary",
      "small": true
    },
    on: {
      "click": _vm.openLightbox
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.buttons.edit')) + " ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }