<template>
    <pui-loader :promise="initialPromise">
        <div class="cc-table">
            <table-header
                :hide-search-bar="true"
                :page-size="tableHeaderFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
            />
            <ag-grid-vue
                :grid-options="gridOptions"
                class="ag-theme-alpine"
            />
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideGetRowsParams, ITooltipParams,
    SetFilterValuesFuncParams, ValueFormatterParams
} from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import {
    CostCoefficientEditDto,
    CostCoefficientRowDto, EditCostCoefficientRequest,
    ICostCoefficientEditDto
} from '@/service-proxies/service-proxies.g';
import { FetchPaginationPartial } from '@/models/steps/common';
import { FetchCostCoefficientsFilters, FetchCostCoefficientsPayload } from '@/models/steps/mtc';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';
import { formatNumberForTableDisplay } from '@/utils/formatters';

type CostCoefficientsRow = {
    kpiId: string,
    kpiDescription: string,
    plantName: string,
    unitName: string,
    countryName: string,
    technology: string,
    [key: string]: any,
};

@Component({
    components: {
        AgGridVue,
        TableHeader,
        SimpleTooltip,
    }
})
export default class CostCoefficientsTable extends Vue {
    private readonly COLUMN_KEYS = {
        KPI_ID: 'kpiId',
        PLANT_NAME: 'plantName',
        UNIT_NAME: 'unitName',
        COUNTRY_NAME: 'countryName',
        TECHNOLOGY: 'technology',
    } as const;

    private tableHeaderFilters = {
        pageSize: 20,
    };

    private gridApi: GridApi<CostCoefficientsRow> | null = null;
    private initialPromise: Promise<any> | null = Promise.resolve();

    private isYearDataLoaded = false;
    private yearKeys: string[] = [];
    private modifiedValues = new Map<number, ICostCoefficientEditDto>();

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get totalCount(): number {
        return this.$store.getters['scenario/mtcStep/getCostCoefficientsTotalCount'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private gridOptions: GridOptions<CostCoefficientsRow> = {
        rowModelType: 'serverSide',
        domLayout: 'autoHeight',
        serverSideDatasource: {
            getRows: (params): void => {
                const paginationParams = this.getPaginationForServerRequestParams(params);
                const filterParams = this.getSelectedFiltersForServerRequestParams(params);

                const payload: FetchCostCoefficientsPayload = {
                    scenarioId: this.scenarioId,
                    ...paginationParams,
                    ...filterParams,
                };

                this.$store.dispatch('scenario/mtcStep/fetchCostCoefficientsValues', payload)
                    .then((costCoefficientValues: CostCoefficientRowDto[]) => {
                        const rowData: CostCoefficientsRow[] = [];
                        const years = new Set<number>();

                        costCoefficientValues.forEach(value => {
                            const row: CostCoefficientsRow = {
                                kpiId: value.kpiId ?? '',
                                kpiDescription: value.kpiDescription ?? '',
                                plantName: value.plantName ?? '',
                                unitName: value.unitName ?? '',
                                countryName: value.country ?? '',
                                technology: value.technology ?? ''
                            };

                            value.years?.forEach(yearDto => {
                                years.add(yearDto.year);
                                row[yearDto.year.toString()] = yearDto.costValue ?? 0;
                                row[this.computeIdKeyForYear(yearDto.year)] = yearDto.id;
                            });

                            rowData.push(row);
                        });

                        this.configureYearColumns(years);

                        params.success({
                            rowData,
                            rowCount: this.totalCount,
                        });
                    })
                    .catch(() => params.fail());
            },
        },
        columnDefs: [
            {
                field: this.COLUMN_KEYS.KPI_ID,
                headerName: this.$t('setup.scenario.mtcForm.tables.cc.headers.kpiId'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.mtcKpiIds);
                    },
                },
                tooltipField: 'kpiDescription',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<CostCoefficientsRow>): { content?: string } => ({
                    content: params.data?.kpiDescription,
                }),
                pinned: 'left',
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.PLANT_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.cc.headers.plantName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.plants.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.plants.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.UNIT_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.cc.headers.unitName'),
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.cc.headers.countryName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.countries.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const countrySid = Number(params.value);
                        return this.scenarioFilters.countries.find(e => e.sid === countrySid)?.name ?? this.$t('unknown');
                    },
                },
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.TECHNOLOGY,
                headerName: this.$t('setup.scenario.mtcForm.tables.cc.headers.technology'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.technologies.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const technologySid = Number(params.value);
                        return this.scenarioFilters.technologies.find(e => e.sid === technologySid)?.name ?? this.$t('unknown');
                    },
                },
                resizable: true,
            },
        ],
        defaultColDef: {
            menuTabs: [],
            cellClass: 'is-non-editable-cell',
        },
        serverSideInfiniteScroll: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onModelUpdated: (event) => {
            event.api.forEachNode(node => {
                this.applyLocalChangesToRowId(node.id ?? '');
            });
        },
        onGridReady: this.onGridReady,
    };

    private onGridReady(event: GridReadyEvent<CostCoefficientsRow>): void {
        event.api.sizeColumnsToFit();

        this.gridApi = event.api;
    }

    private configureYearColumns(years: Set<number>): void {
        if (this.isYearDataLoaded || !this.gridApi) {
            return;
        }

        const columnDefinitions = this.gridApi.getColumnDefs() ?? [];

        years.forEach(year => {
            this.yearKeys.push(year.toString());

            columnDefinitions.push({
                field: year.toString(),
                headerName: year.toString(),
                editable: true,
                cellClass: '',
                cellClassRules: {
                    'is-different-cell': (params): boolean => {
                        const currentYear = Number(params.column.getColId());
                        const previousYear = currentYear - 1;

                        if (!params.data || !params.data[previousYear.toString()]) {
                            return false;
                        }

                        return params.data[previousYear.toString()] !== params.data[currentYear.toString()];
                    },
                },
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue.toString() || !params.data) {
                        return false;
                    }

                    const costValue = this.parseCoefficientValue(params.newValue);
                    const year = Number(params.column.getColId());
                    const id = Number(params.data[this.computeIdKeyForYear(year)]);

                    // Update modified value in this.modifiedValues
                    const costCoefficientEditDto: ICostCoefficientEditDto = { id, costValue };
                    this.modifiedValues.set(id, costCoefficientEditDto);

                    // Update modified value in table data
                    params.data[params.column.getColId()] = costValue;
                    return true;
                },
                valueFormatter: (params): string => {
                    const value: number = params.data?.[params.column.getColId()] ?? '';
                    return formatNumberForTableDisplay(value);
                },
            });
        });

        this.isYearDataLoaded = true;
        this.gridApi.setColumnDefs(columnDefinitions);
        this.gridApi.sizeColumnsToFit();
    }

    public async submitForm(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        if (this.modifiedValues.size === 0 && saveAsDraft) {
            return true;
        }

        const costCoefficients: CostCoefficientEditDto[] = [];
        this.modifiedValues.forEach(value => costCoefficients.push(new CostCoefficientEditDto(value)));

        const editCostCoefficientsPayload = new EditCostCoefficientRequest({
            scenarioId: this.scenarioId,
            isSaveAsDraft: saveAsDraft,
            costCoefficients,
        });

        try {
            await this.$store.dispatch('scenario/mtcStep/saveCostCoefficients', editCostCoefficientsPayload);
            this.modifiedValues.clear();
            this.refreshData();

            if (automaticSubmit) {
                this.$pui.toast({
                    type: 'success',
                    title: this.$t('setup.scenario.mtcForm.toastMessages.automaticSubmitMcy.title'),
                    copy: this.$t('setup.scenario.mtcForm.toastMessages.automaticSubmitMcy.copy'),
                });
            } else {
                await this.$store.dispatch('scenario/mtcStep/clearMaintenanceCycle');
            }
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });

            return false;
        }

        return true;
    }

    private refreshData(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private computeIdKeyForYear(year: number): string {
        return `${year}_ID`;
    }

    private parseCoefficientValue(value: string): number {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? 0 : parsedValue;
    }

    private applyLocalChangesToRowId(rowId: string): void {
        const node = this.gridApi?.getRowNode(rowId);

        if (!node || !node.data) {
            return;
        }

        const data: CostCoefficientsRow = node.data;

        this.yearKeys.forEach(yearKey => {
            const yearIdKey = this.computeIdKeyForYear(Number(yearKey));

            if (!data[yearIdKey] || !this.modifiedValues.has(data[yearIdKey])) {
                return;
            }

            // Non-null guaranteed by previous Map#has() call.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const modifiedValue = this.modifiedValues.get(data[yearIdKey])!;
            node.setDataValue(yearKey, modifiedValue.costValue);
        });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private getPaginationForServerRequestParams(params: IServerSideGetRowsParams): FetchPaginationPartial {
        return {
            pageNumber: Math.floor((params.request.endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize),
            pageSize: this.tableHeaderFilters.pageSize,
        };
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): FetchCostCoefficientsFilters {
        return {
            kpiIds: params.request.filterModel?.[this.COLUMN_KEYS.KPI_ID]?.values,
            plants: params.request.filterModel?.[this.COLUMN_KEYS.PLANT_NAME]?.values?.map((e: string) => Number(e)),
            countries: params.request.filterModel?.[this.COLUMN_KEYS.COUNTRY_NAME]?.values?.map((e: string) => Number(e)),
            technologies: params.request.filterModel?.[this.COLUMN_KEYS.TECHNOLOGY]?.values?.map((e: string) => Number(e)),
        };
    }
}
</script>

<style scoped lang="scss">
.cc-table {
    width: 100%;
    @include pui-box();

    ::v-deep .is-different-cell {
        color: red;
    }

    ::v-deep .is-non-editable-cell {
        background-color: $non-editable-cell-color;
    }
}
</style>
