var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "pui-on-click-away",
      rawName: "v-pui-on-click-away",
      value: {
        callback: _vm.onClickOutside
      },
      expression: "{ callback: onClickOutside }"
    }],
    staticClass: "bm-type-ahead",
    class: ['bm-type-ahead', {
      'bm-type-ahead--disabled': _vm.isDisabled
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputString,
      expression: "inputString"
    }],
    staticClass: "bm-type-ahead__input",
    attrs: {
      "placeholder": _vm.placeholder,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "value": _vm.inputString
    },
    on: {
      "focusin": _vm.onInputFocusIn,
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.inputString = $event.target.value;
      }, _vm.onInputChanged]
    }
  }), _vm.isLoading ? _c('pui-loading', {
    staticClass: "bm-type-ahead__loading-icon",
    attrs: {
      "inline": true,
      "request-completed": false,
      "icon-size": "2.4rem"
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowMessageBox,
      expression: "shouldShowMessageBox"
    }],
    staticClass: "bm-type-ahead__message"
  }, [_vm._v(" " + _vm._s(_vm.messageBoxText) + " ")]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowResults,
      expression: "shouldShowResults"
    }],
    staticClass: "bm-type-ahead__results"
  }, _vm._l(_vm.options, function (option, index) {
    var _vm$value;
    return _c('li', {
      key: index,
      class: ['bm-type-ahead__results__item', {
        'bm-type-ahead__results__item--selected': option.value === ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.value)
      }],
      on: {
        "click": function ($event) {
          return _vm.onOptionClick(option);
        }
      }
    }, [_c('div', {
      staticClass: "bm-type-ahead__results__item__label"
    }, [_vm._v(" " + _vm._s(option.label) + " ")]), option.secondaryLabel ? _c('div', {
      staticClass: "bm-type-ahead__results__item__label--secondary"
    }, [_vm._v(" " + _vm._s(option.secondaryLabel) + " ")]) : _vm._e()]);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }