<template>
    <div />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { IGetScenarioResponse } from '@/service-proxies/service-proxies.g';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';
import { SCENARIO_FORM_STEP } from '@/config/steps';

@Component({})
export default class InitialLoaderScenarioStep extends mixins(ScenarioStepNavigation) {
    private get scenario(): IGetScenarioResponse | undefined {
        return this.$store.getters['scenario/getScenario'];
    }

    private mounted(): void {
        if (this.scenario) {
            this.replaceToStep(this.scenario.step);
        } else {
            this.replaceToStep(SCENARIO_FORM_STEP.SETUP);
        }
    }
}
</script>

<style lang="scss" scoped>
.initial-loader-step {
    @include rem(padding, pui-spacing(l));
}
</style>
