var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [_c('div', {
    staticClass: "fc-form"
  }, [_c('flagged-costs-form-overview', {
    attrs: {
      "budget-request": _vm.budgetRequest,
      "cost-modifications": _vm.costModifications
    }
  }), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 11,
      expression: "11"
    }],
    attrs: {
      "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.justification'),
      "has-label-padding": false
    }
  }, [_c('pui-form-textarea', {
    model: {
      value: _vm.justification,
      callback: function ($$v) {
        _vm.justification = $$v;
      },
      expression: "justification"
    }
  })], 1), _c('flagged-costs-form-exclusions', {
    ref: _vm.REF_CONSTANTS.EXCLUSIONS_SECTION,
    attrs: {
      "budget-request": _vm.budgetRequest,
      "cost-modifications": _vm.costModifications
    },
    on: {
      "change:form-modification": _vm.handleFormModificationChange,
      "remove:form-modification": _vm.handleFormModificationRemove
    }
  }), _c('flagged-costs-form-corrections', {
    ref: _vm.REF_CONSTANTS.CORRECTIONS_SECTION,
    attrs: {
      "budget-request": _vm.budgetRequest,
      "cost-modifications": _vm.costModifications
    },
    on: {
      "change:form-modification": _vm.handleFormModificationChange,
      "remove:form-modification": _vm.handleFormModificationRemove
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }