export enum SCENARIO_FORM_STEP {
    INITIAL_LOADER = 0,
    SETUP = 1,
    UNIT = 2,
    FORMULA = 3,
    INFLATION = 4,
    MTC = 5,
    COST_EXCLUSION = 6,
    PRIMARY_KPI = 7,
    CONFIRMATION = 8
}

export const SCENARIO_FORM_STEP_ROUTE_MAP = {
    [SCENARIO_FORM_STEP.INITIAL_LOADER]: 'SetupCreateScenario_InitialLoader',
    [SCENARIO_FORM_STEP.SETUP]: 'SetupCreateScenario_Step_Setup',
    [SCENARIO_FORM_STEP.UNIT]: 'SetupCreateScenario_Step_Unit',
    [SCENARIO_FORM_STEP.FORMULA]: 'SetupCreateScenario_Step_Formula',
    [SCENARIO_FORM_STEP.INFLATION]: 'SetupCreateScenario_Step_Inflation',
    [SCENARIO_FORM_STEP.MTC]: 'SetupCreateScenario_Step_Mtc',
    [SCENARIO_FORM_STEP.PRIMARY_KPI]: 'SetupCreateScenario_Step_PrimaryKpi',
    [SCENARIO_FORM_STEP.COST_EXCLUSION]: 'SetupCreateScenario_Step_CostExclusion',
    [SCENARIO_FORM_STEP.CONFIRMATION]: 'SetupCreateScenario_Confirmation',
} as const;
