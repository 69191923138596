import { Module } from 'vuex';
import { AddKpiRequest, AddKpisToScenarioRequest, EditKpiRequest, ISummaryResponse, KpiResponseDto, KpiServiceProxy } from '@/service-proxies/service-proxies.g';

const kpiServiceProxy = new KpiServiceProxy();

const FormulaStepModule: Module<any, any> = {
    state: {},

    mutations: {},

    actions: {
        async fetchKpiList(context, { searchQuery, scenarioId }: { searchQuery: string; scenarioId: number; }): Promise<KpiResponseDto[]> {
            const response = await kpiServiceProxy.list4(scenarioId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, searchQuery, undefined);
            return response.result.items ?? [];
        },
        async addKpi(context, payload: AddKpiRequest): Promise<void> {
            await kpiServiceProxy.add2(payload);
        },
        async editKpi(context, payload: EditKpiRequest): Promise<void> {
            await kpiServiceProxy.update2(payload);
        },
        async deleteKpi(context, { kpiNumber, kpiId, scenarioId }): Promise<void> {
            await kpiServiceProxy.delete(kpiNumber, kpiId, scenarioId);
        },
        async bulkSaveKpiActive(context, payload: AddKpisToScenarioRequest): Promise<void> {
            await kpiServiceProxy.addActiveKpisToScenario(payload);
        },
        async fetchSummary(context, { scenarioId, isPrimary}: { scenarioId: number; isPrimary: boolean }): Promise<ISummaryResponse> {
            const response = await kpiServiceProxy.summary2(scenarioId, isPrimary);
            return response.result;
        },
        async importScenario({ dispatch }, { currentScenarioId , previousScenarioId }: { currentScenarioId : number; previousScenarioId : number }): Promise<void> {
            await kpiServiceProxy.importKpiFromScenario(currentScenarioId, previousScenarioId);
            await dispatch('scenario/fetchScenario', {
                id: currentScenarioId,
                isDuplicating: false,
            }, { root: true });
        }
    },

    getters: {},

    namespaced: true,
};

export default FormulaStepModule;
