<template>
    <div class="confirmation-lightbox-wrapper">
        <pui-lightbox
            :ref="REF_CONSTANTS.LIGHTBOX"
            :default-header-label="$t('dataManagement.overview.scenarioTable.confirmation.title')"
            :default-footer-confirm-label="$t('dataManagement.overview.scenarioTable.actions.delete')"
            :default-footer-cancel-label="$t('dataManagement.overview.scenarioTable.actions.cancel')"
            :on-confirm-callback="emitConfirm"
            :close-on-e-s-c="false"
            fit-content
            @puilightbox:close="emitClose"
        >
            <div class="confirmation-lightbox-wrapper__content">
                <div class="confirmation-lightbox-wrapper__content__title">
                    {{ $t('dataManagement.overview.scenarioTable.confirmation.message') }}
                </div>

                <div class="confirmation-lightbox-wrapper__content__list">
                    {{ list }}
                </div>
            </div>
        </pui-lightbox>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';

const REF_CONSTANTS = {
    LIGHTBOX: 'confirmation-lightbox',
} as const;

@Component({})
export default class ConfirmationLightboxComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    @Prop()
    private list!: string;

    @Prop()
    private lightboxOpen!: boolean;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }
    
    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(): void {
        this.$emit('close');
    }

    private emitConfirm(): void {
        this.$emit('confirm');
    }
}
</script>

<style lang="scss" scoped>
.confirmation-lightbox-wrapper {
    &__content {
        @include rem(padding, pui-spacing(s) 0);

        &__title {
            font-weight: bold;
        }

        &__list {
            @include rem(line-height, 2rem);
            @include rem(padding-top, pui-spacing(xxs));
        }
    }
}
</style>
