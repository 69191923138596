<template>
    <div
        class="bm-type-ahead"
        :class="['bm-type-ahead', { 'bm-type-ahead--disabled': isDisabled }]"
        v-pui-on-click-away="{ callback: onClickOutside }"
    >
        <input
            v-model="inputString"
            :placeholder="placeholder"
            :disabled="isDisabled"
            class="bm-type-ahead__input"
            @focusin="onInputFocusIn"
            @input="onInputChanged"
        >
        <pui-loading
            v-if="isLoading"
            :inline="true"
            :request-completed="false"
            icon-size="2.4rem"
            class="bm-type-ahead__loading-icon"
        />
        <div
            v-show="shouldShowMessageBox"
            class="bm-type-ahead__message"
        >
            {{ messageBoxText }}
        </div>
        <ul
            v-show="shouldShowResults"
            class="bm-type-ahead__results"
        >
            <li
                v-for="(option, index) in options"
                :key="index"
                :class="[
                    'bm-type-ahead__results__item',
                    {
                        'bm-type-ahead__results__item--selected': option.value === value?.value,
                    }
                ]"
                @click="onOptionClick(option)"
            >
                <div class="bm-type-ahead__results__item__label">
                    {{ option.label }}
                </div>
                <div
                    v-if="option.secondaryLabel"
                    class="bm-type-ahead__results__item__label--secondary"
                >
                    {{ option.secondaryLabel }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PuiSelectOption } from '@/models/pebble-ui';
import { mixins } from 'vue-class-component';
import { Debouncer } from '@/mixins/debouncer';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';

type SelectOption = PuiSelectOption<any>;

type RequestPromiseGenerator = (query: string, signal: AbortSignal) => Promise<SelectOption[]>;

@Component({})
export default class BenchmarkingTypeAhead extends mixins(Debouncer, ManagedAbortControllers) {
    @Prop({ default: () => '' })
    private placeholder!: string;

    @Prop()
    private requestPromiseGenerator?: RequestPromiseGenerator;

    @Prop({ default: () => 1000 })
    private debounceTimeout!: number;

    @Prop()
    private value?: SelectOption;

    @Prop(Boolean)
    private isDisabled!: boolean;

    private isFetchError = false;
    private isFirstOpen = true;
    private isOpen = false;
    private isLoading = false;
    private inputString = '';

    private options: SelectOption[] = [];

    private get shouldShowResults(): boolean {
        return this.isOpen && !this.isLoading && this.options.length !== 0 && !this.isFetchError;
    }

    private get shouldShowMessageBox(): boolean {
        return this.isOpen && (this.isLoading || this.options.length === 0 || this.isFetchError);
    }

    private get messageBoxText(): string {
        if (this.isLoading) {
            return this.$t('typeAhead.messageBox.loading');
        }

        if (this.isFetchError) {
            return this.$t('typeAhead.messageBox.fetchError');
        }

        if (this.options.length === 0) {
            return this.$t('typeAhead.messageBox.noResults');
        }

        return '';
    }

    @Watch('value', { immediate: true })
    private onValueChanged(newValue: SelectOption | null): void {
        if (newValue) {
            this.inputString = newValue.label;
        }
    }

    public clearInput(): void {
        this.inputString = '';
        this.removeSelectedOption();
        this.isFirstOpen = true;
    }

    private onOptionClick(option: SelectOption): void {
        this.setSelectedOption(option);
        this.closeResults();
    }

    private onInputFocusIn(): void {
        this.openResults();
    }

    private onClickOutside(): void {
        this.closeResults();
    }

    private onInputChanged(): void {
        this.removeSelectedOption();
        this.isLoading = true;

        this.debounce(
            'fetchTypeahead',
            () => {
                this.fetchEntries();
            },
            this.debounceTimeout
        );
    }

    private setSelectedOption(option: SelectOption): void {
        this.$emit('input', option);
    }

    private removeSelectedOption(): void {
        this.$emit('input', null);
    }

    private async fetchEntries(): Promise<void> {
        if (!this.requestPromiseGenerator) {
            return;
        }

        this.isLoading = true;
        this.isFetchError = false;

        const signal = this.getSignal('fetchTypeahead', true);

        try {
            this.options = await this.requestPromiseGenerator(this.inputString, signal);
        } catch (err) {
            if (!signal.aborted) {
                this.isFetchError = true;
            }
        } finally {
            this.isLoading = false;
            this.openResults();
        }
    }

    private openResults(): void {
        if (this.isFirstOpen) {
            this.fetchEntries().then();
            this.isFirstOpen = false;
        }

        this.isOpen = true;
    }

    private closeResults(): void {
        this.isOpen = false;
    }
}
</script>

<style scoped lang="scss">
.bm-type-ahead {
    $input-height: 38px;
    $input-left-padding: pui-spacing(xs);

    @include rem(border-radius, 4px);

    border: 1px solid $warm-grey;
    background-color: $white;
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;

    &:hover {
        border-color: $uniper-blue;
    }

    &--disabled {
        background-color: $pale-grey;

        &:hover {
            border-color: $warm-grey;
        }
    }

    &__input {
        @include pui-font(base);
        @include rem(padding, 0 $input-left-padding);
        @include rem(line-height, 16px);
        @include rem(height, $input-height);

        color: $dark-grey;
        background-color: transparent;
        border: 0;
        outline: none;
        width: 100%;
    }

    &__loading-icon {
        @include rem(left, -1rem);
    }

    &__results {
        @include rem(top, $input-height + 10px);
        @include pui-box();

        padding: 0;
        margin: 0;
        background-color: white;
        width: 100%;
        position: absolute;
        z-index: 100;
        min-height: 1rem;
        max-height: 22rem;
        overflow: auto;

        &__item {
            @include rem(font-size, 14px);
            @include rem(padding, 10px $input-left-padding);
            line-height: 1.5;

            display: flex;
            flex-direction: column;

            &:hover {
                background-color: transparentize($uniper-blue, 0.8);
            }

            &--selected {
                background-color: transparentize($uniper-blue, 0.9);
                font-weight: bold;
            }

            &__label--secondary {
                font-size: 1.2rem;
            }
        }
    }

    &__message {
        @include rem(padding, pui-spacing(xs) $input-left-padding);
        @include rem(top, $input-height + 10px);
        @include rem(font-size, 14px);
        @include pui-box();

        text-align: center;
        background-color: white;
        width: 100%;
        position: absolute;
        z-index: 1;
    }
}
</style>
