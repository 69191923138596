var render = function render(){
  var _vm$kpiData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.kpiData ? _c('div', {
    staticClass: "sc-table"
  }, [_c('div', {
    staticClass: "sc-table__header"
  }, [_c('div', {
    staticClass: "sc-table__header__section"
  }, [_c('scenario-compare-filters', {
    attrs: {
      "namespace": _vm.isPrimaryKpiData ? 'primKpi' : 'secondKpi',
      "is-primary-kpi": _vm.isPrimaryKpiData
    }
  }), _c('scenario-compare-table-overview', {
    attrs: {
      "overview-data": (_vm$kpiData = _vm.kpiData) === null || _vm$kpiData === void 0 ? void 0 : _vm$kpiData.overview
    }
  })], 1), _c('table-header', {
    attrs: {
      "hide-search-bar": true,
      "page-size": _vm.tableHeaderFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged
    }
  })], 1), _vm.hasReportedUnits ? _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    }
  }) : _vm._e()], 1) : _c('pui-loader', {
    staticClass: "sc-table__loader",
    attrs: {
      "promise": _vm.infinitePromise,
      "title": _vm.$t('results.scenarioCompare.loader.title'),
      "message": _vm.$t('results.scenarioCompare.loader.message')
    }
  }), _c('scenario-compare-details-lightbox', {
    ref: _vm.REF_CONSTANTS.DETAILS_LIGHTBOX
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }