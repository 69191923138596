<template>
    <div class="checkbox-wrapper">
        <pui-form-checkbox
            label=" "
            :checked="checked"
            @change="onChange"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ValueFormatterParams } from 'ag-grid-community';
import { McyTableRow } from '@/components/mtc-step/mcy-table/mcy-table.vue';

@Component({})
export default class McyTableCheckboxCell extends Vue {
    private params?: ValueFormatterParams<McyTableRow>;
    private checked = false;

    private get colId(): string {
        return this.params?.column.getColId() ?? '';
    }

    private mounted(): void {
        this.checked = this.params?.node?.data?.[this.colId] ?? false;
    }

    private onChange(value: boolean): void {
        this.checked = value;
        this.params?.node?.setDataValue(this.colId, value);
    }
}
</script>

<style scoped lang="scss">
.checkbox-wrapper {
    height: 100%;
    display: flex;
    @include rem(margin-top, pui-spacing(xxxs));
}
</style>
