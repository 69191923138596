var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fc-form-year-display"
  }, [_vm._l(_vm.values, function (value, key, index) {
    return [_c('div', {
      key: `${key}_display`,
      class: [{
        'fc-form-year-display__cell--empty': value === 0,
        'fc-form-year-display__cell--negative': value < 0,
        'fc-form-year-display__cell--positive': value > 0
      }, 'fc-form-year-display__cell']
    }, [_c('pui-headline', {
      staticClass: "fc-form-year-display__cell__year",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s(key) + " ")]), _c('pui-headline', {
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatBudgetValue")(value)) + " ")])], 1), index !== Object.keys(_vm.values).length - 1 ? _c('div', {
      key: `${key}_separator`,
      staticClass: "fc-form-year-display__spacer"
    }) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }