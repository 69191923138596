var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scenario-actions-cell-wrapper"
  }, [_c('pui-button', {
    attrs: {
      "icon": "edit",
      "state": "secondary",
      "small": true
    },
    on: {
      "click": function ($event) {
        return _vm.sendToEditScenario();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.overview.scenarioTable.actions.edit')) + " ")]), _c('pui-button', {
    attrs: {
      "icon": "add",
      "state": "secondary",
      "small": true,
      "disabled": !_vm.canDuplicate
    },
    on: {
      "click": function ($event) {
        return _vm.sendToDuplicateScenario();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.overview.scenarioTable.actions.duplicate')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }