var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldDisplayTooltip ? _c('div', {
    staticClass: "tooltip",
    attrs: {
      "role": "tooltip"
    }
  }, [_c('div', {
    staticClass: "tooltip-inner"
  }, [_c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.inflationForm.tooltip.referenceYear', {
    year: _vm.years.referenceYear
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.inflationForm.tooltip.startYear', {
    year: _vm.years.startYear
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.inflationForm.tooltip.endYear', {
    year: _vm.years.endYear
  })) + " ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }