<template>
    <div class="trend-reports">
        <pui-loader-error
            v-if="shouldShowErrorMessage"
            :title="$t('results.siteView.noData.title')"
            :message="isTrendReportOptionsIncomplete ? $t('results.siteView.noData.message') : ''"
            icon="error-empty-data"
            class="trend-reports__loader-error"
        />
        <pui-loader
            v-show="!shouldShowErrorMessage"
            :promise="trendReportsPromise"
        >
            <pui-collapsible
                :headline="$t('results.siteView.trendReports.title')"
                class="trend-reports__collapsible"
            >
                <div class="trend-reports__collapsible__content">
                    <site-view-trend-reports-chart
                        :title="$t(TREND_REPORTS_CONFIG[NET_GENERATION_VOLUME].title)"
                        :chart-data="this.trendReport?.trendChartData?.[NET_GENERATION_VOLUME] ?? []"
                        :scenario-filters="scenarioFilters"
                    />
                    <site-view-trend-reports-chart
                        :title="$t(TREND_REPORTS_CONFIG[TOTAL_NUMBER_OF_STARTS].title)"
                        :chart-data="this.trendReport?.trendChartData?.[TOTAL_NUMBER_OF_STARTS] ?? []"
                        :scenario-filters="scenarioFilters"
                    />
                    <site-view-trend-reports-chart
                        :title="$t(TREND_REPORTS_CONFIG[OPERATIONAL_HOURS].title)"
                        :chart-data="this.trendReport?.trendChartData?.[OPERATIONAL_HOURS] ?? []"
                        :scenario-filters="scenarioFilters"
                    />
                </div>
            </pui-collapsible>
        </pui-loader>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    GetFiltersResponse,
    IGetTrendReportsResponse,
    SiteViewServiceProxy
} from '@/service-proxies/service-proxies.g';
import SiteViewTrendReportsChart from '@/components/results/site-view/charts/site-view-trend-reports-chart.vue';
import {
    NET_GENERATION_VOLUME,
    OPERATIONAL_HOURS,
    TOTAL_NUMBER_OF_STARTS,
    TREND_REPORTS_CONFIG
} from '@/config/trend-reports';
import { TrendReportsRequest } from '@/models/results/site-view';

@Component({
    components: {
        SiteViewTrendReportsChart,
    }
})
export default class SiteViewTrendReports extends Vue {
    private readonly siteViewService = new SiteViewServiceProxy();

    private readonly NET_GENERATION_VOLUME = NET_GENERATION_VOLUME;
    private readonly OPERATIONAL_HOURS = OPERATIONAL_HOURS;
    private readonly TOTAL_NUMBER_OF_STARTS = TOTAL_NUMBER_OF_STARTS;
    private readonly TREND_REPORTS_CONFIG = TREND_REPORTS_CONFIG;

    private isLoadingTrendReports = false;
    private trendReportsPromise: Promise<any> | null = null;

    @Prop({ default: () => ({}) })
    private trendReportOptions!: Partial<TrendReportsRequest>;

    @Prop({ required: true })
    private scenarioFilters!: GetFiltersResponse;

    private trendReport: IGetTrendReportsResponse | null = null;

    private get isNoDataLoaded(): boolean {
        return !this.trendReport?.trendChartData
            || Object.keys(this.trendReport.trendChartData).length === 0;
    }

    private get isTrendReportOptionsIncomplete(): boolean {
        return !this.trendReportOptions.scenarioId
            || !this.trendReportOptions.startYear
            || !this.trendReportOptions.endYear
            || !this.trendReportOptions.unitSids
            || this.trendReportOptions.unitSids.length === 0;
    }

    private get shouldShowErrorMessage(): boolean {
        return (this.isTrendReportOptionsIncomplete || this.isNoDataLoaded) && !this.isLoadingTrendReports;
    }

    @Watch('trendReportOptions')
    private onTrendReportOptionsChange({ scenarioId, startYear, endYear, unitSids }: Partial<TrendReportsRequest>): void {
        if (!scenarioId || !startYear || !endYear || !unitSids || unitSids.length === 0) {
            return;
        }

        this.isLoadingTrendReports = true;
        this.trendReportsPromise = this.siteViewService.trendReports(scenarioId, startYear, endYear, unitSids)
            .then((response) => {
                this.trendReport = response.result;
                this.isLoadingTrendReports = false;
            });
    }
}
</script>

<style scoped lang="scss">
.trend-reports {
    &__loader-error {
        @include pui-box();
    }

    &__collapsible {
        background-color: white;

        &__content {
            @include rem(padding, 0 pui-spacing(m));
            @include rem(padding-top, pui-spacing(l));

            display: flex;
        }
    }
}
</style>
