var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-lightbox-wrapper"
  }, [_c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.$t('setup.scenario.formulaStep.toastMessages.confirmDeleteToastMessages.title'),
      "default-footer-confirm-label": _vm.$t('setup.scenario.formulaStep.actions.delete'),
      "default-footer-cancel-label": _vm.$t('setup.scenario.formulaStep.actions.cancel'),
      "on-confirm-callback": _vm.triggerDeleteKpi,
      "close-on-e-s-c": false,
      "fit-content": ""
    },
    on: {
      "puilightbox:close": _vm.emitClose
    }
  }, [_c('div', {
    staticClass: "delete-lightbox-wrapper__content"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.toastMessages.confirmDeleteToastMessages.copy')) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }