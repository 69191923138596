<template>
    <div
        :class="['ka-kpi-selectors', {
            'ka-kpi-selectors--full-width': isFullWidth
        }]"
    >
        <pui-form-group
            class="ka-kpi-selectors__kpi-type"
            :label="$t('results.siteView.kpiReport.inputs.selectKpiType')"
            :cta-label="$t('form.clear')"
            :has-label-padding="false"
            :has-cta="true"
            :cta-callback="clearKpiType"
            label-for="kpiType"
        >
            <pui-form-select
                v-model="selectedKpiType"
                :options="kpiTypeOptions"
                :is-disabled="isDisabled"
                :label="$t('results.siteView.kpiReport.inputs.selectKpiType')"
                :search-input-placeholder="$t('results.siteView.selector.selectPlaceholder')"
                search-input-id="kpiType"
            />
        </pui-form-group>
        <pui-form-group
            class="ka-kpi-selectors__kpi-id"
            :show-required-label="true"
            :label="$t('results.siteView.kpiReport.inputs.selectKpiId')"
            :has-label-padding="false"
            label-for="kpiId"
        >
            <benchmarking-type-ahead
                :ref="REF_CONSTANTS.TYPEAHEAD"
                v-model="selectedKpiId"
                :is-disabled="isDisabled"
                :placeholder="$t('results.siteView.selector.selectPlaceholder')"
                :request-promise-generator="kpisRequestPromiseGenerator"
            />
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { GetFiltersResponse, KpiServiceProxy } from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import BenchmarkingTypeAhead from '@/components/inputs/benchmarking-type-ahead/benchmarking-type-ahead.vue';

const REF_CONSTANTS = {
    TYPEAHEAD: 'typeahead',
} as const;

@Component({
    components: {
        BenchmarkingTypeAhead
    }
})
export default class KpiAnalysisKpiSelectors extends mixins(ManagedAbortControllers) {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly kpiService = new KpiServiceProxy();

    @Prop({ required: true })
    private scenarioFilters!: GetFiltersResponse | null;

    @Prop({ required: true })
    private scenarioId!: number | null;

    @Prop({ default: () => false })
    private isDisabled!: boolean;

    @Prop({ default: () => false })
    private isFullWidth!: boolean;

    private selectedKpiType: number | null = null;
    private selectedKpiId: PuiSelectOption | null = null;

    $refs!: {
        [REF_CONSTANTS.TYPEAHEAD]?: BenchmarkingTypeAhead,
    };

    private get kpiTypeOptions(): PuiSelectOptions<number> {
        if (!this.scenarioFilters) {
            return [];
        }

        return Object.keys(this.scenarioFilters?.kpiTypes ?? {})
            .map(key => ({
                label: this.scenarioFilters?.kpiTypes?.[key] ?? '',
                value: Number(key)
            }));
    }

    @Watch('selectedKpiType')
    private onKpiTypeChange(): void {
        this.$refs.typeahead?.clearInput();
    }

    @Watch('selectedKpiId')
    private onKpiIdChange(newKpiId: PuiSelectOption | null): void {
        this.$emit('change:kpi-id', newKpiId?.value);
    }

    private async kpisRequestPromiseGenerator(query: string, signal: AbortSignal): Promise<PuiSelectOptions> {
        if (!this.scenarioId) {
            return [];
        }

        const kpiTypeParam = this.selectedKpiType ? [this.selectedKpiType] : undefined;

        const response = await this.kpiService.list4(this.scenarioId, undefined, kpiTypeParam, true, undefined, undefined, 1, 20, query, undefined, signal);
        return response.result.items?.map(e => ({
            label: e.kpiFormula.kpiId ?? '',
            secondaryLabel: e.kpiFormula.kpiDesc ?? '',
            value: e.kpiFormula.kpiId ?? '',
        })) ?? [];
    }

    private clearKpiType(): void {
        this.selectedKpiType = null;
    }
}
</script>

<style scoped lang="scss">
.ka-kpi-selectors {
    @include rem(gap, pui-spacing(m));

    display: flex;

    &--full-width > * {
        flex-grow: 1;
    }

    &__kpi-type {
        min-width: 250px;
    }

    &__kpi-id {
        min-width: 300px;
    }
}
</style>
