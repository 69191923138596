var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.empty.title')) + " ")]), _c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.empty.subtitle')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }