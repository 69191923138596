var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-analysis"
  }, [_c('kpi-analysis-selectors', {
    on: {
      "change:scenario-id": function ($event) {
        _vm.scenarioId = $event;
      },
      "change:kpi-id": function ($event) {
        _vm.kpiId = $event;
      },
      "change:year": function ($event) {
        _vm.year = $event;
      },
      "change:unit": function ($event) {
        _vm.unitSid = $event;
      }
    }
  }), _c('kpi-analysis-display', {
    attrs: {
      "scenario-id": _vm.scenarioId,
      "kpi-id": _vm.kpiId,
      "year": _vm.year,
      "unit-sid": _vm.unitSid
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }