var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "engine-run-start-card"
  }, [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "engine-run-start-card__select-scenario-column",
    attrs: {
      "cols": 4
    }
  }, [_c('div', {
    staticClass: "engine-run-start-card__select-scenario-column__content"
  }, [_c('pui-form-group', {
    attrs: {
      "label": _vm.$t('engineRun.engineControl.selectScenario'),
      "cta-label": _vm.$t('form.clear'),
      "has-cta": true,
      "cta-callback": _vm.clearRunnableScenarios,
      "has-label-padding": false,
      "label-for": "selectScenario"
    }
  }, [_c('benchmarking-type-ahead', {
    ref: _vm.REF_CONSTANTS.TYPEAHEAD,
    attrs: {
      "placeholder": _vm.$t('engineRun.engineControl.selectScenarioPlaceholder'),
      "request-promise-generator": _vm.scenariosRequestPromiseGenerator
    },
    model: {
      value: _vm.selectedRunnableScenarioOption,
      callback: function ($$v) {
        _vm.selectedRunnableScenarioOption = $$v;
      },
      expression: "selectedRunnableScenarioOption"
    }
  })], 1)], 1)]), _c('pui-grid-column', {
    staticClass: "engine-run-start-card__summary-column",
    attrs: {
      "cols": 4
    }
  }, [_vm.selectedRunnableScenario ? _c('div', {
    staticClass: "engine-run-start-card__summary-column__content"
  }, [_c('pui-headline', {
    staticClass: "engine-run-start-card__summary-column__content__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.summary')) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-start-card__summary-column__content__item",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.selectedKpis', {
    count: _vm.selectedRunnableScenario.kpisCalculated
  })) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-start-card__summary-column__content__item",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.sites', {
    count: _vm.selectedRunnableScenario.sites
  })) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-start-card__summary-column__content__item",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.units', {
    count: _vm.selectedRunnableScenario.units
  })) + " ")])], 1) : _vm._e()]), _c('pui-grid-column', {
    staticClass: "engine-run-start-card__start-engine-column",
    attrs: {
      "cols": 4
    }
  }, [_c('pui-button', {
    attrs: {
      "disabled": !_vm.isRunnableScenarioValid
    },
    on: {
      "click": _vm.startEngineRunClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.engineControl.startEngine')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }