var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "snackbar",
    class: {
      'show': _vm.isVisible
    }
  }, [_vm.message !== '' ? _c('span', [_vm._v(_vm._s(_vm.$t(_vm.message)))]) : _vm._e(), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.dismiss();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('dismiss')) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }