<template>
    <div class="inflation-table-wrapper">
        <table-header
            :hide-search-bar="true"
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
        />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import InflationTable, { InflationTableRow } from '@/components/inflation-step/common/inflation-table';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { FetchInflationValuesPayload } from '@/models/steps/inflation';
import {
    AddInflationRequest,
    ICountryInflationDto,
    IInflationDto,
    InflationDto
} from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { formatNumberForTableDisplay } from '@/utils/formatters';

@Component({
    components: {
        TableHeader,
    }
})
export default class YearlyInflationTable extends InflationTable {
    private modifiedValues = new Map<number, IInflationDto>();

    private get yearlyValuesCount(): number {
        return this.$store.getters['scenario/inflationStep/getYearlyTotalCount'];
    }

    private get gridOptions(): GridOptions<InflationTableRow> {
        return {
            ...this.commonGridOptions,
            serverSideDatasource: {
                getRows: (params: IServerSideGetRowsParams): void => {
                    const paginationParams = this.getPaginationForServerRequestParams(params);

                    const payload: FetchInflationValuesPayload = {
                        scenarioId: this.scenarioId,
                        ...paginationParams,
                    };

                    this.$store.dispatch('scenario/inflationStep/fetchYearlyValues', payload)
                        .then((yearlyValues: ICountryInflationDto[]) => {
                            const rowData: InflationTableRow[] = [];
                            const years = new Set<number>();

                            yearlyValues.forEach(countryInflation => {
                                const row: InflationTableRow = {
                                    countryName: countryInflation.countryName ?? '',
                                };

                                countryInflation.inflations?.forEach(inflation => {
                                    row[inflation.year.toString()] = this.transformInflationValueFromServer(inflation.value);
                                    row[this.computeIdKeyForYear(inflation.year)] = inflation.id;
                                    years.add(inflation.year);
                                });

                                rowData.push(row);
                            });

                            this.configureYearColumns(years);

                            params.success({
                                rowData,
                                rowCount: this.yearlyValuesCount,
                            })
                        })
                        .catch(() => params.fail());
                },
            },
            defaultColDef: {
                ...this.commonGridOptions.defaultColDef,
                editable: true,
                valueFormatter: (params): string => {
                    const value: number = params.data?.[params.column.getColId()] ?? '';
                    return this.formatInflationValueForDisplay(value);
                },
                valueGetter: (params): string => {
                    const value: number = params.data?.[params.column.getColId()] ?? '';
                    return this.formatInflationValueForEdit(value);
                },
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue.toString() || !params.data) {
                        return false;
                    }

                    const value = this.parseInflationValue(params.newValue);
                    const year = Number(params.column.getColId());
                    const id = this.getIdForYearFromRow(year, params.data);

                    // Update modified value in this.modifiedValues
                    const inflationDto: IInflationDto = { id, year, value: this.transformInflationValueToServer(value) };
                    this.modifiedValues.set(id, inflationDto);

                    // Update modified value in table data
                    params.data[params.column.getColId()] = value;
                    return true;
                }
            }
        };
    }

    private formatInflationValueForDisplay(value: number): string {
        const formattedValue = formatNumberForTableDisplay(value);
        return `${formattedValue}%`;
    }

    private formatInflationValueForEdit(value: number): string {
        return value.toString();
    }

    private parseInflationValue(value: string): number {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? 0 : parsedValue;
    }

    private transformInflationValueFromServer(value: number): number {
        return value * 100;
    }

    private transformInflationValueToServer(value: number): number {
        return value / 100;
    }

    private computeIdKeyForYear(year: number): string {
        return `${year}_ID`;
    }

    private getIdForYearFromRow(year: number, row: InflationTableRow): number {
        return Number(row[`${year}_ID`] ?? -1);
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    public async submitForm(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        if (this.modifiedValues.size === 0 && saveAsDraft) {
            return true;
        }

        const inflations: InflationDto[] = [];
        this.modifiedValues.forEach(value => inflations.push(new InflationDto(value)));

        const addInflationRequest = new AddInflationRequest({
            scenarioId: this.scenarioId,
            inflations,
            saveAsDraft,
        });

        try {
            await this.$store.dispatch('scenario/inflationStep/saveInflationValues', addInflationRequest);
            this.modifiedValues.clear();

            if (automaticSubmit) {
                this.$pui.toast({
                    type: 'success',
                    title: this.$t('setup.scenario.inflationForm.toastMessages.automaticSubmit.title'),
                    copy: this.$t('setup.scenario.inflationForm.toastMessages.automaticSubmit.copy'),
                });
            } else {
                await this.$store.dispatch('scenario/inflationStep/clearInflation');
            }
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });

            return false;
        }

        return true;
    }
}
</script>

<style scoped lang="scss">
.inflation-table-wrapper {
    width: 100%;
    @include pui-box();

    ::v-deep &__cell {
        &--highlighted {
            background-color: transparentize($uniper-blue, 0.85) !important;
        }

        &--centered {
            text-align: center !important;

            .ag-header-cell-label {
                justify-content: center;
            }
        }

        &--non-editable {
            background-color: $non-editable-cell-color;
        }
    }
}
</style>
