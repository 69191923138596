import { IKpiDto, InsertFormulaKpiDto, KpiUsedByDto } from '@/service-proxies/service-proxies.g';
import { i18n } from '@/utils/i18n';

export interface SecondaryKpi extends IKpiDto {
    kpis: InsertFormulaKpiDto[] | undefined;
    kpiUsedBy: KpiUsedByDto;
    runByEngine: boolean;
}

export const MAX_LENGTH_CONFIG = {
    kpiId: 20,
    kpiDesc: 100,
    kpiFormula: 200,
} as const;

export const INPUT_SELECTOR_OPTIONS = [
    {
        label: i18n.t('setup.scenario.formulaStepForm.selector.yes'),
        value: 'yes',
    },
    {
        label: i18n.t('setup.scenario.formulaStepForm.selector.no'),
        value: 'no',
    },
];
