<template>
    <div class="ce-step">
        <pui-loader :promise="initialPromise">
            <template v-if="hasMtpYear">
                <pui-tabs
                    :selected="selectedTabIndex"
                    class="ce-step__tabs"
                    @changed="onTabChanged"
                >
                    <pui-tab :title="$t('setup.scenario.ceForm.tabs.all')" />
                    <pui-tab :title="$t('setup.scenario.ceForm.tabs.flagged')" />
                </pui-tabs>
                <all-costs-table v-show="selectedTabIndex === COST_EXCLUSION_TAB.ALL" />
                <flagged-costs-table
                    v-show="selectedTabIndex === COST_EXCLUSION_TAB.FLAGGED"
                    :ref="FLAGGED_TABLE_REF"
                />
            </template>
            <pui-form-inline-notification
                v-else
                :title="$t('setup.scenario.ceForm.noMtpNotification.title')"
                :text="$t('setup.scenario.ceForm.noMtpNotification.text')"
                type="info"
            />
        </pui-loader>
        <div class="ce-step__buttons">
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.next') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import AllCostsTable from '@/components/cost-exclusion-step/all-costs-table/all-costs-table.vue';
import FlaggedCostsTable from '@/components/cost-exclusion-step/flagged-costs-table/flagged-costs-table.vue';
import {
    AddBudgetsRequest,
    BudgetRequestServiceProxy,
    IGetScenarioResponse
} from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';
import { SCENARIO_FORM_STEP } from '@/config/steps';

enum COST_EXCLUSION_TAB {
    ALL = 0,
    FLAGGED = 1,
}

const FLAGGED_TABLE_REF = 'flaggedTable' as const;

@Component({
    components: {
        FlaggedCostsTable,
        AllCostsTable,
    },
})
export default class CostExclusionScenarioStep extends mixins(ScenarioStepNavigation) {
    private readonly COST_EXCLUSION_TAB = COST_EXCLUSION_TAB;
    private readonly FLAGGED_TABLE_REF = FLAGGED_TABLE_REF;

    private readonly budgetService = new BudgetRequestServiceProxy();

    private initialPromise: Promise<any> | null = null;

    private selectedTabIndex = COST_EXCLUSION_TAB.ALL;
    private isFormSubmitting = false;

    $refs!: {
        [FLAGGED_TABLE_REF]: FlaggedCostsTable,
    };

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get scenario(): IGetScenarioResponse | undefined {
        return this.$store.getters['scenario/getScenario'];
    }

    private get hasMtpYear(): boolean {
        return (this.scenario?.mtpYear ?? 0) !== 0;
    }

    private mounted(): void {
        this.initialPromise = this.$store.dispatch('scenario/filters/ensureFiltersAreLoaded')
    }

    private onTabChanged(newTabIndex: number): void {
        this.selectedTabIndex = newTabIndex;

        if (newTabIndex === COST_EXCLUSION_TAB.FLAGGED) {
            this.$refs.flaggedTable.refreshData();
        }
    }

    private async submitForm(): Promise<boolean> {
        const payload = new AddBudgetsRequest({
            scenarioId: this.scenarioId,
            saveAsDraft: false,
            globalIds: [],
        });

        try {
            this.isFormSubmitting = true;
            await this.budgetService.addBudget(payload);
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });

            this.isFormSubmitting = false;
            return false;
        }

        this.isFormSubmitting = false;
        return true;
    }

    private async backButtonClicked(): Promise<void> {
        await this.pushToStep(SCENARIO_FORM_STEP.MTC);
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        await this.pushToScenarioOverview();
    }

    private async nextButtonClicked(): Promise<void> {
        if (await this.submitForm()) {
            await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.PRIMARY_KPI);
            await this.pushToStep(SCENARIO_FORM_STEP.PRIMARY_KPI);
        }
    }
}
</script>

<style scoped lang="scss">
.ce-step {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    &__tabs {
        margin-bottom: -1rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }

    .pui-form-inline-notification--info {
        background-color: transparentize($uniper-blue, 0.8);
        border-color: $uniper-blue;

        ::v-deep svg {
            color: $uniper-blue !important;
        }
    }
}
</style>
