var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-type-tab"
  }, [_c('table-header', {
    attrs: {
      "search-term": _vm.tableHeaderFilters.searchTerm,
      "page-size": _vm.tableHeaderFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }