<template>
    <div class="table-header">
        <div class="table-header__search">
            <template v-if="!hideSearchBar">
                <pui-form-input-field
                    :id="searchFieldId"
                    v-model="tableExtraFilters.searchTerm"
                    :placeholder-text="$t('table.searchPlaceHolder')"
                    trailing-icon-name="close"
                    trailing-icon-is-clickable
                    @trailing-icon:click="onClearSearchTerm"
                />
                <div class="table-header__search__button">
                    <pui-button
                        icon="search"
                        state="primary"
                        icon-only
                        @click="onTriggerSearch"
                    />
                </div>
            </template>
        </div>
        <div class="table-header__page-size">
            {{ $t('table.resultsPerPage') }}
            <div class="table-header__page-size__select">
                <pui-form-select
                    label
                    search-input-placeholder=" "
                    v-model="tableExtraFilters.pageSize"
                    :value="tableExtraFilters.pageSize"
                    :options="PAGE_SIZE_OPTIONS"
                    @change="onPageSizeChanged"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PuiSelectOption } from '@/models/pebble-ui';

@Component({})
export default class TableHeaderComponent extends Vue {
    private readonly searchFieldId = 'search-field';
    private readonly PAGE_SIZE_OPTIONS: PuiSelectOption<number>[] = [
        {
            label: '5',
            value: 5,
        },
        {
            label: '10',
            value: 10,
        },
        {
            label: '20',
            value: 20,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value: 100,
        },
    ];

    /**
     * This prop is required to know the page size.
     */
    @Prop({ required: false, default: () => '' })
    private searchTerm!: string;

    /**
     * This prop is required to know the page size.
     */
    @Prop({ required: true })
    private pageSize!: number;

    /**
     * This prop is used to hide the search bar.
     */
    @Prop({ default: () => false })
    private hideSearchBar!: boolean;

    private tableExtraFilters = {
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
    }

    private get hasSearchTerm(): boolean {
        return this.tableExtraFilters.searchTerm.trim().length >= 1;
    }

    private mounted(): void {
        const inputElement = this.$el.querySelector(`#${this.searchFieldId}`);

        // Typescript not recognizing correct event type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputElement?.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter' && this.hasSearchTerm) {
                this.onTriggerSearch();
            }
        });
    }

    private onPageSizeChanged(): void {
        this.$emit('page-size-changed', this.tableExtraFilters.pageSize);
    }

    private onTriggerSearch(): void {
        if (this.hasSearchTerm) {
            this.$emit('search-triggered', this.tableExtraFilters.searchTerm.trim());
        }
    }

    private onClearSearchTerm(): void {
        if (this.hasSearchTerm) {
            this.tableExtraFilters.searchTerm = '';
        }

        this.$emit('clear-search-term');
    }
}
</script>

<style lang="scss" scoped>
.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include rem(padding, pui-spacing(xs) pui-spacing(s));

    &__search {
      display: flex;
      align-items: center;

      .pui-form-input-field {
        @include rem(border-radius, pui-spacing(xxxs) 0 0 pui-spacing(xxxs));
        @include rem(border-right-width, 0);
      }


      &__button {
        .pui-button {
            @include rem(border-radius, 0 pui-spacing(xxxs) pui-spacing(xxxs) 0);
        }
      }
    }

    &__page-size {
      display: flex;
      align-items: center;
      @include rem(gap, pui-spacing(xs));

      &__select {
        width: 80px;
      }
    }
}
</style>
