var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fc-form-exclusions"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.title')) + " ")]), _vm._l(_vm.exclusions, function (exclusion) {
    return [_c('div', {
      key: exclusion.internalId
    }, [_c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 3,
        expression: "3"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionFrom'),
        "is-valid": !exclusion.errorMessage
      },
      scopedSlots: _vm._u([{
        key: "error-message",
        fn: function () {
          var _exclusion$errorMessa;
          return [_vm._v(" " + _vm._s((_exclusion$errorMessa = exclusion.errorMessage) !== null && _exclusion$errorMessa !== void 0 ? _exclusion$errorMessa : '') + " ")];
        },
        proxy: true
      }], null, true)
    }, [_c('pui-form-select', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionFrom'),
        "options": _vm.mtpYearOptions,
        "is-valid": !exclusion.errorMessage,
        "value": exclusion.fromYear
      },
      on: {
        "change": function ($event) {
          return _vm.onFromYearChange($event, exclusion);
        }
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 2,
        expression: "2"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionPercentage')
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionPercentage'),
        "value": exclusion.percentage.toString()
      },
      on: {
        "input": function ($event) {
          return _vm.onPercentageChange($event, exclusion);
        }
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 2,
        expression: "2"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionAmount')
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionAmount'),
        "is-disabled": true,
        "value": _vm.formatExclusionAmount(exclusion.amount)
      }
    })], 1), _c('pui-button', {
      staticClass: "fc-form-exclusions__remove-button",
      attrs: {
        "small": true,
        "icon": "delete"
      },
      on: {
        "click": function ($event) {
          return _vm.emitExclusionRemoval(exclusion.internalId);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.buttons.removeExclusion')) + " ")])], 1)])];
  }), _c('pui-button', {
    staticClass: "fc-form-exclusions__add-button",
    attrs: {
      "small": true,
      "icon": "add",
      "state": "primary"
    },
    on: {
      "click": _vm.addNewExclusion
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.buttons.addExclusion')) + " ")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }