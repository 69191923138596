var render = function render(){
  var _this$trendReport$tre, _this$trendReport, _this$trendReport$tre2, _this$trendReport$tre3, _this$trendReport2, _this$trendReport2$tr, _this$trendReport$tre4, _this$trendReport3, _this$trendReport3$tr;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trend-reports"
  }, [_vm.shouldShowErrorMessage ? _c('pui-loader-error', {
    staticClass: "trend-reports__loader-error",
    attrs: {
      "title": _vm.$t('results.siteView.noData.title'),
      "message": _vm.isTrendReportOptionsIncomplete ? _vm.$t('results.siteView.noData.message') : '',
      "icon": "error-empty-data"
    }
  }) : _vm._e(), _c('pui-loader', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shouldShowErrorMessage,
      expression: "!shouldShowErrorMessage"
    }],
    attrs: {
      "promise": _vm.trendReportsPromise
    }
  }, [_c('pui-collapsible', {
    staticClass: "trend-reports__collapsible",
    attrs: {
      "headline": _vm.$t('results.siteView.trendReports.title')
    }
  }, [_c('div', {
    staticClass: "trend-reports__collapsible__content"
  }, [_c('site-view-trend-reports-chart', {
    attrs: {
      "title": _vm.$t(_vm.TREND_REPORTS_CONFIG[_vm.NET_GENERATION_VOLUME].title),
      "chart-data": (_this$trendReport$tre = (_this$trendReport = this.trendReport) === null || _this$trendReport === void 0 ? void 0 : (_this$trendReport$tre2 = _this$trendReport.trendChartData) === null || _this$trendReport$tre2 === void 0 ? void 0 : _this$trendReport$tre2[_vm.NET_GENERATION_VOLUME]) !== null && _this$trendReport$tre !== void 0 ? _this$trendReport$tre : [],
      "scenario-filters": _vm.scenarioFilters
    }
  }), _c('site-view-trend-reports-chart', {
    attrs: {
      "title": _vm.$t(_vm.TREND_REPORTS_CONFIG[_vm.TOTAL_NUMBER_OF_STARTS].title),
      "chart-data": (_this$trendReport$tre3 = (_this$trendReport2 = this.trendReport) === null || _this$trendReport2 === void 0 ? void 0 : (_this$trendReport2$tr = _this$trendReport2.trendChartData) === null || _this$trendReport2$tr === void 0 ? void 0 : _this$trendReport2$tr[_vm.TOTAL_NUMBER_OF_STARTS]) !== null && _this$trendReport$tre3 !== void 0 ? _this$trendReport$tre3 : [],
      "scenario-filters": _vm.scenarioFilters
    }
  }), _c('site-view-trend-reports-chart', {
    attrs: {
      "title": _vm.$t(_vm.TREND_REPORTS_CONFIG[_vm.OPERATIONAL_HOURS].title),
      "chart-data": (_this$trendReport$tre4 = (_this$trendReport3 = this.trendReport) === null || _this$trendReport3 === void 0 ? void 0 : (_this$trendReport3$tr = _this$trendReport3.trendChartData) === null || _this$trendReport3$tr === void 0 ? void 0 : _this$trendReport3$tr[_vm.OPERATIONAL_HOURS]) !== null && _this$trendReport$tre4 !== void 0 ? _this$trendReport$tre4 : [],
      "scenario-filters": _vm.scenarioFilters
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }