<template>
    <div class="er-units-list-wrapper">
        <template v-if="filteredUnitsCount !== 0">
            <span
                v-for="(unit, index) in filteredUnits"
                :key="unit.unitSid"
                v-tooltip="tooltipContent(unit)"
            >
                <span v-if="index !== 0">, </span>
                <span
                    :class="['er-units-list-wrapper__unit-sid', {
                        'er-units-list-wrapper__unit-sid--clickable': hasCompleteKpiDataForAnalysis,
                    }]"
                    @click="unitClicked(unit)"
                >{{ unit.unitSid }}</span>
            </span>
        </template>
        <template v-else>
            -
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import { EngineUnitDto, IEngineLogDto } from '@/service-proxies/service-proxies.g';

@Component({})
export default class ERUnitsListCellComponent extends Vue {
    private params?: ICellRendererParams<IEngineLogDto>;

    private get filteredUnits(): EngineUnitDto[] {
        return this.params?.data?.units?.filter(u => u.unitSid !== 0) ?? [];
    }

    private get filteredUnitsCount(): number {
        return this.filteredUnits.length;
    }

    private get hasCompleteKpiDataForAnalysis(): boolean {
        return !!this.params?.node?.data?.kpiId && !!this.params?.node?.data?.year;
    }

    private tooltipContent(unit: EngineUnitDto): string {
        return `${unit.unitName} (${unit.unitUi})`;
    }

    private unitClicked(unit: EngineUnitDto): void {
        if (!this.params?.node?.data?.kpiId || !this.params?.node?.data?.year || !this.params?.context?.handleUnitClick) {
            return;
        }

        this.params.context.handleUnitClick({
            kpiId: this.params.node.data.kpiId,
            year: this.params.node.data.year,
            unitSid: unit.unitSid,
            unitUi: unit.unitUi,
        });
    }
}
</script>

<style scoped lang="scss">
    .er-units-list-wrapper {
        &__unit-sid {
            white-space: nowrap;

            &--clickable {
                color: $uniper-blue;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
</style>
