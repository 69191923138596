var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fc-form-corrections"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.title')) + " ")]), _vm._l(_vm.corrections, function (correction) {
    return [_c('div', {
      key: correction.internalId
    }, [_c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 3,
        expression: "3"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionFrom'),
        "is-valid": !correction.errorMessage
      },
      scopedSlots: _vm._u([{
        key: "error-message",
        fn: function () {
          return [_vm._v(" " + _vm._s(correction.errorMessage) + " ")];
        },
        proxy: true
      }], null, true)
    }, [_c('pui-form-select', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionFrom'),
        "options": _vm.mtpYearOptions,
        "is-valid": !correction.errorMessage,
        "value": correction.fromYear
      },
      on: {
        "change": function ($event) {
          return _vm.onFromYearChange($event, correction);
        }
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 3,
        expression: "3"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionTo'),
        "is-valid": !correction.errorMessage
      }
    }, [_c('pui-form-select', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionTo'),
        "options": _vm.mtcYearOptions,
        "is-valid": !correction.errorMessage,
        "value": correction.toYear
      },
      on: {
        "change": function ($event) {
          return _vm.onToYearChange($event, correction);
        }
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 2,
        expression: "2"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionPercentage')
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionPercentage'),
        "value": correction.percentage.toString()
      },
      on: {
        "input": function ($event) {
          return _vm.onPercentageChange($event, correction);
        }
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: 2,
        expression: "2"
      }],
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionAmount')
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "label": _vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionAmount'),
        "is-disabled": true,
        "value": _vm.formatCorrectionAmount(correction.amount)
      }
    })], 1), _c('pui-button', {
      staticClass: "fc-form-exclusions__remove-button",
      attrs: {
        "small": true,
        "icon": "delete"
      },
      on: {
        "click": function ($event) {
          return _vm.emitCorrectionRemoval(correction.internalId);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.buttons.removeCorrection')) + " ")])], 1)])];
  }), _c('pui-button', {
    staticClass: "fc-form-corrections__add-button",
    attrs: {
      "small": true,
      "icon": "add",
      "state": "primary"
    },
    on: {
      "click": _vm.addNewCorrection
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.corrections.buttons.addCorrection')) + " ")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }