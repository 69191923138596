var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inflation-step"
  }, [_c('pui-tabs', {
    staticClass: "inflation-step__tabs",
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.inflationForm.tabs.nominated')
    }
  }), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('setup.scenario.inflationForm.tabs.yearly')
    }
  })], 1), _vm.isFormSubmitting ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise,
      "title": _vm.$t('setup.scenario.inflationForm.loader.savingYearly.title'),
      "message": _vm.$t('setup.scenario.inflationForm.loader.savingYearly.message')
    }
  }) : _vm._e(), _c('nominated-inflation-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isFormSubmitting && _vm.selectedTabIndex === _vm.INFLATION_STEP_TAB.NOMINATED,
      expression: "!isFormSubmitting && selectedTabIndex === INFLATION_STEP_TAB.NOMINATED"
    }],
    ref: _vm.NOMINATED_TABLE_REF
  }), _c('yearly-inflation-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isFormSubmitting && _vm.selectedTabIndex === _vm.INFLATION_STEP_TAB.YEARLY,
      expression: "!isFormSubmitting && selectedTabIndex === INFLATION_STEP_TAB.YEARLY"
    }],
    ref: _vm.YEARLY_TABLE_REF
  }), _c('div', {
    staticClass: "inflation-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.next')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }