<template>
    <div class="overlay">
        <div class="overlay__message">
            {{ params.message ?? $t('dataMissing') }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NoRowsOverlayComponent extends Vue {
}
</script>

<style scoped lang="scss">
.overlay {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__message {
        color: $warm-grey;
        font-size: 14px;
        text-align: center;
        @include rem(margin-top, pui-spacing(xxxs));
    }
}
</style>
