var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dm-table"
  }, [_c('div', {
    staticClass: "dm-table__actions"
  }, [_c('div', {
    staticClass: "dm-table__actions__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataManagement.overview.scenarioTable.selectedCount', {
    count: _vm.selectedScenariosCount
  })) + " ")]), _c('pui-button', {
    attrs: {
      "state": "primary",
      "disabled": _vm.isDeleteDisabled
    },
    on: {
      "click": _vm.openConfirmationLightbox
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('dataManagement.overview.scenarioTable.actions.delete')) + " ")])], 1), _c('div', {
    staticClass: "dm-table__table"
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.promise
    }
  }, [_c('table-header', {
    attrs: {
      "search-term": _vm.tableExtraFilters.searchTerm,
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1)], 1), _c('confirmation-lightbox', {
    attrs: {
      "list": _vm.selectedScenarioNameList,
      "lightbox-open": _vm.isConfirmationLightboxOpen
    },
    on: {
      "close": _vm.closeConfirmationLightbox,
      "confirm": _vm.handleBulkDelete
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }