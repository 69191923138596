import Vue from 'vue';
import Vuex from 'vuex';
import ScenarioModule from '@/store/modules/scenario/scenario.module';
import StatusModule from '@/store/modules/status.module';
import SetupModule from '@/store/modules/setup.module';
import EngineRunModule from '@/store/modules/engine-run/engine-run.module';
import DataManagementModule from '@/store/modules/data-management/data-management.module';
import ResultsModule from '@/store/modules/results.module';
import ConstantsModule from '@/store/modules/constants.module';
import PreventionModule from '@/store/modules/prevention.module';

Vue.use(Vuex);

type ApplicationState = {
    isLoading: boolean;
};

const applicationState: ApplicationState = {
    isLoading: false,
};

export default new Vuex.Store({
    state: applicationState,

    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
    },

    actions: {},

    modules: {
        scenario: ScenarioModule,
        status: StatusModule,
        setup: SetupModule,
        engineRun: EngineRunModule,
        dataManagement: DataManagementModule,
        results: ResultsModule,
        constants: ConstantsModule,
        prevention: PreventionModule,
    },

    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
    },
});
