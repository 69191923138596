import {
    IScenarioList,
    ListSortDirection,
    ScenarioServiceProxy,
    SortableScenarioField
} from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';

const scenarioService = new ScenarioServiceProxy();

type SetupState = {
    scenarios: IScenarioList[];
};

const initialState: SetupState = {
    scenarios: [],
};

const SetupModule: Module<SetupState, any> = {
    state: initialState,

    mutations: {
        setScenarios(state, payload: IScenarioList[]): void {
            state.scenarios = payload;
        },
        resetScenarios(state): void {
            state.scenarios = [];
        },
    },

    actions: {
        async fetchScenarios({ commit }): Promise<void> {
            try {
                const response = await scenarioService.list2(undefined, undefined, SortableScenarioField.CREATED_AT, ListSortDirection.Descending, undefined, undefined, undefined, undefined);
                commit('setScenarios', response.result.items ?? []);
            } catch (err) {
                commit('resetScenarios');
                throw err;
            }
        }
    },

    getters: {
        getScenarios(state): IScenarioList[] {
            return state.scenarios;
        }
    },

    namespaced: true,
};

export default SetupModule;
