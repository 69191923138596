import {
    AddMaintenanceCycleValuesRequest,
    CostCoefficientRowDto,
    CostCoefficientsServiceProxy,
    EditCostCoefficientRequest,
    MaintenanceCycleValuesServiceProxy,
    MaintenanceCycleValuesViewDto
} from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';
import { FetchCostCoefficientsPayload, FetchMaintenanceCycleValuesPayload } from '@/models/steps/mtc';

const mtcService = new MaintenanceCycleValuesServiceProxy();
const costCoefficientsService = new CostCoefficientsServiceProxy();

type MtcStepModuleState = {
    maintenanceCycleValuesTotalCount: number;
    costCoefficientsTotalCount: number;
};

const initialState: MtcStepModuleState = {
    maintenanceCycleValuesTotalCount: 0,
    costCoefficientsTotalCount: 0,
};

const MtcStepModule: Module<MtcStepModuleState, any> = {
    state: initialState,

    mutations: {
        setMaintenanceCycleValuesTotalCount(state, payload: number): void {
            state.maintenanceCycleValuesTotalCount = payload;
        },
        resetMaintenanceCycleValuesTotalCount(state): void {
            state.maintenanceCycleValuesTotalCount = 0;
        },
        setCostCoefficientsTotalCount(state, payload: number): void {
            state.costCoefficientsTotalCount = payload;
        },
        resetCostCoefficientsTotalCount(state): void {
            state.costCoefficientsTotalCount = 0;
        }
    },

    actions: {
        async fetchCostCoefficientsValues({ commit }, payload: FetchCostCoefficientsPayload): Promise<CostCoefficientRowDto[]> {
            const response = await costCoefficientsService.listCostCoefficients(payload.scenarioId, payload.kpiIds, payload.plants, payload.technologies, payload.countries, payload.pageNumber, payload.pageSize, undefined, undefined);
            commit('setCostCoefficientsTotalCount', response.result.total);
            return response.result.items ?? [];
        },
        async fetchMaintenanceCycleValues({ commit }, payload: FetchMaintenanceCycleValuesPayload): Promise<MaintenanceCycleValuesViewDto[]> {
            const response = await mtcService.listMaintenanceCycleValues(payload.scenarioId, payload.plants, payload.technologies, payload.countries, payload.pageNumber, payload.pageSize, payload.searchTerm, undefined);
            commit('setMaintenanceCycleValuesTotalCount', response.result.total);
            return response.result.maintenanceCycleValues ?? [];
        },
        async saveMaintenanceCycleValues(context, payload: AddMaintenanceCycleValuesRequest): Promise<void> {
            await mtcService.addMaintenanceCycleValues(payload);
        },
        async saveCostCoefficients(context, payload: EditCostCoefficientRequest): Promise<void> {
            await costCoefficientsService.editCostCoefficients(payload);
        },
        clearMaintenanceCycle({ commit }): void {
            commit('resetMaintenanceCycleValuesTotalCount');
        },
        clearCostCoefficients({ commit }): void {
            commit('resetCostCoefficientsTotalCount');
        }
    },

    getters: {
        getMaintenanceCycleValuesTotalCount(state): number {
            return state.maintenanceCycleValuesTotalCount;
        },
        getCostCoefficientsTotalCount(state): number {
            return state.costCoefficientsTotalCount;
        }
    },

    namespaced: true,
};

export default MtcStepModule;
