var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "wrapper__heading"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('engineRun.overview.title')))])]), _c('engine-run-control-card'), _c('engine-run-table', {
    ref: _vm.REF_CONSTANTS.ENGINE_RUN_TABLE
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }