import { Module } from 'vuex';
import {
    EngineRunServiceProxy,
    IScenarioRunDto,
    StopAllEngineRunRequest
} from '@/service-proxies/service-proxies.g';
import { PayloadWithSignal } from '@/models/interfaces';
import { SCENARIO_STATUS } from '@/config/status';
import EngineRunSummaryModule from '@/store/modules/engine-run/engine-run-summary.module';

const engineRunService = new EngineRunServiceProxy();

export enum EngineRunTrackingState {
    STARTED = 1,
    COMPLETED = 2,
    FAILED = 3,
    STOPPED = 4,
    MANUALLY_STOPPED = 5
}

export type EngineRunTrackingData = {
    scenarioId: number,
    startedAt?: Date,
    name?: string,
    state: EngineRunTrackingState
}

type EngineRunState = {
    runnableScenarios: IScenarioRunDto[],
    trackedScenarios: EngineRunTrackingData[],
};

const initialState: EngineRunState = {
    runnableScenarios: [],
    trackedScenarios: [],
};

const EngineRunModule: Module<EngineRunState, any> = {
    state: initialState,

    mutations: {
        setRunnableScenarios(state, payload: IScenarioRunDto[]): void {
            state.runnableScenarios = payload;
        },
        resetRunnableScenarios(state): void {
            state.runnableScenarios = [];
        },
        updateTrackingState(state, payload: { scenarioId: number, state: EngineRunTrackingState, startedAt?: Date, name?: string }): void {
            const existingRecord = state.trackedScenarios.find(e => e.scenarioId === payload.scenarioId);

            state.trackedScenarios = [
                ...state.trackedScenarios.filter(e => e.scenarioId !== payload.scenarioId),
                {
                    ...existingRecord ?? {},
                    scenarioId: payload.scenarioId,
                    state: payload.state,
                    startedAt: payload.startedAt,
                    name: payload.name,
                }
            ];
        },
    },

    actions: {
        async checkEngineRunState({ state, commit }, { signal }: PayloadWithSignal): Promise<void> {
            try {
                const trackedStatuses = [
                    Number(SCENARIO_STATUS.ENGINE_RUN_STARTED),
                    Number(SCENARIO_STATUS.ENGINE_RUN_STOPPED),
                    Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)
                ];

                const trackedStatusesStateMap: Record<number, EngineRunTrackingState> = {
                    [Number(SCENARIO_STATUS.ENGINE_RUN_STARTED)]: EngineRunTrackingState.STARTED,
                    [Number(SCENARIO_STATUS.ENGINE_RUN_STOPPED)]: EngineRunTrackingState.STOPPED,
                    [Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)]: EngineRunTrackingState.FAILED,
                };

                const response = await engineRunService.list7(undefined, undefined, trackedStatuses, 1, 100, undefined, undefined, signal);

                response.result.items?.forEach(scenario => {
                    const trackedScenario = state.trackedScenarios.find(e => e.scenarioId === scenario.id);

                    if (
                        (trackedScenario && trackedScenario.state !== EngineRunTrackingState.STARTED) ||
                        (trackedScenario && trackedScenario.state === EngineRunTrackingState.MANUALLY_STOPPED) ||
                        (!trackedScenario && scenario.status !== Number(SCENARIO_STATUS.ENGINE_RUN_STARTED))
                    ) {
                        return;
                    }

                    commit('updateTrackingState', {
                        scenarioId: scenario.id,
                        state: trackedStatusesStateMap[scenario.status],
                        name: scenario.name,
                        startedAt: scenario.startedAt,
                    });
                });

                const trackStatusScenarioIds = response.result.items?.map(e => e.id) ?? [];

                state.trackedScenarios.filter(e => !trackStatusScenarioIds.includes(e.scenarioId))
                    .forEach(completedScenario => {
                        commit('updateTrackingState', {
                            scenarioId: completedScenario.scenarioId,
                            state: EngineRunTrackingState.COMPLETED,
                        });
                    })
            } catch (err) {
                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        async fetchRunnableScenarios({ commit }, { query, signal }: PayloadWithSignal<{ query?: string }>): Promise<void> {
            const runnableScenarioStatuses = [
                Number(SCENARIO_STATUS.READY_FOR_ENGINE_RUN),
                Number(SCENARIO_STATUS.ENGINE_RUN_STOPPED),
                Number(SCENARIO_STATUS.ENGINE_RUN_COMPLETED),
                Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)
            ];

            try {
                const response = await engineRunService.list7(undefined, undefined, runnableScenarioStatuses, 1, 20, query, undefined, signal);
                commit('setRunnableScenarios', response.result.items ?? []);
            } catch (err) {
                commit('resetRunnableScenarios');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        async startEngineRun({ commit, dispatch }, { scenarioId }: { scenarioId: number }): Promise<void> {
            await engineRunService.start(scenarioId);

            commit('updateTrackingState', {
                scenarioId,
                state: EngineRunTrackingState.STARTED,
                startedAt: new Date(),
            });

            await dispatch('checkEngineRunState', {});
        },
        async stopCurrentEngineRun({ state, commit, dispatch }): Promise<void> {
            await engineRunService.stopAll(new StopAllEngineRunRequest());

            state.trackedScenarios
                .filter(e => e.state === EngineRunTrackingState.STARTED)
                .forEach(e => {
                    commit('updateTrackingState', {
                        scenarioId: e.scenarioId,
                        state: EngineRunTrackingState.MANUALLY_STOPPED,
                    });
                });

            await dispatch('checkEngineRunState', {});
        }
    },

    getters: {
        runnableScenarios(state): IScenarioRunDto[] {
            return state.runnableScenarios;
        },
        trackedScenarios(state): Record<number, EngineRunTrackingData> {
            const trackedData: Record<number, EngineRunTrackingData> = {};

            state.trackedScenarios.forEach(e => {
                trackedData[e.scenarioId] = { ...e };
            });

            return trackedData;
        },
        latestStartedAt(state): Date | undefined {
            const startedAtDates = Object.values(state.trackedScenarios)
                .filter(e => e.state === EngineRunTrackingState.STARTED)
                .map(e => e.startedAt)
                .filter((e): e is Date => !!e)
                .sort((a: Date, b: Date) => b.getTime() - a.getTime()) ?? [];

            return startedAtDates.length > 0 ? startedAtDates[0] : undefined;
        },
        runningCount(state): number {
            return Object.values(state.trackedScenarios).filter(e => e.state === EngineRunTrackingState.STARTED).length;
        }
    },

    modules: {
        summary: EngineRunSummaryModule,
    },

    namespaced: true,
};

export default EngineRunModule;
