var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.headerLabel,
      "close-on-e-s-c": false,
      "default-footer-confirm-label": _vm.$t('setup.scenario.ceForm.tables.flagged.buttons.save'),
      "default-footer-cancel-label": _vm.$t('setup.scenario.ceForm.tables.flagged.buttons.cancel'),
      "default-footer-confirm-disabled": _vm.isFormSubmitting,
      "default-footer-cancel-disabled": _vm.isFormSubmitting,
      "on-confirm-callback": _vm.handleLightboxConfirm
    },
    on: {
      "puilightbox:close": function ($event) {
        return _vm.handleFormClose(false);
      }
    }
  }, [_c('flagged-costs-form', {
    ref: _vm.REF_CONSTANTS.FORM,
    attrs: {
      "budget-request-id": _vm.budgetRequestId
    },
    on: {
      "change:global-id": _vm.handleChangeGlobalId,
      "close:form": _vm.handleFormClose
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }