import { PUICOLOR_DARK_BLUE } from '@enerlytics/pebble-ui/dist/constants/colors.js'

export enum BM_LOG_LEVEL {
    TOTAL = 'total',
    WARNINGS = 'warnings',
    ERRORS = 'errors',
    COMPLETED = 'completed',
    DEBUG = 'debug',
    ACCEPTABLE = 'acceptable',
}

interface BMLogLevelId {
    [key: string]: string;
}

export const BM_LOG_LEVEL_ID: BMLogLevelId = {
    1: BM_LOG_LEVEL.WARNINGS,
    2: BM_LOG_LEVEL.ERRORS,
    3: BM_LOG_LEVEL.COMPLETED,
    4: BM_LOG_LEVEL.DEBUG,
    5: BM_LOG_LEVEL.ACCEPTABLE,
}

export interface BMLogLevelDetails {
    [key: string]: {
        COLOR: string;
        NAME: string;
    }
}

const DEBUG_COLOR = '#A9AED4';
const ERROR_COLOR = '#FC0D1B';
const COMPLETED_COLOR = '#94CE58';
const WARNING_COLOR = '#FDA428';
export const ACCEPTABLE_COLOR = '#19C142';

export const BM_LOG_LEVEL_DETAILS: BMLogLevelDetails = {
    [BM_LOG_LEVEL.TOTAL]: {
        COLOR: PUICOLOR_DARK_BLUE,
        NAME: 'engineRun.summary.messageCards.total',
    },
    [BM_LOG_LEVEL.WARNINGS]: {
        COLOR: WARNING_COLOR,
        NAME: 'engineRun.summary.messageCards.warnings',
    },
    [BM_LOG_LEVEL.ERRORS]: {
        COLOR: ERROR_COLOR,
        NAME: 'engineRun.summary.messageCards.errors',
    },
    [BM_LOG_LEVEL.COMPLETED]: {
        COLOR: COMPLETED_COLOR,
        NAME: 'engineRun.summary.messageCards.completed',
    },
    [BM_LOG_LEVEL.DEBUG]: {
        COLOR: DEBUG_COLOR,
        NAME: 'engineRun.summary.messageCards.debug',
    },
    [BM_LOG_LEVEL.ACCEPTABLE]: {
        COLOR: ACCEPTABLE_COLOR,
        NAME: 'engineRun.summary.messageCards.acceptable',
    },
}
