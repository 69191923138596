import { Module } from 'vuex';
import {
    IScenarioRunDto
} from '@/service-proxies/service-proxies.g';
import ScenarioCompareModule from '@/store/modules/results/scenario-compare.module';

type ResultModuleState = {
    selectedScenario?: IScenarioRunDto,
};

const initialState: ResultModuleState = {
    selectedScenario: undefined,
};

const ResultsModule: Module<ResultModuleState, any> = {
    state: initialState,

    mutations: {
        setSelectedScenario(state, payload?: IScenarioRunDto): void {
            state.selectedScenario = payload;
        }
    },

    actions: {},

    getters: {
        getSelectedScenario(state): IScenarioRunDto | undefined {
            return state.selectedScenario;
        }
    },

    modules: {
        scenarioCompare: ScenarioCompareModule,
    },

    namespaced: true,
};

export default ResultsModule;
