import { Module } from 'vuex';
import SetupStepModule from '@/store/modules/scenario/steps/setup-step.module';
import { IGetScenarioResponse, ScenarioServiceProxy } from '@/service-proxies/service-proxies.g';
import { PayloadWithSignal } from '@/models/interfaces';
import InflationStepModule from '@/store/modules/scenario/steps/inflation-step.module';
import MtcStepModule from '@/store/modules/scenario/steps/mtc-step.module';
import CostCorrectionStepModule from '@/store/modules/scenario/steps/cost-correction-step.module';
import FormulaStepModule from '@/store/modules/scenario/steps/formula-step.module';
import PrimaryKpiStepModule from '@/store/modules/scenario/steps/primary-kpi-step.module';
import FiltersModule from '@/store/modules/scenario/steps/filters.module';

const scenarioService = new ScenarioServiceProxy();

type ScenarioModuleState = {
    scenario?: IGetScenarioResponse;
    isDuplicating: boolean;
};

const initialState: ScenarioModuleState = {
    scenario: undefined,
    isDuplicating: false,
};

const ScenarioModule: Module<ScenarioModuleState, any> = {
    state: initialState,

    mutations: {
        setScenario(state, scenario: IGetScenarioResponse): void {
            state.scenario = scenario;
        },
        resetScenario(state): void {
            state.scenario = undefined;
        },
        setIsDuplicating(state, payload: boolean): void {
            state.isDuplicating = payload;
        },
        resetIsDuplicating(state): void {
            state.isDuplicating = false;
        },
        updateStep(state, payload: number): void {
            if (!state.scenario) {
                return;
            }

            state.scenario.step = payload;
        }
    },

    actions: {
        async fetchScenario({ commit }, { id, isDuplicating, signal }: PayloadWithSignal<{ id: number, isDuplicating: boolean }>): Promise<IGetScenarioResponse | undefined> {
            try {
                const response = await scenarioService.get(id);
                const scenarioData = response.result;

                if (isDuplicating) {
                    scenarioData.step = 1;
                }

                commit('setScenario', scenarioData);
                commit('setIsDuplicating', isDuplicating);

                return scenarioData;
            } catch (err) {
                commit('resetScenario');
                commit('resetIsDuplicating');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        increaseStepIfNeeded({ state, commit }, targetStep: number): void {
            if ((state.scenario?.step ?? 0) > targetStep) {
                return;
            }

            commit('updateStep', targetStep);
        },
        updateScenario({ commit }, payload: IGetScenarioResponse): void {
            commit('setScenario', payload);
        },
        clearScenario({ commit, dispatch }): void {
            commit('resetScenario');
            commit('resetIsDuplicating');

            dispatch('scenario/filters/clearFilters', undefined, { root: true });
        },
    },

    getters: {
        getScenario(state): IGetScenarioResponse | undefined {
            return state.scenario;
        },
        getScenarioId(state): number | undefined {
            return state.scenario?.id;
        },
        isScenarioLoaded(state): boolean {
            return state.scenario !== undefined;
        },
        isDuplicating(state): boolean {
            return state.isDuplicating;
        }
    },

    modules: {
        setupStep: SetupStepModule,
        formulaStep: FormulaStepModule,
        inflationStep: InflationStepModule,
        mtcStep: MtcStepModule,
        costCorrectionStep: CostCorrectionStepModule,
        primaryKpiStep: PrimaryKpiStepModule,
        filters: FiltersModule,
    },

    namespaced: true,
};

export default ScenarioModule;
