var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-scenario"
  }, [_c('pui-form-group', {
    attrs: {
      "label": _vm.$t('results.kpiAnalysis.selector.selectScenario'),
      "cta-label": _vm.$t('form.clear'),
      "has-cta": true,
      "cta-callback": _vm.clearScenario,
      "show-required-label": true,
      "has-label-padding": false,
      "label-for": "selectScenario"
    }
  }, [_c('benchmarking-type-ahead', {
    ref: _vm.REF_CONSTANTS.TYPEAHEAD,
    attrs: {
      "placeholder": _vm.$t('results.kpiAnalysis.selector.selectPlaceholder'),
      "request-promise-generator": _vm.scenariosRequestPromiseGenerator
    },
    model: {
      value: _vm.selectedScenario,
      callback: function ($$v) {
        _vm.selectedScenario = $$v;
      },
      expression: "selectedScenario"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }