<template>
    <div class="control-card">
        <pui-loader :promise="initialPromise">
            <engine-run-start-card v-if="!isEngineRunning" />
            <engine-run-running-card v-else />
        </pui-loader>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import EngineRunStartCard from '@/components/engine-run-overview/engine-run-cards/engine-run-start-card.vue';
import EngineRunRunningCard from '@/components/engine-run-overview/engine-run-cards/engine-run-running-card.vue';

@Component({
    components: {
        EngineRunRunningCard,
        EngineRunStartCard,
    },
})
export default class EngineRunControlCard extends mixins(ManagedAbortControllers) {
    private initialPromise: Promise<any> | null = null;

    private get engineRunCount(): number {
        return this.$store.getters['engineRun/runningCount'];
    }

    private get isEngineRunning(): boolean {
        return this.engineRunCount !== 0;
    }

    private mounted(): void {
        this.initialPromise = this.$store.dispatch('engineRun/checkEngineRunState', { signal: this.getSignal('checkEngineRun') })
    }
}
</script>

<style scoped lang="scss">
.control-card {
    @include pui-box();
    @include rem(padding, pui-spacing(l));

    display: flex;
    align-items: center;

    background-color: $white;
}
</style>
