import { Module } from 'vuex';
import {
    AddScenarioRequest,
    EditScenarioRequest,
    GetPmlMtpsRequest,
    IAddScenarioRequest,
    IEditScenarioRequest,
    IScenarioList,
    ListSortDirection,
    PmlServiceProxy,
    ScenarioServiceProxy,
    SortableScenarioField,
    IReportingOccasionDto,
} from '@/service-proxies/service-proxies.g';
import { numberCompareAscFn } from '@/utils/sort-utils';
import { PayloadWithSignal } from '@/models/interfaces';

const pmlServiceProxy = new PmlServiceProxy();
const scenarioServiceProxy = new ScenarioServiceProxy();

type SetupStepModuleState = {
    reportingOccasions: IReportingOccasionDto[];
    mtpYears: number[];
    scenarios: IScenarioList[];
}

const initialState: SetupStepModuleState = {
    reportingOccasions: [],
    mtpYears: [],
    scenarios: [],
}

const SetupStepModule: Module<SetupStepModuleState, any> = {
    state: initialState,

    mutations: {
        setReportingOccasions(state, payload: IReportingOccasionDto[]): void {
            state.reportingOccasions = payload;
        },
        resetReportingOccasions(state): void {
            state.reportingOccasions = [];
        },
        setMtpYears(state, payload: number[]): void {
            state.mtpYears = payload;
        },
        resetMtpYears(state): void {
            state.mtpYears = [];
        },
        setScenarios(state, payload: IScenarioList[]): void {
            state.scenarios = payload;
        },
        resetScenarios(state): void {
            state.scenarios = [];
        }
    },

    actions: {
        async fetchReportingOccasions({ commit }, { query, signal }: PayloadWithSignal<{ query: string; }>): Promise<void> {
            try {
                const response = await scenarioServiceProxy.reportingOccasion(query, signal);
                const reportingOccasions = response.result.reportingOccasions ?? [];

                commit('setReportingOccasions', reportingOccasions);
            } catch (err) {
                commit('resetReportingOccasions');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        async fetchMtpYears({ commit }, { signal }: PayloadWithSignal): Promise<void> {
            try {
                const response = await pmlServiceProxy.getMtps(new GetPmlMtpsRequest({}), signal);
                const mtpList = response.result.mtpList ?? [];
                mtpList.sort(numberCompareAscFn);

                commit('setMtpYears', mtpList);
            } catch (err) {
                commit('resetMtpYears');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        async fetchScenarios({ commit }, { query, signal }: PayloadWithSignal<{ query: string; }>): Promise<void> {
            try {
                const response = await scenarioServiceProxy.list2(undefined, undefined, SortableScenarioField.CREATED_AT, ListSortDirection.Descending, 1, 20, query, undefined, signal);
                commit('setScenarios', response.result.items ?? []);
            } catch (err) {
                commit('resetScenarios');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
        async createScenario({ dispatch }, payload: IAddScenarioRequest): Promise<void> {
            const response = await scenarioServiceProxy.add(new AddScenarioRequest(payload));
            await dispatch('scenario/updateScenario', response.result, { root: true });
        },
        async updateScenario({ dispatch }, payload: IEditScenarioRequest): Promise<void> {
            const response = await scenarioServiceProxy.update(new EditScenarioRequest(payload));
            await dispatch('scenario/updateScenario', response.result, { root: true });
        }
    },

    getters: {
        reportingOccasions(state): IReportingOccasionDto[] {
            return state.reportingOccasions;
        },
        mtpYears(state): number[] {
            return state.mtpYears;
        },
        scenarios(state): IScenarioList[] {
            return state.scenarios;
        }
    },

    namespaced: true,
};

export default SetupStepModule;
