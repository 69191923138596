import { Module } from 'vuex';

const unsavedChangesBeforeUnloadEventHandler = (event: BeforeUnloadEvent): string => {
    event.preventDefault();
    event.returnValue = 'message';

    return 'message';
}

type PreventionState = {
    leavePreventRegistrations: Set<string>;
};

const initialState: PreventionState = {
    leavePreventRegistrations: new Set<string>(),
};

const PreventionModule: Module<PreventionState, any> = {
    state: initialState,

    mutations: {
        registerPrevention(state, payload: string): void {
            state.leavePreventRegistrations.add(payload);
        },
        unregisterPrevention(state, payload: string): void {
            state.leavePreventRegistrations.delete(payload);
        }
    },

    actions: {
        registerPrevention({ state, commit }, payload: string): void {
            if (state.leavePreventRegistrations.size === 0) {
                addEventListener('beforeunload', unsavedChangesBeforeUnloadEventHandler, { capture: true });
            }

            commit('registerPrevention', payload);
        },
        unregisterPrevention({ state, commit }, payload: string): void {
            commit('unregisterPrevention', payload);

            if (state.leavePreventRegistrations.size === 0) {
                removeEventListener('beforeunload', unsavedChangesBeforeUnloadEventHandler, { capture: true });
            }
        }
    },

    namespaced: true,
};

export default PreventionModule;
