<template>
    <div class="inner-unit-cell">
        <div
            v-if="isNotSelectable"
            class="inner-unit-cell__checkbox"
        >
            <pui-form-checkbox
                label=""
                :is-disabled="true"
                :checked="true"
            />
        </div>
        <div>{{ textValue }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import { UnitRowModel } from '@/pages/setup/scenario/steps/unit-scenario-step.vue';

@Component({})
export default class InnerUnitGroupCell extends Vue {
    private params?: ICellRendererParams<UnitRowModel>;

    private get textValue(): string {
        if (!this.params) {
            return '';
        }

        return (this.params.node?.level === 0 ? this.params.value : this.params.data?.unitName) ?? '';
    }

    private get isNotSelectable(): boolean {
        if (!this.params) {
            return false;
        }

        return !this.params.node.selectable;
    }
}
</script>

<style scoped lang="scss">
.inner-unit-cell {
    display: flex;
    align-items: center;

    &__checkbox {
        padding-top: 0.8rem;
    }
}
</style>
