var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.headerLabel,
      "fit-content": true,
      "close-on-e-s-c": false,
      "default-footer-confirm-label": _vm.$t('setup.scenario.formulaStepForm.buttons.save'),
      "default-footer-cancel-label": _vm.$t('setup.scenario.formulaStepForm.buttons.cancel'),
      "default-footer-confirm-disabled": !_vm.isFormSubmittable,
      "on-confirm-callback": _vm.lightboxConfirm
    },
    on: {
      "puilightbox:close": function ($event) {
        return _vm.emitClose();
      }
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": _vm.$t(_vm.CONFIG[_vm.currentMode].inputLabel)
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": _vm.$t('admin.modal.input.placeholder')
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }