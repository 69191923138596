var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "status-cell-wrapper"
  }, [_c('div', {
    staticClass: "status-cell-wrapper__dot",
    style: {
      backgroundColor: _vm.statusColor
    }
  }), _c('div', [_vm._v(_vm._s(_vm.statusName))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }