<template>
    <div
        :style="dataCellStyle"
        class="sc-data-cell"
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
        @click="onCellClicked"
    >
        <div
            v-if="isHovering"
            class="sc-data-cell__text"
        >
            {{ formattedPercentageValue }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import { getColorForScenarioCompareStatus } from '@/config/scenario-compare';
import { ScenarioCompareRow } from '@/components/results/scenario-compare/sections/scenario-compare-table.vue';
import { OpenScenarioCompareDetailsPayload } from '@/models/results/scenario-compare';

@Component({})
export default class ScenarioCompareDataCell extends Vue {
    private params?: ICellRendererParams<ScenarioCompareRow>;

    private isHovering = false;

    private get formattedPercentageValue(): string {
        if (!this.params?.data || !this.params?.colDef?.field) {
            return 'N/A';
        }

        const data = this.params.data.kpiData[this.params.colDef.field];

        if (!data || data.percentageChange === undefined) {
            return 'N/A';
        }

        return `${data.percentageChange}%`;
    }

    private get dataCellStyle(): Record<string, string> {
        if (!this.params?.data || !this.params?.colDef?.field) {
            return {};
        }

        return {
            backgroundColor: getColorForScenarioCompareStatus(this.params.data.kpiData[this.params.colDef.field]?.status)
        };
    }

    private onCellClicked(): void {
        if (
            !this.params?.context?.openDetails
            || !this.params.colDef?.field
            || !this.params?.data
            || !this.params.data.unitName
            || !this.params.data.machineSid
            || !this.params.data.kpiData[this.params.colDef.field]
        ) {
            return;
        }

        const payload: OpenScenarioCompareDetailsPayload = {
            unitName: this.params.data.unitName,
            machineSid: this.params.data.machineSid,
            kpi: this.params.colDef.field
        };

        this.params.context.openDetails(payload);
    }
}
</script>

<style scoped lang="scss">
.sc-data-cell {
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;

    cursor: pointer;

    &__text {
        @include rem(border-radius, pui-spacing(xxxs));

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 10px;
        background-color: $dark-grey;
        box-shadow: 0 0 15px rgba(74, 74, 74, 0.25);
        color: $white;
        line-height: 0;
    }
}
</style>
