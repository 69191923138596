var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip"
  }, [_vm.show ? _c('div', {
    staticClass: "tooltip-inner"
  }, [_vm.content ? _c('div', [_vm._v(" " + _vm._s(_vm.content) + " ")]) : _vm._e(), _vm.additionalContent ? _c('div', [_vm._v(" " + _vm._s(_vm.additionalContent) + " ")]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }