import {
    IGetFiltersResponse,
    IScenarioRunDto, ResultServiceProxy,
    RunResultServiceProxy,
    ScenarioCompareResponse
} from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';
import ScenarioCompareDetailsModule from '@/store/modules/results/scenario-compare-details.module';
import { arrayContentsEqual } from '@/utils/array-utils';

const runResultService = new RunResultServiceProxy();
const resultsService = new ResultServiceProxy();

export type ScenarioCompareFilterOptions = {
    countries: number[];
    plants: number[];
    units: number[];
    kpiTypes: number[];
    kpiIds: string[];
    status: number[];
};

type ScenarioCompareModuleState = {
    scenarios: IScenarioRunDto[];
    firstScenario?: IScenarioRunDto;
    secondScenario?: IScenarioRunDto;
    primaryKpiData?: ScenarioCompareResponse;
    secondaryKpiData?: ScenarioCompareResponse;
    filters?: IGetFiltersResponse;
    filterOptions: ScenarioCompareFilterOptions;
    reportedUnits: number[];
};

const initialState: ScenarioCompareModuleState = {
    scenarios: [],
    firstScenario: undefined,
    secondScenario: undefined,
    primaryKpiData: undefined,
    secondaryKpiData: undefined,
    filters: undefined,
    filterOptions: {
        countries: [],
        plants: [],
        units: [],
        kpiTypes: [],
        kpiIds: [],
        status: [],
    },
    reportedUnits: [],
};

const ScenarioCompareModule: Module<ScenarioCompareModuleState, any> = {
    state: initialState,

    mutations: {
        setFirstScenario(state, payload?: IScenarioRunDto): void {
            state.firstScenario = payload;
        },
        setSecondScenario(state, payload?: IScenarioRunDto): void {
            state.secondScenario = payload;
        },
        setPrimaryKpiData(state, payload: ScenarioCompareResponse): void {
            state.primaryKpiData = payload;
        },
        resetPrimaryKpiData(state): void {
            state.primaryKpiData = undefined;
        },
        setSecondaryKpiData(state, payload: ScenarioCompareResponse): void {
            state.secondaryKpiData = payload;
        },
        resetSecondaryKpiData(state): void {
            state.secondaryKpiData = undefined;
        },
        resetSelectedScenarios(state): void {
            state.firstScenario = undefined;
            state.secondScenario = undefined;
        },
        setFilters(state, payload: IGetFiltersResponse): void {
            state.filters = payload;
        },
        resetFilters(state): void {
            state.filters = undefined;
        },
        setFilterOptions(state, payload: ScenarioCompareFilterOptions): void {
            state.filterOptions = payload;
        },
        resetFilterOptions(state): void {
            state.filterOptions = {
                countries: [],
                plants: [],
                units: [],
                kpiTypes: [],
                kpiIds: [],
                status: [],
            };
        },
        setReportedUnits(state, payload: number[]): void {
            state.reportedUnits = payload;
        },
        resetReportedUnits(state): void {
            state.reportedUnits = [];
        }
    },

    actions: {
        async fetchFilters({ state, commit }): Promise<void> {
            if (!state.firstScenario || !state.secondScenario) {
                return;
            }

            const scenarioIds: number[] = [state.firstScenario.id, state.secondScenario.id];

            try {
                commit('resetFilterOptions');
                const response = await resultsService.filter(scenarioIds, true);
                commit('setFilters', response.result);
            } catch (err) {
                commit('resetFilters');
                commit('resetFilterOptions');

                throw err;
            }
        },
        async fetchPrimaryData({ state, commit }): Promise<void> {
            if (!state.firstScenario || !state.secondScenario) {
                return;
            }

            try {
                commit('resetPrimaryKpiData');
                const response = await runResultService.scenarioCompareList(state.firstScenario.id, state.secondScenario.id, state.reportedUnits, undefined, undefined, state.filterOptions.kpiTypes, undefined, state.filterOptions.status, true);
                commit('setPrimaryKpiData', response.result);
            } catch (err) {
                commit('resetPrimaryKpiData');
                throw err;
            }
        },
        async fetchSecondaryData({ state, commit }): Promise<void> {
            if (!state.firstScenario || !state.secondScenario) {
                return;
            }

            try {
                commit('resetSecondaryKpiData');
                const response = await runResultService.scenarioCompareList(state.firstScenario.id, state.secondScenario.id, state.reportedUnits, undefined, undefined, state.filterOptions.kpiTypes, undefined, state.filterOptions.status, false);
                commit('setSecondaryKpiData', response.result);
            } catch (err) {
                commit('resetSecondaryKpiData');
                throw err;
            }
        },
        setFirstScenario({ dispatch, commit }, scenario: IScenarioRunDto | undefined): void {
            commit('setFirstScenario', scenario);

            dispatch('fetchPrimaryData');
            dispatch('fetchSecondaryData');
            dispatch('fetchFilters');
        },
        setSecondScenario({ dispatch, commit }, scenario: IScenarioRunDto | undefined): void {
            commit('setSecondScenario', scenario);

            dispatch('fetchPrimaryData');
            dispatch('fetchSecondaryData');
            dispatch('fetchFilters');
        },
        setFilterOptions({ state, dispatch, commit }, payload: ScenarioCompareFilterOptions): void {
            const shouldRefetch = !arrayContentsEqual(state.filterOptions.kpiTypes, payload.kpiTypes)
                || !arrayContentsEqual(state.filterOptions.status, payload.status);

            commit('setFilterOptions', payload);

            if (shouldRefetch) {
                dispatch('fetchPrimaryData');
                dispatch('fetchSecondaryData');
            }
        },
        setReportedUnits({ state, dispatch, commit }, payload: number[]): void {
            const shouldRefetch = !arrayContentsEqual(state.reportedUnits, payload);
            commit('setReportedUnits', payload);

            if (shouldRefetch) {
                dispatch('fetchPrimaryData');
                dispatch('fetchSecondaryData');
            }
        },
        clearAppliedFilters({ commit }): void {
            commit('resetPrimaryKpiData');
            commit('resetSecondaryKpiData');
            commit('resetFilters');
            commit('resetFilterOptions');
            commit('resetReportedUnits');
        },
        clearScenarioCompare({ commit }): void {
            commit('resetPrimaryKpiData');
            commit('resetSecondaryKpiData');
            commit('resetSelectedScenarios');
            commit('resetFilters');
            commit('resetFilterOptions');
            commit('resetReportedUnits');
        }
    },

    getters: {
        getFirstScenario(state): IScenarioRunDto | undefined {
            return state.firstScenario;
        },
        getSecondScenario(state): IScenarioRunDto | undefined {
            return state.secondScenario;
        },
        getPrimaryKpiData(state): ScenarioCompareResponse | undefined {
            return state.primaryKpiData;
        },
        getSecondaryKpiData(state): ScenarioCompareResponse | undefined {
            return state.secondaryKpiData;
        },
        getFilters(state): IGetFiltersResponse | undefined {
            return state.filters;
        },
        getFilterOptions(state): ScenarioCompareFilterOptions {
            return state.filterOptions;
        },
        getReportedUnits(state): number[] {
            return state.reportedUnits;
        },
        areScenariosNotSelected(state): boolean {
            return !state.firstScenario || !state.secondScenario;
        }
    },

    modules: {
        details: ScenarioCompareDetailsModule,
    },

    namespaced: true,
};

export default ScenarioCompareModule;
