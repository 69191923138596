<template>
    <div class="inflation-table-wrapper">
        <table-header
            :hide-search-bar="true"
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
        />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import InflationTable, { InflationTableRow } from '@/components/inflation-step/common/inflation-table';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { FetchInflationValuesPayload } from '@/models/steps/inflation';
import { ICountryInflationDto } from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';

@Component({
    components: {
        TableHeader,
    }
})
export default class NominatedInflationTable extends InflationTable {
    private get nominatedValuesCount(): number {
        return this.$store.getters['scenario/inflationStep/getNominatedTotalCount'];
    }

    private get gridOptions(): GridOptions<InflationTableRow> {
        return  {
            ...this.commonGridOptions,
            serverSideDatasource: {
                getRows: (params: IServerSideGetRowsParams): void => {
                    const paginationParams = this.getPaginationForServerRequestParams(params);

                    const payload: FetchInflationValuesPayload = {
                        scenarioId: this.scenarioId,
                        ...paginationParams,
                    };

                    this.$store.dispatch('scenario/inflationStep/fetchNominatedValues', payload)
                        .then((nominatedValues: ICountryInflationDto[]) => {
                            const rowData: InflationTableRow[] = [];
                            const years = new Set<number>();

                            nominatedValues.forEach(countryInflation => {
                                const row: InflationTableRow = {
                                    countryName: countryInflation.countryName ?? '',
                                };

                                countryInflation.inflations?.forEach(inflation => {
                                    row[inflation.year.toString()] = inflation.value;
                                    years.add(inflation.year);
                                });

                                rowData.push(row);
                            });

                            this.configureYearColumns(years);

                            params.success({
                                rowData,
                                rowCount: this.nominatedValuesCount,
                            })
                        })
                        .catch(() => params.fail());
                },
            },
            defaultColDef: {
                ...this.commonGridOptions.defaultColDef,
                valueFormatter: (params): string => {
                    const value = params.data?.[params.column.getColId()] ?? '';
                    return this.formatNominatedValue(value);
                },
            }
        };
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private formatNominatedValue(value: number): string {
        const formattedValue = Intl.NumberFormat('en-EN', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(value);
        return `${formattedValue}`;
    }
}
</script>

<style scoped lang="scss">
.inflation-table-wrapper {
    width: 100%;
    @include pui-box();

    ::v-deep &__cell {
        &--highlighted {
            background-color: transparentize($uniper-blue, 0.85) !important;
        }

        &--centered {
            text-align: center !important;

            .ag-header-cell-label {
                justify-content: center;
            }
        }

        &--non-editable {
            background-color: $non-editable-cell-color;
        }
    }
}
</style>
