var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sv-scenario-selector"
  }, [_c('div', {
    staticClass: "sv-scenario-selector__scenario"
  }, [_c('pui-form-group', {
    attrs: {
      "label": _vm.$t('results.siteView.selector.selectScenario'),
      "cta-label": _vm.$t('form.clear'),
      "has-cta": true,
      "cta-callback": _vm.clearScenario,
      "has-label-padding": false,
      "label-for": "selectScenario"
    }
  }, [_c('benchmarking-type-ahead', {
    ref: _vm.REF_CONSTANTS.TYPEAHEAD,
    attrs: {
      "value": _vm.selectedScenarioOption,
      "placeholder": _vm.$t('results.siteView.selector.selectPlaceholder'),
      "request-promise-generator": _vm.scenariosRequestPromiseGenerator
    },
    on: {
      "input": _vm.onSelectedScenarioChange
    }
  })], 1)], 1), _c('div', {
    staticClass: "sv-scenario-selector__year"
  }, [_c('pui-form-group', {
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('results.siteView.selector.selectStartYear'),
      "has-label-padding": false,
      "label-for": "startYear"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "value": _vm.startYear,
      "options": _vm.yearSelectorOptions,
      "label": _vm.$t('results.siteView.selector.selectStartYear'),
      "is-disabled": _vm.yearSelectorOptions.length === 0,
      "search-input-placeholder": _vm.$t('results.siteView.selector.selectPlaceholder'),
      "search-input-id": "startYear"
    },
    on: {
      "change": _vm.onSelectedStartYearChange
    }
  })], 1)], 1), _c('div', {
    staticClass: "sv-scenario-selector__year"
  }, [_c('pui-form-group', {
    attrs: {
      "show-required-label": true,
      "is-valid": !_vm.isEndYearInvalid,
      "label": _vm.$t('results.siteView.selector.selectEndYear'),
      "has-label-padding": false,
      "label-for": "endYear"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('results.siteView.errors.invalidEndYear')) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "value": _vm.endYear,
      "options": _vm.yearSelectorOptions,
      "label": _vm.$t('results.siteView.selector.selectEndYear'),
      "is-valid": !_vm.isEndYearInvalid,
      "is-disabled": _vm.yearSelectorOptions.length === 0,
      "search-input-placeholder": _vm.$t('results.siteView.selector.selectPlaceholder'),
      "search-input-id": "endYear"
    },
    on: {
      "change": _vm.onSelectedEndYearChange
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }