import { Component } from 'vue-property-decorator';
import { GridApi, GridOptions, GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { InflationStepYears } from '@/models/steps/inflation';
import InflationHeaderTooltip
    from '@/components/inflation-step/common/inflation-header-tooltip.vue';
import { FetchPaginationPartial } from '@/models/steps/common';
import { IColumnLimit } from 'ag-grid-community/dist/lib/gridApi';

export type InflationTableRow = {
    countryName: string;
    [key: string]: any;
};

@Component({
    components: {
        AgGridVue,
        InflationHeaderTooltip
    }
})
export default class InflationTable extends mixins(ManagedAbortControllers) {
    private readonly COLUMN_KEYS = {
        COUNTRY_NAME: 'countryName',
    } as const;

    private readonly COLUMN_LIMITS: IColumnLimit[] = [
        { key: this.COLUMN_KEYS.COUNTRY_NAME, maxWidth: 400 },
    ];

    protected tableHeaderFilters = {
        pageSize: 20,
    };

    protected gridApi: GridApi<InflationTableRow> | null = null;
    private isYearDataLoaded = false;

    protected get scenarioId(): number  {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get years(): InflationStepYears {
        return this.$store.getters['scenario/inflationStep/getYears'];
    }

    protected commonGridOptions: Partial<GridOptions<InflationTableRow>> = {
        rowModelType: 'serverSide',
        domLayout: 'autoHeight',
        columnDefs: [
            {
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                headerName: this.$t('setup.scenario.inflationForm.table.countryName'),
                minWidth: 250,
                pinned: 'left',
                editable: false,
                cellClass: 'inflation-table-wrapper__cell--non-editable',
                valueFormatter: (params): string => {
                    return params.data?.[this.COLUMN_KEYS.COUNTRY_NAME] ?? '';
                }
            }
        ],
        defaultColDef: {
            menuTabs: [],
            minWidth: 120,
        },
        serverSideInfiniteScroll: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        onModelUpdated: (event) => {
            event.api.sizeColumnsToFit({ columnLimits: this.COLUMN_LIMITS });
        },
        onGridSizeChanged: (event) => {
            event.api.sizeColumnsToFit({ columnLimits: this.COLUMN_LIMITS });
        },
        onGridReady: this.onGridReady,
    };

    private onGridReady(event: GridReadyEvent<InflationTableRow>): void {
        event.api.sizeColumnsToFit({ columnLimits: this.COLUMN_LIMITS });

        this.gridApi = event.api;
    }

    private isYearHighlighted(year: number): boolean {
        return this.years.referenceYear === year;
    }

    protected configureYearColumns(years: Set<number>): void {
        if (this.isYearDataLoaded || !this.gridApi) {
            return;
        }

        const columnDefinitions = this.gridApi.getColumnDefs() ?? [];

        years.forEach(year => {
            const isHighlighted = this.isYearHighlighted(year);

            const classes = [
                'inflation-table-wrapper__cell--centered',
                isHighlighted ? 'inflation-table-wrapper__cell--highlighted' : '',
            ];

            columnDefinitions.push({
                field: year.toString(),
                headerName: year.toString(),
                headerClass: classes,
                cellClass: classes,
                headerTooltip: isHighlighted ? year.toString() : undefined,
                tooltipComponent: isHighlighted ? 'InflationHeaderTooltip' : undefined,
            });
        });

        this.isYearDataLoaded = true;
        this.gridApi.setColumnDefs(columnDefinitions);
        this.gridApi.sizeColumnsToFit();
    }

    protected getPaginationForServerRequestParams(params: IServerSideGetRowsParams): FetchPaginationPartial {
        return {
            pageNumber: Math.floor((params.request.endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize),
            pageSize: this.tableHeaderFilters.pageSize,
        };
    }

    public refreshData(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }
}
