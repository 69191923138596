<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :close-on-e-s-c="false"
        :default-header-label="headerLabel"
    >
        <pui-loader :promise="detailsPromise">
            <scenario-compare-details-table />
        </pui-loader>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import { OpenScenarioCompareDetailsPayload } from '@/models/results/scenario-compare';
import ScenarioCompareDetailsTable
    from '@/components/results/scenario-compare/lightbox/scenario-compare-details-table.vue';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({
    components: {
        ScenarioCompareDetailsTable
    }
})
export default class ScenarioCompareDetailsLightbox extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private currentPayload: OpenScenarioCompareDetailsPayload | null = null;
    private detailsPromise: Promise<any> | null = null;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox,
    };

    private get headerLabel(): string {
        if (!this.currentPayload?.unitName || !this.currentPayload?.kpi) {
            return '';
        }

        return `${this.currentPayload.unitName} (${this.currentPayload.kpi})`
    }

    public open(payload: OpenScenarioCompareDetailsPayload): void {
        this.currentPayload = payload;
        this.detailsPromise = this.fetchDetails();

        this.$refs.lightbox.open();
    }

    public close(): void {
        this.$refs.lightbox.close();
    }

    private async fetchDetails(): Promise<void> {
        if (!this.currentPayload?.machineSid || !this.currentPayload?.kpi) {
            return;
        }

        return this.$store.dispatch('results/scenarioCompare/details/fetchScenarioCompareDetails', {
            machineSid: this.currentPayload.machineSid,
            kpi: this.currentPayload.kpi,
        });
    }
}
</script>
