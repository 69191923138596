<template>
    <div class="engine-run-message-cards-wrapper">
        <donut-card
            v-for="messageLog in summaryMessageLogCards"
            :key="`messageLog_${messageLog.id}`"
            :promise="promise"
            :title="messageLog.title"
            :color="messageLog.color"
            :count="messageLog.count"
            :total="totalCount"
            :hoverable="isCardHoverable"
            :selected="selectedMessage === messageLog.id"
            wide
            @click.native="selectMessageLog(messageLog.id)"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEngineRunSummaryResponse } from '@/service-proxies/service-proxies.g';
import Spinner from '@/components/spinner/spinner.vue';
import DonutCard from '@/components/donut-card/donut-card.vue';
import { DonutCardDetails } from '@/models/interfaces';
import { BM_LOG_LEVEL, BM_LOG_LEVEL_DETAILS } from '@/config/log-level';

@Component({
    components: {
        Spinner,
        DonutCard
    }
})
export default class EngineRunsummaryMessageCardsComponent extends Vue {
    private selectedMessage: string | null  = null;

    @Prop()
    private promise!: Promise<void>;
    
    @Prop()
    private isCardHoverable!: boolean;
    
    private get summaryMessageLogCards(): DonutCardDetails[] {
        return [
            {
                id: BM_LOG_LEVEL.TOTAL,
                title: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.TOTAL].NAME),
                color: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.TOTAL].COLOR,
                count: this.summary?.total ?? 0,
            },
            {
                id: BM_LOG_LEVEL.WARNINGS,
                title: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.WARNINGS].NAME),
                color: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.WARNINGS].COLOR,
                count: this.summary?.warnings ?? 0,
            },
            {
                id: BM_LOG_LEVEL.ERRORS,
                title: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.ERRORS].NAME),
                color: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.ERRORS].COLOR,
                count: this.summary?.errors ?? 0,
            },
            {
                id: BM_LOG_LEVEL.COMPLETED,
                title: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.COMPLETED].NAME),
                color: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL.COMPLETED].COLOR,
                count: this.summary?.infos ?? 0,
            },
        ];
    }

    private get summary(): IEngineRunSummaryResponse | undefined {
        return this.$store.getters['engineRun/summary/getEngineRunSummary'];
    }

    private get totalCount(): number {
        return this.summary && this.summary.total !== 0 ? this.summary.total : 1;
    }

    private selectMessageLog(id: string): void {
        if (!this.isCardHoverable) {
            return;
        }
        
        this.selectedMessage = this.selectedMessage !== id ? id : null;
        this.$emit('on-selected-message-changed', this.selectedMessage);
    }
}
</script>

<style scoped lang="scss">
.engine-run-message-cards-wrapper {
    display: flex;
    @include rem(gap, pui-spacing(m));
}
</style>