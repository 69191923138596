import { Module } from 'vuex';
import { EditPrimaryKpiRequest, FileParameter, IPrimaryDataSummaryResponse, PrimaryDataServiceProxy } from '@/service-proxies/service-proxies.g';
import { FilterParams, Summary } from '@/models/interfaces';
import Vue from 'vue';

const primaryDataServiceProxy = new PrimaryDataServiceProxy();

type PrimaryKpiStepState = {
    summary: Summary;
    file: FileParameter,
};

const initialState: PrimaryKpiStepState = {
    summary: {
        kpis: 0,
        physicalUnits: 0,
        virtualUnits: 0,
        sites: 0,
        missingKpisPercent: 0,
        uploadedFile: null,
    },
    file: {
        data: null,
        fileName: '',
    }
};

const PrimaryKpiStepModule: Module<any, any> = {
    state: initialState,

    mutations: {
        setSummmary(state, payload: IPrimaryDataSummaryResponse) {
            state.summary = payload;
        },
        resetSummmary(state) {
            state.summary = {
                kpis: 0,
                physicalUnits: 0,
                virtualUnits: 0,
                sites: 0,
                missingKpisPercent: 0,
                uploadedFile: null,
            };
        },
        setFile(state, payload: FileParameter) {
            state.file = payload;
        },
        resetFile(state) {
            state.file = {
                data: null,
                fileName: '',
            }
        }
    },

    actions: {
        async fetchUser(context, kid: string): Promise<string | undefined> {
            const response = await Vue.prototype.$sdk.$http.$core.userAccessManagement.request.api.get(`/users/by-kid/${kid}`);
            return response.data?.result?.name;
        },
        async fetchSummary({ commit },  id: number): Promise<void> {
            try {
                const response = await primaryDataServiceProxy.summary(id);
                if (response.result) {
                    commit('setSummmary', response.result);
                } else {
                    return Promise.reject(206);
                }
            } catch (err) {
                commit('resetSummmary');
                throw err;
            }
        },
        async editPrimaryData(context, payload: EditPrimaryKpiRequest): Promise<void> {
            await primaryDataServiceProxy.editPrimaryData(payload);
        },
        async uploadFile(context, { file, scenarioId }: { file: FileParameter, scenarioId: number }): Promise<void> {
            await primaryDataServiceProxy.uploadPrimaryData(file, scenarioId);
        },
        async exportPrimaryData({ commit }, filterParams: FilterParams): Promise<void> {
            try {
                const {
                    scenarioId,
                    sortField,
                    sortDirection,
                    showOnlyMissingValues,
                    searchTerm,
                    plants,
                    countries,
                    kpiTypes,
                    years,
                    technologies,
                } = filterParams;

                const params = new URLSearchParams();

                if (sortField !== undefined) {
                    params.append('sortField', sortField.toString());
                }

                if (sortDirection !== undefined) {
                    params.append('sortDirection', sortDirection.toString());
                }

                params.append('term', searchTerm);
                params.append('showOnlyMissingValues', showOnlyMissingValues.toString());

                plants.forEach(e => params.append('plantSid', e.toString()));
                countries.forEach(e => params.append('countrySid', e.toString()));
                kpiTypes.forEach(e => params.append('kpiType', e.toString()));
                years.forEach(e => params.append('years', e.toString()));
                technologies.forEach(e => params.append('technologySid', e.toString()));

                const response = await Vue.prototype.$sdk.$http.$generic.request.api.get(`/api/v1/primary-data-kpi/export/${scenarioId}?${params.toString()}`, { responseType: 'blob' });
                const name = response.headers['content-disposition'].split('UTF-8\'\'')[1];

                if (response && response.data) {
                    const fileResponse: FileParameter = {
                        data: response.data,
                        fileName: name,
                    }
                    commit('setFile', fileResponse);
                }
            } catch(e) {
                commit('resetFile');
                throw e;
            }
        }
    },

    getters: {
        getSummary(state): Summary {
            return state.summary;
        },
        getFile(state): FileParameter {
            return state.file;
        }
    },

    namespaced: true,
};

export default PrimaryKpiStepModule;
