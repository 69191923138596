var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "primary-data-table"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.disableExport && _vm.isYearDataLoaded,
      expression: "!disableExport && isYearDataLoaded"
    }],
    staticClass: "primary-data-table__year-filter"
  }, _vm._l(_vm.yearRange, function (year) {
    return _c('pui-chip', {
      key: year.value.toString(),
      staticClass: "primary-data-table__year-filter__chip",
      attrs: {
        "label": year.value.toString(),
        "border-color": _vm.selectedYearColor(year.value)
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.handleSelectYear(year.value);
        }
      }
    });
  }), 1), _c('div', {
    staticClass: "primary-data-table__table"
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.filtersPromise,
      "size": "60px"
    }
  }, [_c('div', {
    staticClass: "primary-data-table__table__extra-header"
  }, [_c('pui-toggle', {
    on: {
      "change": _vm.handleShowOnlyMissingValues
    },
    scopedSlots: _vm._u([{
      key: "puiToggleRight",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.table.showOnlyMissingValues')) + " ")];
      },
      proxy: true
    }])
  }), _c('pui-button', {
    attrs: {
      "small": "",
      "icon": "download",
      "state": "secondary",
      "disabled": _vm.disableExport
    },
    on: {
      "click": function ($event) {
        return _vm.handleExportPrimaryDataClick();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.table.exportToExcel')) + " ")])], 1), _c('table-header', {
    attrs: {
      "search-term": _vm.tableExtraFilters.searchTerm,
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1), _c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: _vm.PRIMARY_DATA_FILE,
    attrs: {
      "href": _vm.primaryDataUrl,
      "download": _vm.primaryDataFile.fileName
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }