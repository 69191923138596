<template>
    <div class="engine-run-table">
        <div class="engine-run-table__table">
            <table-header
                :search-term="tableExtraFilters.searchTerm"
                :page-size="tableExtraFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
                @search-triggered="onTriggerSearch"
                @clear-search-term="onClearSearchTerm"
            />
            <ag-grid-vue
                class="ag-theme-alpine"
                :grid-options="gridOptions"
                @grid-ready="onGridReady"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
    GridApi,
    GridOptions,
    ICellRendererParams,
    IServerSideGetRowsParams,
    ITooltipParams,
    ValueFormatterParams
} from 'ag-grid-community';
import { EngineRunServiceProxy, IScenarioRunDto, ListSortDirection, SortableEngineRunField } from '@/service-proxies/service-proxies.g';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { getFormattedTime } from '@/utils/formatters';
import LinkCell from '@/components/benchmarking-table/cell-types/link-cell.vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import ScenarioStatusCell from '@/components/benchmarking-table/cell-types/scenario-status-cell.vue';
import ERActions from '@/components/engine-run-overview/engine-run-table/er-actions-cell.vue';
import { SCENARIO_STATUS, SCENARIO_STATUS_DETAILS } from '@/config/status';
import { LinkCellParams } from '@/models/interfaces';

@Component({
    components: {
        AgGridVue,
        LinkCell,
        TableHeader,
        SimpleTooltip,
        ScenarioStatusCell,
        ERActions,
    }
})
export default class EngineRunTableComponent extends Vue {
    private engineRunService = new EngineRunServiceProxy();
    private gridApi: GridApi<IScenarioRunDto> | null = null;
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    private gridOptions: GridOptions<IScenarioRunDto> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const sortField = this.sortField(params.request.sortModel[0]?.colId);
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort);
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;
                const filters = this.getSelectedFiltersForServerRequestParams(params);

                const status = filters.status
                    ? filters.status.map(e => Number(e))
                    : [Number(SCENARIO_STATUS.ENGINE_RUN_COMPLETED), Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)];

                this.engineRunService.list7(sortField, sortDirection, status, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        params.success({ rowData: response.result.items ?? [], rowCount: response.result.count });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.name'),
                field: 'name',
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams<IScenarioRunDto>): LinkCellParams => {
                    return {
                        title: params.data?.name,
                        id: params.data?.id,
                        routerName: 'EngineRunSummary',
                    }
                },
                tooltipField: 'name',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<IScenarioRunDto>): { content?: string } => ({
                    content: params.data?.description
                }),
                pinned: 'left',
                resizable: true,
                minWidth: 600,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.status'),
                field: 'status',
                cellRenderer: 'ScenarioStatusCell',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: [
                        Number(SCENARIO_STATUS.ENGINE_RUN_COMPLETED),
                        Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)
                    ],
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.$t(SCENARIO_STATUS_DETAILS[params.value].NAME);
                    }
                },
                sortable: false,
                minWidth: 250,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.kpisCalculated'),
                field: 'kpisCalculated',
                sortable: false,
                minWidth: 150,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.sites'),
                field: 'sites',
                sortable: false,
                minWidth: 100,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.units'),
                field: 'units',
                sortable: false,
                minWidth: 100,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.warnings'),
                field: 'warnings',
                sortable: false,
                minWidth: 100,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.startedBy'),
                field: 'startedBy',
                valueFormatter: (params): string => params.value ?? '-',
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.createdUpdated'),
                field: 'createdUpdated',
                sort: 'desc',
                valueFormatter: (params): string => params.value ? getFormattedTime(params.value) : '-',
                minWidth: 250,
            },
            {
                headerName: this.$t('engineRun.overview.engineRunTable.headers.actions'),
                field: 'actions',
                cellRenderer: 'ERActions',
                sortable: false,
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: true,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
            maxWidth: 800,
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    public refreshData(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onGridReady(params: AgGridCommon<IScenarioRunDto>): void {
        this.gridApi = params.api;
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if (!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortableEngineRunField | undefined {
        if (!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortableEngineRunField> = {
            'name': SortableEngineRunField.NAME,
            'startedBy': SortableEngineRunField.Started_By,
            'createdUpdated': SortableEngineRunField.Created_Updated,
        };

        return fieldKey[colId];
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): { status?: string[] } {
        return {
            status: params.request.filterModel?.status?.values,
        };
    }
}
</script>

<style lang="scss" scoped>
.engine-run-table {
    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3rem;

        &__text {
            font-size: 1.4rem;
            font-weight: bold;
            color: $dark-blue-lighter;
        }
    }

    &__table {
        width: 100%;
        background-color: $white;
        @include pui-box();
    }
}
</style>

