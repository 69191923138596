export enum ScenarioCompareStatus {
    GREEN = 1,
    RED = 2,
    GREY = 3,
    ORANGE = 4,
}

export const getColorForScenarioCompareStatus = (status?: number): string => {
    switch (status) {
    case ScenarioCompareStatus.GREEN:
        return '#19C142';
    case ScenarioCompareStatus.RED:
        return '#CD0000';
    case ScenarioCompareStatus.GREY:
        return '#ADADAD';
    case ScenarioCompareStatus.ORANGE:
        return '#FFAB00';
    default:
        return '#FFFFFF';
    }
}

export const getNameForScenarioCompateStatus = (status?: number): string => {
    switch (status) {
    case ScenarioCompareStatus.GREEN:
        return 'results.scenarioCompare.status.green';
    case ScenarioCompareStatus.RED:
        return 'results.scenarioCompare.status.red';
    case ScenarioCompareStatus.GREY:
        return 'results.scenarioCompare.status.grey';
    case ScenarioCompareStatus.ORANGE:
        return 'results.scenarioCompare.status.orange';
    default:
        return '-';
    }
}
