<template>
    <div class="kpi-report">
        <pui-collapsible
            :headline="$t('results.siteView.kpiReport.title')"
            class="kpi-report__collapsible"
        >
            <div class="kpi-report__collapsible__inputs">
                <kpi-analysis-kpi-selectors
                    :is-full-width="true"
                    :scenario-id="scenarioId"
                    :scenario-filters="scenarioFilters"
                    :is-disabled="!scenarioFilters"
                    @change:kpi-id="selectedKpiId = $event"
                />
            </div>
            <pui-loader-error
                v-if="shouldShowErrorMessage"
                :title="$t('results.siteView.noData.title')"
                :message="isTrendReportOptionsIncomplete ? $t('results.siteView.noData.message') :$t('results.siteView.noData.kpiMessage') "
                icon="error-empty-data"
            />
            <pui-loader
                v-show="!shouldShowErrorMessage"
                :promise="kpiReportPromise"
            >
                <site-view-trend-reports-chart
                    v-if="kpiReport.length > 0"
                    :chart-data="kpiReport"
                    :scenario-filters="scenarioFilters"
                />
                <pui-loader-error
                    v-else
                    :title="$t('results.siteView.noData.title')"
                    icon="error-empty-data"
                />
            </pui-loader>
        </pui-collapsible>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import {
    GetFiltersResponse,
    IKpiReportUnitDto, SiteViewServiceProxy
} from '@/service-proxies/service-proxies.g';
import SiteViewTrendReportsChart from '@/components/results/site-view/charts/site-view-trend-reports-chart.vue';
import KpiAnalysisKpiSelectors from '@/components/results/kpi-analysis/selectors/kpi-analysis-kpi-selectors.vue';
import { TrendReportsRequest } from '@/models/results/site-view';

@Component({
    components: {
        KpiAnalysisKpiSelectors,
        SiteViewTrendReportsChart,
    }
})
export default class SiteViewKpiReport extends mixins(ManagedAbortControllers) {
    private readonly siteViewService = new SiteViewServiceProxy()

    private isLoadingKpiReport = false;
    private kpiReportPromise: Promise<any> | null = null;

    private selectedKpiId: string | null = null;
    private kpiReport: IKpiReportUnitDto[] = [];

    @Prop({ default: () => ({}) })
    private trendReportOptions!: Partial<TrendReportsRequest>;

    @Prop({ required: true })
    private scenarioId!: number | null;

    @Prop({ required: true })
    private scenarioFilters!: GetFiltersResponse | null;

    private get isTrendReportOptionsIncomplete(): boolean {
        return !this.trendReportOptions.scenarioId
            || !this.trendReportOptions.startYear
            || !this.trendReportOptions.endYear
            || !this.trendReportOptions.unitSids
            || this.trendReportOptions.unitSids.length === 0;
    }

    private get isKpiSelectionIncomplete(): boolean {
        return !this.selectedKpiId;
    }

    private get shouldShowErrorMessage(): boolean {
        return (this.isTrendReportOptionsIncomplete || this.isKpiSelectionIncomplete)
            && !this.isLoadingKpiReport;
    }

    @Watch('selectedKpiId')
    @Watch('trendReportOptions', { deep: true })
    private onSelectedKpiIdChange(): void {
        const { scenarioId, startYear, endYear, unitSids } = this.trendReportOptions;

        if (!scenarioId || !startYear || !endYear || !unitSids || !this.selectedKpiId) {
            return;
        }

        this.kpiReportPromise = this.siteViewService.kpiReport(scenarioId, this.selectedKpiId, startYear, endYear, unitSids)
            .then(response => this.kpiReport = response.result.items ?? []);
    }
}
</script>

<style scoped lang="scss">
.kpi-report {
    &__collapsible {
        background-color: white;

        &__content {
            @include rem(padding, 0 pui-spacing(m));
            @include rem(padding-top, pui-spacing(l));

            display: flex;
        }

        &__inputs {
            @include rem(gap, pui-spacing(s));
            @include rem(padding, pui-spacing(m));

            display: flex;
            padding-bottom: 0;

            & > * {
                flex-grow: 1;
            }
        }
    }
}
</style>
