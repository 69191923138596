<template>
    <div
        v-if="shouldDisplayTooltip"
        class="tooltip"
        role="tooltip"
    >
        <div class="tooltip-inner">
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.inflationForm.tooltip.referenceYear', { year: years.referenceYear }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.inflationForm.tooltip.startYear', { year: years.startYear }) }}
            </div>
            <div class="tooltip-inner__text">
                {{ $t('setup.scenario.inflationForm.tooltip.endYear', { year: years.endYear }) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ITooltipParams } from 'ag-grid-community';
import { InflationStepYears } from '@/models/steps/inflation';

@Component({})
export default class InflationHeaderTooltip extends Vue {
    private params?: ITooltipParams;

    private get years(): InflationStepYears {
        return this.$store.getters['scenario/inflationStep/getYears'];
    }

    private get shouldDisplayTooltip(): boolean {
        return !this.params?.rowIndex;
    }
}
</script>

<style scoped lang="scss">
.tooltip {
    .tooltip-inner {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        &__text {
            color: $white;
            font-size: 1.4rem;
        }
    }
}
</style>
