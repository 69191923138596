<template>
    <div class="site-view">
        <div class="site-view__selectors">
            <site-view-scenario-selector
                :start-year="startYear"
                :end-year="endYear"
                @change:start-year="startYear = $event"
                @change:end-year="endYear = $event"
            />
            <site-view-units-selector
                :filters="scenarioFilters"
                @change:units="unitSids = $event"
            />
        </div>
        <div class="site-view__trend-reports">
            <site-view-trend-reports
                :trend-report-options="trendReportOptions"
                :scenario-filters="scenarioFilters"
            />
        </div>
        <div
            :key="selectedScenarioId ?? -1"
            class="site-view__kpi-report"
        >
            <site-view-kpi-report
                :scenario-id="selectedScenarioId"
                :scenario-filters="scenarioFilters"
                :trend-report-options="trendReportOptions"
            />
            <site-view-kpi-report
                :scenario-id="selectedScenarioId"
                :scenario-filters="scenarioFilters"
                :trend-report-options="trendReportOptions"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import SiteViewScenarioSelector from '@/components/results/site-view/site-view-scenario-selector.vue';
import SiteViewTrendReports from '@/components/results/site-view/sections/site-view-trend-reports.vue';
import SiteViewUnitsSelector from '@/components/results/site-view/site-view-units-selector.vue';
import SiteViewKpiReport from '@/components/results/site-view/sections/site-view-kpi-report.vue';
import { GetFiltersResponse, IScenarioRunDto, ResultServiceProxy } from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { TrendReportsRequest } from '@/models/results/site-view';

@Component({
    components: {
        SiteViewKpiReport,
        SiteViewScenarioSelector,
        SiteViewUnitsSelector,
        SiteViewTrendReports,
    }
})
export default class ResultsSiteView extends mixins(ManagedAbortControllers) {
    private readonly resultsService = new ResultServiceProxy();

    private startYear: number | null = null;
    private endYear: number | null = null;
    private unitSids: number[] | null = null;

    private scenarioFilters: GetFiltersResponse | null = null;

    private get selectedScenario(): IScenarioRunDto | undefined {
        return this.$store.getters['results/getSelectedScenario'];
    }

    private get selectedScenarioId(): number | undefined {
        return this.selectedScenario?.id;
    }

    private get trendReportOptions(): Partial<TrendReportsRequest> {
        return {
            scenarioId: this.selectedScenarioId,
            startYear: this.startYear ?? undefined,
            endYear: this.endYear ?? undefined,
            unitSids: this.unitSids ?? undefined,
        };
    }

    @Watch('selectedScenarioId', { immediate: true })
    private async onSelectedScenarioIdChange(newSelectedScenarioId: number | null): Promise<void> {
        this.scenarioFilters = null;

        if (!newSelectedScenarioId) {
            return;
        }

        const response = await this.resultsService.filter([newSelectedScenarioId], true, this.getSignal('filters', true));
        this.scenarioFilters = response.result;
    }
}
</script>

<style scoped lang="scss">
.site-view {
    @include rem(padding, pui-spacing(m) 0);
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: column;

    &__selectors {
        display: flex;
        justify-content: space-between;
    }

    &__kpi-report {
        @include rem(gap, pui-spacing(m));
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
</style>
