<template>
    <div class="summary-card">
        <spinner
            :promise="summaryPromise"
            :is-inline-error="false"
            size="60px"
            min-height="100px"
        >
            <pui-grid-row>
                <pui-grid-column
                    class="summary-card__section"
                    :cols="COLUMN_CONFIG"
                >
                    <pui-headline
                        class="summary-card__section__headline"
                        type="h5"
                    >
                        {{ $t('setup.scenario.primaryKpiStep.summaryCard.summary') }}
                    </pui-headline>
                    <div class="summary-card__section__item">
                        <pui-headline
                            class="summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.kpisSelected', { count: summary.kpis }) }}
                        </pui-headline>
                    </div>
                    <div class="summary-card__section__item">
                        <pui-headline
                            class="summary-card__section__item__content summary-card__section__item__content--highlight"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.missingKpis', { count: summary.missingKpisPercent }) }}
                        </pui-headline>
                    </div>
                    <div class="summary-card__section__item summary-card__section__item--inline">
                        <pui-headline
                            class="summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.sites', { count: summary.sites }) }}
                        </pui-headline>
                        <pui-headline
                            class="summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.physicalUnits', { count: summary.physicalUnits }) }}
                        </pui-headline>
                        <pui-headline
                            class="summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.virtualUnits', { count: summary.virtualUnits }) }}
                        </pui-headline>
                    </div>
                </pui-grid-column>

                <pui-grid-column
                    class="summary-card__section--upload-info-column"
                    :cols="COLUMN_CONFIG"
                >
                    <pui-headline
                        class="summary-card__section__headline"
                        type="h5"
                    >
                        {{ $t('setup.scenario.primaryKpiStep.summaryCard.uploadedFiles') }}
                    </pui-headline>
                    <div class="summary-card__section__item">
                        <pui-headline
                            class="summary-card__section__item__title"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.fileName') }}:
                            <span class="summary-card__section__item__content summary-card__section__item__content--info">
                                {{ summary.uploadedFile?.name ?? '-' }}
                            </span>
                        </pui-headline>
                    </div>
                    <div class="summary-card__section__item">
                        <pui-headline
                            class="summary-card__section__item__title"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.date') }}:
                            <span class="summary-card__section__item__content summary-card__section__item__content--info">
                                {{ formattedDate }}
                            </span>
                        </pui-headline>
                    </div>
                    <div class="summary-card__section__item">
                        <pui-headline
                            class="summary-card__section__item__title"
                            type="h6"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.uploadedBy') }}:
                            <span class="summary-card__section__item__content summary-card__section__item__content--info">
                                {{ uploadedByName ?? '-' }}
                            </span>
                        </pui-headline>
                    </div>
                </pui-grid-column>

                <pui-grid-column
                    class="summary-card__section--action-column"
                    :cols="COLUMN_CONFIG"
                >
                    <div class="summary-card__section--action-column__content">
                        <pui-form-file
                            :key="fileFormKey"
                            :label="$t('setup.scenario.primaryKpiStep.summaryCard.uploadFile')"
                            accept=".xlsx, .xls"
                            :max-file-weight="1e8"
                            :on-change-callback="onFileAdded"
                        />
                        <pui-button
                            small
                            @click="handleOpenETLLightbox"
                        >
                            {{ $t('setup.scenario.primaryKpiStep.summaryCard.showETLLogMessages') }}
                        </pui-button>
                    </div>
                </pui-grid-column>
            </pui-grid-row>
        </spinner>
        <etl-lightbox
            :lightbox-open="isETLLightboxOpen"
            @close-lightbox="handleCloseETLLightbox"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Summary } from '@/models/interfaces';
import Spinner from '@/components/spinner/spinner.vue';
import ETLLightbox from '@/components/primary-kpi-step/summary/etl-lightbox/etl-lightbox.vue';
import { InternalServerErrorResponse } from '@/service-proxies/service-proxies.g';
import { getFormattedTime } from '@/utils/formatters';

@Component({
    components: {
        Spinner,
        'etl-lightbox': ETLLightbox
    }
})
export default class SummaryCardComponent extends Vue {
    private summaryPromise: Promise<void> | null = null;

    private uploadedByName: string | null = null;
    private fileFormKey = 1;

    private isETLLightboxOpen = false;

    private readonly COLUMN_CONFIG = { s: 4 };

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get summary(): Summary {
        return this.$store.getters['scenario/primaryKpiStep/getSummary'];
    }

    private mounted(): void {
        this.fetchSummary();
    }

    private fetchSummary(): void {
        const id = this.scenarioId;
        this.summaryPromise = this.$store.dispatch('scenario/primaryKpiStep/fetchSummary', id)
            .then(async () => {
                if (this.summary.uploadedFile) {
                    this.uploadedByName = await this.$store.dispatch('scenario/primaryKpiStep/fetchUser', this.summary.uploadedFile.uploadedBy);
                }
            })
    }

    private get formattedDate(): string {
        return this.summary?.uploadedFile?.uploadedAt ? getFormattedTime(this.summary.uploadedFile.uploadedAt) : '-';
    }

    private async onFileAdded(file: File): Promise<void> {
        const payload = {
            file: {
                data: file,
                fileName: file.name,
            },
            scenarioId: this.scenarioId,
        }
        try {
            this.$emit('on-handle-saving', true);
            await this.$store.dispatch('scenario/primaryKpiStep/uploadFile', payload);
            this.fetchSummary();
        } catch(e) {
            let copy = '';
            if (e instanceof InternalServerErrorResponse) {
                copy = e.message ?? this.$t('setup.scenario.primaryKpiStep.summaryCard.toastMessages.uploadFile.copy');
            }
            this.$pui.toast({
                type: 'error',
                title: this.$t('setup.scenario.primaryKpiStep.summaryCard.toastMessages.uploadFile.title'),
                copy,
            });
        } finally {
            this.$emit('on-handle-saving', false);
            this.fileFormKey++;
        }
    }

    private handleCloseETLLightbox(): void {
        this.isETLLightboxOpen = false;
    }

    private handleOpenETLLightbox(): void {
        this.isETLLightboxOpen = true;
    }
}
</script>

<style lang="scss" scoped>
.summary-card {
    @include pui-box();
    @include rem(padding, pui-spacing(s));
    border-left: 6px solid red;

    &__section {
        @include rem(padding-left, pui-spacing(m));

        &--upload-info-column {
            border-left: 1px solid $warm-grey-25;
            @include rem(padding-left, pui-spacing(xl));
        }

        &--action-column {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                @include rem(gap, pui-spacing(s));

                .pui-form-file {
                    padding: 0;
                }
            }
        }

        &__headline {
            color: $warm-grey;
            @include rem(margin-bottom, pui-spacing(s));
        }

        &__item {
            @include rem(margin-bottom, pui-spacing(s));

            &--inline {
                display: flex;
                @include rem(gap, pui-spacing(xl));
            }

            &__content {
                color: $dark-grey;
                font-weight: bold;

                &--highlight {
                    color: red;
                }

                &--info {
                    @include rem(padding-left, pui-spacing(xs));
                }
            }

            &__title {
                color: $warm-grey;
                font-weight: normal;
            }
        }
    }
}
</style>
