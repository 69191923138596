<template>
    <div class="secondary-kpi-tab">
        <kpi-summary-card :ref="KPI_SUMMARY_CARD_REF" />
        <div class="secondary-kpi-tab__actions">
            <div class="secondary-kpi-tab__actions__buttons">
                <secondary-kpi-scenario-import
                    :is-disabled="!!rowCount"
                    @import:finished="reloadAll"
                />
                <pui-button
                    icon="add"
                    state="primary"
                    :small="true"
                    :disabled="isSaving"
                    @click="openSecondaryKpiLightbox()"
                >
                    {{ $t('setup.scenario.formulaStep.addNewKpi') }}
                </pui-button>
            </div>
        </div>
        <div class="secondary-kpi-tab__secondary-kpi-table">
            <spinner
                v-if="isSaving"
                :promise="infinitePromise"
            />
            <spinner
                v-else
                :promise="filtersPromise"
            >
                <table-header
                    :page-size="tableExtraFilters.pageSize"
                    @page-size-changed="onPageSizeChanged"
                    @search-triggered="onTriggerSearch"
                    @clear-search-term="onClearSearchTerm"
                />
                <ag-grid-vue
                    class="ag-theme-alpine"
                    :grid-options="gridOptions"
                    @grid-ready="onGridReady"
                />
            </spinner>
        </div>
        <secondary-kpi-lightbox
            :secondary-kpi="secondaryKpiDetails"
            :lightbox-open="isSecondaryKpiLightboxOpen"
            @close-lightbox="handleCloseSecondaryKpiLightbox"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { GridApi, GridOptions, ICellRendererParams, IHeaderParams, IServerSideGetRowsParams, NewValueParams, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { ActivateDeactivateKpiDto, AddKpisToScenarioRequest, IKpiDto, KpiResponseDto, KpiServiceProxy, ListSortDirection, SortableKpiField } from '@/service-proxies/service-proxies.g';
import CheckboxCell from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-checkbox-cell.vue';
import SecondaryKpiActionsCell from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-actions-cell.vue';
import SecondaryKpiLightbox from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-lightbox/secondary-kpi-lightbox.vue';
import LoadingOverlay from '@/components/benchmarking-table/overlay-types/loading-overlay.vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import Spinner from '@/components/spinner/spinner.vue';
import KpiSummaryCard from '@/components/formula-step/kpi-summary-card/kpi-summary-card.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';
import SecondaryKpiScenarioImport from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-scenario-import.vue';
import CheckboxHeaderCell from '@/components/benchmarking-table/cell-types/checkbox-header-cell.vue';
import { ConstantEntry } from '@/store/modules/constants.module';

type PrimaryDataKpiSetFilters = {
    kpiTypes: number[],
};

const KPI_SUMMARY_CARD_REF = 'kpiSummaryCard' as const;

@Component({
    components: {
        SecondaryKpiLightbox,
        'ag-grid-vue': AgGridVue,
        'checkboxCell': CheckboxCell,
        'secondaryKpiActionsCell': SecondaryKpiActionsCell,
        'loadingOverlay': LoadingOverlay,
        TableHeader,
        Spinner,
        KpiSummaryCard,
        SecondaryKpiScenarioImport,
        CheckboxHeaderCell,
    }
})
export default class SecondaryKpiTabComponent extends Vue {
    private readonly KPI_SUMMARY_CARD_REF = KPI_SUMMARY_CARD_REF;
    private kpiService = new KpiServiceProxy();
    private gridApi: GridApi<IKpiDto> | null = null;
    private secondaryKpiDetails: IKpiDto | null = null
    private modifiedKpiActive: ActivateDeactivateKpiDto[] = [];

    $refs!: {
        [KPI_SUMMARY_CARD_REF]: KpiSummaryCard,
    };

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
    private filtersPromise: Promise<any> | null = null;
    private scenarioPromise: Promise<any> | null = Promise.resolve();

    private isSecondaryKpiLightboxOpen = false;
    private isSaving = false;

    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    private rowCount = 1;

    private readonly GREEN = 'green';
    private readonly RED = 'red';
    private readonly YES = this.$t('setup.scenario.formulaStep.cell.yes');
    private readonly NO = this.$t('setup.scenario.formulaStep.cell.no');

    /**
     * This prop is required to know the selected status.
     */
    @Prop({ default: '0' })
    private selectedStatus!: string;

    private gridOptions: GridOptions<IKpiDto> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const { kpiTypes } = this.getSelectedFiltersForServerRequestParams(params);
                const scenarioId = this.scenarioId;
                const kpiActiveFilter = this.kpiActiveFilter(params.request.filterModel?.kpiActive?.values);
                const sortField = this.sortField(params.request.sortModel[0]?.colId)
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort)
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;

                this.kpiService.list4(scenarioId, false, kpiTypes, kpiActiveFilter, sortField, sortDirection, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        this.setRowCount(response.result.count);
                        params.success({ rowData: response.result.items?.map(e => ({ ...e.kpiFormula, kpis: e.kpis, kpiUsedBy: e.kpiUsedBy, runByEngine: e.runByEngine })) ?? [], rowCount: response.result.count });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiType'),
                field: 'kpiTypeId',
                valueFormatter: (params: ValueFormatterParams): string => {
                    return params.value ? this.scenarioFilters.kpiTypes[params.value] : '-';
                },
                pinned: 'left',
                minWidth: 200,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.scenarioFilters.kpiTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return params.value ? this.scenarioFilters.kpiTypes[params.value] : '-';
                    }
                },
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiId'),
                field: 'kpiId',
                minWidth: 200,
                sortable: true,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiDescription'),
                field: 'kpiDesc',
                minWidth: 300,
                resizable: true,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiUom'),
                field: 'unitOfMeasureId',
                valueFormatter: (params: ValueFormatterParams): string => {
                    return this.unitsOfMeasure.find(e => e.id.toString() === params.value?.toString())?.value ?? '-';
                },
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.unit'),
                field: 'unit',
                valueFormatter: (params): string => params.value ? this.YES : this.NO,
                cellStyle: (params): { color: string } => ({ color: params.value ? this.GREEN : this.RED }),
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.unitZero'),
                field: 'unitZero',
                valueFormatter: (params): string => params.value ? this.YES : this.NO,
                cellStyle: (params): { color: string } => ({ color: params.value ? this.GREEN : this.RED }),
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiFormula'),
                field: 'kpiFormula',
                minWidth: 300,
                resizable: true,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiActive'),
                field: 'kpiActive',
                headerComponent: 'CheckboxHeaderCell',
                headerComponentParams: (params: IHeaderParams<IKpiDto>): { isChecked: boolean } => {
                    const areAllRenderedActive = params.api.getRenderedNodes().every(rowNode => rowNode.data?.kpiActive === true);
                    return {
                        isChecked: areAllRenderedActive,
                    }
                },
                cellRenderer: 'checkboxCell',
                onCellValueChanged: (params: NewValueParams<IKpiDto>): void => {
                    this.handleKpiActiveArray(params);
                },
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: ['yes', 'no'],
                    valueFormatter: (params: ValueFormatterParams): string => params.value === 'yes' ? this.YES : this.NO
                },
                minWidth: 150,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.actions'),
                field: 'actions',
                pinned: 'right',
                cellRenderer: 'secondaryKpiActionsCell',
                cellRendererParams: (params: ICellRendererParams<KpiResponseDto>): { dependencies: string[] | undefined } => {
                    return {
                        dependencies: params.data?.kpiUsedBy.kpisDependent,
                    }
                },
                minWidth: 200,
                cellStyle: {
                    padding: '0 8px',
                }
            }
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 100,
            maxWidth: 400,
        },
        context: {
            openSecondaryKpiLightbox: this.openSecondaryKpiLightbox,
            refreshTable: this.refreshTable,
            handleShowOverlay: this.handleShowOverlay,
            reloadSummary: this.reloadSummary,
            bulkHandleKpiActiveArray: this.bulkHandleKpiActiveArray,
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        loadingOverlayComponent: 'loadingOverlay',
        onPaginationChanged (event) {
            event.api.refreshHeader();
        },
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onModelUpdated: (event) => {
            event.api.forEachNode(node => {
                this.applyLocalChangesToRowId(node.id ?? '');
            });
        },
    }

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private get unitsOfMeasure(): ConstantEntry[] {
        return this.$store.getters['constants/unitsOfMeasure'];
    }

    private mounted(): void {
        this.filtersPromise = this.$store.dispatch('scenario/filters/ensureFiltersAreLoaded');
    }

    private setRowCount(count: number): void {
        this.rowCount = count;
    }

    private onGridReady(params: AgGridCommon<IKpiDto>): void {
        this.gridApi = params.api;
    }

    private handleShowOverlay(value: boolean): void {
        this.isSaving = value;
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if (!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortableKpiField | undefined {
        if (!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortableKpiField> = {
            'kpiTypeId': SortableKpiField.KPI_TYPE,
            'kpiId': SortableKpiField.KPI_ID,
            'kpiDesc': SortableKpiField.KPI_DESC,
        };

        return fieldKey[colId];
    }

    private kpiActiveFilter(options: string[]): boolean | null {
        if (!options || !options.length || options.length === 2) {
            return null;
        }
        return options[0] === 'yes';
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): PrimaryDataKpiSetFilters {
        return {
            kpiTypes: params.request.filterModel?.kpiTypeId?.values?.map((e: string) => Number(e)),
        };
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    public async openSecondaryKpiLightbox(params: IKpiDto | null = null): Promise<void> {
        await this.bulkSaveKpiActive(true);
        this.secondaryKpiDetails = params;
        this.isSecondaryKpiLightboxOpen = true;
    }

    private handleCloseSecondaryKpiLightbox(refresh = false): void {
        this.secondaryKpiDetails = null;
        this.isSecondaryKpiLightboxOpen = false;
        if (refresh) {
            this.refreshTable();
            this.reloadSummary();
        }
    }

    private applyLocalChangesToRowId(rowId: string): void {
        const node = this.gridApi?.getRowNode(rowId);

        if (!node || !node.data) {
            return;
        }

        const data: IKpiDto = node.data;

        const kpiDto = this.modifiedKpiActive.find(kpi => kpi.kpiNumber === data.kpiNumber)

        if (!kpiDto) {
            return;
        }

        node.setDataValue('kpiActive', kpiDto.isActive);
    }

    public bulkHandleKpiActiveArray(value: boolean): void {
        this.gridApi?.getRenderedNodes().forEach(rowNode => {
            if (rowNode.data) {
                if (!this.modifiedKpiActive.find(kpi => kpi.kpiNumber === rowNode.data?.kpiNumber)) {
                    this.modifiedKpiActive.push(
                        new ActivateDeactivateKpiDto({
                            scenarioId: this.scenarioId,
                            kpiNumber: rowNode.data.kpiNumber,
                            isActive: value,
                        })
                    );
                } else {
                    const index = this.modifiedKpiActive.findIndex(kpi => kpi.kpiNumber === rowNode.data?.kpiNumber);
                    this.modifiedKpiActive[index].isActive = value;
                }

                rowNode.setDataValue('kpiActive', value);
            }
        });
    }

    public handleKpiActiveArray(params: NewValueParams<IKpiDto>): void {
        if (!this.modifiedKpiActive.find(kpi => kpi.kpiNumber === params.data.kpiNumber)) {
            this.modifiedKpiActive.push(
                new ActivateDeactivateKpiDto({
                    scenarioId: this.scenarioId,
                    kpiNumber: params.data.kpiNumber,
                    isActive: params.newValue,
                })
            );
        } else {
            const index = this.modifiedKpiActive.findIndex(kpi => kpi.kpiNumber === params.data.kpiNumber);
            this.modifiedKpiActive[index].isActive = params.newValue;
        }
    }

    public async bulkSaveKpiActive(saveAsDraft: boolean): Promise<boolean> {
        if (this.modifiedKpiActive.length === 0 && saveAsDraft) {
            return true;
        }

        this.isSaving = true;

        const payload: AddKpisToScenarioRequest = new AddKpisToScenarioRequest({
            scenarioId: this.scenarioId,
            kpis: this.modifiedKpiActive,
            isSaveAsDraft: saveAsDraft,
        });

        try {
            await this.$store.dispatch('scenario/formulaStep/bulkSaveKpiActive', payload);
            this.modifiedKpiActive = [];
            this.$pui.toast({
                type: 'success',
                title: this.$t('setup.scenario.formulaStep.toastMessages.automaticSubmitSecondaryKpi.title'),
                copy: this.$t('setup.scenario.formulaStep.toastMessages.automaticSubmitSecondaryKpi.copy'),
            });
            this.refreshTable();
            this.reloadSummary();
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
            return false;
        } finally {
            this.isSaving = false;
        }

        return true;
    }

    private reloadSummary(): void {
        this.$refs.kpiSummaryCard.fetchSummary();
    }

    private reloadAll(): void {
        this.refreshTable();
        this.reloadSummary();
    }
}
</script>

<style lang="scss">
.secondary-kpi-tab {
    &__actions {
        @include rem(margin-top, pui-spacing(m));

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__secondary-kpi-table {
        width: 100%;
        background-color: $white;
        @include rem(margin-top, pui-spacing(m));
        @include rem(margin-bottom, pui-spacing(m));
        @include pui-box();
    }
}
</style>
