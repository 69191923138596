var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.headerLabel,
      "close-on-e-s-c": false,
      "default-footer-confirm-label": _vm.$t('setup.scenario.formulaStepForm.buttons.save'),
      "default-footer-cancel-label": _vm.$t('setup.scenario.formulaStepForm.buttons.cancel'),
      "on-confirm-callback": _vm.triggerSubmitForm
    },
    on: {
      "puilightbox:close": function ($event) {
        return _vm.emitClose();
      }
    }
  }, [_c('secondary-kpi-form', {
    ref: _vm.REF_CONSTANTS.FORM,
    attrs: {
      "secondary-kpi": _vm.secondaryKpi
    },
    on: {
      "close-lightbox": function (refresh) {
        return _vm.emitClose(refresh);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }