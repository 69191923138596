<template>
    <div
        v-if="showButtons"
        class="fc-actions-cell-wrapper"
    >
        <pui-button
            icon="edit"
            state="secondary"
            :small="true"
            @click="openLightbox"
        >
            {{ $t('setup.scenario.ceForm.tables.flagged.buttons.edit') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValueFormatterParams } from 'ag-grid-community';

@Component({})
export default class FlaggedCostsActionsCell extends Vue {
    private params?: ValueFormatterParams;

    private get showButtons(): boolean {
        return this.params?.node?.level === 0 ?? false;
    }

    private openLightbox(): void {
        if (!this.params?.node?.data?.id) {
            return;
        }

        this.params.context.openLightbox(this.params.node.data.id);
    }
}
</script>

<style scoped lang="scss">
.fc-actions-cell-wrapper {
    height: 100%;
    display: flex;
    gap: 10px;
}
</style>
