<template>
    <div class="wrapper">
        <scenario-status-cards @on-selected-status-changed="selectedStatusChanged" />
        <data-management-table :selected-status="selectedStatus" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ScenarioStatusCards from '@/components/scenario-status-cards/scenario-status-cards.vue';
import DataManagementTable from '@/components/data-management-overview/data-management-table.vue';

@Component({
    components: {
        ScenarioStatusCards,
        DataManagementTable,
    }
})
export default class AdminScenarioTab extends Vue {
    private selectedStatus: string | null = null;

    private selectedStatusChanged(value: string | null): void {
        this.selectedStatus = value;
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    &__heading {
        display: flex;
        justify-content: space-between;
        @include rem(margin-bottom, pui-spacing(m));
    }
}
</style>
