import { render, staticRenderFns } from "./secondary-kpi-checkbox-cell.vue?vue&type=template&id=8c4f0344&scoped=true"
import script from "./secondary-kpi-checkbox-cell.vue?vue&type=script&lang=ts"
export * from "./secondary-kpi-checkbox-cell.vue?vue&type=script&lang=ts"
import style0 from "./secondary-kpi-checkbox-cell.vue?vue&type=style&index=0&id=8c4f0344&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c4f0344",
  null
  
)

export default component.exports