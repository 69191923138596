var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-loader', {
    attrs: {
      "promise": _vm.loaderPromise
    }
  }, [_c('div', {
    staticClass: "setup-step"
  }, [_vm.isReferenceYearDifferentFromExternal ? _c('pui-form-inline-notification', {
    attrs: {
      "title": _vm.$t('setup.scenario.setupStepForm.differentReferenceYear.title'),
      "text": _vm.$t('setup.scenario.setupStepForm.differentReferenceYear.text', {
        year: _vm.EXTERNAL_REFERENCE_YEAR
      }),
      "type": "info"
    }
  }) : _vm._e(), _c('pui-form', {
    staticClass: "setup-step__form"
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.FULL,
      expression: "GRID_COLUMN_SPAN.FULL"
    }],
    staticClass: "setup-step__form__name-group",
    attrs: {
      "is-valid": _vm.formData.validation.name,
      "has-label-padding": false,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.name'),
      "label-for": "scenarioName"
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.values.name.length > _vm.MAX_LENGTH_CONFIG.name / 2,
            expression: "formData.values.name.length > (MAX_LENGTH_CONFIG.name / 2)"
          }]
        }, [_vm._v(" " + _vm._s(_vm.$t('form.characterCounter', {
          current: _vm.formData.values.name.length,
          max: _vm.MAX_LENGTH_CONFIG.name
        })) + " ")])];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.name) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "scenarioName",
      "is-disabled": _vm.isFormDisabled,
      "is-valid": _vm.formData.validation.name,
      "max-length": _vm.MAX_LENGTH_CONFIG.name,
      "placeholder-text": _vm.$t('setup.scenario.setupStepForm.placeholders.name')
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('name');
      }
    },
    model: {
      value: _vm.formData.values.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "name", $$v);
      },
      expression: "formData.values.name"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.TWO_THIRDS,
      expression: "GRID_COLUMN_SPAN.TWO_THIRDS"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.previousExercise,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.previousExercise'),
      "cta-label": _vm.$t('form.clear'),
      "has-cta": !_vm.isFormDisabled,
      "cta-callback": _vm.clearPreviousExercise,
      "label-for": "previousExercise"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.previousExercise) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-type-ahead', {
    attrs: {
      "options": _vm.scenarioOptions,
      "use-local-cache": true,
      "is-static-mode": true,
      "is-disabled": _vm.isFormDisabled,
      "is-valid": _vm.formData.validation.previousExercise,
      "is-loading": _vm.isLoadingScenarios,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.previousExercise'),
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.previousExercise'),
      "search-input-id": "previousExercise"
    },
    on: {
      "search": _vm.scenarioTypeaheadSearch,
      "abort": _vm.scenarioTypeaheadAborted,
      "change": _vm.previousExerciseChanged
    },
    model: {
      value: _vm.formData.values.previousExercise,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "previousExercise", $$v);
      },
      expression: "formData.values.previousExercise"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.ONE_THIRD,
      expression: "GRID_COLUMN_SPAN.ONE_THIRD"
    }],
    staticClass: "setup-step__form__radio-group",
    attrs: {
      "is-valid": _vm.formData.validation.importType,
      "label": ""
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.importType) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-radio', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.$t('setup.scenario.setupStepForm.tooltips.keepAllData'),
      expression: "$t('setup.scenario.setupStepForm.tooltips.keepAllData')"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.importType,
      "is-disabled": _vm.isFormDisabled || !_vm.isImportTypeSelectable,
      "value": _vm.ImportType.KEEP_ALL_DATA,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.keepAllData')
    },
    on: {
      "change": _vm.importTypeChanged
    },
    model: {
      value: _vm.formData.values.importType,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "importType", _vm._n($$v));
      },
      expression: "formData.values.importType"
    }
  }), _c('pui-form-radio', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.$t('setup.scenario.setupStepForm.tooltips.reloadOnlyCertainData'),
      expression: "$t('setup.scenario.setupStepForm.tooltips.reloadOnlyCertainData')"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.importType,
      "is-disabled": _vm.isFormDisabled || !_vm.isImportTypeSelectable,
      "value": _vm.ImportType.RELOAD_CERTAIN,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.reloadOnlyCertainData')
    },
    on: {
      "change": _vm.importTypeChanged
    },
    model: {
      value: _vm.formData.values.importType,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "importType", _vm._n($$v));
      },
      expression: "formData.values.importType"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.FULL,
      expression: "GRID_COLUMN_SPAN.FULL"
    }],
    attrs: {
      "label": _vm.$t('setup.scenario.setupStepForm.labels.description'),
      "is-valid": _vm.formData.validation.description
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.values.description.length > _vm.MAX_LENGTH_CONFIG.description / 2,
            expression: "formData.values.description.length > (MAX_LENGTH_CONFIG.description / 2)"
          }]
        }, [_vm._v(" " + _vm._s(_vm.$t('form.characterCounter', {
          current: _vm.formData.values.description.length,
          max: _vm.MAX_LENGTH_CONFIG.description
        })) + " ")])];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.description) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-textarea', {
    attrs: {
      "is-valid": _vm.formData.validation.description,
      "is-disabled": _vm.isFormDisabled,
      "max-length": _vm.MAX_LENGTH_CONFIG.description,
      "placeholder-text": _vm.$t('setup.scenario.setupStepForm.placeholders.description')
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('description');
      }
    },
    model: {
      value: _vm.formData.values.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "description", $$v);
      },
      expression: "formData.values.description"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.HALF,
      expression: "GRID_COLUMN_SPAN.HALF"
    }],
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.mtpYear'),
      "is-valid": _vm.formData.validation.mtpYear,
      "label-for": "mtpYear"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.mtpYear) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.mtpYearOptions,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.mtpYear'),
      "is-valid": _vm.formData.validation.mtpYear,
      "is-disabled": _vm.isFormDisabled || _vm.isLoadingSelectedScenario,
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.mtpYear'),
      "search-input-id": "mtpYear"
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('mtpYear');
      }
    },
    model: {
      value: _vm.formData.values.mtpYear,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "mtpYear", $$v);
      },
      expression: "formData.values.mtpYear"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.HALF,
      expression: "GRID_COLUMN_SPAN.HALF"
    }],
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.referenceYear'),
      "is-valid": _vm.formData.validation.referenceYear,
      "label-for": "referenceYear"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.referenceYear) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.getYearOptions(_vm.YEAR_RANGE_CONFIG.referenceYear),
      "label": _vm.$t('setup.scenario.setupStepForm.labels.referenceYear'),
      "is-valid": _vm.formData.validation.referenceYear,
      "is-disabled": _vm.isFormDisabled || _vm.isLoadingSelectedScenario,
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.referenceYear'),
      "search-input-id": "referenceYear"
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('referenceYear');
      }
    },
    model: {
      value: _vm.formData.values.referenceYear,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "referenceYear", $$v);
      },
      expression: "formData.values.referenceYear"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.HALF,
      expression: "GRID_COLUMN_SPAN.HALF"
    }],
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.startYear'),
      "is-valid": _vm.formData.validation.startYear,
      "label-for": "startYear"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.startYear) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.getYearOptions(_vm.YEAR_RANGE_CONFIG.startYear),
      "label": _vm.$t('setup.scenario.setupStepForm.labels.startYear'),
      "is-valid": _vm.formData.validation.startYear,
      "is-disabled": _vm.isFormDisabled || _vm.isLoadingSelectedScenario,
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.startYear'),
      "search-input-id": "startYear"
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('startYear');
      }
    },
    model: {
      value: _vm.formData.values.startYear,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "startYear", $$v);
      },
      expression: "formData.values.startYear"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.HALF,
      expression: "GRID_COLUMN_SPAN.HALF"
    }],
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.endYear'),
      "is-valid": _vm.formData.validation.endYear,
      "label-for": "endYear"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.endYear) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.getYearOptions(_vm.YEAR_RANGE_CONFIG.endYear),
      "label": _vm.$t('setup.scenario.setupStepForm.labels.endYear'),
      "is-valid": _vm.formData.validation.endYear,
      "is-disabled": _vm.isFormDisabled || _vm.isLoadingSelectedScenario,
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.endYear'),
      "search-input-id": "endYear"
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('endYear');
      }
    },
    model: {
      value: _vm.formData.values.endYear,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "endYear", $$v);
      },
      expression: "formData.values.endYear"
    }
  })], 1), _vm.showReportingOccasion ? _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.GRID_COLUMN_SPAN.HALF,
      expression: "GRID_COLUMN_SPAN.HALF"
    }],
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.reportingOccasion'),
      "is-valid": _vm.formData.validation.reportingOccasion,
      "label-for": "reportingOccasion"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.reportingOccasion) + " ")];
      },
      proxy: true
    }], null, false, 3149059635)
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.reportingOccasion,
      "label": _vm.$t('setup.scenario.setupStepForm.labels.reportingOccasion'),
      "is-valid": _vm.formData.validation.reportingOccasion,
      "is-disabled": _vm.isFormDisabled || _vm.isLoadingSelectedScenario,
      "search-input-placeholder": _vm.$t('setup.scenario.setupStepForm.placeholders.reportingOccasion'),
      "search-input-id": "reportingOccasion"
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('reportingOccasion');
      }
    },
    model: {
      value: _vm.formData.values.reportingOccasion,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "reportingOccasion", $$v);
      },
      expression: "formData.values.reportingOccasion"
    }
  })], 1) : _vm._e()], 1)]), _c('p', {
    staticClass: "setup-step__instruction-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.setupStepForm.editableWarningMessage')) + " ")]), _c('div', {
    staticClass: "setup-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isBackButtonDisabled,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isSaveAsDraftButtonDisabled,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isNextButtonDisabled,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.next')) + " ")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }