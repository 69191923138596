<template>
    <div :style="styles">
        {{ value }}
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import {
    ScenarioCompareDetailsRow
} from '@/components/results/scenario-compare/lightbox/scenario-compare-details-table.vue';
import { getColorForScenarioCompareStatus, ScenarioCompareStatus } from '@/config/scenario-compare';

@Component({})
export default class ScenarioCompareDetailsValueCell extends Vue {
    private params?: ICellRendererParams<ScenarioCompareDetailsRow>;

    private get value(): string {
        return this.params?.value?.toString() ?? this.$t('results.scenarioCompare.details.table.cells.none');
    }

    private get isMissingStatus(): boolean {
        return this.params?.data?.status === ScenarioCompareStatus.RED;
    }

    private get styles(): Record<string, string> {
        if (!this.isMissingStatus) {
            return {};
        }

        return {
            color: getColorForScenarioCompareStatus(this.params?.data?.status)
        };
    }
}
</script>
