export enum AdminModalMode {
  KPI_TYPE = 1,
  UOM = 2
}

export type AdminFormConfig = {
  [key in AdminModalMode]: {
      titleLabel: string;
      inputLabel: string;
      addFn: () => Promise<void>;
  };
};
