var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scenario-status-cards-wrapper"
  }, _vm._l(_vm.statusCards, function (status) {
    return _c('donut-card', {
      key: `status_${status.id}`,
      attrs: {
        "promise": _vm.statusDistributionPromise,
        "title": status.title,
        "color": status.color,
        "count": status.count,
        "total": _vm.totalCount,
        "hoverable": _vm.isStatusCardHoverable,
        "selected": _vm.selectedStatus === status.id
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.selectStatus(status.id);
        }
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }