var render = function render(){
  var _this$scenario$name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "confirmation-step"
  }, [_c('div', {
    staticClass: "confirmation-step__icon-wrapper"
  }, [_c('pui-icon', {
    attrs: {
      "icon-color": _vm.CHECK_ICON_COLOR,
      "size": _vm.CHECK_ICON_SIZE,
      "icon-name": "check"
    }
  })], 1), _c('pui-headline', {
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.confirmation.title', {
    name: (_this$scenario$name = this.scenario.name) !== null && _this$scenario$name !== void 0 ? _this$scenario$name : ''
  })) + " ")]), _c('pui-button', {
    on: {
      "click": _vm.backToOverviewClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.confirmation.backToOverview')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }