import { AddInflationRequest, ICountryInflationDto, InflationServiceProxy } from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';
import { FetchInflationValuesPayload, InflationStepYears } from '@/models/steps/inflation';

const inflationService = new InflationServiceProxy();

type InflationStepState = {
    nominatedValues: ICountryInflationDto[];
    nominatedTotalCount: number;
    yearlyValues: ICountryInflationDto[];
    yearlyTotalCount: number;
    years: InflationStepYears;
};

const initialState: InflationStepState = {
    nominatedValues: [],
    nominatedTotalCount: 0,
    yearlyValues: [],
    yearlyTotalCount: 0,
    years: {
        startYear: 0,
        endYear: 0,
        referenceYear: 0,
    },
};

const InflationStepModule: Module<InflationStepState, any> = {
    state: initialState,

    mutations: {
        setNominatedValues(state, payload: ICountryInflationDto[]): void {
            state.nominatedValues = payload;
        },
        resetNominatedValues(state): void {
            state.nominatedValues = [];
        },
        setYearlyValues(state, payload: ICountryInflationDto[]): void {
            state.yearlyValues = payload;
        },
        resetYearlyValues(state): void {
            state.yearlyValues = [];
        },
        setNominatedTotalCount(state, payload: number): void {
            state.nominatedTotalCount = payload;
        },
        resetNominatedTotalCount(state): void {
            state.nominatedTotalCount = 0;
        },
        setYearlyTotalCount(state, payload: number): void {
            state.yearlyTotalCount = payload;
        },
        resetYearlyTotalCount(state): void {
            state.yearlyTotalCount = 0;
        },
        setYears(state, payload: InflationStepYears): void {
            state.years = payload;
        },
        resetYears(state): void {
            state.years = {
                startYear: 0,
                endYear: 0,
                referenceYear: 0,
            };
        }
    },

    actions: {
        async fetchNominatedValues({ commit }, payload: FetchInflationValuesPayload): Promise<ICountryInflationDto[]> {
            const result = (await inflationService.list5(payload.scenarioId, true, payload.pageNumber, payload.pageSize, undefined, undefined)).result;

            commit('setNominatedValues', result.countryInflations ?? []);
            commit('setNominatedTotalCount', result.count);
            commit('setYears', {
                startYear: result.startYear,
                endYear: result.endYear,
                referenceYear: result.referenceYear,
            });

            return result.countryInflations ?? [];
        },
        async fetchYearlyValues({ commit }, payload: FetchInflationValuesPayload): Promise<ICountryInflationDto[]> {
            const result = (await inflationService.list5(payload.scenarioId, false, payload.pageNumber, payload.pageSize, undefined, undefined)).result;

            commit('setYearlyValues', result.countryInflations ?? []);
            commit('setYearlyTotalCount', result.count);
            commit('setYears', {
                startYear: result.startYear,
                endYear: result.endYear,
                referenceYear: result.referenceYear,
            });

            return result.countryInflations ?? [];
        },
        async saveInflationValues(context, payload: AddInflationRequest): Promise<void> {
            await inflationService.addInflation(payload);
        },
        clearInflation({ commit }): void {
            commit('resetNominatedValues');
            commit('resetNominatedTotalCount');
            commit('resetYearlyValues');
            commit('resetYearlyTotalCount');
            commit('resetYears');
        }
    },

    getters: {
        getNominatedTotalCount(state): number {
            return state.nominatedTotalCount;
        },
        getYearlyTotalCount(state): number {
            return state.yearlyTotalCount;
        },
        getYears(state): InflationStepYears {
            return state.years;
        }
    },

    namespaced: true,
};

export default InflationStepModule;


