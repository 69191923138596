import { FiltersServiceProxy, ISidWithNameFilterDto, IYearRangeDto } from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';

const filterService = new FiltersServiceProxy();

export type ScenarioFilters = {
    countries: ISidWithNameFilterDto[],
    technologies: ISidWithNameFilterDto[],
    plants: ISidWithNameFilterDto[],
    mtcKpiIds: string[],
    kpiTypes: Record<string, string>,
    planningControllingClassification: Record<string, string>,
    projectTypes: Record<string, string>,
    aeroClassification: Record<string, string>
    years: IYearRangeDto,
    units: ISidWithNameFilterDto[],
};

type FiltersModuleState = {
    filters?: ScenarioFilters;
};

const initialState: FiltersModuleState = {
    filters: undefined,
};

const FiltersModule: Module<FiltersModuleState, any> = {
    state: initialState,

    mutations: {
        setFilters(state, payload: ScenarioFilters): void {
            state.filters = payload;
        },
        resetFilters(state): void {
            state.filters = undefined;
        },
    },

    actions: {
        async ensureFiltersAreLoaded({ commit, state, rootGetters }): Promise<void> {
            if (!rootGetters['scenario/isScenarioLoaded'] || state.filters) {
                return;
            }

            try {
                const response = await filterService.list6(rootGetters['scenario/getScenarioId']);
                commit('setFilters', response.result);
            } catch (err) {
                commit('resetFilters');
                throw err;
            }
        },
        clearFilters({ commit }): void {
            commit('resetFilters');
        }
    },

    getters: {
        getFilters(state): ScenarioFilters | undefined {
            return state.filters;
        }
    },

    namespaced: true,
};

export default FiltersModule;
