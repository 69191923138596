<template>
    <pui-loader :promise="initialPromise">
        <div class="wrapper">
            <div class="wrapper__heading">
                <h3>{{ $t('results.overview.title') }}</h3>
            </div>
            <pui-tabs>
                <pui-tab :title="$t('results.tabs.siteView')">
                    <results-site-view />
                </pui-tab>
                <pui-tab :title="$t('results.tabs.scenarioCompare')">
                    <results-scenario-compare />
                </pui-tab>
                <pui-tab :title="$t('results.tabs.kpiAnalysis')">
                    <results-kpi-analysis />
                </pui-tab>
            </pui-tabs>
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ResultsSiteView from '@/pages/results/sections/results-site-view.vue';
import ResultsScenarioCompare from '@/pages/results/sections/results-scenario-compare.vue';
import ResultsKpiAnalysis from '@/pages/results/sections/results-kpi-analysis.vue';

@Component({
    components: {
        ResultsKpiAnalysis,
        ResultsSiteView,
        ResultsScenarioCompare,
    }
})
export default class ResultsOverviewPage extends Vue {
    private initialPromise: Promise<any> | null = null;

    private mounted(): void {
        this.initialPromise = this.$store.dispatch('constants/fetchAllConstants');
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: column;

    &__heading {
        display: flex;
        justify-content: space-between;
    }
}
</style>

