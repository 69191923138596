<template>
    <pui-loader :promise="initialPromise">
        <div class="mcy-table">
            <table-header
                :hide-search-bar="true"
                :page-size="tableHeaderFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
            />
            <ag-grid-vue
                :grid-options="gridOptions"
                class="ag-theme-alpine"
            />
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { AgGridVue } from 'ag-grid-vue';
import { Component, Vue } from 'vue-property-decorator';
import {
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideGetRowsParams,
    SetFilterValuesFuncParams, ValueFormatterParams
} from 'ag-grid-community';
import {
    FetchMaintenanceCycleFilters,
    FetchMaintenanceCycleValuesPayload
} from '@/models/steps/mtc';
import {
    AddMaintenanceCycleValuesRequest,
    IMachineSidYearDto, MachineSidYearDto,
    MaintenanceCycleValuesViewDto,
    MaintenanceYearDto
} from '@/service-proxies/service-proxies.g';
import McyTableCheckboxCell from '@/components/mtc-step/mcy-table/mcy-table-checkbox-cell.vue';
import { FetchPaginationPartial } from '@/models/steps/common';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';

export type McyTableRow = {
    machineSid: number,
    plantName: string,
    unitName: string,
    countryName: string,
    technology: string,
    years: number,
    [key: string]: any,
};

@Component({
    components: {
        AgGridVue,
        McyTableCheckboxCell,
        TableHeader,
    }
})
export default class McyTable extends Vue {
    private readonly COLUMN_KEYS = {
        PLANT_NAME: 'plantName',
        UNIT_NAME: 'unitName',
        COUNTRY_NAME: 'countryName',
        TECHNOLOGY: 'technology',
        YEARS: 'years',
    } as const;

    private tableHeaderFilters = {
        pageSize: 20,
    };

    private gridApi: GridApi<McyTableRow> | null = null;
    private initialPromise: Promise<any> | null = null;

    private isYearDataLoaded = false;
    private yearKeys: string[] = [];
    private modifiedYears: Record<string, IMachineSidYearDto> = {};

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get totalCount(): number {
        return this.$store.getters['scenario/mtcStep/getMaintenanceCycleValuesTotalCount'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private mounted(): void {
        this.initialPromise = this.$store.dispatch('scenario/filters/ensureFiltersAreLoaded');
    }

    private gridOptions: GridOptions<McyTableRow> = {
        rowModelType: 'serverSide',
        domLayout: 'autoHeight',
        serverSideDatasource: {
            getRows: (params): void => {
                const paginationParams = this.getPaginationForServerRequestParams(params);
                const filterParams = this.getSelectedFiltersForServerRequestParams(params);

                const payload: FetchMaintenanceCycleValuesPayload = {
                    scenarioId: this.scenarioId,
                    ...paginationParams,
                    ...filterParams,
                };

                this.$store.dispatch('scenario/mtcStep/fetchMaintenanceCycleValues', payload)
                    .then((maintenanceCycleValues: MaintenanceCycleValuesViewDto[]) => {
                        const rowData: McyTableRow[] = [];
                        const years = new Set<number>();

                        maintenanceCycleValues.forEach(value => {
                            const row: McyTableRow = {
                                machineSid: value.machineSid,
                                countryName: value.country ?? '',
                                unitName: value.unit ?? '',
                                plantName: value.plant ?? '',
                                technology: value.technology ?? '',
                                years: value.numberOfSelectedYears,
                            };

                            value.years?.forEach(maintenanceYear => {
                                row[maintenanceYear.year.toString()] = maintenanceYear.isSelected;
                                years.add(maintenanceYear.year);
                            });

                            rowData.push(row);
                        });

                        this.configureYearColumns(years);

                        params.success({
                            rowData,
                            rowCount: this.totalCount,
                        });
                    })
                    .catch(() => params.fail());
            },
        },
        columnDefs: [
            {
                field: this.COLUMN_KEYS.PLANT_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.mcy.headers.plantName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.plants.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.plants.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
                pinned: 'left',
                initialWidth: 300,
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.UNIT_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.mcy.headers.unitName'),
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                headerName: this.$t('setup.scenario.mtcForm.tables.mcy.headers.countryName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.countries.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const countrySid = Number(params.value);
                        return this.scenarioFilters.countries.find(e => e.sid === countrySid)?.name ?? this.$t('unknown');
                    },
                },
                initialWidth: 300,
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.TECHNOLOGY,
                headerName: this.$t('setup.scenario.mtcForm.tables.mcy.headers.technology'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.technologies.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const technologySid = Number(params.value);
                        return this.scenarioFilters.technologies.find(e => e.sid === technologySid)?.name ?? this.$t('unknown');
                    },
                },
                initialWidth: 200,
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.YEARS,
                initialWidth: 150,
                resizable: true,
            }
        ],
        defaultColDef: {
            menuTabs: [],
            cellClass: 'mcy-table__cell--non-editable'
        },
        serverSideInfiniteScroll: true,
        serverSideFilterOnServer: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        onModelUpdated: (event) => {
            event.api.forEachNode(node => {
                this.applyLocalChangesToRowId(node.id ?? '');
                this.recomputeYearCounterValuesForRowId(node.id ?? '');
            });
        },
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
    };

    public async submitForm(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        if (Object.keys(this.modifiedYears).length === 0 && saveAsDraft) {
            return true;
        }

        const machineSidYears: MachineSidYearDto[] = Object.keys(this.modifiedYears).map(key => new MachineSidYearDto(this.modifiedYears[key]));

        const addMaintenanceCycleValues = new AddMaintenanceCycleValuesRequest({
            scenarioId: this.scenarioId,
            isSaveAsDraft: saveAsDraft,
            machineSidYears
        });

        try {
            await this.$store.dispatch('scenario/mtcStep/saveMaintenanceCycleValues', addMaintenanceCycleValues);
            this.modifiedYears = {};
            this.refreshData();

            if (automaticSubmit) {
                this.$pui.toast({
                    type: 'success',
                    title: this.$t('setup.scenario.mtcForm.toastMessages.automaticSubmitMcy.title'),
                    copy: this.$t('setup.scenario.mtcForm.toastMessages.automaticSubmitMcy.copy'),
                });
            } else {
                await this.$store.dispatch('scenario/mtcStep/clearMaintenanceCycle');
            }
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });

            return false;
        }

        return true;
    }

    private refreshData(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onGridReady(event: GridReadyEvent<McyTableRow>): void {
        event.api.sizeColumnsToFit();

        this.gridApi = event.api;
    }

    private configureYearColumns(years: Set<number>): void {
        if (this.isYearDataLoaded || !this.gridApi) {
            return;
        }

        const columnDefinitions = this.gridApi.getColumnDefs() ?? [];

        years.forEach(year => {
            this.yearKeys.push(year.toString());

            columnDefinitions.push({
                field: year.toString(),
                headerName: year.toString(),
                cellRenderer: 'McyTableCheckboxCell',
                cellClass: '',
                onCellValueChanged: (params) => {
                    this.setModifiedYearForMachineSid(params.data.machineSid, Number(params.column.getColId()), params.newValue);
                    this.recomputeYearCounterValuesForRowId(params.node?.id ?? '');
                },
            });
        });

        this.isYearDataLoaded = true;
        this.gridApi.setColumnDefs(columnDefinitions);
        this.gridApi.sizeColumnsToFit();
    }

    private setModifiedYearForMachineSid(machineSid: number, year: number, isSelected: boolean): void {
        if (!this.modifiedYears[machineSid.toString()]) {
            this.$set(this.modifiedYears, machineSid.toString(), {
                machineSid,
                years: [],
            });
        }

        const machineSidYearDto = this.modifiedYears[machineSid.toString()];
        if (!machineSidYearDto.years) {
            return;
        }

        const yearDto = machineSidYearDto.years.find(e => e.year === year);
        if (yearDto) {
            yearDto.isSelected = isSelected;
        } else {
            machineSidYearDto.years.push(new MaintenanceYearDto({ year, isSelected }));
        }
    }

    private recomputeYearCounterValuesForRowId(rowId: string): void {
        const node = this.gridApi?.getRowNode(rowId);

        if (!node || !node.data) {
            return;
        }

        let counter = 0;
        this.yearKeys.forEach(yearKey => {
            if (node.data?.[yearKey]) {
                counter++;
            }
        });

        node.setDataValue(this.COLUMN_KEYS.YEARS, counter);
    }

    private applyLocalChangesToRowId(rowId: string): void {
        const node = this.gridApi?.getRowNode(rowId);

        if (!node || !node.data) {
            return;
        }

        const data: McyTableRow = node.data;

        if (!this.modifiedYears[data.machineSid.toString()]) {
            return;
        }

        const yearsDto = this.modifiedYears[data.machineSid.toString()];
        yearsDto.years?.forEach(e => {
            node.setDataValue(e.year.toString(), e.isSelected);
        });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private getPaginationForServerRequestParams(params: IServerSideGetRowsParams): FetchPaginationPartial {
        return {
            pageNumber: Math.floor((params.request.endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize),
            pageSize: this.tableHeaderFilters.pageSize,
        };
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): FetchMaintenanceCycleFilters {
        return {
            plants: params.request.filterModel?.[this.COLUMN_KEYS.PLANT_NAME]?.values?.map((e: string) => Number(e)),
            countries: params.request.filterModel?.[this.COLUMN_KEYS.COUNTRY_NAME]?.values?.map((e: string) => Number(e)),
            technologies: params.request.filterModel?.[this.COLUMN_KEYS.TECHNOLOGY]?.values?.map((e: string) => Number(e)),
        };
    }
}
</script>

<style scoped lang="scss">
.mcy-table {
    width: 100%;
    @include pui-box();

    ::v-deep &__cell {
        &--non-editable {
            background-color: $non-editable-cell-color;
        }
    }
}
</style>
