<template>
    <div class="confirmation-step">
        <div class="confirmation-step__icon-wrapper">
            <pui-icon
                :icon-color="CHECK_ICON_COLOR"
                :size="CHECK_ICON_SIZE"
                icon-name="check"
            />
        </div>
        <pui-headline type="h3">
            {{ $t('setup.scenario.confirmation.title', { name: this.scenario.name ?? '' }) }}
        </pui-headline>
        <pui-button @click="backToOverviewClicked">
            {{ $t('setup.scenario.confirmation.backToOverview') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { IGetScenarioResponse } from '@/service-proxies/service-proxies.g';
import { PUICOLOR_UNIPER_BLUE } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

@Component({})
export default class ConfirmationScreenStep extends mixins(ScenarioStepNavigation) {
    private readonly CHECK_ICON_COLOR = PUICOLOR_UNIPER_BLUE;
    private readonly CHECK_ICON_SIZE = '12rem';

    private get scenario(): IGetScenarioResponse {
        return this.$store.getters['scenario/getScenario'];
    }

    private backToOverviewClicked(): void {
        this.pushToScenarioOverview();
    }
}
</script>

<style scoped lang="scss">
.confirmation-step {
    @include rem(gap, pui-spacing(m));
    @include rem(padding-top, pui-spacing(xl));
    @include rem(padding-bottom, pui-spacing(xl));

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon-wrapper {
        padding: 3rem;
        border-radius: 50%;

        border: 10px solid $uniper-blue;
    }
}
</style>
