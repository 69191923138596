var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "secondary-kpi-form-wrapper"
  }, [_vm.isFormSubmitting ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise
    }
  }) : _c('div', [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 4,
      expression: "4"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiType,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiType'),
      "label-for": "kpiType"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiType) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "id": "kpiType",
      "options": _vm.kpiTypeSelectorOptions,
      "is-valid": _vm.formData.validation.kpiType,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiType'),
      "search-input-placeholder": _vm.$t('setup.scenario.formulaStepForm.placeholders.kpiType')
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('kpiType');
      }
    },
    model: {
      value: _vm.formData.values.kpiType,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiType", $$v);
      },
      expression: "formData.values.kpiType"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 4,
      expression: "4"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiId,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiId'),
      "label-for": "kpiId"
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.values.kpiId.length > _vm.MAX_LENGTH_CONFIG.kpiId / 2,
            expression: "formData.values.kpiId.length > (MAX_LENGTH_CONFIG.kpiId / 2)"
          }]
        }, [_vm._v(" " + _vm._s(_vm.$t('form.characterCounter', {
          current: _vm.formData.values.kpiId.length,
          max: _vm.MAX_LENGTH_CONFIG.kpiId
        })) + " ")])];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_c('span', {
          staticClass: "secondary-kpi-form-wrapper__highlighted-text"
        }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStepForm.errors.notValidKpiId')) + " ")]), _vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiId) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "kpiId",
      "is-valid": _vm.formData.validation.kpiId,
      "max-length": _vm.MAX_LENGTH_CONFIG.kpiId,
      "placeholder-text": _vm.$t('setup.scenario.formulaStepForm.placeholders.kpiId')
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('kpiId', true);
      }
    },
    model: {
      value: _vm.formData.values.kpiId,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiId", $$v);
      },
      expression: "formData.values.kpiId"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 4,
      expression: "4"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiUom,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiUom'),
      "label-for": "kpiUom"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiUom) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "id": "kpiUom",
      "options": _vm.kpiUomSelectorOptions,
      "is-valid": _vm.formData.validation.kpiUom,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiUom'),
      "search-input-placeholder": _vm.$t('setup.scenario.formulaStepForm.placeholders.kpiUom')
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('kpiUom');
      }
    },
    model: {
      value: _vm.formData.values.kpiUom,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiUom", $$v);
      },
      expression: "formData.values.kpiUom"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiDesc,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiDesc'),
      "label-for": "kpiDesc"
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.values.kpiDesc.length > _vm.MAX_LENGTH_CONFIG.kpiDesc / 2,
            expression: "formData.values.kpiDesc.length > (MAX_LENGTH_CONFIG.kpiDesc / 2)"
          }]
        }, [_vm._v(" " + _vm._s(_vm.$t('form.characterCounter', {
          current: _vm.formData.values.kpiDesc.length,
          max: _vm.MAX_LENGTH_CONFIG.kpiDesc
        })) + " ")])];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiDesc) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "kpiDesc",
      "is-valid": _vm.formData.validation.kpiDesc,
      "max-length": _vm.MAX_LENGTH_CONFIG.kpiDesc,
      "placeholder-text": _vm.$t('setup.scenario.formulaStepForm.placeholders.kpiDesc')
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('kpiDesc');
      }
    },
    model: {
      value: _vm.formData.values.kpiDesc,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiDesc", $$v);
      },
      expression: "formData.values.kpiDesc"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.selectKpi,
      "show-required-label": !_vm.secondaryKpi,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.selectKpi'),
      "label-for": "selectKpi"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.selectKpi) + " ")];
      },
      proxy: true
    }])
  }, [_vm.formData.values.selectKpi.length > 0 ? _c('div', {
    staticClass: "secondary-kpi-form-wrapper__kpi-list"
  }, _vm._l(_vm.formData.values.selectKpi, function (kpi) {
    return _c('pui-chip', {
      key: kpi.value,
      attrs: {
        "label": kpi.value,
        "border-color": _vm.usedKpiColor(kpi.value),
        "draggable": true
      },
      nativeOn: {
        "dragstart": function ($event) {
          return _vm.kpiDragStart($event, kpi.value);
        }
      }
    });
  }), 1) : _vm._e(), _c('pui-form-type-ahead', {
    staticClass: "secondary-kpi-form-wrapper__type-ahead-input",
    attrs: {
      "id": "selectKpi",
      "is-loading": _vm.kpiIsLoading,
      "is-valid": _vm.formData.validation.selectKpi,
      "options": _vm.kpiSearchResults,
      "use-local-cache": false,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.selectKpi'),
      "search-input-placeholder": _vm.$t('setup.scenario.formulaStepForm.placeholders.selectKpi'),
      "multiple": true
    },
    on: {
      "abort": _vm.clearSearchResults,
      "search": _vm.fetchKpiList,
      "change": function ($event) {
        return _vm.validateField('selectKpi');
      }
    },
    model: {
      value: _vm.formData.values.selectKpi,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "selectKpi", $$v);
      },
      expression: "formData.values.selectKpi"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiFormula,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiFormula'),
      "label-for": "kpiFormula"
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm.formData.values.kpiFormula.length > _vm.MAX_LENGTH_CONFIG.kpiFormula / 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('form.characterCounter', {
          current: _vm.formData.values.kpiFormula.length,
          max: _vm.MAX_LENGTH_CONFIG.kpiFormula
        })) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStepForm.helpers.dragAndDropFrom', {
          field: _vm.$t('setup.scenario.formulaStepForm.labels.selectKpi')
        })) + " ")])];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiFormula) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "kpiFormula",
      "is-valid": _vm.formData.validation.kpiFormula,
      "max-length": _vm.MAX_LENGTH_CONFIG.kpiFormula,
      "placeholder-text": _vm.$t('setup.scenario.formulaStepForm.placeholders.kpiFormula')
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('kpiFormula');
      }
    },
    nativeOn: {
      "dragover": function ($event) {
        return _vm.textFieldDragOver.apply(null, arguments);
      },
      "drop": function ($event) {
        return _vm.textFieldDrop($event, _vm.handleDropForKpiFormula);
      }
    },
    model: {
      value: _vm.formData.values.kpiFormula,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiFormula", $$v);
      },
      expression: "formData.values.kpiFormula"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "is-valid": _vm.formData.validation.kpiActive,
      "show-required-label": true,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.kpiActive'),
      "label-for": "kpiActive"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.kpiActive) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    staticClass: "secondary-kpi-form-wrapper__input-selector",
    attrs: {
      "id": "kpiActive",
      "name": "kpiActive",
      "options": _vm.INPUT_SELECTOR_OPTIONS,
      "selected-value": _vm.formData.values.kpiActive
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "kpiActive", $event);
      },
      "update:selected-value": function ($event) {
        return _vm.$set(_vm.formData.values, "kpiActive", $event);
      },
      "input": function ($event) {
        return _vm.validateField('kpiActive');
      }
    },
    model: {
      value: _vm.formData.values.kpiActive,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "kpiActive", $$v);
      },
      expression: "formData.values.kpiActive"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "label": "",
      "is-valid": _vm.formData.validation.unit
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.unit) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-checkbox', {
    attrs: {
      "id": "unit",
      "is-valid": _vm.formData.validation.unit,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.unit')
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('unit');
      }
    },
    model: {
      value: _vm.formData.values.unit,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "unit", $$v);
      },
      expression: "formData.values.unit"
    }
  }), _c('pui-form-checkbox', {
    attrs: {
      "id": "unitZero",
      "is-valid": _vm.formData.validation.unit,
      "label": _vm.$t('setup.scenario.formulaStepForm.labels.unitZero')
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('unit');
      }
    },
    model: {
      value: _vm.formData.values.unitZero,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "unitZero", $$v);
      },
      expression: "formData.values.unitZero"
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }