<template>
    <div class="engine-run-summary-card">
        <spinner
            :promise="promise"
            min-height="100px"
        >
            <pui-grid-row>
                <pui-grid-column
                    class="engine-run-summary-card__section"
                    :cols="COLUMN_CONFIG"
                >
                    <pui-headline
                        class="engine-run-summary-card__section__headline"
                        type="h5"
                    >
                        {{ $t('engineRun.summary.summaryCard.runSummary') }}
                    </pui-headline>
                    <div class="engine-run-summary-card__section__item">
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('engineRun.summary.summaryCard.selectedKpis', { count: summary?.kpisCalculated ?? 0 }) }}
                        </pui-headline>
                    </div>
                    <div class="engine-run-summary-card__section__item engine-run-summary-card__section__item--inline">
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('engineRun.summary.summaryCard.sites', { count: summary?.sites ?? 0 }) }}
                        </pui-headline>
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('engineRun.summary.summaryCard.units', { count: summary?.units ?? 0 }) }}
                        </pui-headline>
                    </div>
                    <div class="engine-run-summary-card__section__item engine-run-summary-card__section__item--inline">
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('engineRun.summary.summaryCard.startYear', { year: summary?.startYear ?? 0 }) }}
                        </pui-headline>
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ $t('engineRun.summary.summaryCard.endYear', { year: summary?.endYear ?? 0 }) }}
                        </pui-headline>
                    </div>
                </pui-grid-column>

                <pui-grid-column
                    class="engine-run-summary-card__section--upload-info-column"
                    :cols="COLUMN_CONFIG"
                >
                    <pui-headline
                        class="engine-run-summary-card__section__headline"
                        type="h5"
                    >
                        {{ $t('engineRun.summary.summaryCard.createdUpdated') }}
                    </pui-headline>
                    <div class="engine-run-summary-card__section__item">
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ formattedDate }}
                        </pui-headline>
                    </div>
                    <pui-headline
                        class="engine-run-summary-card__section__headline"
                        type="h5"
                    >
                        {{ $t('engineRun.summary.summaryCard.createdBy') }}
                    </pui-headline>
                    <div class="engine-run-summary-card__section__item">
                        <pui-headline
                            class="engine-run-summary-card__section__item__content"
                            type="h6"
                        >
                            {{ summary?.createdBy ?? '-' }}
                        </pui-headline>
                    </div>
                </pui-grid-column>
            </pui-grid-row>
        </spinner>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEngineRunSummaryResponse } from '@/service-proxies/service-proxies.g';
import { getFormattedTime } from '@/utils/formatters';
import Spinner from '@/components/spinner/spinner.vue';

@Component({
    components: {
        Spinner,
    }
})
export default class EngineRunSummaryCardComponent extends Vue {
    private readonly COLUMN_CONFIG = { s: 6 };

    @Prop()
    private promise!: Promise<void>;
    
    private get summary(): IEngineRunSummaryResponse | undefined {
        return this.$store.getters['engineRun/summary/getEngineRunSummary'];
    }

    private get formattedDate(): string {
        return this.summary?.createdUpdated ? getFormattedTime(this.summary.createdUpdated) : '-';
    }
}
</script>

<style lang="scss" scoped>
.engine-run-summary-card {
  @include pui-box();
  @include rem(padding, pui-spacing(m));
  @include rem(margin, pui-spacing(m) 0);
  background: $white;

    &__section {
    @include rem(padding-left, pui-spacing(m));

    &--upload-info-column {
      border-left: 1px solid $warm-grey-25;
      @include rem(padding-left, pui-spacing(xl));
    }

    &--action-column {
      &__content {
        float: right;
        height: 100%;

        .pui-form-file {
            padding: 0;
        }
      }
    }

    &__headline {
      color: $warm-grey;
      @include rem(margin-bottom, pui-spacing(s));
    }

    &__item {
      @include rem(margin-bottom, pui-spacing(s));

      &--inline {
        display: flex;
        @include rem(gap, pui-spacing(xl));
      }

      &__content {
        color: $dark-grey;
        font-weight: bold;

        &--highlight {
          color: red;
        }

        &--info {
          @include rem(padding-left, pui-spacing(xs));
        }
      }

      &__title {
        color: $warm-grey;
        font-weight: normal;
      }
    }
  }
}
</style>

