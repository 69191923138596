<template>
    <div class="etl-table">
        <table-header
            :page-size="tableExtraFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
            @search-triggered="onTriggerSearch"
            @clear-search-term="onClearSearchTerm"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { IScenarioList, PrimaryDataServiceProxy } from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import BadgeCell from '@/components/benchmarking-table/cell-types/badge-cell.vue';
import { BM_LOG_LEVEL_DETAILS, BM_LOG_LEVEL_ID } from '@/config/log-level';

@Component({
    components: {
        AgGridVue,
        TableHeader,
        BadgeCell,
    }
})
export default class ETLTableComponent extends Vue {
    private primaryDataService = new PrimaryDataServiceProxy();
    private gridApi: GridApi<IScenarioList> | null = null;
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    private gridOptions: GridOptions<IScenarioList> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;

                this.primaryDataService.listEtlLogs(this.scenarioId, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        params.success({ rowData: response.result.items ?? [], rowCount: response.result.total });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.etlModal.etlTable.headers.logLevel'),
                field: 'logLevel',
                cellRenderer: 'BadgeCell',
                cellRendererParams: (params: ICellRendererParams): { text: string, backgroundColor: string } => ({
                    text: this.$t(BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL_ID[params.value]].NAME),
                    backgroundColor: BM_LOG_LEVEL_DETAILS[BM_LOG_LEVEL_ID[params.value]].COLOR,
                }),
                minWidth: 150,
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.etlModal.etlTable.headers.logMethod'),
                field: 'logMethod',
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.etlModal.etlTable.headers.logMessage'),
                field: 'logMessage',
                resizable: true,
                width: 400,
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.etlModal.etlTable.headers.subsection'),
                field: 'subsection',
                valueFormatter: (params): string => params.value !== '' ? params.value : '-',
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.etlModal.etlTable.headers.kpiId'),
                field: 'kpiId',
                valueFormatter: (params): string => params.value !== '' ? params.value : '-',
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            menuTabs: [],
            minWidth: 200,
            maxWidth: 600,
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private onGridReady(params: AgGridCommon<IScenarioList>): void {
        this.gridApi = params.api;
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }
}
</script>

<style lang="scss" scoped>
.etl-table {
  width: 100%;
  background-color: $white;
  @include pui-box();
}
</style>