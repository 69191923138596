<template>
    <div class="setup-overview">
        <div class="setup-overview__heading">
            <h3>{{ $t('setup.overview.title') }}</h3>
            <pui-button @click="newScenarioClicked">
                {{ $t('setup.overview.newScenario') }}
            </pui-button>
        </div>
        <scenario-status-cards @on-selected-status-changed="selectedStatusChanged" />
        <scenario-table :selected-status="selectedStatus" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IScenarioList } from '@/service-proxies/service-proxies.g';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';
import ScenarioStatusCards from '@/components/scenario-status-cards/scenario-status-cards.vue';
import ScenarioTable from '@/components/setup-overview/scenario-table.vue';

@Component({
    components: {
        ScenarioStatusCards,
        ScenarioTable
    }
})
export default class SetupOverviewPage extends Vue {
    private selectedStatus: string | null = null;

    private get scenarios(): IScenarioList[] {
        return this.$store.getters['setup/getScenarios'];
    }

    private async newScenarioClicked(): Promise<void> {
        await this.$store.dispatch('scenario/clearScenario');
        await this.$router.push({
            name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
            params: {
                id: 'new',
            },
        });
    }

    private selectedStatusChanged(value: string | null): void {
        this.selectedStatus = value;
    }
}
</script>

<style scoped lang="scss">
.setup-overview {
    @include rem(padding, pui-spacing(l));

    &__heading {
        display: flex;
        justify-content: space-between;
        @include rem(padding-bottom, pui-spacing(m));
    }

    &__scenario-list {
        @include pui-box();
        background-color: $white;

        li {
            @include rem(padding-left, pui-spacing(s));
            cursor: pointer;
        }
    }
}
</style>
