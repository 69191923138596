import { FormValidationFn, NoContextFormValidationFn } from '@/models/form';
import { i18n } from '@/utils/i18n';

export const objectNotNullOrUndefinedValidationFn: NoContextFormValidationFn<any> = (input): string | undefined => {
    if (input === null || input === undefined) {
        return i18n.t('form.commonErrors.objectNull');
    }
};

export const stringNotEmptyValidationFn: NoContextFormValidationFn<string | undefined> = (input): string | undefined => {
    if (input === null || input === undefined || input.trim() === '') {
        return i18n.t('form.commonErrors.stringEmpty');
    }
};

export const numberNotNaNValidationFn: NoContextFormValidationFn<number | undefined> = (input): string | undefined => {
    if (input && isNaN(input)) {
        return i18n.t('form.commonErrors.notANumber');
    }
};

export const createStringLengthValidationFn: (lowerBound: number, upperBound: number) => NoContextFormValidationFn<string> = (lowerBound: number, upperBound: number) => {
    return (input): string | undefined => {
        if (input.length > upperBound) {
            return i18n.t('form.commonErrors.stringGreaterThan', { bound: upperBound });
        }

        if (input.length < lowerBound) {
            return i18n.t('form.commonErrors.stringLessThan', { bound: lowerBound });
        }
    };
};

export const arrayNotEmptyValidationFn: NoContextFormValidationFn<any[]> = (input): string | undefined => {
    if (input.length === 0) {
        return i18n.t('form.commonErrors.arrayEmpty');
    }
};

export const combineValidationFns = <T, C>(...validationFns: FormValidationFn<T, C>[]): FormValidationFn<T, C> => {
    return (input, context) => {
        for (const validationFn of validationFns) {
            const result = validationFn(input, context);

            if (result) {
                return result;
            }
        }
    };
};
