<template>
    <div class="kpi-analysis-display">
        <pui-loader
            v-if="isKpiAnalysisLoading"
            :promise="infinitePromise"
            :title="$t('results.kpiAnalysis.loader.title')"
            :message="$t('results.kpiAnalysis.loader.message')"
        />
        <pui-loader-error
            v-else-if="noDataLoaded || !hasAllParametersForKpiAnalysis"
            :title="$t('results.kpiAnalysis.errors.title')"
            :message="$t('results.kpiAnalysis.errors.message')"
            icon="error-empty-data"
        />
        <div
            v-show="!noDataLoaded && hasAllParametersForKpiAnalysis && !isKpiAnalysisLoading"
            :ref="REF_CONSTANTS.CHART"
            class="kpi-analysis-display__chart"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GoogleCharts } from 'google-charts';
import { IKpiAnalysisItemDto, KpiAnalysisResponse, RunResultServiceProxy } from '@/service-proxies/service-proxies.g';

const NODE_COLORS = {
    RED: '#FDC2C4',
    GREEN: '#E4FBC3',
    GRAY: '#C2C2C2'
} as const;

const REF_CONSTANTS = {
    CHART: 'chart',
} as const;

type KpiAnalysisTraversalItem = {
    nodeId?: number;
} & IKpiAnalysisItemDto;

type ChartItem = {
    nodeId: string;
    kpiId: string;
    formula?: string;
    value?: number;
    parentNodeId?: string;
    isLeafNode: boolean;
};

@Component({})
export default class KpiAnalysisDisplay extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly RUN_RESULTS_SERVICE = new RunResultServiceProxy();

    @Prop({ required: true })
    private readonly scenarioId!: number;

    @Prop({ required: true })
    private readonly kpiId!: string;

    @Prop({ required: true })
    private readonly year!: number;

    @Prop({ required: true })
    private readonly unitSid!: number;

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    private isKpiAnalysisLoading = false;
    private kpiAnalysis: KpiAnalysisResponse | null = null;

    private nextId = 1;
    private flattenedChartItems: ChartItem[] = [];
    private orgChart?: any;

    $refs!: {
        [REF_CONSTANTS.CHART]?: HTMLDivElement;
    };

    private get noDataLoaded(): boolean {
        return !this.kpiAnalysis;
    }

    private get hasAllParametersForKpiAnalysis(): boolean {
        return !!this.kpiId && !!this.scenarioId && !!this.year && !!this.unitSid;
    }

    created(): void {
        GoogleCharts.load(this.drawOrgChart, {
            packages: ['orgchart'],
        });
    }

    mounted(): void {
        GoogleCharts.load(() => {
            this.orgChart = new GoogleCharts.api.visualization.OrgChart(this.$refs.chart);
            this.onKpiAnalysisParameterChange();
        }, {
            packages: ['orgchart'],
        });
    }

    @Watch('scenarioId')
    @Watch('kpiId')
    @Watch('year')
    @Watch('unitSid')
    private onKpiAnalysisParameterChange(): void {
        this.fetchKpiAnalysis();
    }

    private async fetchKpiAnalysis(): Promise<void> {
        if (!this.hasAllParametersForKpiAnalysis) {
            return;
        }

        this.isKpiAnalysisLoading = true;
        try {
            const response = await this.RUN_RESULTS_SERVICE.kpiAnalysis(this.scenarioId, this.kpiId, this.year, this.unitSid);
            this.kpiAnalysis = response.result;
        } catch {
            this.kpiAnalysis = null;
        } finally {
            this.isKpiAnalysisLoading = false;
        }
    }

    private traverseKpiAnalysisItems(children: KpiAnalysisTraversalItem[], parent?: KpiAnalysisTraversalItem): void {
        if (children.length === 0) {
            return;
        }

        if (!parent) {
            this.flattenedChartItems = [];
            this.nextId = 1;
        }

        children.forEach(e => {
            if (!e.id) {
                return;
            }

            const itemId = this.nextId++;

            this.flattenedChartItems.push({
                nodeId: `${itemId}`,
                kpiId: e.id,
                formula: e.formula,
                value: e.value,
                isLeafNode: e.children ? e.children.length === 0 : true,
                parentNodeId: parent ? `${parent?.nodeId}` : undefined,
            });

            this.traverseKpiAnalysisItems(e.children ?? [], {
                ...e,
                nodeId: itemId,
            });
        })
    }

    @Watch('kpiAnalysis')
    private drawOrgChart(): void {
        if (!this.kpiAnalysis || !this.orgChart) {
            return;
        }

        this.traverseKpiAnalysisItems([{
            ...this.kpiAnalysis.rootKpi,
            nodeId: this.nextId,
        }]);

        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'KpiId');
        data.addColumn('string', 'ParentKpiId');
        data.addColumn('string', 'Tooltip');

        data.addRows(this.flattenedChartItems.map(e => {
            const firstRow = `<div>${e.kpiId}: ${e.value ?? 'None'}</div>`;
            const secondRow = e.formula ? `<div>${e.formula ?? ''}</div>` : '';

            return [
                {
                    v: e.nodeId,
                    f: `<div class="chart-item-content">${firstRow}${secondRow}</div>`
                },
                e.parentNodeId,
                ''
            ];
        }));

        data.getSortedRows()
            .forEach((e: number) => {
                const nodeId = data.getValue(e, 0);
                const chartItem = this.flattenedChartItems.find(e => e.nodeId === nodeId);

                if (!chartItem) {
                    return;
                }

                data.setRowProperty(e, 'style', this.getStyleForItem(chartItem.isLeafNode, chartItem.value !== undefined))
            });

        this.orgChart.draw(data, {
            allowHtml: true,
            selectedNodeClass: 'a',
        });
    }

    private getStyleForItem(isLeafNode: boolean, hasValue: boolean): string {
        let colorString;

        if (hasValue) {
            colorString = NODE_COLORS.GREEN;
        } else {
            colorString = isLeafNode ? NODE_COLORS.RED : NODE_COLORS.GRAY;
        }

        return `background-color: ${colorString} !important;`;
    }
}
</script>

<style scoped lang="scss">
.kpi-analysis-display {
    @include pui-box();

    background-color: $white;
    overflow: scroll;

    &__chart {
        @include rem(padding-top, pui-spacing(s));

        flex-grow: 1;

        ::v-deep table {
            @include rem(padding-left, pui-spacing(s));
            @include rem(padding-right, pui-spacing(s));
        }

        ::v-deep td {
            min-width: unset;
        }

        ::v-deep .google-visualization-orgchart-connrow-medium {
            background-color: $white;
        }

        ::v-deep .google-visualization-orgchart-noderow-medium {
            background-color: $white;
        }

        ::v-deep .google-visualization-orgchart-node {
            border: unset;
            border-radius: 0.4rem;
            background: unset;
            box-shadow: unset;
            --webkit-box-shadow: unset;
        }

        ::v-deep .google-visualization-orgchart-linenode {
            border-color: $dark-grey;
        }
    }

    ::v-deep .chart-item-content {
        display: flex;
        flex-direction: column;

        white-space: nowrap;
    }
}
</style>
