var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "close-on-e-s-c": false,
      "default-header-label": _vm.headerLabel
    }
  }, [_c('pui-loader', {
    attrs: {
      "promise": _vm.detailsPromise
    }
  }, [_c('scenario-compare-details-table')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }