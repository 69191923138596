var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.headerText,
      "close-on-e-s-c": false,
      "close-on-overlay-click": true
    }
  }, [_c('kpi-analysis-display', {
    attrs: {
      "scenario-id": _vm.scenarioId,
      "kpi-id": _vm.kpiId,
      "year": _vm.year,
      "unit-sid": _vm.unitSid
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }