<template>
    <div class="sc-summary">
        <pui-loader-error
            v-if="hasNoScenario"
            :title="$t('results.scenarioCompare.errors.atLeastOne.title')"
            :message="$t('results.scenarioCompare.errors.atLeastOne.message')"
            icon="error-empty-data"
        />
        <div
            v-else
            class="sc-summary__section"
        >
            <div class="sc-summary__section__content">
                <template v-if="firstScenario">
                    <div class="sc-summary__section__content__title">
                        {{ $t('results.scenarioCompare.summary.title', { name: firstScenario.name ?? '' }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.kpis', { count: firstScenario.kpisCalculated }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.sites', { count: firstScenario.sites }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.years', { count: firstScenario.years }) }}
                    </div>
                </template>
            </div>
            <div class="sc-summary__section__separator" />
            <div class="sc-summary__section__content sc-summary__section__content--second">
                <template v-if="secondScenario">
                    <div class="sc-summary__section__content__title">
                        {{ $t('results.scenarioCompare.summary.title', { name: secondScenario.name ?? '' }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.kpis', { count: secondScenario.kpisCalculated }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.sites', { count: secondScenario.sites }) }}
                    </div>
                    <div class="sc-summary__section__content__item">
                        {{ $t('results.scenarioCompare.summary.years', { count: secondScenario.years ?? 0 }) }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IScenarioRunDto } from '@/service-proxies/service-proxies.g';

@Component({})
export default class ScenarioCompareSummary extends Vue {
    private get firstScenario(): IScenarioRunDto | undefined {
        return this.$store.getters['results/scenarioCompare/getFirstScenario'];
    }

    private get secondScenario(): IScenarioRunDto | undefined {
        return this.$store.getters['results/scenarioCompare/getSecondScenario'];
    }

    private get hasNoScenario(): boolean {
        return !this.firstScenario
            && !this.secondScenario;
    }
}
</script>

<style scoped lang="scss">
.sc-summary {
    @include pui-box();
    @include rem(padding, pui-spacing(s));

    display: flex;
    align-items: center;

    background-color: $white;

    &__section {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;

        width: 100%;

        &__content {
            @include rem(padding, pui-spacing(s));
            @include rem(gap, pui-spacing(xs));

            display: flex;
            flex-direction: column;

            &__title {
                font-size: 1.6rem;
                color: $warm-grey;
            }

            &__item {
                font-size: 1.4rem;
            }

            &--second {
                @include rem(padding-left, pui-spacing(m));
            }
        }

        &__separator {
            width: 1px;
            height: 100%;
            background-color: $separator-grey;
        }
    }
}
</style>
