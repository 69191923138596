export const NET_GENERATION_VOLUME = 'NETGV' as const;
export const TOTAL_NUMBER_OF_STARTS = 'NROST' as const;
export const OPERATIONAL_HOURS = 'OPERH' as const;

export const TREND_REPORTS_CONFIG = {
    [NET_GENERATION_VOLUME]: {
        title: 'results.siteView.trendReports.netGenerationVolume',
        color: ['#008CCC'],
    },
    [TOTAL_NUMBER_OF_STARTS]: {
        title: 'results.siteView.trendReports.numberOfStarts',
        color: ['#006999'],
    },
    [OPERATIONAL_HOURS]: {
        title: 'results.siteView.trendReports.operatingHours',
        color: ['#00B0FF'],
    },
} as const;
