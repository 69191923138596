var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sv-units-selector"
  }, [_vm.filters ? _c('pui-filter', {
    attrs: {
      "use-router": false,
      "config": _vm.filterConfiguration,
      "vuex-namespace": "ns1"
    },
    on: {
      "changed:applied-values": _vm.filtersChanged
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }