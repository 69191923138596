<template>
    <div class="primary-kpi-tab">
        <kpi-summary-card is-primary />
        <div class="primary-kpi-tab__primary-kpi-table">
            <spinner :promise="filtersPromise">
                <table-header
                    :page-size="tableExtraFilters.pageSize"
                    @page-size-changed="onPageSizeChanged"
                    @search-triggered="onTriggerSearch"
                    @clear-search-term="onClearSearchTerm"
                />
                <ag-grid-vue
                    class="ag-theme-alpine"
                    :grid-options="gridOptions"
                    @grid-ready="onGridReady"
                />
            </spinner>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
    GridApi,
    GridOptions,
    IServerSideGetRowsParams,
    SetFilterValuesFuncParams,
    ValueFormatterParams
} from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { IKpiDto, KpiServiceProxy, ListSortDirection, SortableKpiField } from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import Spinner from '@/components/spinner/spinner.vue';
import KpiSummaryCard from '@/components/formula-step/kpi-summary-card/kpi-summary-card.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';
import { ConstantEntry } from '@/store/modules/constants.module';

type PrimaryKpiSetFilters = {
    kpiTypes: number[],
};

@Component({
    components: {
        AgGridVue,
        KpiSummaryCard,
        TableHeader,
        Spinner,
    }
})
export default class PrimaryKpiTabComponent extends Vue {
    private kpiService = new KpiServiceProxy();
    private gridApi: GridApi<IKpiDto> | null = null;
    private filtersPromise: Promise<any> | null = null;

    private readonly GREEN = 'green';
    private readonly RED = 'red';

    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    /**
     * This prop is required to know the selected status.
     */
    @Prop({ default: '0' })
    private selectedStatus!: string;

    private gridOptions: GridOptions<IKpiDto> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const { kpiTypes } = this.getSelectedFiltersForServerRequestParams(params);
                const scenarioId = this.scenarioId;
                const sortField = this.sortField(params.request.sortModel[0]?.colId)
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort)
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;

                this.kpiService.list4(scenarioId, true, kpiTypes, undefined, sortField, sortDirection, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        params.success({ rowData: response.result.items?.map(e => e.kpiFormula) ?? [], rowCount: response.result.count });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiType'),
                field: 'kpiTypeId',
                valueFormatter: (params: ValueFormatterParams): string => {
                    return params.value ? this.scenarioFilters.kpiTypes[params.value] : '-';
                },
                pinned: 'left',
                minWidth: 200,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.scenarioFilters.kpiTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return params.value ? this.scenarioFilters.kpiTypes[params.value] : '-';
                    }
                },
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiId'),
                field: 'kpiId',
                minWidth: 200,
                sortable: true,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiDescription'),
                field: 'kpiDesc',
                minWidth: 600,
                maxWidth: 600,
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.kpiUom'),
                field: 'unitOfMeasureId',
                valueFormatter: (params: ValueFormatterParams): string => {
                    return this.unitsOfMeasure.find(e => e.id.toString() === params.value?.toString())?.value ?? '-';
                },
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.unit'),
                field: 'unit',
                valueFormatter: (params): string => params.value ? this.$t('setup.scenario.formulaStep.cell.yes') : this.$t('setup.scenario.formulaStep.cell.no'),
                cellStyle: (params): { color: string } => ({ color: params.value ? this.GREEN : this.RED }),
            },
            {
                headerName: this.$t('setup.scenario.formulaStep.headers.unitZero'),
                field: 'unitZero',
                valueFormatter: (params): string => params.value ? this.$t('setup.scenario.formulaStep.cell.yes') : this.$t('setup.scenario.formulaStep.cell.no'),
                cellStyle: (params): { color: string } => ({ color: params.value ? this.GREEN : this.RED }),
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 100,
            maxWidth: 400,
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private get unitsOfMeasure(): ConstantEntry[] {
        return this.$store.getters['constants/unitsOfMeasure'];
    }

    private mounted(): void {
        this.filtersPromise = this.$store.dispatch('scenario/filters/ensureFiltersAreLoaded');
    }

    private onGridReady(params: AgGridCommon<IKpiDto>): void {
        this.gridApi = params.api;
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if (!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortableKpiField | undefined {
        if (!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortableKpiField> = {
            'kpiTypeId': SortableKpiField.KPI_TYPE,
            'kpiId': SortableKpiField.KPI_ID,
            'kpiDesc': SortableKpiField.KPI_DESC,
        };

        return fieldKey[colId];
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize);
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): PrimaryKpiSetFilters {
        return {
            kpiTypes: params.request.filterModel?.kpiTypeId?.values?.map((e: string) => Number(e)),
        };
    }
}
</script>

<style lang="scss">
.primary-kpi-tab {
    &__primary-kpi-table {
        width: 100%;
        background-color: $white;
        @include rem(margin-top, pui-spacing(m));
        @include rem(margin-bottom, pui-spacing(m));
        @include pui-box();
    }
}
</style>
