<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :default-header-label="headerLabel"
        :fit-content="true"
        :close-on-e-s-c="false"
        :default-footer-confirm-label="$t('setup.scenario.formulaStepForm.buttons.save')"
        :default-footer-cancel-label="$t('setup.scenario.formulaStepForm.buttons.cancel')"
        :default-footer-confirm-disabled="!isFormSubmittable"
        :on-confirm-callback="lightboxConfirm"
        @puilightbox:close="emitClose()"
    >
        <pui-form-group :label="$t(CONFIG[currentMode].inputLabel)">
            <pui-form-input-field
                v-model="inputValue"
                :placeholder-text="$t('admin.modal.input.placeholder')"
            />
        </pui-form-group>
    </pui-lightbox>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import { AddKpiTypeRequest } from '@/service-proxies/service-proxies.g';
import { AdminFormConfig, AdminModalMode } from '@/models/admin/constants';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({})
export default class ConstantsLightboxComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    private isFormSubmitting = false;
    private inputValue = '';

    private readonly CONFIG: AdminFormConfig = {
        [AdminModalMode.KPI_TYPE]: {
            titleLabel: 'admin.modal.title.newKpiType',
            inputLabel: 'admin.modal.input.label.kpiType',
            addFn: this.addKpiType,
        },
        [AdminModalMode.UOM]: {
            titleLabel: 'admin.modal.title.newUOM',
            inputLabel: 'admin.modal.input.label.uom',
            addFn: this.addUOM,
        },
    } as const;

    @Prop()
    private currentMode!: AdminModalMode;

    @Prop()
    private lightboxOpen!: boolean;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private get headerLabel(): string {
        return this.$t(this.CONFIG[this.currentMode].titleLabel);
    }

    private get isFormSubmittable(): boolean {
        return this.inputValue.trim() !== ''
            && !this.isFormSubmitting;
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(refresh = false): void {
        this.$emit('close-lightbox', { refresh, currentModalMode: this.currentMode });
        this.clearForm();
    }

    private clearForm(): void {
        this.inputValue = '';
    }

    private async addKpiType(): Promise<void> {
        const payload: AddKpiTypeRequest = new AddKpiTypeRequest({
            kpiTypes: [this.inputValue],
        });
        return await this.$store.dispatch('constants/addKpiType', payload);
    }

    private async addUOM(): Promise<void> {
        const payload: string[] = [this.inputValue];
        return await this.$store.dispatch('constants/addUOM', payload);
    }

    private lightboxConfirm(): void {
        this.isFormSubmitting = true;

        this.CONFIG[this.currentMode].addFn()
            .then(() => {
                this.$pui.toast({
                    type: 'success',
                    title: this.$t('admin.modal.toast.success.title'),
                    copy: this.$t('admin.modal.toast.success.copy'),
                });
                this.emitClose(true);
            })
            .catch(() => {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('admin.modal.toast.error.title'),
                    copy: this.$t('admin.modal.toast.error.copy'),
                });
            })
            .finally(() => {
                this.isFormSubmitting = false;
            });
    }
}
</script>
