<template>
    <pui-loader :promise="loaderPromise">
        <div class="setup-step">
            <pui-form-inline-notification
                v-if="isReferenceYearDifferentFromExternal"
                :title="$t('setup.scenario.setupStepForm.differentReferenceYear.title')"
                :text="$t('setup.scenario.setupStepForm.differentReferenceYear.text', { year: EXTERNAL_REFERENCE_YEAR })"
                type="info"
            />
            <pui-form class="setup-step__form">
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :is-valid="formData.validation.name"
                        :has-label-padding="false"
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.name')"
                        label-for="scenarioName"
                        class="setup-step__form__name-group"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.FULL"
                    >
                        <pui-form-input-field
                            id="scenarioName"
                            v-model="formData.values.name"
                            :is-disabled="isFormDisabled"
                            :is-valid="formData.validation.name"
                            :max-length="MAX_LENGTH_CONFIG.name"
                            :placeholder-text="$t('setup.scenario.setupStepForm.placeholders.name')"
                            @input="validateField('name')"
                        />
                        <template #helper-text>
                            <span v-show="formData.values.name.length > (MAX_LENGTH_CONFIG.name / 2)">
                                {{ $t('form.characterCounter', { current: formData.values.name.length, max: MAX_LENGTH_CONFIG.name }) }}
                            </span>
                        </template>
                        <template #error-message>
                            {{ formData.errorMessages.name }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :is-valid="formData.validation.previousExercise"
                        :label="$t('setup.scenario.setupStepForm.labels.previousExercise')"
                        :cta-label="$t('form.clear')"
                        :has-cta="!isFormDisabled"
                        :cta-callback="clearPreviousExercise"
                        label-for="previousExercise"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.TWO_THIRDS"
                    >
                        <pui-form-type-ahead
                            v-model="formData.values.previousExercise"
                            :options="scenarioOptions"
                            :use-local-cache="true"
                            :is-static-mode="true"
                            :is-disabled="isFormDisabled"
                            :is-valid="formData.validation.previousExercise"
                            :is-loading="isLoadingScenarios"
                            :label="$t('setup.scenario.setupStepForm.labels.previousExercise')"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.previousExercise')"
                            search-input-id="previousExercise"
                            @search="scenarioTypeaheadSearch"
                            @abort="scenarioTypeaheadAborted"
                            @change="previousExerciseChanged"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.previousExercise }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        class="setup-step__form__radio-group"
                        :is-valid="formData.validation.importType"
                        label=""
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.ONE_THIRD"
                    >
                        <pui-form-radio
                            v-model.number="formData.values.importType"
                            :is-valid="formData.validation.importType"
                            :is-disabled="isFormDisabled || !isImportTypeSelectable"
                            :value="ImportType.KEEP_ALL_DATA"
                            :label="$t('setup.scenario.setupStepForm.labels.keepAllData')"
                            v-tooltip="$t('setup.scenario.setupStepForm.tooltips.keepAllData')"
                            @change="importTypeChanged"
                        />
                        <pui-form-radio
                            v-model.number="formData.values.importType"
                            :is-valid="formData.validation.importType"
                            :is-disabled="isFormDisabled || !isImportTypeSelectable"
                            :value="ImportType.RELOAD_CERTAIN"
                            :label="$t('setup.scenario.setupStepForm.labels.reloadOnlyCertainData')"
                            v-tooltip="$t('setup.scenario.setupStepForm.tooltips.reloadOnlyCertainData')"
                            @change="importTypeChanged"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.importType }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('setup.scenario.setupStepForm.labels.description')"
                        :is-valid="formData.validation.description"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.FULL"
                    >
                        <pui-form-textarea
                            v-model="formData.values.description"
                            :is-valid="formData.validation.description"
                            :is-disabled="isFormDisabled"
                            :max-length="MAX_LENGTH_CONFIG.description"
                            :placeholder-text="$t('setup.scenario.setupStepForm.placeholders.description')"
                            @input="validateField('description')"
                        />
                        <template #helper-text>
                            <span v-show="formData.values.description.length > (MAX_LENGTH_CONFIG.description / 2)">
                                {{ $t('form.characterCounter', { current: formData.values.description.length, max: MAX_LENGTH_CONFIG.description }) }}
                            </span>
                        </template>
                        <template #error-message>
                            {{ formData.errorMessages.description }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.mtpYear')"
                        :is-valid="formData.validation.mtpYear"
                        label-for="mtpYear"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.HALF"
                    >
                        <pui-form-select
                            v-model="formData.values.mtpYear"
                            :options="mtpYearOptions"
                            :label="$t('setup.scenario.setupStepForm.labels.mtpYear')"
                            :is-valid="formData.validation.mtpYear"
                            :is-disabled="isFormDisabled || isLoadingSelectedScenario"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.mtpYear')"
                            search-input-id="mtpYear"
                            @change="validateField('mtpYear')"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.mtpYear }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.referenceYear')"
                        :is-valid="formData.validation.referenceYear"
                        label-for="referenceYear"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.HALF"
                    >
                        <pui-form-select
                            v-model="formData.values.referenceYear"
                            :options="getYearOptions(YEAR_RANGE_CONFIG.referenceYear)"
                            :label="$t('setup.scenario.setupStepForm.labels.referenceYear')"
                            :is-valid="formData.validation.referenceYear"
                            :is-disabled="isFormDisabled || isLoadingSelectedScenario"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.referenceYear')"
                            search-input-id="referenceYear"
                            @change="validateField('referenceYear')"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.referenceYear }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.startYear')"
                        :is-valid="formData.validation.startYear"
                        label-for="startYear"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.HALF"
                    >
                        <pui-form-select
                            v-model="formData.values.startYear"
                            :options="getYearOptions(YEAR_RANGE_CONFIG.startYear)"
                            :label="$t('setup.scenario.setupStepForm.labels.startYear')"
                            :is-valid="formData.validation.startYear"
                            :is-disabled="isFormDisabled || isLoadingSelectedScenario"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.startYear')"
                            search-input-id="startYear"
                            @change="validateField('startYear')"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.startYear }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.endYear')"
                        :is-valid="formData.validation.endYear"
                        label-for="endYear"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.HALF"
                    >
                        <pui-form-select
                            v-model="formData.values.endYear"
                            :options="getYearOptions(YEAR_RANGE_CONFIG.endYear)"
                            :label="$t('setup.scenario.setupStepForm.labels.endYear')"
                            :is-valid="formData.validation.endYear"
                            :is-disabled="isFormDisabled || isLoadingSelectedScenario"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.endYear')"
                            search-input-id="endYear"
                            @change="validateField('endYear')"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.endYear }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        v-if="showReportingOccasion"
                        :show-required-label="true"
                        :label="$t('setup.scenario.setupStepForm.labels.reportingOccasion')"
                        :is-valid="formData.validation.reportingOccasion"
                        label-for="reportingOccasion"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.HALF"
                    >
                        <pui-form-select
                            v-model="formData.values.reportingOccasion"
                            :options="reportingOccasion"
                            :label="$t('setup.scenario.setupStepForm.labels.reportingOccasion')"
                            :is-valid="formData.validation.reportingOccasion"
                            :is-disabled="isFormDisabled || isLoadingSelectedScenario"
                            :search-input-placeholder="$t('setup.scenario.setupStepForm.placeholders.reportingOccasion')"
                            search-input-id="reportingOccasion"
                            @change="validateField('reportingOccasion')"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.reportingOccasion }}
                        </template>
                    </pui-form-group>
                </div>
            </pui-form>
            <p class="setup-step__instruction-text">
                {{ $t('setup.scenario.setupStepForm.editableWarningMessage') }}
            </p>
            <div class="setup-step__buttons">
                <pui-button
                    :disabled="isBackButtonDisabled"
                    state="secondary"
                    @click="backButtonClicked"
                >
                    {{ $t('setup.scenario.buttons.back') }}
                </pui-button>
                <pui-button
                    :disabled="isSaveAsDraftButtonDisabled"
                    state="secondary"
                    @click="saveAsDraftButtonClicked"
                >
                    {{ $t('setup.scenario.buttons.saveAsDraft') }}
                </pui-button>
                <pui-button
                    :disabled="isNextButtonDisabled"
                    state="primary"
                    @click="nextButtonClicked"
                >
                    {{ $t('setup.scenario.buttons.next') }}
                </pui-button>
            </div>
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import { IFormData } from '@/models/form';
import {
    IAddScenarioRequest,
    IEditScenarioRequest,
    IGetScenarioResponse,
    IReportingOccasionDto,
    IScenarioList,
    ScenarioServiceProxy
} from '@/service-proxies/service-proxies.g';
import Component, { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import {
    combineValidationFns,
    createStringLengthValidationFn,
    numberNotNaNValidationFn,
    objectNotNullOrUndefinedValidationFn,
    stringNotEmptyValidationFn
} from '@/utils/form-validation/form-validation-utils';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';
import { formatMtpYear, getFormattedShortDate } from '@/utils/formatters';

export enum ImportType {
    KEEP_ALL_DATA = 1,
    RELOAD_CERTAIN = 2
}

const SetupFormKeys = ['name', 'previousExercise', 'importType', 'description', 'mtpYear', 'referenceYear', 'startYear', 'endYear', 'reportingOccasion'] as const;
type SetupFormKeysType = typeof SetupFormKeys[number];

type SetupFormValuesType = {
    name: string;
    previousExercise: PuiSelectOption[];
    importType: ImportType | undefined;
    description: string;
    mtpYear: number | undefined;
    referenceYear: number | undefined;
    startYear: number | undefined;
    endYear: number | undefined;
    reportingOccasion: string;
};

enum SetupFormType {
    CREATE = 1,
    UPDATE = 2
}

@Component({
    beforeRouteLeave(to, from, next) {
        if ((this as SetupScenarioStep).shouldSaveOnRouteLeave) {
            (this as SetupScenarioStep).submitForm(true);
        }

        next();
    }
})
export default class SetupScenarioStep extends mixins(ManagedAbortControllers, ScenarioStepNavigation) {
    private readonly ImportType = ImportType;

    private readonly EXTERNAL_REFERENCE_YEAR = 2018;

    private readonly GRID_COLUMN_SPAN = {
        ONE_THIRD: 4,
        HALF: 6,
        TWO_THIRDS: 8,
        FULL: 12,
    } as const;

    private readonly YEAR_RANGE_CONFIG = {
        referenceYear: {
            start: 2018,
            end: 2030,
        },
        startYear: {
            start: 2011,
            end: 2030,
        },
        endYear: {
            start: 2011,
            end: 2030,
        },
    } as const;

    private readonly MAX_LENGTH_CONFIG = {
        name: 50,
        description: 250,
    } as const;

    private scenarioService = new ScenarioServiceProxy();
    private loaderPromise: Promise<any> | null = null;

    private isLoadingScenarios = false;
    private isLoadingSelectedScenario = false;
    private isFormSubmitting = false;
    private isFormDisabled = false;
    private isFormSubmitted = false;

    private formData: IFormData<SetupFormKeysType, SetupFormValuesType> = {
        initialValues: {
            name: '',
            previousExercise: [],
            importType: undefined,
            description: '',
            mtpYear: undefined,
            referenceYear: undefined,
            startYear: undefined,
            endYear: undefined,
            reportingOccasion: '',
        },
        values: {
            name: '',
            previousExercise: [],
            importType: undefined,
            description: '',
            mtpYear: undefined,
            referenceYear: 2018,
            startYear: undefined,
            endYear: undefined,
            reportingOccasion: '',
        },
        validation: {
            name: true,
            previousExercise: true,
            importType: true,
            description: true,
            mtpYear: true,
            referenceYear: true,
            startYear: true,
            endYear: true,
            reportingOccasion: true,
        },
        validators: {
            name: combineValidationFns(
                stringNotEmptyValidationFn,
                createStringLengthValidationFn(1, this.MAX_LENGTH_CONFIG.name),
            ),
            previousExercise: () => undefined,
            importType: (input, context) => {
                if (context.previousExercise.length !== 0 && input && !ImportType[input]) {
                    return this.$t('setup.scenario.setupStepForm.errors.importTypeNotSelected');
                }
            },
            description: createStringLengthValidationFn(0, this.MAX_LENGTH_CONFIG.description),
            mtpYear: combineValidationFns(
                objectNotNullOrUndefinedValidationFn,
                numberNotNaNValidationFn
            ),
            referenceYear: combineValidationFns(
                objectNotNullOrUndefinedValidationFn,
                numberNotNaNValidationFn
            ),
            startYear: combineValidationFns(
                objectNotNullOrUndefinedValidationFn,
                numberNotNaNValidationFn
            ),
            endYear: combineValidationFns(
                objectNotNullOrUndefinedValidationFn,
                numberNotNaNValidationFn,
                (input, context) => {
                    if (input !== undefined && context.startYear !== undefined && input < context.startYear) {
                        return this.$t('setup.scenario.setupStepForm.errors.endYearBeforeStartYear');
                    }
                },
            ),
            reportingOccasion: (input, context) => {
                const currentYear = new Date().getFullYear();
                if (!context.endYear || (context.endYear && context.endYear >= currentYear)) {
                    return stringNotEmptyValidationFn(input)
                }
            },
        },
        errorMessages: {
            name: undefined,
            previousExercise: undefined,
            importType: undefined,
            description: undefined,
            mtpYear: undefined,
            referenceYear: undefined,
            startYear: undefined,
            endYear: undefined,
            reportingOccasion: undefined,
        },
    }

    private mounted(): void {
        const initialPromises = [
            this.$store.dispatch('scenario/setupStep/fetchReportingOccasions', { signal: this.getSignal('scenarioSetupStepInitial') }),
            this.$store.dispatch('scenario/setupStep/fetchMtpYears', { signal: this.getSignal('scenarioSetupStepInitial') }),
            this.$store.dispatch('scenario/setupStep/fetchScenarios', { signal: this.getSignal('scenarioSetupSearch') }),
        ];

        this.loaderPromise = Promise.all(initialPromises)
            .then(() => {
                if (this.$store.getters['scenario/isScenarioLoaded']) {
                    this.loadDataFromExistingScenario();
                }
            });
    }

    private beforeDestroy(): void {
        this.abortAndClearAllSignals();
    }

    private get shouldSaveOnRouteLeave(): boolean {
        return !this.isFormSubmitted
            && !this.isFormDisabled
            && this.formType === SetupFormType.UPDATE;
    }

    private get isReferenceYearDifferentFromExternal(): boolean {
        return this.formData.values.referenceYear ? this.formData.values.referenceYear !== this.EXTERNAL_REFERENCE_YEAR : false;
    }

    private get isBackButtonDisabled(): boolean {
        return this.isFormSubmitting;
    }

    private get isSaveAsDraftButtonDisabled(): boolean {
        return this.isFormClean
            || this.isFormSubmitting
            || this.isLoadingSelectedScenario
            || this.isFormDisabled;
    }

    private get isNextButtonDisabled(): boolean {
        if (this.isFormDisabled) {
            return false;
        }

        return this.isFormSubmitting
            || this.isLoadingSelectedScenario;
    }

    private get isFormClean(): boolean {
        return SetupFormKeys
            .map(key => this.formData.values[key] === this.formData.initialValues[key])
            .reduce((previousValue, currentValue) => previousValue && currentValue, true);
    }

    private get isFormValid(): boolean {
        return SetupFormKeys.every(key => this.formData.validation[key]);
    }

    private get isDuplicating(): boolean {
        return this.$store.getters['scenario/isDuplicating'];
    }

    private get existingScenario(): IGetScenarioResponse | undefined {
        return this.$store.getters['scenario/getScenario'];
    }

    private get formType(): SetupFormType {
        return this.$store.getters['scenario/isScenarioLoaded'] && !this.isDuplicating ? SetupFormType.UPDATE : SetupFormType.CREATE;
    }

    private get scenarioOptions(): PuiSelectOptions {
        return this.$store.getters['scenario/setupStep/scenarios'].map((scenario: IScenarioList) => ({
            label: scenario.name,
            value: scenario.id,
        }));
    }

    private get showReportingOccasion(): boolean {
        const endYear = this.formData.values.endYear; 
        const currentYear = new Date().getFullYear();

        if (!endYear) {
            return true
        }

        return endYear >= currentYear
    }

    private get reportingOccasion(): PuiSelectOptions<string> {
        return this.$store.getters['scenario/setupStep/reportingOccasions'].map((item: IReportingOccasionDto) => ({
            label: `${item.reportingOccasion} (as of ${getFormattedShortDate(item.asOfDate)})`,
            value: item.reportingOccasion,
        }));
    }

    private get mtpYearOptions(): PuiSelectOptions<number> {
        const mtpYears = this.$store.getters['scenario/setupStep/mtpYears'].map((year: number) => ({
            label: formatMtpYear(year),
            value: year,
        }));

        const noMtpOption = {
            label: this.$t('setup.scenario.setupStepForm.noMtpYearOption'),
            value: 0,
        };

        return [noMtpOption, ...mtpYears];
    }

    private get isImportTypeSelectable(): boolean {
        return this.formData.values.previousExercise.length !== 0;
    }

    private async loadDataFromExistingScenario(): Promise<void> {
        if (!this.existingScenario) {
            return;
        }

        this.formData.values.name = this.existingScenario.name ?? '';
        this.formData.values.description = this.existingScenario.description ?? '';
        this.formData.values.mtpYear = this.existingScenario.mtpYear;
        this.formData.values.referenceYear = this.existingScenario.referenceYear;
        this.formData.values.startYear = this.existingScenario.startYear;
        this.formData.values.endYear = this.existingScenario.endYear;
        this.formData.values.reportingOccasion = this.existingScenario.reportingOccasion ?? '';

        this.$nextTick(() => {
            this.clearFieldValidation('reportingOccasion');
        });

        if (this.isDuplicating) {
            this.formData.values.importType = this.existingScenario.persistAllData ? ImportType.KEEP_ALL_DATA : ImportType.RELOAD_CERTAIN;
            this.formData.values.previousExercise = [
                {
                    label: this.existingScenario.name ?? '',
                    value: this.existingScenario.id.toString() ?? '',
                }
            ];
        } else if (this.existingScenario.previousScenarioId) {
            this.formData.values.importType = this.existingScenario.persistAllData ? ImportType.KEEP_ALL_DATA : ImportType.RELOAD_CERTAIN;
            this.formData.values.previousExercise = [
                {
                    label: this.existingScenario.previousScenarioName ?? '',
                    value: this.existingScenario.previousScenarioId?.toString() ?? '',
                }
            ];
        }

        this.isFormDisabled = !this.existingScenario.saveAsDraft && !this.isDuplicating;

        this.setCurrentValuesAsInitialValues();
    }

    private setCurrentValuesAsInitialValues(): void {
        SetupFormKeys.forEach(key => {
            this.formData.initialValues[key] = this.formData.values[key] as never;
        });
    }

    private getYearOptions(config: { start: number, end: number }): PuiSelectOptions<number> {
        const options: PuiSelectOptions<number> = [];

        for (let i = config.start; i <= config.end; i++) {
            options.push({
                label: i.toString(),
                value: i,
            });
        }

        return options;
    }

    private clearFieldValidation(key: SetupFormKeysType): void {
        this.formData.errorMessages[key] = undefined;
        this.formData.validation[key] = true;
    }

    private clearFormValidation(): void {
        SetupFormKeys.forEach(this.clearFieldValidation);
    }

    private validateField(key: SetupFormKeysType): boolean {
        this.formData.errorMessages[key] = this.formData.validators[key](this.formData.values[key] as never, this.formData.values);
        this.formData.validation[key] = !this.formData.errorMessages[key];
        return this.formData.validation[key];
    }

    private validateFields(...key: SetupFormKeysType[]): void {
        return key.forEach(this.validateField);
    }

    private validateForm(): boolean {
        SetupFormKeys.forEach(this.validateField);
        return this.isFormValid;
    }

    private async importTypeChanged(): Promise<void> {
        if (this.validateField('importType') && this.validateField('previousExercise')) {
            await this.fillSelectedScenarioData();
        }
    }

    private async previousExerciseChanged(): Promise<void> {
        if (!this.isImportTypeSelectable) {
            this.formData.values.importType = undefined;
        }

        if (this.isImportTypeSelectable && this.validateField('previousExercise') && this.validateField('importType')) {
            await this.fillSelectedScenarioData();
        }
    }

    private clearPreviousExercise(): void {
        this.formData.values.previousExercise = [];
        this.previousExerciseChanged();
    }

    private async fillSelectedScenarioData(): Promise<void> {
        const signal = this.getSignal('getSelectedScenario', true);

        try {
            this.isLoadingSelectedScenario = true;

            const selectedScenario = (await this.scenarioService.get(Number(this.formData.values.previousExercise[0]?.value ?? 0), signal)).result;
            this.formData.values.mtpYear = selectedScenario.mtpYear;
            this.formData.values.referenceYear = selectedScenario.referenceYear;
            this.formData.values.startYear = selectedScenario.startYear;
            this.formData.values.endYear = selectedScenario.endYear;
            this.formData.values.reportingOccasion = selectedScenario.reportingOccasion ?? '';

            this.$nextTick(() => {
                this.clearFieldValidation('reportingOccasion');
            });
        } catch {
            if (!signal?.aborted) {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('form.toastMessages.formDataFetchError.title'),
                    copy: this.$t('form.toastMessages.formDataFetchError.copy'),
                });
            }
        } finally {
            this.isLoadingSelectedScenario = false;
        }
    }

    private async createScenario(saveAsDraft: boolean): Promise<void> {
        const previousScenarioId = this.formData.values.previousExercise[0]?.value ? Number(this.formData.values.previousExercise[0].value) : undefined;

        const payload: IAddScenarioRequest = {
            name: this.formData.values.name,
            description: this.formData.values.description,
            previousScenarioId,
            persistAllData: this.formData.values.importType === ImportType.KEEP_ALL_DATA,
            mtpYear: this.formData.values.mtpYear,
            referenceYear: this.formData.values.referenceYear,
            startYear: this.formData.values.startYear,
            endYear: this.formData.values.endYear,
            saveAsDraft,
            reportingOccasion: this.showReportingOccasion ? this.formData.values.reportingOccasion : undefined,
        };

        await this.$store.dispatch('scenario/setupStep/createScenario', payload);
    }

    private async updateScenario(saveAsDraft: boolean): Promise<void> {
        if (!this.existingScenario) {
            return;
        }

        const previousScenarioId = this.formData.values.previousExercise[0]?.value ? Number(this.formData.values.previousExercise[0].value) : undefined;

        const payload: IEditScenarioRequest = {
            id: this.existingScenario.id,
            name: this.formData.values.name,
            description: this.formData.values.description,
            previousScenarioId,
            persistAllData: this.formData.values.importType === ImportType.KEEP_ALL_DATA,
            mtpYear: this.formData.values.mtpYear,
            referenceYear: this.formData.values.referenceYear,
            startYear: this.formData.values.startYear,
            endYear: this.formData.values.endYear,
            saveAsDraft,
            reportingOccasion: this.showReportingOccasion ? this.formData.values.reportingOccasion : undefined,
        };

        await this.$store.dispatch('scenario/setupStep/updateScenario', payload);
    }

    private async submitForm(saveAsDraft = false): Promise<boolean> {
        this.clearFormValidation();

        if (saveAsDraft && !this.validateField('name') || !saveAsDraft && !this.validateForm()) {
            return false;
        }

        try {
            this.isFormSubmitting = true;

            if (this.formType === SetupFormType.CREATE) {
                await this.createScenario(saveAsDraft);
            } else {
                await this.updateScenario(saveAsDraft);
            }

            this.isFormSubmitted = true;
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });

            return false;
        } finally {
            this.isFormSubmitting = false;
        }

        return true;
    }

    private async scenarioTypeaheadSearch(query: string): Promise<void> {
        this.isLoadingScenarios = true;
        await this.$store.dispatch('scenario/setupStep/fetchScenarios', { query, signal: this.getSignal('scenarioSetupSearch', true) });
        this.isLoadingScenarios = false;
    }

    private scenarioTypeaheadAborted(): void {
        this.abortSignal('scenarioSetupSearch');
        this.isLoadingScenarios = false;
    }

    private backButtonClicked(): void {
        this.pushToScenarioOverview();
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (this.isFormDisabled || await this.submitForm(true)) {
            await this.pushToScenarioOverview();
        }
    }

    private async nextButtonClicked(): Promise<void> {
        if (this.isFormDisabled || await this.submitForm()) {
            await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.UNIT);

            await this.replaceToStep(SCENARIO_FORM_STEP.SETUP, this.existingScenario?.id);
            await this.pushToStep(SCENARIO_FORM_STEP.UNIT, this.existingScenario?.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.setup-step {
    @include rem(padding, pui-spacing(l));

    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__form {
        &__radio-group {
            padding-top: 2.4rem;
        }
    }

    &__instruction-text {
        font-size: 1.2rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }

    .pui-form-grid-row {
        padding: 0 !important;
    }


    .pui-form-inline-notification--info {
        background-color: transparentize($uniper-blue, 0.8);
        border-color: $uniper-blue;

        ::v-deep svg {
            color: $uniper-blue !important;
        }
    }
}
</style>
