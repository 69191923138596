var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "secondary-kpi-tab"
  }, [_c('kpi-summary-card', {
    ref: _vm.KPI_SUMMARY_CARD_REF
  }), _c('div', {
    staticClass: "secondary-kpi-tab__actions"
  }, [_c('div', {
    staticClass: "secondary-kpi-tab__actions__buttons"
  }, [_c('secondary-kpi-scenario-import', {
    attrs: {
      "is-disabled": !!_vm.rowCount
    },
    on: {
      "import:finished": _vm.reloadAll
    }
  }), _c('pui-button', {
    attrs: {
      "icon": "add",
      "state": "primary",
      "small": true,
      "disabled": _vm.isSaving
    },
    on: {
      "click": function ($event) {
        return _vm.openSecondaryKpiLightbox();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.addNewKpi')) + " ")])], 1)]), _c('div', {
    staticClass: "secondary-kpi-tab__secondary-kpi-table"
  }, [_vm.isSaving ? _c('spinner', {
    attrs: {
      "promise": _vm.infinitePromise
    }
  }) : _c('spinner', {
    attrs: {
      "promise": _vm.filtersPromise
    }
  }, [_c('table-header', {
    attrs: {
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1)], 1), _c('secondary-kpi-lightbox', {
    attrs: {
      "secondary-kpi": _vm.secondaryKpiDetails,
      "lightbox-open": _vm.isSecondaryKpiLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleCloseSecondaryKpiLightbox
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }