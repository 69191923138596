var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['donut-card', {
      'donut-card--active': _vm.hoverable && !_vm.selected
    }, {
      'donut-card--selected': _vm.selected
    }]
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.promise,
      "size": "35px",
      "min-height": "100%",
      "is-inline-error": _vm.wide
    }
  }, [_c('div', {
    class: ['donut-card__donut-container', {
      'donut-card__donut-container--wide': _vm.wide
    }]
  }, [_c('div', {
    class: ['donut-card__donut-container__donut', {
      'donut-card__donut-container--wide__donut': _vm.wide
    }]
  }, [_c('pui-donut', {
    attrs: {
      "title": "",
      "segment-width": _vm.segmentWidth,
      "values": _vm.segments
    }
  })], 1), _c('div', {
    class: ['donut-card__donut-container__additional-info', {
      'donut-card__donut-container--wide__additional-info': _vm.wide
    }]
  }, [_c('pui-headline', {
    class: ['donut-card__donut-container__additional-info__count', {
      'donut-card__donut-container--wide__additional-info__count': _vm.wide
    }],
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.count) + " ")]), _c('span', {
    class: ['donut-card__donut-container__additional-info__title', {
      'donut-card__donut-container__additional-info__title--selected': _vm.selected
    }]
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }