import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SetupOverviewPage from '@/pages/setup/setup-overview.vue';
import EngineRunOverviewPage from '@/pages/engine-run/engine-run-overview.vue';
import EngineRunSummaryPage from '@/pages/engine-run/summary/engine-run-summary.vue';
import ResultsOverviewPage from '@/pages/results/results-overview.vue';
import DataManagementOverviewPage from '@/pages/data-management/data-management-overview.vue';
import SetupCreateScenarioPage from '@/pages/setup/scenario/setup-create-scenario.vue';
import SetupScenarioStep from '@/pages/setup/scenario/steps/setup-scenario-step.vue';
import UnitScenarioStep from '@/pages/setup/scenario/steps/unit-scenario-step.vue';
import InitialLoaderScenarioStep from '@/pages/setup/scenario/steps/initial-loader-scenario-step.vue';
import FormulaScenarioStep from '@/pages/setup/scenario/steps/formula-scenario-step.vue';
import InflationScenarioStep from '@/pages/setup/scenario/steps/inflation-scenario-step.vue';
import MtcScenarioStep from '@/pages/setup/scenario/steps/mtc-scenario-step.vue';
import PrimaryKpiScenarioStep from '@/pages/setup/scenario/steps/primary-kpi-scenario-step.vue';
import CostExclusionScenarioStep from '@/pages/setup/scenario/steps/cost-exclusion-scenario-step.vue';
import ConfirmationScreenStep from '@/pages/setup/scenario/steps/confirmation-screen-step.vue';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/setup',
    },
    {
        path: '/setup',
        name: 'SetupOverview',
        component: SetupOverviewPage,
        meta: {
            navbar: {
                title: 'setup.navigationTitle',
            },
        },
    },
    {
        path: '/setup/scenario/:id',
        component: SetupCreateScenarioPage,
        props: true,
        children: [
            {
                path: '',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
                component: InitialLoaderScenarioStep
            },
            {
                path: 'setup',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.SETUP],
                component: SetupScenarioStep,
            },
            {
                path: 'unit',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.UNIT],
                component: UnitScenarioStep,
            },
            {
                path: 'formula',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.FORMULA],
                component: FormulaScenarioStep,
            },
            {
                path: 'inflation',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INFLATION],
                component: InflationScenarioStep,
            },
            {
                path: 'mtc',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.MTC],
                component: MtcScenarioStep,
            },
            {
                path: 'primary-kpi',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.PRIMARY_KPI],
                component: PrimaryKpiScenarioStep,
            },
            {
                path: 'cost-exclusion',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.COST_EXCLUSION],
                component: CostExclusionScenarioStep,
            },
            {
                path: 'confirmation',
                name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.CONFIRMATION],
                component: ConfirmationScreenStep,
            },
        ]
    },
    {
        path: '/engine-run',
        name: 'EngineRunOverview',
        component: EngineRunOverviewPage,
        meta: {
            navbar: {
                title: 'engineRun.navigationTitle',
            },
        },
    },
    {
        path: '/engine-run/summary/:id',
        name: 'EngineRunSummary',
        props: true,
        component: EngineRunSummaryPage,
    },
    {
        path: '/results',
        name: 'ResultsOverview',
        component: ResultsOverviewPage,
        meta: {
            navbar: {
                title: 'results.navigationTitle',
            },
        },
    },
    {
        path: '/data-management',
        name: 'DataManagementOverview',
        component: DataManagementOverviewPage,
        meta: {
            navbar: {
                title: 'dataManagement.navigationTitle',
            },
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
