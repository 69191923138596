var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dm-table"
  }, [_c('div', {
    staticClass: "dm-table__table"
  }, [_c('div', {
    staticClass: "dm-table__table__extra-header"
  }, [_c('pui-headline', {
    staticClass: "dm-table__table__extra-header__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.selectedMessage === _vm.BM_LOG_LEVEL.ERRORS ? _c('pui-button', {
    staticClass: "dm-table__table__extra-header__button",
    attrs: {
      "icon": "check",
      "state": "primary",
      "small": "",
      "disabled": _vm.isMarkingDisabled
    },
    on: {
      "click": _vm.markAsAcceptableErrors
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryTable.actions.markAsacceptableErrors')) + " ")]) : _vm._e(), _c('pui-toggle', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showIsAcceptableColumn,
      expression: "showIsAcceptableColumn"
    }],
    on: {
      "change": _vm.handleShowOnlyAcceptableErrors
    },
    scopedSlots: _vm._u([{
      key: "puiToggleRight",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryTable.actions.showOnlyAcceptableErrors')) + " ")];
      },
      proxy: true
    }])
  })], 1), _c('table-header', {
    attrs: {
      "search-term": _vm.tableExtraFilters.searchTerm,
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _vm.showLoadingOverlay ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise,
      "title": _vm.$t('engineRun.summary.summaryTable.loader.title'),
      "message": _vm.$t('engineRun.summary.summaryTable.loader.message')
    }
  }) : _vm._e(), _c('ag-grid-vue', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showLoadingOverlay,
      expression: "!showLoadingOverlay"
    }],
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }