var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip-wrapper"
  }, [_c('pui-link', {
    attrs: {
      "title": _vm.title,
      "variant": "primary"
    },
    on: {
      "click": _vm.sendToLink
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }