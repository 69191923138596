var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('kpi-analysis-lightbox', {
    ref: _vm.REF_CONSTANTS.KPI_ANALYSIS_LIGHTBOX
  }), _c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onActiveRouteChanged
    }
  }), _c('engine-run-summary-card', {
    attrs: {
      "promise": _vm.summaryPromise
    }
  }), _c('engine-run-summary-message-cards', {
    attrs: {
      "promise": _vm.summaryPromise,
      "is-card-hoverable": _vm.isCardHoverable
    },
    on: {
      "on-selected-message-changed": _vm.selectedMessageChange
    }
  }), _c('engine-run-summary-table', {
    attrs: {
      "selected-message": _vm.selectedMessage
    },
    on: {
      "reload-summary": _vm.loadSummary,
      "open:kpi-analysis-lightbox": _vm.openKpiAnalysisLightbox
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }