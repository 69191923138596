<template>
    <div class="kpi-analysis-unit-selectors">
        <pui-form-group
            :show-required-label="true"
            :label="$t('results.kpiAnalysis.selector.selectYear')"
            :has-label-padding="false"
            label-for="years"
        >
            <pui-form-select
                :value="selectedYear"
                :options="yearsOptions"
                :label="$t('results.kpiAnalysis.selector.selectYear')"
                :search-input-placeholder="$t('results.kpiAnalysis.selector.selectPlaceholder')"
                search-input-id="years"
                @change="selectedYearChanged"
            />
        </pui-form-group>
        <pui-form-group
            :show-required-label="true"
            :label="$t('results.kpiAnalysis.selector.selectUnit')"
            :has-label-padding="false"
            label-for="units"
        >
            <pui-form-select
                :value="selectedUnit"
                :options="unitsOptions"
                :label="$t('results.kpiAnalysis.selector.selectUnit')"
                :search-input-placeholder="$t('results.kpiAnalysis.selector.selectPlaceholder')"
                search-input-id="units"
                @change="selectedUnitChanged"
            />
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetFiltersResponse } from '@/service-proxies/service-proxies.g';
import { PuiSelectOptions } from '@/models/pebble-ui';

@Component({})
export default class KpiAnalysisUnitSelectors extends Vue {
    @Prop({ required: true })
    private scenarioFilters!: GetFiltersResponse | null;

    @Prop({ required: true })
    private startYear!: number | null;

    @Prop({ required: true })
    private endYear!: number | null;

    private selectedYear: number | null = null;
    private selectedUnit: number | null = null;

    private get unitsNameMap(): { [key: string]: string } {
        const unitNameMap: { [key: string]: string } = {};

        this.scenarioFilters?.countries?.flatMap(e => e.plants ?? [])
            .flatMap(e => e.units ?? [])
            .forEach(unit => {
                unitNameMap[unit.unitSid.toString()] = unit.unitName ?? unit.unitSid.toString();
            });

        return unitNameMap;
    }

    private get yearsOptions(): PuiSelectOptions<number> {
        if (!this.startYear || !this.endYear) {
            return [];
        }

        const options: PuiSelectOptions<number> = [];

        for (let i = this.startYear; i <= this.endYear; i++) {
            options.push({
                label: i.toString(),
                value: i,
            });
        }

        return options;
    }

    private get unitsOptions(): PuiSelectOptions<number> {
        return Object.keys(this.unitsNameMap).map(e => ({
            label: this.unitsNameMap[e],
            value: Number(e)
        }));
    }

    private selectedYearChanged(newSelectedYear: number | null): void {
        this.selectedYear = newSelectedYear;
        this.$emit('change:year', newSelectedYear);
    }

    private selectedUnitChanged(newSelectedUnit: number | null): void {
        this.selectedUnit = newSelectedUnit;
        this.$emit('change:unit', newSelectedUnit);
    }
}
</script>

<style scoped lang="scss">
.kpi-analysis-unit-selectors {
    @include rem(gap, pui-spacing(m));

    display: flex;

    & > * {
        min-width: 200px;
    }
}
</style>
