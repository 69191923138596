<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :default-header-label="headerLabel"
        :close-on-e-s-c="false"
        :default-footer-confirm-label="$t('setup.scenario.ceForm.tables.flagged.buttons.save')"
        :default-footer-cancel-label="$t('setup.scenario.ceForm.tables.flagged.buttons.cancel')"
        :default-footer-confirm-disabled="isFormSubmitting"
        :default-footer-cancel-disabled="isFormSubmitting"
        :on-confirm-callback="handleLightboxConfirm"
        @puilightbox:close="handleFormClose(false)"
    >
        <flagged-costs-form
            :ref="REF_CONSTANTS.FORM"
            :budget-request-id="budgetRequestId"
            @change:global-id="handleChangeGlobalId"
            @close:form="handleFormClose"
        />
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import FlaggedCostsForm
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form.vue';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
    FORM: 'form',
} as const;

@Component({
    components: {
        FlaggedCostsForm,
    }
})
export default class FlaggedCostsLightbox extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private budgetRequestId = 0;
    private globalId: string | null = null;
    private isFormSubmitting = false;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox,
        [REF_CONSTANTS.FORM]: FlaggedCostsForm,
    };

    private get headerLabel(): string {
        return this.globalId ?? this.$t('setup.scenario.ceForm.tables.flagged.form.initialTitle');
    }

    public open(budgetRequestId: number): void {
        this.budgetRequestId = budgetRequestId;
        this.$refs.lightbox.open();
    }

    public close(): void {
        this.$refs.lightbox.close();
    }

    private handleChangeGlobalId(globalId: string): void {
        this.globalId = globalId;
    }

    private async handleLightboxConfirm(): Promise<void> {
        if (this.isFormSubmitting) {
            return;
        }

        this.isFormSubmitting = true;
        const hasSaved = await this.$refs.form.submitForm();
        this.isFormSubmitting = false;

        if (hasSaved) {
            this.handleFormClose(true);
        }
    }

    private handleFormClose(hasSaved: boolean): void {
        if (hasSaved) {
            this.$emit('saved:form');
        }

        this.close();
    }
}
</script>
