<template>
    <v-chart
        :ref="REF_CONSTANTS.CHART"
        :option="option"
        class="tr-chart"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ECharts from 'vue-echarts';
import { EChartsOption } from 'echarts';
import { GetFiltersResponse, IKpiReportUnitDto } from '@/service-proxies/service-proxies.g';

export type NormalizedSiteViewData = {
    machineSid: number;
    years?: {
        year: number;
        value: number;
    }[];
};

const REF_CONSTANTS = {
    CHART: 'chart',
} as const;

@Component({
    components: {
        VChart: ECharts,
    }
})
export default class SiteViewTrendReportsChart extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    @Prop({ default: () => [] })
    private chartData!: IKpiReportUnitDto[];

    @Prop({ required: true })
    private scenarioFilters!: GetFiltersResponse;

    @Prop({ default: () => '' })
    private title!: string;

    $refs!: {
        [REF_CONSTANTS.CHART]?: Vue;
    };

    private get unitNameMap(): { [key: string]: string } {
        const unitNameMap: { [key: string]: string } = {};

        this.scenarioFilters?.countries?.flatMap(e => e.plants ?? [])
            .flatMap(e => e.units ?? [])
            .forEach(unit => {
                unitNameMap[unit.unitSid.toString()] = unit.unitName ?? unit.unitSid.toString();
            });

        return unitNameMap;    }

    private get years(): number[] {
        const years = new Set<number>();

        this.chartData.flatMap(e => e.years ?? [])
            .forEach(e => {
                years.add(e.year);
            });

        const yearsArray = [...years];
        yearsArray.sort((a, b) => a - b);

        return yearsArray;
    }

    private get normalizedSiteViewData(): NormalizedSiteViewData[] {
        const data = [...this.chartData];

        data.sort((a, b) => a.machineSid - b.machineSid);
        data.map(e => e.years).forEach(year => year?.sort((a, b) => a.year - b.year));

        return data;
    }

    private get option(): EChartsOption {
        return {
            title: {
                text: this.title,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.years.map(e => e.toString()),
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: (value: number): string => {
                            return new Intl.NumberFormat(undefined, { notation: 'compact' }).format(value);
                        }
                    }
                }
            ],
            series: this.normalizedSiteViewData.map(e => ({
                name: this.unitNameMap[e.machineSid.toString()] ?? e.machineSid.toString(),
                type: 'bar',
                barGap: 1,
                data: e.years?.map(yearEntry => yearEntry.value) ?? [],
                emphasis: {
                    focus: 'series',
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }))
        };
    }
}
</script>

<style scoped lang="scss">
.tr-chart {
    height: 400px;
}
</style>
