var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['ka-kpi-selectors', {
      'ka-kpi-selectors--full-width': _vm.isFullWidth
    }]
  }, [_c('pui-form-group', {
    staticClass: "ka-kpi-selectors__kpi-type",
    attrs: {
      "label": _vm.$t('results.siteView.kpiReport.inputs.selectKpiType'),
      "cta-label": _vm.$t('form.clear'),
      "has-label-padding": false,
      "has-cta": true,
      "cta-callback": _vm.clearKpiType,
      "label-for": "kpiType"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.kpiTypeOptions,
      "is-disabled": _vm.isDisabled,
      "label": _vm.$t('results.siteView.kpiReport.inputs.selectKpiType'),
      "search-input-placeholder": _vm.$t('results.siteView.selector.selectPlaceholder'),
      "search-input-id": "kpiType"
    },
    model: {
      value: _vm.selectedKpiType,
      callback: function ($$v) {
        _vm.selectedKpiType = $$v;
      },
      expression: "selectedKpiType"
    }
  })], 1), _c('pui-form-group', {
    staticClass: "ka-kpi-selectors__kpi-id",
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('results.siteView.kpiReport.inputs.selectKpiId'),
      "has-label-padding": false,
      "label-for": "kpiId"
    }
  }, [_c('benchmarking-type-ahead', {
    ref: _vm.REF_CONSTANTS.TYPEAHEAD,
    attrs: {
      "is-disabled": _vm.isDisabled,
      "placeholder": _vm.$t('results.siteView.selector.selectPlaceholder'),
      "request-promise-generator": _vm.kpisRequestPromiseGenerator
    },
    model: {
      value: _vm.selectedKpiId,
      callback: function ($$v) {
        _vm.selectedKpiId = $$v;
      },
      expression: "selectedKpiId"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }