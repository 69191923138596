import { IFormCostModification, OperationType } from '@/models/steps/cost-correction';
import Decimal from 'decimal.js';

export const computeExclusionAmount = (initialValue: Decimal, exclusionPercentage: number): Decimal => {
    return initialValue.mul(exclusionPercentage).div(100);
}

export const computeAfterExclusionTotals = (mtcRunningTotal: Record<number, Decimal>, exclusions: IFormCostModification[]): Record<number, Decimal> => {
    const runningTotal = { ...mtcRunningTotal };
    const validatedExclusions = exclusions.filter(e => e.costModificationType === OperationType.EXCLUSION && mtcRunningTotal[e.fromYear] !== undefined);

    validatedExclusions.forEach(e => {
        runningTotal[e.fromYear] = runningTotal[e.fromYear].sub(computeExclusionAmount(runningTotal[e.fromYear], e.percentage));
    });

    return runningTotal;
}

export const computeCorrectionAmount = (mtcRunningTotal: Record<number, Decimal>, correction: IFormCostModification): Decimal => {
    return mtcRunningTotal[correction.fromYear].mul(correction.percentage).div(100);
}

export const computeAfterCorrectionTotals = (mtcRunningTotal: Record<number, Decimal>, corrections: IFormCostModification[], afterAmountComputation?: (correction: IFormCostModification, amount: Decimal) => void): Record<number, Decimal> => {
    const runningTotal = { ...mtcRunningTotal };
    const validatedCorrections = corrections.filter(e => e.costModificationType === OperationType.CORRECTION && e.toYear && mtcRunningTotal[e.fromYear] !== undefined && mtcRunningTotal[e.toYear] !== undefined);

    validatedCorrections.forEach(correction => {
        if (!correction.toYear) {
            return;
        }

        const correctionAmount = computeCorrectionAmount(mtcRunningTotal, correction);
        afterAmountComputation?.(correction, correctionAmount);

        runningTotal[correction.fromYear] = runningTotal[correction.fromYear].sub(correctionAmount);
        runningTotal[correction.toYear] = runningTotal[correction.toYear].add(correctionAmount);
    });

    return runningTotal;
}
