<template>
    <div class="loading-bar">
        <div class="loading-bar__bar" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LoadingBar extends Vue {}
</script>

<style scoped lang="scss">
.loading-bar {
    @keyframes indeterminate-progress {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(100%);
        }
    }

    position: relative;
    border: 1px solid $warm-grey-25;
    border-radius: 1rem;
    overflow: hidden;

    &__bar {
        position: absolute;

        height: 100%;
        width: 100%;
        background-color: $uniper-blue;

        animation: indeterminate-progress 1.6s ease-in-out infinite;
    }
}
</style>
