import { render, staticRenderFns } from "./mcy-table-checkbox-cell.vue?vue&type=template&id=63227300&scoped=true"
import script from "./mcy-table-checkbox-cell.vue?vue&type=script&lang=ts"
export * from "./mcy-table-checkbox-cell.vue?vue&type=script&lang=ts"
import style0 from "./mcy-table-checkbox-cell.vue?vue&type=style&index=0&id=63227300&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63227300",
  null
  
)

export default component.exports