<template>
    <div class="sc-tabs">
        <pui-loader-error
            v-if="hasNoScenario"
            :title="$t('results.scenarioCompare.errors.twoScenarios.title')"
            :message="$t('results.scenarioCompare.errors.twoScenarios.message')"
            icon="error-empty-data"
            class="sc-tabs__loader-error"
        />
        <template v-else>
            <pui-tabs
                :selected="selectedTabIndex"
                class="sc-tabs__tabs"
                @changed="onTabChanged"
            >
                <pui-tab :title="$t('results.scenarioCompare.tabs.primaryKpi')">
                    <div class="sc-tabs__tabs__content">
                        <scenario-compare-table :is-primary-kpi-data="true" />
                    </div>
                </pui-tab>
                <pui-tab :title="$t('results.scenarioCompare.tabs.secondaryKpi')">
                    <div class="sc-tabs__tabs__content">
                        <scenario-compare-table :is-primary-kpi-data="false" />
                    </div>
                </pui-tab>
            </pui-tabs>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ScenarioCompareTable from '@/components/results/scenario-compare/sections/scenario-compare-table.vue';

enum SCENARIO_COMPARE_TABS {
    PRIMARY_KPI = 0,
    SECONDARY_KPI = 1,
}

@Component({
    components: {ScenarioCompareTable}
})
export default class ScenarioCompareTabs extends Vue {
    private selectedTabIndex = SCENARIO_COMPARE_TABS.PRIMARY_KPI;

    private get hasNoScenario(): boolean {
        return this.$store.getters['results/scenarioCompare/areScenariosNotSelected'];
    }

    private onTabChanged(newTabIndex: number): void {
        this.selectedTabIndex = newTabIndex;
    }
}
</script>

<style scoped lang="scss">
.sc-tabs {
    &__loader-error {
        @include pui-box();
    }

    &__tabs {
        @include rem(margin-bottom, pui-spacing(s));

        &__content {
            @include rem(margin-top, pui-spacing(s));
        }
    }
}
</style>
