<template>
    <div class="flagged-table">
        <table-header
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
            @search-triggered="onTriggerSearch"
            @clear-search-term="onClearSearchTerm"
        />
        <ag-grid-vue
            :grid-options="gridOptions"
            class="ag-theme-alpine"
        />
        <flagged-costs-lightbox
            :ref="REF_CONSTANTS.FLAGGED_COSTS_LIGHTBOX"
            @saved:form="refreshData"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
    CellClassParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideGetRowsParams,
    SetFilterValuesFuncParams, ValueFormatterParams
} from 'ag-grid-community';
import { FetchPaginationPartial } from '@/models/steps/common';
import {
    FetchCostCorrectionFilters,
    FetchCostCorrectionValuesPayload,
    OPERATION_TYPE_PRINT_KEY_MAP, OperationType
} from '@/models/steps/cost-correction';
import { IBudgetFlaggedDto, IBudgetRequestGetDto } from '@/service-proxies/service-proxies.g';
import { IColumnLimit } from 'ag-grid-community/dist/lib/gridApi';
import FlaggedCostsLightbox from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-lightbox.vue';
import FlaggedCostsActionsCell
    from '@/components/cost-exclusion-step/flagged-costs-table/flagged-costs-actions-cell.vue';
import FlaggedCostsNoRowsOverlay
    from '@/components/cost-exclusion-step/flagged-costs-table/flagged-costs-no-rows-overlay.vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';
import { formatNumberForTableDisplay } from '@/utils/formatters';

const REF_CONSTANTS = {
    FLAGGED_COSTS_LIGHTBOX: 'flaggedCostsLightbox',
} as const;

type FlaggedCostRow = {
    id?: number;
    globalId: string;
    name: string;
    countryName: string;
    plantName: string;
    unitName: string;
    technologyName: string;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    correctionType?: number;
};

@Component({
    components: {
        AgGridVue,
        FlaggedCostsLightbox,
        FlaggedCostsActionsCell,
        FlaggedCostsNoRowsOverlay,
        TableHeader,
    }
})
export default class FlaggedCostsTable extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly COLUMN_KEYS = {
        GROUP_KEY: 'ag-Grid-AutoColumn',
        ID: 'id',
        GLOBAL_ID: 'globalId',
        NAME: 'name',
        COUNTRY_NAME: 'countryName',
        PLANT_NAME: 'plantName',
        UNIT_NAME: 'unitName',
        TECHNOLOGY_NAME: 'technologyName',
        FORECAST: 'forecast',
        NEW1: 'new1',
        NEW2: 'new2',
        NEW3: 'new3',
        CORRECTION_TYPE: 'correctionType',
    } as const;

    private gridApi: GridApi<FlaggedCostRow> | null = null;

    private tableHeaderFilters = {
        pageSize: 20,
        searchTerm: '',
    };

    private columnLimits: IColumnLimit[] = [
        {
            key: this.COLUMN_KEYS.GROUP_KEY,
            minWidth: 200,
        },
        {
            key: this.COLUMN_KEYS.NAME,
            minWidth: 500,
        },
    ];

    $refs!: {
        [REF_CONSTANTS.FLAGGED_COSTS_LIGHTBOX]: FlaggedCostsLightbox,
    };

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get scenarioMtpYear(): number {
        return this.$store.getters['scenario/getScenario']?.mtpYear ?? 0;
    }

    private get budgetRequestsTotalCount(): number {
        return this.$store.getters['scenario/costCorrectionStep/getBudgetRequestsTotalCount'];
    }

    private get modifications(): Record<string, IBudgetFlaggedDto[]> {
        return this.$store.getters['scenario/costCorrectionStep/getModifications'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private gridOptions: GridOptions<FlaggedCostRow> = {
        rowModelType: 'serverSide',
        domLayout: 'autoHeight',
        serverSideDatasource: {
            getRows: (params) => {
                if (params.request.groupKeys.length === 0) {
                    this.fetchTopLevelRowsFromParams(params);
                } else {
                    this.fetchSubLevelRowsFromParams(params);
                }
            },
        },
        columnDefs: [
            {
                field: this.COLUMN_KEYS.GLOBAL_ID,
                rowGroup: true,
                hide: true,
            },
            {
                field: this.COLUMN_KEYS.NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.name'),
                pinned: 'left',
                resizable: true,
            },
            {
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.countryName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.countries.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.countries.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.PLANT_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.plantName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.plants.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.plants.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.UNIT_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.unitName'),
            },
            {
                field: this.COLUMN_KEYS.TECHNOLOGY_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.technologyName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.technologies.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const technologySid = Number(params.value);
                        return this.scenarioFilters.technologies.find(e => e.sid === technologySid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.FORECAST,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.forecast', { year: this.scenarioMtpYear }),
                cellClassRules: {
                    'is-negative-cell': this.isValueNegative,
                },
                valueFormatter: (params): string => formatNumberForTableDisplay(params.value),
            },
            {
                field: this.COLUMN_KEYS.NEW1,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.new1', { year: this.scenarioMtpYear + 1 }),
                cellClassRules: {
                    'is-negative-cell': this.isValueNegative,
                },
                valueFormatter: (params): string => formatNumberForTableDisplay(params.value),
            },
            {
                field: this.COLUMN_KEYS.NEW2,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.new2', { year: this.scenarioMtpYear + 2 }),
                cellClassRules: {
                    'is-negative-cell': this.isValueNegative,
                },
                valueFormatter: (params): string => formatNumberForTableDisplay(params.value),
            },
            {
                field: this.COLUMN_KEYS.NEW3,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.new3', { year: this.scenarioMtpYear + 3 }),
                cellClassRules: {
                    'is-negative-cell': this.isValueNegative,
                },
                valueFormatter: (params): string => formatNumberForTableDisplay(params.value),
            },
            {
                field: this.COLUMN_KEYS.CORRECTION_TYPE,
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.correctionType'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: [OperationType.CORRECTION.toString(), OperationType.EXCLUSION.toString()],
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const operationType = Number(params.value) as  1 | 2;
                        return this.$t(OPERATION_TYPE_PRINT_KEY_MAP[operationType]);
                    },
                },
                valueFormatter: (params): string => {
                    return OPERATION_TYPE_PRINT_KEY_MAP[params.value as 1 | 2] ? this.$t(OPERATION_TYPE_PRINT_KEY_MAP[params.value as 1 | 2]) : '-';
                }
            },
            {
                field: 'actions',
                headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.actions'),
                cellRenderer: 'FlaggedCostsActionsCell',
                cellStyle: {
                    padding: '0 8px',
                },
                pinned: 'right',
            }
        ],
        defaultColDef: {
            menuTabs: [],
            cellClassRules: {
                'is-subgroup-cell': this.isCellExpanded,
            },
        },
        autoGroupColumnDef: {
            headerName: this.$t('setup.scenario.ceForm.tables.flagged.headers.globalId'),
            pinned: 'left',
            resizable: true
        },
        serverSideInfiniteScroll: true,
        serverSideFilterAllLevels: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        noRowsOverlayComponent: 'FlaggedCostsNoRowsOverlay',
        context: {
            openLightbox: this.openLightbox,
        },
        onGridSizeChanged: (event) => {
            event.api.sizeColumnsToFit({ defaultMinWidth: 200, columnLimits: this.columnLimits });
        },
        onGridReady: this.onGridReady,
    };

    private openLightbox(budgetRequestId: number): void {
        this.$refs.flaggedCostsLightbox.open(budgetRequestId);
    }

    private onGridReady(event: GridReadyEvent<FlaggedCostRow>): void {
        event.api.sizeColumnsToFit({ defaultMinWidth: 200, columnLimits: this.columnLimits });

        this.gridApi = event.api;
    }

    public refreshData(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    public isValueNegative(params: CellClassParams<FlaggedCostRow>): boolean {
        return Number(params.value) < 0;
    }

    public isCellExpanded(params: CellClassParams<FlaggedCostRow>): boolean {
        return params.node.level !== 0;
    }

    private getPaginationForServerRequestParams(params: IServerSideGetRowsParams): FetchPaginationPartial {
        return {
            pageNumber: Math.floor((params.request.endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize),
            pageSize: this.tableHeaderFilters.pageSize,
        };
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): FetchCostCorrectionFilters {
        return {
            plantSids: params.request.filterModel?.[this.COLUMN_KEYS.PLANT_NAME]?.values?.map((e: string) => Number(e)),
            technologySids: params.request.filterModel?.[this.COLUMN_KEYS.TECHNOLOGY_NAME]?.values?.map((e: string) => Number(e)),
            countrySids: params.request.filterModel?.[this.COLUMN_KEYS.COUNTRY_NAME]?.values?.map((e: string) => Number(e)),
            operationTypes: params.request.filterModel?.[this.COLUMN_KEYS.CORRECTION_TYPE]?.values?.map((e: string) => Number(e)),
            searchTerm: this.tableHeaderFilters.searchTerm,
        };
    }

    private fetchTopLevelRowsFromParams(params: IServerSideGetRowsParams<FlaggedCostRow>): void {
        const pagination = this.getPaginationForServerRequestParams(params);
        const filters = this.getSelectedFiltersForServerRequestParams(params);

        const payload: FetchCostCorrectionValuesPayload = {
            scenarioId: this.scenarioId,
            ...pagination,
            ...filters,
        };

        params.api.hideOverlay();

        this.$store.dispatch('scenario/costCorrectionStep/fetchBudgetRequests', payload)
            .then((budgets: IBudgetRequestGetDto[]) => {
                const rowData: FlaggedCostRow[] = budgets.map(budget => ({
                    id: budget.id,
                    globalId: budget.globalId ?? '',
                    name: budget.projectName ?? '',
                    countryName: budget.countryName ?? '',
                    plantName: budget.plantName ?? '',
                    unitName: budget.unitName ?? '',
                    technologyName: budget.technologyName ?? '',
                    forecast: budget.forecast,
                    new1: budget.new1,
                    new2: budget.new2,
                    new3: budget.new3,
                    correctionType: budget.correctionType,
                }));

                if (this.budgetRequestsTotalCount === 0) {
                    params.api.showNoRowsOverlay();
                }

                params.success({
                    rowData,
                    rowCount: this.budgetRequestsTotalCount,
                })
            })
            .catch(() => params.fail());
    }

    private fetchSubLevelRowsFromParams(params: IServerSideGetRowsParams<FlaggedCostRow>): void {
        const globalId = params.request.groupKeys[0];
        const modifications = this.modifications[globalId];

        if (!modifications) {
            params.fail();
            return;
        }

        const rowData: FlaggedCostRow[] = modifications.map(budget => ({
            globalId: globalId,
            name: budget.name ?? '',
            countryName: budget.countryName ?? '',
            plantName: budget.plantName ?? '',
            unitName: budget.unitName ?? '',
            technologyName: budget.technologyName ?? '',
            forecast: budget.forecast,
            new1: budget.new1,
            new2: budget.new2,
            new3: budget.new3,
            correctionType: budget.correctionType,
        }));

        params.success({
            rowData,
            rowCount: modifications.length,
        });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableHeaderFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableHeaderFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }
}
</script>

<style scoped lang="scss">
.flagged-table {
    width: 100%;
    @include pui-box();
    @include rem(margin-top, pui-spacing(l));

    ::v-deep .is-negative-cell {
        color: red;
    }

    ::v-deep .is-subgroup-cell {
        background-color: transparentize($dark-grey, 0.9);
    }
}
</style>
