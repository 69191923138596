<template>
    <div class="fc-form-overview">
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.before') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="beforeValues" />
        <div class="fc-form-overview__separator" />
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.afterExclusion') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="afterExclusionValues" />
        <div class="fc-form-overview__separator" />
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.afterCorrection') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="afterCorrectionValues" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IGetScenarioResponse, ViewResponse } from '@/service-proxies/service-proxies.g';
import { IFormCostModification, OperationType } from '@/models/steps/cost-correction';
import {
    computeAfterCorrectionTotals,
    computeAfterExclusionTotals
} from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-computations';
import FlaggedCostsFormOverviewYearDisplay
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-overview-year-display.vue';
import Decimal from 'decimal.js';

@Component({
    components: {
        FlaggedCostsFormOverviewYearDisplay,
    }
})
export default class FlaggedCostsFormOverview extends Vue {
    @Prop({ required: true })
    private budgetRequest!: ViewResponse;

    @Prop({ required: true, default: () => [] })
    private costModifications!: IFormCostModification[];

    private get scenario(): IGetScenarioResponse {
        return this.$store.getters['scenario/getScenario'];
    }

    private get mtpYear(): number {
        return this.scenario.mtpYear ?? 0;
    }

    private get yearBudgetKeyMap(): Record<number, 'forecast' | 'new1' | 'new2' | 'new3'> {
        return {
            [this.mtpYear]: 'forecast',
            [this.mtpYear + 1]: 'new1',
            [this.mtpYear + 2]: 'new2',
            [this.mtpYear + 3]: 'new3'
        };
    }

    private get startYear(): number {
        if (!this.scenario.startYear) {
            return 0;
        }

        return this.mtpYear < this.scenario.startYear ? this.mtpYear : this.scenario.startYear;
    }

    private get endYear(): number {
        if (!this.scenario.endYear) {
            return 0;
        }

        const endMtpYear = this.mtpYear + 3;

        return this.scenario.endYear < endMtpYear ? endMtpYear : this.scenario.endYear;
    }

    private get years(): number[] {
        const years: number[] = [];

        for (let i = this.startYear; i <= this.endYear; i++) {
            years.push(i);
        }

        return years;
    }

    private get beforeValues(): Record<number, Decimal> {
        const values: Record<number, Decimal> = {};

        this.years.forEach(year => {
            values[year] = new Decimal(this.yearBudgetKeyMap[year] ? this.budgetRequest[this.yearBudgetKeyMap[year]] : 0);
        });

        return values;
    }

    private get afterExclusionValues(): Record<number, Decimal> {
        return computeAfterExclusionTotals(this.beforeValues, this.costModifications.filter(e => e.costModificationType === OperationType.EXCLUSION));
    }

    private get afterCorrectionValues(): Record<number, Decimal> {
        return computeAfterCorrectionTotals(this.afterExclusionValues, this.costModifications.filter(e => e.costModificationType === OperationType.CORRECTION));
    }
}
</script>

<style scoped lang="scss">
.fc-form-overview {
    @include pui-box();
    @include rem(padding, pui-spacing(s));

    &__row {
        @include rem(margin-top, pui-spacing(s));
        @include rem(gap, pui-spacing(s));

        display: flex;

        &__value {
            &__year {
                color: $grey-light;
            }

            &--empty {
                color: $grey-light;
            }

            &--positive {
                color: $black;
            }

            &--negative {
                color: $red-darker;
            }
        }

        &__spacer {
            flex-grow: 1;
            max-width: 7.5rem;
        }
    }

    &__separator {
        @include rem(margin-top, pui-spacing(s));
        @include rem(margin-bottom, pui-spacing(s));

        height: 1px;
        background-color: $grey-light;
    }
}
</style>
