<template>
    <div class="benchmarking-table">
        <table-header
            :page-size="tableExtraFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
            @search-triggered="onTriggerSearch"
            @clear-search-term="onClearSearchTerm"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, ITooltipParams } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { IScenarioList, ListSortDirection, ScenarioServiceProxy, SortableScenarioField } from '@/service-proxies/service-proxies.g';
import { formatMtpYear, getFormattedCreatedBy, getFormattedTime } from '@/utils/formatters';
import LinkCell from '@/components/benchmarking-table/cell-types/link-cell.vue';
import ScenarioStatusCell from '@/components/benchmarking-table/cell-types/scenario-status-cell.vue';
import ScenarioActionsCell from '@/components/setup-overview/scenario-actions-cell.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';
import { LinkCellParams } from '@/models/interfaces';
import { SCENARIO_STATUS } from '@/config/status';

@Component({
    components: {
        AgGridVue,
        LinkCell,
        ScenarioActionsCell,
        ScenarioStatusCell,
        SimpleTooltip,
        TableHeader,
    }
})
export default class ScenarioTableComponent extends Vue {
    private scenarioService = new ScenarioServiceProxy();
    private gridApi: GridApi<IScenarioList> | null = null;
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    /**
     * This prop is required to know the selected status.
     */
    @Prop({ default: '0' })
    private selectedStatus!: string;

    private gridOptions: GridOptions<IScenarioList> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const sortField = this.sortField(params.request.sortModel[0]?.colId)
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort)
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;

                this.scenarioService.list2(this.statusFilter, undefined, sortField, sortDirection, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        params.success({ rowData: response.result.items ?? [], rowCount: response.result.count });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.name'),
                field: 'name',
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams<IScenarioList>): LinkCellParams => {
                    return {
                        title: params.data?.name,
                        id: params.data?.id,
                        routerName: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
                    }
                },
                tooltipField: 'name',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<IScenarioList>): { content?: string } => ({
                    content: params.data?.description
                }),
                pinned: 'left',
                resizable: true,
                minWidth: 250,
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.referenceYear'),
                field: 'referenceYear',
                minWidth: 150,
                valueFormatter: (params): string => params.value,
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.mtpYear'),
                field: 'mtpYear',
                minWidth: 200,
                valueFormatter: (params): string => {
                    if (params.value === 0) {
                        return this.$t('setup.scenario.setupStepForm.noMtpYearOption');
                    }

                    return params.value ? formatMtpYear(params.value) : '-';
                }
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.status'),
                field: 'status',
                cellRenderer: 'ScenarioStatusCell',
                tooltipField: 'status',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<IScenarioList>): { show: boolean, content: string, additionalContent: string } => ({
                    show: params.data?.status?.toString() === SCENARIO_STATUS.IN_REVIEW,
                    content: this.$t('setup.overview.scenarioTable.tooltip.totalItemsFlagged', { count: params.data?.flagged }),
                    additionalContent: this.$t('setup.overview.scenarioTable.tooltip.reviewed', { count: params.data?.reviewed }),
                }),
                minWidth: 300,
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.createdBy'),
                field: 'createdByKid',
                valueFormatter: (params): string => params.value ? getFormattedCreatedBy(params.value, params.data?.createdByName) : '-',
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.createdAt'),
                field: 'createdAt',
                sort: 'desc',
                valueFormatter: (params): string => params.value ? getFormattedTime(params.value) : '-',
                valueGetter: (params): Date | undefined => params.data?.updatedAt ?? params.data?.createdAt,
            },
            {
                headerName: this.$t('setup.overview.scenarioTable.headers.actions'),
                field: 'actions',
                cellRenderer: 'ScenarioActionsCell',
                pinned: 'right',
                sortable: false,
                filter: false,
                cellStyle: {
                    padding: '0 8px',
                }
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: true,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
            maxWidth: 600,
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    @Watch('selectedStatus')
    onSelectedStatusChanged(): void {
        this.gridApi?.onFilterChanged();
    }

    private onGridReady(params: AgGridCommon<IScenarioList>): void {
        this.gridApi = params.api;
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private get statusFilter(): number | undefined {
        const status = Number(this.selectedStatus);
        if(this.selectedStatus && status !== 0) {
            return status;
        }

        return undefined;
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if(!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortableScenarioField | undefined {
        if(!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortableScenarioField> = {
            'mtpYear': SortableScenarioField.MTP_YEAR,
            'referenceYear': SortableScenarioField.REFERENCE_YEAR,
            'name': SortableScenarioField.NAME,
            'status': SortableScenarioField.STATUS,
            'createdByKid': SortableScenarioField.CREATED_BY_KID,
            'createdByName': SortableScenarioField.CREATED_BY_NAME,
            'createdAt': SortableScenarioField.CREATED_AT,
            'updatedAt': SortableScenarioField.UPDATED_AT,
        };

        return fieldKey[colId];
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }
}
</script>

<style lang="scss">
.benchmarking-table {
    width: 100%;
    background-color: $white;
    @include rem(margin-top, pui-spacing(m));
    @include rem(margin-bottom, pui-spacing(m));
    @include pui-box();
}
</style>
