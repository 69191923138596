<template>
    <div class="sv-units-selector">
        <pui-filter
            v-if="filters"
            :use-router="false"
            :config="filterConfiguration"
            vuex-namespace="ns1"
            @changed:applied-values="filtersChanged"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PuiFilterAppliedValues, PuiFilterConfig, PuiFilterOption } from '@/models/pebble-ui';
import { GetFiltersResponse } from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';

@Component({})
export default class SiteViewUnitsSelector extends mixins(ManagedAbortControllers) {
    @Prop({ required: true })
    private filters!: GetFiltersResponse | null;

    private filterConfiguration: PuiFilterConfig = { filters: [] };
    private isReadyForSelect = false;
    private selectedCountries: number[] = [];
    private selectedPlants: number[] = [];
    private selectedUnits: number[] = [];

    private get reportedUnits(): number[] {
        if (!this.isReadyForSelect) {
            return [];
        }

        return this.selectedUnits.length > 0
            ? this.selectedUnits
            : this.unitsFilterOptions.map(e => Number(e.value));
    }

    private get countriesFilterOptions(): PuiFilterOption[] {
        return this.filters?.countries?.map(e => ({
            displayName: e.countryName ?? '',
            value: e.countryId,
        })) ?? [];
    }

    private get plantsFilterOptions(): PuiFilterOption[] {
        let filteredCountries = this.filters?.countries ?? [];

        if (this.selectedCountries.length > 0) {
            filteredCountries = filteredCountries.filter(e => this.selectedCountries.includes(e.countryId));
        }

        const plants = filteredCountries.flatMap(e => e.plants ?? []);
        return plants.map(e => ({
            displayName: e.plantName ?? '',
            value: e.plantSid,
        }));
    }

    private get unitsFilterOptions(): PuiFilterOption[] {
        let filteredCountries = this.filters?.countries ?? [];

        if (this.selectedCountries.length > 0) {
            filteredCountries = filteredCountries.filter(e => this.selectedCountries.includes(e.countryId));
        }

        let filteredPlants = filteredCountries.flatMap(e => e.plants ?? []);

        if (this.selectedPlants.length > 0) {
            filteredPlants = filteredPlants.filter(e => this.selectedPlants.includes(e.plantSid));
        }

        const units = filteredPlants.flatMap(e => e.units ?? []);
        return units.map(e => ({
            displayName: e.unitName ?? '',
            value: e.unitSid,
        }));
    }

    @Watch('filters', { immediate: true })
    private async onFiltersChange(newFilters: GetFiltersResponse | undefined): Promise<void> {
        if (!newFilters) {
            return;
        }

        this.clearFilters();
        this.isReadyForSelect = true;
        this.configureFilters();
    }

    @Watch('reportedUnits')
    private async onReportedUnitsChange(newReportedUnits: number[]): Promise<void> {
        this.$emit('change:units', newReportedUnits);
    }

    private hasSameSelectedOptions(appliedValues: any[], selectedOptions: any[]): boolean {
        return appliedValues.every(item => selectedOptions.includes(item))
            && selectedOptions.every(item => appliedValues.includes(item));
    }

    private filtersChanged(appliedValues: PuiFilterAppliedValues): void {
        const hasDifferentCountries = !this.hasSameSelectedOptions(appliedValues['countries'] as number[], this.selectedCountries);
        const hasDifferentPlants = !this.hasSameSelectedOptions(appliedValues['plants'] as number[], this.selectedPlants);

        this.selectedCountries = appliedValues['countries'] as number[];
        this.selectedPlants = appliedValues['plants'] as number[];
        this.selectedUnits = appliedValues['units'] as number[];

        if (hasDifferentCountries) {
            this.selectedPlants = [];
            this.selectedUnits = [];
        }

        if (hasDifferentPlants) {
            this.selectedUnits = [];
        }

        this.configureFilters();
    }

    private configureFilters(): void {
        this.filterConfiguration = {
            filters: [
                {
                    name: 'countries',
                    displayName: this.$t('results.siteView.selector.countries'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.countriesFilterOptions,
                    },
                    selectedValues: {
                        options: this.selectedCountries,
                    },
                    appliedValues: {
                        options: this.selectedCountries,
                    }
                },
                {
                    name: 'plants',
                    displayName: this.$t('results.siteView.selector.plants'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.plantsFilterOptions,
                    },
                    selectedValues: {
                        options: this.selectedPlants,
                    },
                    appliedValues: {
                        options: this.selectedPlants,
                    }
                },
                {
                    name: 'units',
                    displayName: this.$t('results.siteView.selector.units'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.unitsFilterOptions,
                    },
                    selectedValues: {
                        options: this.selectedUnits,
                    },
                    appliedValues: {
                        options: this.selectedUnits,
                    }
                }
            ]
        };
    }

    private clearFilters(): void {
        this.isReadyForSelect = false;

        this.selectedCountries = [];
        this.selectedPlants = [];
        this.selectedUnits = [];
    }
}
</script>

<style scoped lang="scss">
.sv-units-selector {
    flex-grow: 1;

    ::v-deep .pui-filter__toolbar {
        justify-content: flex-end;
    }
}
</style>
