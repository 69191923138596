var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chart', {
    ref: _vm.REF_CONSTANTS.CHART,
    staticClass: "tr-chart",
    attrs: {
      "option": _vm.option
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }