var render = function render(){
  var _vm$selectedScenarioI;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "site-view"
  }, [_c('div', {
    staticClass: "site-view__selectors"
  }, [_c('site-view-scenario-selector', {
    attrs: {
      "start-year": _vm.startYear,
      "end-year": _vm.endYear
    },
    on: {
      "change:start-year": function ($event) {
        _vm.startYear = $event;
      },
      "change:end-year": function ($event) {
        _vm.endYear = $event;
      }
    }
  }), _c('site-view-units-selector', {
    attrs: {
      "filters": _vm.scenarioFilters
    },
    on: {
      "change:units": function ($event) {
        _vm.unitSids = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "site-view__trend-reports"
  }, [_c('site-view-trend-reports', {
    attrs: {
      "trend-report-options": _vm.trendReportOptions,
      "scenario-filters": _vm.scenarioFilters
    }
  })], 1), _c('div', {
    key: (_vm$selectedScenarioI = _vm.selectedScenarioId) !== null && _vm$selectedScenarioI !== void 0 ? _vm$selectedScenarioI : -1,
    staticClass: "site-view__kpi-report"
  }, [_c('site-view-kpi-report', {
    attrs: {
      "scenario-id": _vm.selectedScenarioId,
      "scenario-filters": _vm.scenarioFilters,
      "trend-report-options": _vm.trendReportOptions
    }
  }), _c('site-view-kpi-report', {
    attrs: {
      "scenario-id": _vm.selectedScenarioId,
      "scenario-filters": _vm.scenarioFilters,
      "trend-report-options": _vm.trendReportOptions
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }