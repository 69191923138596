import {
    DeleteRequest, MarkDto, MarkRequest,
    ScenarioServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';

const DEBOUNCE_TIME_IN_SECONDS = 2 as const;
const scenarioService = new ScenarioServiceProxy();

type DataManagementState = {
    showInPowerBiChangelist: Map<number, boolean>;
    showInPowerBiDebounceTimeoutId?: number;
};

const initialState: DataManagementState = {
    showInPowerBiChangelist: new Map(),
    showInPowerBiDebounceTimeoutId: undefined,
};

const DataManagementModule: Module<DataManagementState, any> = {
    state: initialState,

    mutations: {
        addChangeToShowInPowerBiChangelist(state, payload: { scenarioId: number, showInPowerBi: boolean }): void {
            state.showInPowerBiChangelist.set(payload.scenarioId, payload.showInPowerBi);
        },
        clearShowInPowerBiChangelist(state): void {
            state.showInPowerBiChangelist.clear();
        },
        setShowInPowerBiTimeoutId(state, payload: number): void {
            state.showInPowerBiDebounceTimeoutId = payload;
        },
        cancelShowInPowerBiTimeout(state): void {
            clearTimeout(state.showInPowerBiDebounceTimeoutId);
            state.showInPowerBiDebounceTimeoutId = undefined;
        }
    },

    actions: {
        registerUnsavedChangesEventHandler({ dispatch }): void {
            dispatch('prevention/registerPrevention', 'dataManagement', { root: true })
        },
        unregisterUnsavedChangesEventHandler({ dispatch }): void {
            dispatch('prevention/unregisterPrevention', 'dataManagement', { root: true })
        },
        addShowInPowerBiToChangelist({ commit, dispatch }, payload: { scenarioId: number, showInPowerBi: boolean }): void {
            commit('cancelShowInPowerBiTimeout');
            commit('addChangeToShowInPowerBiChangelist', payload);

            dispatch('registerUnsavedChangesEventHandler');
            dispatch('scheduleSaveShowInPowerBiChangelist');
        },
        scheduleSaveShowInPowerBiChangelist({ commit, dispatch }): void {
            commit('setShowInPowerBiTimeoutId', setTimeout(() => dispatch('saveShowInPowerBiChangelist'), DEBOUNCE_TIME_IN_SECONDS * 1000));
        },
        async saveShowInPowerBiChangelist({ state, commit, dispatch }): Promise<void> {
            if (state.showInPowerBiChangelist.size === 0) {
                return;
            }

            commit('cancelShowInPowerBiTimeout');

            const markDtos: MarkDto[] = [];

            state.showInPowerBiChangelist.forEach((value, key) => {
                markDtos.push(new MarkDto({
                    scenarioId: key,
                    isMarked: value,
                }));
            });

            commit('clearShowInPowerBiChangelist');

            await scenarioService.mark(new MarkRequest({
                scenarios: markDtos,
            }));

            dispatch('unregisterUnsavedChangesEventHandler');
        },
        async deleteScenarios(context, payload: DeleteRequest): Promise<void> {
            await scenarioService.deleteScenario(payload);
        }
    },

    getters: {},

    namespaced: true,
};

export default DataManagementModule;
