var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-wrapper"
  }, [_c('pui-form-checkbox', {
    attrs: {
      "label": " ",
      "checked": _vm.checked
    },
    on: {
      "change": _vm.onChange
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }