var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('scenario-status-cards', {
    on: {
      "on-selected-status-changed": _vm.selectedStatusChanged
    }
  }), _c('data-management-table', {
    attrs: {
      "selected-status": _vm.selectedStatus
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }