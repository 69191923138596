var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-loader', {
    attrs: {
      "promise": _vm.initialPromise
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "wrapper__heading"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('results.overview.title')))])]), _c('pui-tabs', [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('results.tabs.siteView')
    }
  }, [_c('results-site-view')], 1), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('results.tabs.scenarioCompare')
    }
  }, [_c('results-scenario-compare')], 1), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('results.tabs.kpiAnalysis')
    }
  }, [_c('results-kpi-analysis')], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }