<template>
    <div class="delete-action-cell">
        <pui-button
            icon="delete"
            state="secondary"
            :small="true"
            :disabled="!params?.data?.canBeDeleted"
            @click="onDeleteClicked"
        >
            {{ $t('admin.actions.delete') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

@Component({})
export default class AdminActionCell extends Vue {
    private params?: ICellRendererParams<{ id: number, canBeDeleted: boolean }>;

    private onDeleteClicked(): void {
        if (!this.params
            || !this.params.context
            || !this.params.context.scheduleEntityDeletion
            || !this.params.data?.id
            || !this.params.data.canBeDeleted) {
            return;
        }

        this.params.context.scheduleEntityDeletion(this.params.data.id);
    }
}
</script>

<style scoped lang="scss">
.delete-action-cell {
    margin-left: -10px;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
</style>
