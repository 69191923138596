var render = function render(){
  var _vm$params$message;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay__message"
  }, [_vm._v(" " + _vm._s((_vm$params$message = _vm.params.message) !== null && _vm$params$message !== void 0 ? _vm$params$message : _vm.$t('dataMissing')) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }