<template>
    <div class="etl-lightbox">
        <pui-lightbox
            :ref="REF_CONSTANTS.LIGHTBOX"
            :default-header-label="$t('setup.scenario.primaryKpiStep.etlModal.title')"
            :close-on-e-s-c="false"
            @puilightbox:close="emitClose"
        >
            <etl-table />
        </pui-lightbox>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import ETLTable from '@/components/primary-kpi-step/summary/etl-lightbox/etl-table/etl-table.vue';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({
    components: {
        'etl-table': ETLTable,
    }
})
export default class ETLLightboxComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };
    
    @Prop()
    private lightboxOpen!: boolean;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }
    
    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(): void {
        this.$emit('close-lightbox');
    }
}
</script>
