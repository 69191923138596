var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unit-step"
  }, [_c('div', {
    staticClass: "unit-step__selection-counts"
  }, [_c('div', {
    staticClass: "unit-step__selection-counts__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.selectUnitForm.counters.sites', {
    count: _vm.selectedSitesCount
  })) + " ")]), _c('div', {
    staticClass: "unit-step__selection-counts__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.selectUnitForm.counters.units', {
    count: _vm.selectedUnitsCount
  })) + " ")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isFormSubmitting,
      expression: "!isFormSubmitting"
    }],
    staticClass: "unit-step__table-wrapper"
  }, [_c('table-header', {
    attrs: {
      "page-size": _vm.tableHeaderFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    }
  })], 1), _vm.isFormSubmitting ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise
    }
  }) : _vm._e(), _c('div', {
    staticClass: "unit-step__buttons"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "secondary"
    },
    on: {
      "click": _vm.backButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.back')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting || !_vm.isEditable,
      "state": "secondary"
    },
    on: {
      "click": _vm.saveAsDraftButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.saveAsDraft')) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isFormSubmitting,
      "state": "primary"
    },
    on: {
      "click": _vm.nextButtonClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.buttons.next')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }