<template>
    <div class="scenario-actions-cell-wrapper">
        <pui-button
            icon="edit"
            state="secondary"
            :small="true"
            @click="sendToEditScenario()"
        >
            {{ $t('setup.overview.scenarioTable.actions.edit') }}
        </pui-button>
        <pui-button
            icon="add"
            state="secondary"
            :small="true"
            :disabled="!canDuplicate"
            @click="sendToDuplicateScenario()"
        >
            {{ $t('setup.overview.scenarioTable.actions.duplicate') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ValueFormatterParams } from 'ag-grid-community';
import { IScenarioList } from '@/service-proxies/service-proxies.g';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';
import { SCENARIO_STATUS } from '@/config/status';

@Component({})
export default class ScenarioActionsCellComponent extends Vue {
    private params?: ValueFormatterParams<IScenarioList>;

    private get id(): number | undefined {
        return this.params?.data?.id;
    }

    private get status(): number {
        return this.params?.data?.status ?? Number(SCENARIO_STATUS.DRAFT);
    }

    private get canDuplicate(): boolean {
        const statusesThatAllowDuplication = [SCENARIO_STATUS.READY_FOR_ENGINE_RUN, SCENARIO_STATUS.ENGINE_RUN_COMPLETED, SCENARIO_STATUS.ENGINE_RUN_FAILED, SCENARIO_STATUS.ENGINE_RUN_STOPPED];

        return statusesThatAllowDuplication.map(e => Number(e)).includes(this.status);
    }

    private sendToEditScenario(): void {
        if (!this.id) {
            return;
        }

        this.$router.push({
            name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
            params: {
                id: this.id.toString(),
            }
        });
    }

    private sendToDuplicateScenario(): void {
        if (!this.id) {
            return;
        }

        this.$router.push({
            name: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
            params: {
                id: this.id.toString(),
            },
            query: {
                isDuplicating: 'true',
            }
        });
    }
}
</script>

<style scoped lang="scss">
.scenario-actions-cell-wrapper {
    height: 100%;
    display: flex;
    gap: 10px;
}
</style>
