var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "confirmation-lightbox-wrapper"
  }, [_c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.$t('dataManagement.overview.scenarioTable.confirmation.title'),
      "default-footer-confirm-label": _vm.$t('dataManagement.overview.scenarioTable.actions.delete'),
      "default-footer-cancel-label": _vm.$t('dataManagement.overview.scenarioTable.actions.cancel'),
      "on-confirm-callback": _vm.emitConfirm,
      "close-on-e-s-c": false,
      "fit-content": ""
    },
    on: {
      "puilightbox:close": _vm.emitClose
    }
  }, [_c('div', {
    staticClass: "confirmation-lightbox-wrapper__content"
  }, [_c('div', {
    staticClass: "confirmation-lightbox-wrapper__content__title"
  }, [_vm._v(" " + _vm._s(_vm.$t('dataManagement.overview.scenarioTable.confirmation.message')) + " ")]), _c('div', {
    staticClass: "confirmation-lightbox-wrapper__content__list"
  }, [_vm._v(" " + _vm._s(_vm.list) + " ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }