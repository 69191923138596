<template>
    <div class="er-actions-cell-wrapper">
        <pui-button
            icon="visible"
            state="secondary"
            :small="true"
            @click="sendToEngineRunSummary"
        >
            {{ $t('engineRun.overview.engineRunTable.actions.viewMore') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IScenarioRunDto } from '@/service-proxies/service-proxies.g';
import { ICellRendererParams } from 'ag-grid-community';

@Component({})
export default class ERActionsCellComponent extends Vue {
    private params?: ICellRendererParams<IScenarioRunDto>;

    private get title(): string | undefined {
        return this.params?.data?.name;
    }

    private get id(): number | undefined {
        return this.params?.data?.id;
    }

    private sendToEngineRunSummary(): void {
        if (!this.id) {
            return;
        }

        this.$router.push({
            name: 'EngineRunSummary',
            params: {
                id: this.id.toString(),
            }
        });
    }
}
</script>

<style scoped lang="scss">
.er-actions-cell-wrapper {
    height: 100%;
    display: flex;
    gap: 10px;
}
</style>
