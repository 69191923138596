import { render, staticRenderFns } from "./secondary-kpi-tab.vue?vue&type=template&id=7f9b0de5"
import script from "./secondary-kpi-tab.vue?vue&type=script&lang=ts"
export * from "./secondary-kpi-tab.vue?vue&type=script&lang=ts"
import style0 from "./secondary-kpi-tab.vue?vue&type=style&index=0&id=7f9b0de5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports