<template>
    <div class="mtc-step">
        <pui-tabs
            :selected="selectedTabIndex"
            class="mtc-step__tabs"
            @changed="onTabChanged"
        >
            <pui-tab :title="$t('setup.scenario.mtcForm.tabs.mcy')" />
            <pui-tab :title="$t('setup.scenario.mtcForm.tabs.cc')" />
        </pui-tabs>
        <pui-loader
            v-if="isStepSubmitting"
            :promise="infinitePromise"
            :title="$t('setup.scenario.mtcForm.loader.savingAll.title')"
            :message="$t('setup.scenario.mtcForm.loader.savingAll.message')"
        />
        <mcy-table
            v-show="!isMcySubmitting && !isStepSubmitting && selectedTabIndex === MTC_STEP_TAB.MCY"
            :ref="MCY_TABLE_REF"
        />
        <cost-coefficients-table
            v-show="!isCostCoefficientsSubmitting && !isStepSubmitting && selectedTabIndex === MTC_STEP_TAB.COST_COEFFICIENTS"
            :ref="CC_TABLE_REF"
        />
        <div class="mtc-step__buttons">
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.next') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import McyTable from '@/components/mtc-step/mcy-table/mcy-table.vue';
import CostCoefficientsTable from '@/components/mtc-step/cost-coefficients/cost-coefficients-table.vue';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

enum MTC_STEP_TAB {
    MCY = 0,
    COST_COEFFICIENTS = 1,
}

const MCY_TABLE_REF = 'mcyTable' as const;
const CC_TABLE_REF = 'ccTable' as const;

@Component({
    components: {
        CostCoefficientsTable,
        McyTable,
    },
    beforeRouteLeave(to, from, next) {
        (this as MtcScenarioStep).saveAllTables(true);
        next();
    }
})
export default class MtcScenarioStep extends mixins(ScenarioStepNavigation) {
    private readonly MTC_STEP_TAB = MTC_STEP_TAB;
    private readonly MCY_TABLE_REF = MCY_TABLE_REF;
    private readonly CC_TABLE_REF = CC_TABLE_REF;

    private selectedTabIndex: MTC_STEP_TAB = MTC_STEP_TAB.MCY;

    private isMcySubmitting = false;
    private isCostCoefficientsSubmitting = false;
    private isStepSubmitting = false;

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    $refs!: {
        [MCY_TABLE_REF]: McyTable,
        [CC_TABLE_REF]: CostCoefficientsTable,
    };

    private get isFormSubmitting(): boolean {
        return this.isMcySubmitting || this.isCostCoefficientsSubmitting;
    }

    private async onTabChanged(newTabIndex: number): Promise<void> {
        this.selectedTabIndex = newTabIndex;

        if (newTabIndex === MTC_STEP_TAB.COST_COEFFICIENTS && !this.isMcySubmitting) {
            await this.saveMcyTable(true, true);
        }

        if (newTabIndex === MTC_STEP_TAB.MCY && !this.isCostCoefficientsSubmitting) {
            await this.saveCostCoefficientTable(true, true);
        }
    }

    private async saveMcyTable(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        this.isMcySubmitting = true;
        const isSaved = await this.$refs.mcyTable.submitForm(saveAsDraft, automaticSubmit);
        this.isMcySubmitting = false;

        return isSaved;
    }

    private async saveCostCoefficientTable(saveAsDraft = false, automaticSubmit = false): Promise<boolean> {
        this.isCostCoefficientsSubmitting = true;
        const isSaved = await this.$refs.ccTable.submitForm(saveAsDraft, automaticSubmit);
        this.isCostCoefficientsSubmitting = false;

        return isSaved;
    }

    private async saveAllTables(saveAsDraft = false): Promise<boolean> {
        this.isStepSubmitting = true;
        const [isMcySaved, isCcSaved] = await Promise.all([
            this.saveMcyTable(saveAsDraft, false),
            this.saveCostCoefficientTable(saveAsDraft, false)
        ]);
        this.isStepSubmitting = false;

        return isMcySaved && isCcSaved;
    }

    private async backButtonClicked(): Promise<void> {
        if (await this.saveAllTables(true)) {
            await this.pushToStep(SCENARIO_FORM_STEP.INFLATION);
        }
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (await this.saveAllTables(true)) {
            await this.pushToScenarioOverview();
        }
    }

    private async nextButtonClicked(): Promise<void> {
        if (await this.saveAllTables(false)) {
            await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.COST_EXCLUSION);
            await this.pushToStep(SCENARIO_FORM_STEP.COST_EXCLUSION);
        }
    }
}
</script>

<style scoped lang="scss">
.mtc-step {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    &__tabs {
        margin-bottom: -1rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }
}
</style>
