var render = function render(){
  var _vm$summary$kpisCalcu, _vm$summary, _vm$summary$sites, _vm$summary2, _vm$summary$units, _vm$summary3, _vm$summary$startYear, _vm$summary4, _vm$summary$endYear, _vm$summary5, _vm$summary$createdBy, _vm$summary6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "engine-run-summary-card"
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.promise,
      "min-height": "100px"
    }
  }, [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "engine-run-summary-card__section",
    attrs: {
      "cols": _vm.COLUMN_CONFIG
    }
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.runSummary')) + " ")]), _c('div', {
    staticClass: "engine-run-summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.selectedKpis', {
    count: (_vm$summary$kpisCalcu = (_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : _vm$summary.kpisCalculated) !== null && _vm$summary$kpisCalcu !== void 0 ? _vm$summary$kpisCalcu : 0
  })) + " ")])], 1), _c('div', {
    staticClass: "engine-run-summary-card__section__item engine-run-summary-card__section__item--inline"
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.sites', {
    count: (_vm$summary$sites = (_vm$summary2 = _vm.summary) === null || _vm$summary2 === void 0 ? void 0 : _vm$summary2.sites) !== null && _vm$summary$sites !== void 0 ? _vm$summary$sites : 0
  })) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.units', {
    count: (_vm$summary$units = (_vm$summary3 = _vm.summary) === null || _vm$summary3 === void 0 ? void 0 : _vm$summary3.units) !== null && _vm$summary$units !== void 0 ? _vm$summary$units : 0
  })) + " ")])], 1), _c('div', {
    staticClass: "engine-run-summary-card__section__item engine-run-summary-card__section__item--inline"
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.startYear', {
    year: (_vm$summary$startYear = (_vm$summary4 = _vm.summary) === null || _vm$summary4 === void 0 ? void 0 : _vm$summary4.startYear) !== null && _vm$summary$startYear !== void 0 ? _vm$summary$startYear : 0
  })) + " ")]), _c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.endYear', {
    year: (_vm$summary$endYear = (_vm$summary5 = _vm.summary) === null || _vm$summary5 === void 0 ? void 0 : _vm$summary5.endYear) !== null && _vm$summary$endYear !== void 0 ? _vm$summary$endYear : 0
  })) + " ")])], 1)], 1), _c('pui-grid-column', {
    staticClass: "engine-run-summary-card__section--upload-info-column",
    attrs: {
      "cols": _vm.COLUMN_CONFIG
    }
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.createdUpdated')) + " ")]), _c('div', {
    staticClass: "engine-run-summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formattedDate) + " ")])], 1), _c('pui-headline', {
    staticClass: "engine-run-summary-card__section__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('engineRun.summary.summaryCard.createdBy')) + " ")]), _c('div', {
    staticClass: "engine-run-summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "engine-run-summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s((_vm$summary$createdBy = (_vm$summary6 = _vm.summary) === null || _vm$summary6 === void 0 ? void 0 : _vm$summary6.createdBy) !== null && _vm$summary$createdBy !== void 0 ? _vm$summary$createdBy : '-') + " ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }