var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ce-all-table-wrapper"
  }, [_c('div', {
    staticClass: "ce-all-table-wrapper__actions"
  }, [_c('div', {
    staticClass: "ce-all-table-wrapper__actions__left-content"
  }, [_c('pui-toggle', {
    attrs: {
      "value": _vm.showOnlyWithValues
    },
    on: {
      "change": _vm.onShowOnlyWithValuesChanged
    },
    scopedSlots: _vm._u([{
      key: "puiToggleRight",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.all.actions.showOnlyWithValues')) + " ")];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "ce-all-table-wrapper__actions__spacer"
  }), _c('div', {
    staticClass: "ce-all-table-wrapper__actions__right-content"
  }, [_c('div', {
    staticClass: "ce-all-table-wrapper__actions__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.all.actions.selectedCount', {
    count: _vm.selectedGlobalIdsCount
  })) + " ")]), _c('pui-button', {
    attrs: {
      "disabled": _vm.isMarkAsFlaggedDisabled,
      "state": "primary"
    },
    on: {
      "click": _vm.submitMarkAsFlagged
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.all.actions.markAsFlagged')) + " ")])], 1)]), _c('div', {
    staticClass: "ce-all-table-wrapper__table"
  }, [_c('table-header', {
    attrs: {
      "page-size": _vm.tableHeaderFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }