<template>
    <div class="formula-step">
        <pui-tabs
            :selected="selectedTabIndex"
            @changed="onTabChanged"
        >
            <pui-tab :title="$t('setup.scenario.formulaStep.tabs.primaryKpi')" />
            <pui-tab :title="$t('setup.scenario.formulaStep.tabs.secondaryKpi')" />
        </pui-tabs>
        <primary-kpi-tab v-show="selectedTabIndex === FORMULA_STEP_TAB.PRIMARY_KPI" />
        <secondary-kpi-tab
            v-show="selectedTabIndex === FORMULA_STEP_TAB.SECONDARY_KPI"
            :ref="SECONDARY_KPI_REF"
        />
        <div class="formula-step__buttons">
            <pui-button
                :disabled="isSecondaryKpiSubmitting"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isSecondaryKpiSubmitting"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isSecondaryKpiSubmitting"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.next') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import PrimaryKpiTab from '@/components/formula-step/primary-kpi-tab/primary-kpi-tab.vue';
import SecondaryKpiTab from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-tab.vue';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

enum FORMULA_STEP_TAB {
    PRIMARY_KPI = 0,
    SECONDARY_KPI = 1,
}

const SECONDARY_KPI_REF = 'secondaryKpi' as const;

@Component({
    components: {
        PrimaryKpiTab,
        SecondaryKpiTab,
    },
    beforeRouteLeave(to, from, next) {
        (this as FormulaScenarioStep).saveSecondaryKpiTable(true);
        next();
    }
})
export default class FormulaScenarioStep extends mixins(ScenarioStepNavigation) {
    private readonly SECONDARY_KPI_REF = SECONDARY_KPI_REF;
    private FORMULA_STEP_TAB = FORMULA_STEP_TAB;
    private selectedTabIndex: FORMULA_STEP_TAB = FORMULA_STEP_TAB.PRIMARY_KPI;

    private isSecondaryKpiSubmitting = false;

    $refs!: {
        [SECONDARY_KPI_REF]: SecondaryKpiTab,
    };

    private async onTabChanged(tabIndex: number): Promise<void> {
        this.selectedTabIndex = tabIndex;

        if (tabIndex === FORMULA_STEP_TAB.PRIMARY_KPI && !this.isSecondaryKpiSubmitting) {
            await this.saveSecondaryKpiTable(true);
        }
    }

    private async saveSecondaryKpiTable(saveAsDraft: boolean): Promise<boolean> {
        this.isSecondaryKpiSubmitting = true;
        const isSaved = await this.$refs.secondaryKpi.bulkSaveKpiActive(saveAsDraft);
        this.isSecondaryKpiSubmitting = false;
        return isSaved;
    }

    private async backButtonClicked(): Promise<void> {
        if (await this.saveSecondaryKpiTable(true)) {
            await this.pushToStep(SCENARIO_FORM_STEP.UNIT);
        }
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (await this.saveSecondaryKpiTable(true)) {
            await this.pushToScenarioOverview();
        }
    }

    private async nextButtonClicked(): Promise<void> {
        if (await this.saveSecondaryKpiTable(false)) {
            await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.INFLATION);
            await this.pushToStep(SCENARIO_FORM_STEP.INFLATION);
        }
    }
}
</script>

<style scoped lang="scss">
.formula-step {
    @include rem(padding, pui-spacing(l));

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }
}
</style>
