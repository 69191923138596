import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/utils/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'adam.ui-core/dist/umd';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { coodeSdkConfig, coodeSdkPlugin } from '@/utils/coode-sdk-config';
import VTooltip from 'v-tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@/assets/stylesheets/v-tooltip.scss';
import '@/assets/stylesheets/benchmarking-table.scss';
import { LicenseManager } from 'ag-grid-enterprise';
import { AG_GRID_LICENCE_KEY } from '@/utils/ag-grid-licencing';
// TODO: Import components to save bundle size after implementing charts
import 'echarts';
import ECharts from 'vue-echarts';

Vue.config.productionTip = false;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VChart', ECharts);

library.add(faCopy as any, faCaretRight as any, faCaretUp as any, faCaretDown as any, faSort as any, faGripLines as any);

Vue.use(coodeSdkPlugin, coodeSdkConfig);
Vue.use(VTooltip);
Vue.use(PebbleUI);

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
