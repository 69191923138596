<template>
    <div class="pk-step">
        <pui-loader
            v-show="isSaving"
            :promise="infinitePromise"
            :title="$t('setup.scenario.primaryKpiStep.loader.savingChanges.title')"
            :message="$t('setup.scenario.primaryKpiStep.loader.savingChanges.message')"
        />
        <div v-show="!isSaving">
            <summary-card @on-handle-saving="handleSaving" />
            <primary-kpi-table :ref="PRIMARY_KPI_TABLE_REF" />
        </div>
        <div class="pk-step__buttons">
            <pui-button
                :disabled="isSaving"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isSaving"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isSaving"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.complete') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SummaryCard from '@/components/primary-kpi-step/summary/summary-card.vue'
import PrimaryKpiTable from '@/components/primary-kpi-step/primary-kpi-table.vue';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

const PRIMARY_KPI_TABLE_REF = 'primaryKpiTableRef' as const;

@Component({
    components: {
        SummaryCard,
        PrimaryKpiTable,
    },
    beforeRouteLeave(to, from, next) {
        (this as PrimaryKpiScenarioStep).saveTableChanges(true);
        next();
    }
})
export default class PrimaryKpiScenarioStep extends mixins(ScenarioStepNavigation) {
    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
    private isSaving = false;

    private readonly PRIMARY_KPI_TABLE_REF = PRIMARY_KPI_TABLE_REF;
    $refs!: {
        [PRIMARY_KPI_TABLE_REF]: PrimaryKpiTable,
    };

    private triggerRefreshTable(): void {
        this.$refs.primaryKpiTableRef.refreshTable();
    }

    private handleSaving(value: boolean): void {
        this.isSaving = value;
        if(!value) {
            this.$refs.primaryKpiTableRef.refreshTable();
        }
    }

    private async saveTableChanges(saveAsDraft = true): Promise<boolean> {
        this.isSaving = true;
        const isTableSaved = await this.$refs.primaryKpiTableRef.submitChanges(saveAsDraft);
        this.isSaving = false;
        return isTableSaved;
    }

    private async backButtonClicked(): Promise<void> {
        if (await this.saveTableChanges()) {
            await this.pushToStep(SCENARIO_FORM_STEP.COST_EXCLUSION);
        }
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (await this.saveTableChanges()) {
            await this.pushToScenarioOverview();
        }
    }

    private async nextButtonClicked(): Promise<void> {
        if (await this.saveTableChanges(false)) {
            await this.pushToStep(SCENARIO_FORM_STEP.CONFIRMATION);
        }
    }
}
</script>

<style lang="scss">
.pk-step {
    @include rem(padding, pui-spacing(l));

    &__tabs {
        margin-bottom: -1rem;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }
}
</style>
