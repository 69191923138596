<template>
    <div class="scenario-status-cards-wrapper">
        <donut-card
            v-for="status in statusCards"
            :key="`status_${status.id}`"
            :promise="statusDistributionPromise"
            :title="status.title"
            :color="status.color"
            :count="status.count"
            :total="totalCount"
            :hoverable="isStatusCardHoverable"
            :selected="selectedStatus === status.id"
            @click.native="selectStatus(status.id)"
        />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Spinner from '@/components/spinner/spinner.vue';
import DonutCard from '@/components/donut-card/donut-card.vue';
import { DonutCardDetails, StatusDistribution } from '@/models/interfaces';
import { SCENARIO_STATUS, SCENARIO_STATUS_DETAILS } from '@/config/status';
import { PUICOLOR_DARK_BLUE } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';

@Component({
    components: {
        Spinner,
        DonutCard
    }
})
export default class ScenarioStatusCardsComponent extends mixins(ManagedAbortControllers) {
    private statusDistributionPromise: Promise<void> | null = null;
    private selectedStatus: string | null  = null;
    private isStatusCardHoverable = false;

    private get statusCards(): DonutCardDetails[] {
        return [
            {
                id: '0',
                title: this.$t('setup.overview.status.totalNumberOfScenarios'),
                color: PUICOLOR_DARK_BLUE,
                count: this.totalCount,
            },
            {
                id: SCENARIO_STATUS.DRAFT,
                title:this.$t(SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.DRAFT].NAME),
                color: SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.DRAFT].COLOR,
                count: this.statusDistribution[SCENARIO_STATUS.DRAFT],
            },
            {
                id: SCENARIO_STATUS.IN_REVIEW,
                title: this.$t(SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.IN_REVIEW].NAME),
                color: SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.IN_REVIEW].COLOR,
                count: this.statusDistribution[SCENARIO_STATUS.IN_REVIEW],
            },
            {
                id: SCENARIO_STATUS.READY_FOR_ENGINE_RUN,
                title: this.$t(SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.READY_FOR_ENGINE_RUN].NAME),
                color: SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.READY_FOR_ENGINE_RUN].COLOR,
                count: this.statusDistribution[SCENARIO_STATUS.READY_FOR_ENGINE_RUN],
            },
            {
                id: SCENARIO_STATUS.ENGINE_RUN_COMPLETED,
                title:this.$t(SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.ENGINE_RUN_COMPLETED].NAME),
                color: SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.ENGINE_RUN_COMPLETED].COLOR,
                count: this.statusDistribution[SCENARIO_STATUS.ENGINE_RUN_COMPLETED],
            },
            {
                id: SCENARIO_STATUS.ENGINE_RUN_FAILED,
                title:this.$t(SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.ENGINE_RUN_FAILED].NAME),
                color: SCENARIO_STATUS_DETAILS[SCENARIO_STATUS.ENGINE_RUN_FAILED].COLOR,
                count: this.statusDistribution[SCENARIO_STATUS.ENGINE_RUN_FAILED],
            },
        ];
    }

    private get statusDistribution(): StatusDistribution {
        return this.$store.getters['status/getStatusDistribution'];
    }

    private get totalCount(): number {
        return this.$store.getters['status/getTotalCount'];
    }

    private mounted(): void {
        this.fetchStatusDistribution();
    }

    private beforeDestroy(): void {
        this.abortAndClearAllSignals();
    }

    private fetchStatusDistribution(): void {
        this.statusDistributionPromise = this.$store.dispatch('status/fetchStatusDistribution', { signal: this.getSignal('fetchStatusDistribution') })
            .then(() => {
                this.isStatusCardHoverable = true;
            });
    }

    private selectStatus(id: string): void {
        if (!this.isStatusCardHoverable) {
            return;
        }

        this.selectedStatus = this.selectedStatus !== id ? id : null;
        this.$emit('on-selected-status-changed', this.selectedStatus);
    }
}
</script>

<style scoped lang="scss">
.scenario-status-cards-wrapper {
    display: flex;
    @include rem(gap, pui-spacing(m));
}
</style>
