<template>
    <div class="secondary-kpi-scenario-import">
        <pui-form-group
            :label="$t('setup.scenario.formulaStep.import.label')"
            :class="['secondary-kpi-scenario-import__dropdown', {'secondary-kpi-scenario-import__dropdown--enabled': !hasImportedScenario }]"
            :cta-label="$t('form.clear')"
            :has-cta="!inputDisabled"
            :cta-callback="clearScenario"
            :has-label-padding="false"
        >
            <benchmarking-type-ahead
                :ref="REF_CONSTANTS.TYPEAHEAD"
                v-model="selectedScenario"
                :placeholder="$t('setup.scenario.formulaStep.import.placeholder')"
                :request-promise-generator="scenariosRequestPromiseGenerator"
                :is-disabled="inputDisabled"
            />
        </pui-form-group>
        <div class="secondary-kpi-scenario-import__button">
            <pui-button
                v-if="!hasImportedScenario"
                icon="imported"
                state="primary"
                icon-only
                :disabled="isImportScenarioDisabled"
                @click="importScenarioClicked"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { IGetScenarioResponse, ListSortDirection, ScenarioList, ScenarioServiceProxy, SortableScenarioField } from '@/service-proxies/service-proxies.g';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import BenchmarkingTypeAhead from '@/components/inputs/benchmarking-type-ahead/benchmarking-type-ahead.vue';

const REF_CONSTANTS = {
    TYPEAHEAD: 'typeahead',
} as const;

@Component({
    components: {
        BenchmarkingTypeAhead
    }
})
export default class SecondaryKpiScenarioImportComponent extends mixins(ManagedAbortControllers) {
    private scenarioServiceProxy = new ScenarioServiceProxy();
    private selectedScenario: PuiSelectOption<number> | null = null;
    private isImportInProgress = false;
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.TYPEAHEAD]: BenchmarkingTypeAhead,
    };

    @Prop({ default: false })
    private isDisabled!: boolean;

    private get scenario(): IGetScenarioResponse {
        return this.$store.getters['scenario/getScenario'];
    }

    private get inputDisabled(): boolean {
        return this.hasImportedScenario || this.isImportScenarioDisabled;
    }

    private get hasImportedScenario(): boolean {
        return !!this.scenario.previousScenarioName || !!this.scenario.previousScenarioFormulaName;
    }

    private get isImportScenarioDisabled(): boolean {
        return this.isImportInProgress || this.isDisabled;
    }

    private mounted(): void {
        const importedScenarioName = this.scenario.previousScenarioName ?? this.scenario.previousScenarioFormulaName;
        if (importedScenarioName) {
            this.selectedScenario = {
                label: importedScenarioName,
                value: 0,
            }
        }
    }

    private scenariosRequestPromiseGenerator = async (query: string, signal: AbortSignal): Promise<PuiSelectOptions<number>> => {
        const response = await this.scenarioServiceProxy.list2(undefined, undefined, SortableScenarioField.CREATED_AT, ListSortDirection.Descending, 1, 20, query, undefined, signal);
        const scenarios: ScenarioList[] = response.result.items ?? [];
        return scenarios.map((scenario: ScenarioList) => ({
            label: scenario.name ?? '-',
            value: scenario.id,
        }));
    }

    private clearScenario(): void {
        this.$refs.typeahead.clearInput();
    }

    private async importScenarioClicked(): Promise<void> {
        if (!this.selectedScenario?.value) {
            return;
        }

        this.isImportInProgress = true;

        const payload = {
            currentScenarioId: this.scenario.id,
            previousScenarioId: this.selectedScenario.value,
        }

        try {
            await this.$store.dispatch('scenario/formulaStep/importScenario', payload);
            this.$pui.toast({
                type: 'success',
                title: this.$t('setup.scenario.formulaStep.toastMessages.importScenario.title'),
                copy: this.$t('setup.scenario.formulaStep.toastMessages.importScenario.copy'),
            });
            this.$emit('import:finished');
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
        } finally {
            this.isImportInProgress = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.secondary-kpi-scenario-import {
    display: flex;
    align-items: flex-end;

    &__dropdown {
        width: 32rem;

        &--enabled {
            .bm-type-ahead {
                @include rem(border-radius, pui-spacing(xxxs) 0 0 pui-spacing(xxxs));
                @include rem(border-right-width, 0);
            }
        }
    }

    &__button {
        .pui-button {
            @include rem(border-radius, 0 pui-spacing(xxxs) pui-spacing(xxxs) 0);
        }
    }
}
</style>