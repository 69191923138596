var render = function render(){
  var _vm$summary$uploadedF, _vm$summary$uploadedF2, _vm$uploadedByName;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-card"
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.summaryPromise,
      "is-inline-error": false,
      "size": "60px",
      "min-height": "100px"
    }
  }, [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "summary-card__section",
    attrs: {
      "cols": _vm.COLUMN_CONFIG
    }
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.summary')) + " ")]), _c('div', {
    staticClass: "summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.kpisSelected', {
    count: _vm.summary.kpis
  })) + " ")])], 1), _c('div', {
    staticClass: "summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__content summary-card__section__item__content--highlight",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.missingKpis', {
    count: _vm.summary.missingKpisPercent
  })) + " ")])], 1), _c('div', {
    staticClass: "summary-card__section__item summary-card__section__item--inline"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.sites', {
    count: _vm.summary.sites
  })) + " ")]), _c('pui-headline', {
    staticClass: "summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.physicalUnits', {
    count: _vm.summary.physicalUnits
  })) + " ")]), _c('pui-headline', {
    staticClass: "summary-card__section__item__content",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.virtualUnits', {
    count: _vm.summary.virtualUnits
  })) + " ")])], 1)], 1), _c('pui-grid-column', {
    staticClass: "summary-card__section--upload-info-column",
    attrs: {
      "cols": _vm.COLUMN_CONFIG
    }
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.uploadedFiles')) + " ")]), _c('div', {
    staticClass: "summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.fileName')) + ": "), _c('span', {
    staticClass: "summary-card__section__item__content summary-card__section__item__content--info"
  }, [_vm._v(" " + _vm._s((_vm$summary$uploadedF = (_vm$summary$uploadedF2 = _vm.summary.uploadedFile) === null || _vm$summary$uploadedF2 === void 0 ? void 0 : _vm$summary$uploadedF2.name) !== null && _vm$summary$uploadedF !== void 0 ? _vm$summary$uploadedF : '-') + " ")])])], 1), _c('div', {
    staticClass: "summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.date')) + ": "), _c('span', {
    staticClass: "summary-card__section__item__content summary-card__section__item__content--info"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate) + " ")])])], 1), _c('div', {
    staticClass: "summary-card__section__item"
  }, [_c('pui-headline', {
    staticClass: "summary-card__section__item__title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.uploadedBy')) + ": "), _c('span', {
    staticClass: "summary-card__section__item__content summary-card__section__item__content--info"
  }, [_vm._v(" " + _vm._s((_vm$uploadedByName = _vm.uploadedByName) !== null && _vm$uploadedByName !== void 0 ? _vm$uploadedByName : '-') + " ")])])], 1)], 1), _c('pui-grid-column', {
    staticClass: "summary-card__section--action-column",
    attrs: {
      "cols": _vm.COLUMN_CONFIG
    }
  }, [_c('div', {
    staticClass: "summary-card__section--action-column__content"
  }, [_c('pui-form-file', {
    key: _vm.fileFormKey,
    attrs: {
      "label": _vm.$t('setup.scenario.primaryKpiStep.summaryCard.uploadFile'),
      "accept": ".xlsx, .xls",
      "max-file-weight": 1e8,
      "on-change-callback": _vm.onFileAdded
    }
  }), _c('pui-button', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.handleOpenETLLightbox
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.primaryKpiStep.summaryCard.showETLLogMessages')) + " ")])], 1)])], 1)], 1), _c('etl-lightbox', {
    attrs: {
      "lightbox-open": _vm.isETLLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleCloseETLLightbox
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }