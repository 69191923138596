var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "etl-lightbox"
  }, [_c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": _vm.$t('setup.scenario.primaryKpiStep.etlModal.title'),
      "close-on-e-s-c": false
    },
    on: {
      "puilightbox:close": _vm.emitClose
    }
  }, [_c('etl-table')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }