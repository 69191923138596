<template>
    <div class="navbar-wrapper">
        <pui-navigation-toolbar
            :link-items="navbarLinks"
            @clicked:tab="handleTabClick"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { NavbarLink, PuiNavigationToolbarClickEvent } from '@/models/pebble-ui';

type ActionNavbarLink = {
    action: () => void;
} & NavbarLink;

@Component({})
export default class NavbarComponent extends Vue {
    private navbarLinks: NavbarLink[] = [];

    private readonly actionNavbarLinks: readonly ActionNavbarLink[] = [
        {
            displayName: this.$t('powerbi.navigationTitle'),
            isActive: false,
            href: '#',
            action: (): void => {
                window.open(process.env.VUE_APP_POWERBI_URL, '_blank');
            }
        }
    ] as const;

    private get routeNavbarLinks(): NavbarLink[] {
        return this.$router.getRoutes()
            .filter(route => route.meta?.navbar)
            .map(route => ({
                displayName: this.$t(route.meta.navbar.title),
                href: route.path,
                isActive: this.isCurrentPath(route.path),
            }));
    }

    private mounted(): void {
        this.computeNavbarLinks();
    }

    private computeNavbarLinks(): void {
        this.navbarLinks = [
            ...this.routeNavbarLinks,
            ...this.actionNavbarLinks
        ];
    }

    private isCurrentPath(path: string): boolean {
        return this.$route.path.includes(path);
    }

    private handleTabClick(event: PuiNavigationToolbarClickEvent): void {
        this.$nextTick(() => {
            this.computeNavbarLinks();
        });
        
        if (event.action) {
            event.action();
            return;
        }

        if (event.href && !this.isCurrentPath(event.href)) {
            this.$router.push(event.href);
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-wrapper {
    :deep(.pui-navigation-tabs__tab) {
        @include rem(line-height, pui-spacing(s));
    }
}
</style>
