var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-analysis-unit-selectors"
  }, [_c('pui-form-group', {
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('results.kpiAnalysis.selector.selectYear'),
      "has-label-padding": false,
      "label-for": "years"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "value": _vm.selectedYear,
      "options": _vm.yearsOptions,
      "label": _vm.$t('results.kpiAnalysis.selector.selectYear'),
      "search-input-placeholder": _vm.$t('results.kpiAnalysis.selector.selectPlaceholder'),
      "search-input-id": "years"
    },
    on: {
      "change": _vm.selectedYearChanged
    }
  })], 1), _c('pui-form-group', {
    attrs: {
      "show-required-label": true,
      "label": _vm.$t('results.kpiAnalysis.selector.selectUnit'),
      "has-label-padding": false,
      "label-for": "units"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "value": _vm.selectedUnit,
      "options": _vm.unitsOptions,
      "label": _vm.$t('results.kpiAnalysis.selector.selectUnit'),
      "search-input-placeholder": _vm.$t('results.kpiAnalysis.selector.selectPlaceholder'),
      "search-input-id": "units"
    },
    on: {
      "change": _vm.selectedUnitChanged
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }