<template>
    <div class="uom-tab">
        <table-header
            :search-term="tableHeaderFilters.searchTerm"
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
            @search-triggered="onTriggerSearch"
            @clear-search-term="onClearSearchTerm"
        />
        <ag-grid-vue
            class="ag-theme-alpine"
            :grid-options="gridOptions"
        />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import {
    GridApi,
    GridOptions,
    GridSizeChangedEvent,
    IServerSideGetRowsParams,
    ValueSetterParams
} from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import {
    UnitOfMeasureUpdateDto,
    UomServiceProxy
} from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { AgGridVue } from 'ag-grid-vue';
import AdminActionCell from '@/components/admin/table-cells/delete-action-cell.vue';
import { mixins } from 'vue-class-component';
import { Debouncer } from '@/mixins/debouncer';

type UomRow = {
    id: number;
    uomName: string;
    canBeDeleted: boolean;
}

@Component({
    components: {
        TableHeader,
        AgGridVue,
        AdminActionCell,
    }
})
export default class AdminUomTab extends mixins(Debouncer) {
    private readonly uomService = new UomServiceProxy();

    private uomChangelist = new Map<number, string>();
    private uomDeletelist = new Set<number>();

    private tableHeaderFilters = {
        pageSize: 20,
        searchTerm: '',
    };

    private gridApi: GridApi<UomRow> | null = null;

    private gridOptions: GridOptions<UomRow> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const pageNumber = this.pageNumber(params.request.endRow);
                const { searchTerm, pageSize } = this.tableHeaderFilters;

                this.uomService.listUnitsOfMeasure(pageNumber, pageSize, searchTerm, undefined)
                    .then(response => {
                        const rowData: UomRow[] = response.result.items?.map(item => ({
                            id: item.id,
                            uomName: item.name ?? '-',
                            canBeDeleted: item.canBeDeleted,
                        })) ?? [];

                        params.success({ rowData, rowCount: response.result.total });
                    })
                    .catch(() => params.fail());
            },
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('admin.modal.table.id'),
                field: 'id',
                maxWidth: 200,
            },
            {
                headerName: this.$t('admin.modal.table.uom'),
                field: 'uomName',
                editable: true,
                valueSetter: (params: ValueSetterParams<UomRow>): boolean => {
                    if (params.oldValue.toString() === params.newValue.toString() || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const newName = params.newValue.toString();

                    this.scheduleUomNameChange(id, newName);
                    params.data.uomName = newName;

                    return true;
                },
            },
            {
                headerName: this.$t('admin.modal.table.actions'),
                maxWidth: 150,
                cellRenderer: 'AdminActionCell',
            }
        ],
        defaultColDef: {
            menuTabs: [],
        },
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        serverSideInfiniteScroll: true,
        onGridSizeChanged(event: GridSizeChangedEvent<UomRow>) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
        context: {
            scheduleEntityDeletion: this.scheduleUomDeletion,
            onRefreshTable: this.onRefreshTable,
        }
    };

    private scheduleUomNameChange(id: number, newKpiTypeName: string): void {
        this.uomChangelist.set(id, newKpiTypeName);

        this.$store.dispatch('prevention/registerPrevention', 'adminUom');
        this.debounce('uomSaveChanges', this.saveChanges, 2000);
    }

    private scheduleUomDeletion(id: number): void {
        this.uomDeletelist.add(id);
        this.uomChangelist.delete(id);

        this.$store.dispatch('prevention/registerPrevention', 'adminUom');

        this.clearDebouncer('uomSaveChanges');
        this.gridApi?.showLoadingOverlay();
        this.saveChanges().finally(() => {
            this.gridApi?.hideOverlay();
            this.gridApi?.refreshServerSide({ purge: true });
        });
    }

    private async saveChanges(): Promise<void> {
        if (this.uomDeletelist.size !== 0) {
            const deleteList: number[] = [];

            for (const id of this.uomDeletelist.values()) {
                deleteList.push(id);
            }

            this.uomDeletelist.clear();

            try {
                await this.uomService.deleteUnitsOfMeasure(deleteList);
            } catch {
                this.$pui.toast({
                    type: 'error',
                    title: 'Cannot delete KPI Types',
                    copy: 'An error has occurred when attempting to delete KPI Types.',
                });

                deleteList.forEach(entry => this.uomDeletelist.add(entry));
            }
        }

        if (this.uomChangelist.size !== 0) {
            const changeList: UnitOfMeasureUpdateDto[] = [];

            for (const [id, name] of this.uomChangelist.entries()) {
                changeList.push(new UnitOfMeasureUpdateDto({ id, name }));
            }

            this.uomChangelist.clear();

            try {
                await this.uomService.editUnitsOfMeasure(changeList);
            } catch {
                this.$pui.toast({
                    type: 'error',
                    title: 'Cannot save KPI Type changes',
                    copy: 'An error has occurred when attempting to save KPI Type changes.',
                });

                changeList.forEach(entry => this.uomChangelist.set(entry.id, entry.name ?? '-'));
            }
        }

        await this.$store.dispatch('prevention/unregisterPrevention', 'adminUom');
    }

    private onGridReady(params: AgGridCommon<UomRow>): void {
        params.api.sizeColumnsToFit();
        this.gridApi = params.api;
    }

    public async onRefreshTable(): Promise<void> {
        this.clearDebouncer('uomSaveChanges');
        await this.saveChanges();

        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableHeaderFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableHeaderFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize)
    }
}
</script>

<style scoped lang="scss">
.uom-tab {
    @include rem(margin, pui-spacing(s) 0);
    @include pui-box();

    width: 100%;
    background-color: $white;
}
</style>
