<template>
    <div class="ce-all-table-wrapper">
        <div class="ce-all-table-wrapper__actions">
            <div class="ce-all-table-wrapper__actions__left-content">
                <pui-toggle
                    :value="showOnlyWithValues"
                    @change="onShowOnlyWithValuesChanged"
                >
                    <template #puiToggleRight>
                        {{ $t('setup.scenario.ceForm.tables.all.actions.showOnlyWithValues') }}
                    </template>
                </pui-toggle>
            </div>
            <div class="ce-all-table-wrapper__actions__spacer" />
            <div class="ce-all-table-wrapper__actions__right-content">
                <div class="ce-all-table-wrapper__actions__text">
                    {{ $t('setup.scenario.ceForm.tables.all.actions.selectedCount', { count: selectedGlobalIdsCount }) }}
                </div>
                <pui-button
                    :disabled="isMarkAsFlaggedDisabled"
                    state="primary"
                    @click="submitMarkAsFlagged"
                >
                    {{ $t('setup.scenario.ceForm.tables.all.actions.markAsFlagged') }}
                </pui-button>
            </div>
        </div>
        <div class="ce-all-table-wrapper__table">
            <table-header
                :page-size="tableHeaderFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
                @search-triggered="onTriggerSearch"
                @clear-search-term="onClearSearchTerm"
            />
            <ag-grid-vue
                :grid-options="gridOptions"
                class="ag-theme-alpine"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideGetRowsParams,
    ModelUpdatedEvent,
    SelectionChangedEvent,
    SetFilterValuesFuncParams,
    ValueFormatterParams
} from 'ag-grid-community';
import { AddBudgetsRequest, BudgetRequestServiceProxy, PmlServiceProxy } from '@/service-proxies/service-proxies.g';
import { FetchPaginationPartial } from '@/models/steps/common';
import { IColumnLimit } from 'ag-grid-community/dist/lib/gridApi';
import CostsTooltip from '@/components/cost-exclusion-step/common/costs-tooltip.vue';
import { FetchAllCostsFilters } from '@/models/steps/cost-correction';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';

type AllCostsTableRow = {
    globalId: string;
    name: string;
    countryName: string;
    plantName: string;
    unitName: string;
    technologyName: string;
    outageId: string;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    planningAndControllingClassification: number;
    projectType: number;
    aeroClassification: number;
    currency: string;
    isFlagged: boolean;
};

@Component({
    components: {
        AgGridVue,
        CostsTooltip,
        TableHeader,
    }
})
export default class AllCostsTable extends Vue {
    private readonly COLUMN_KEYS = {
        GLOBAL_ID: 'globalId',
        NAME: 'name',
        PLANT_NAME: 'plantName',
        UNIT_NAME: 'unitName',
        COUNTRY_NAME: 'countryName',
        TECHNOLOGY_NAME: 'technologyName',
        OUTAGE_ID: 'outageId',
        FORECAST: 'forecast',
        NEW1: 'new1',
        NEW2: 'new2',
        NEW3: 'new3',
        PLANNING_AND_CONTROLLING_CLASSIFICATION: 'planningAndControllingClassification',
        PROJECT_TYPE: 'projectType',
        AERO_CLASSIFICATION: 'aeroClassification',
        CURRENCY: 'currency',
    } as const;

    private readonly pmlService = new PmlServiceProxy();
    private readonly budgetService = new BudgetRequestServiceProxy();

    private gridApi: GridApi<AllCostsTableRow> | null = null;
    private selectedGlobalIds: string[] = [];
    private isFormSubmitting = false;
    private showOnlyWithValues = false;

    private tableHeaderFilters = {
        pageSize: 20,
        searchTerm: '',
    };

    private columnLimits: IColumnLimit[] = [
        {
            key: this.COLUMN_KEYS.GLOBAL_ID,
            minWidth: 200,
        },
        {
            key: this.COLUMN_KEYS.NAME,
            minWidth: 500,
        }
    ];

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get scenarioMtpYear(): number {
        return this.$store.getters['scenario/getScenario']?.mtpYear ?? 0;
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private get selectedGlobalIdsCount(): number {
        return this.selectedGlobalIds.length;
    }

    private get isMarkAsFlaggedDisabled(): boolean {
        return this.selectedGlobalIds.length === 0 || this.isFormSubmitting;
    }

    private gridOptions: GridOptions<AllCostsTableRow> = {
        rowModelType: 'serverSide',
        domLayout: 'autoHeight',
        serverSideDatasource: {
            getRows: (params): void => {
                const pagination = this.getPaginationForServerRequestParams(params);
                const filters = this.getSelectedFiltersForServerRequestParams(params);

                this.pmlService.getBudgets(this.scenarioId, filters.plants, filters.technologies, filters.countries, filters.showOnlyWithValues, pagination.pageNumber, pagination.pageSize, filters.searchTerm, undefined)
                    .then(response => {
                        const budgets = response.result.budgets ?? [];

                        const rowData: AllCostsTableRow[] = budgets.map(budget => ({
                            globalId: budget.globalId ?? '',
                            name: budget.name ?? '',
                            countryName: budget.countryName ?? '',
                            plantName: budget.plantName ?? '',
                            unitName: budget.unitName ?? '',
                            technologyName: budget.technologyName ?? '',
                            outageId: budget.outageId ?? '',
                            forecast: budget.forecast ?? 0,
                            new1: budget.new1 ?? 0,
                            new2: budget.new2 ?? 0,
                            new3: budget.new3 ?? 0,
                            planningAndControllingClassification: budget.planningAndControllingClassification,
                            projectType: budget.projectType,
                            aeroClassification: budget.aeroClassification,
                            currency: budget.currency ?? '',
                            isFlagged: budget.isFlagged,
                        }));

                        params.success({
                            rowData,
                            rowCount: response.result.total,
                        });
                    })
                    .catch(() => params.fail());
            },
        },
        columnDefs: [
            {
                field: this.COLUMN_KEYS.GLOBAL_ID,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.globalId'),
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                tooltipComponent: 'CostsTooltip',
                tooltipField: this.COLUMN_KEYS.GLOBAL_ID,
                pinned: 'left',
            },
            {
                field: this.COLUMN_KEYS.NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.name'),
                menuTabs: ['filterMenuTab'],
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true,
                },
                resizable: true,
                pinned: 'left',
            },
            {
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.countryName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.countries.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.countries.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.PLANT_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.plantName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.plants.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.plants.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.UNIT_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.unitName'),
            },
            {
                field: this.COLUMN_KEYS.TECHNOLOGY_NAME,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.technologyName'),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.technologies.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const technologySid = Number(params.value);
                        return this.scenarioFilters.technologies.find(e => e.sid === technologySid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                field: this.COLUMN_KEYS.PROJECT_TYPE,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.projectType'),
                valueFormatter: (params): string => this.scenarioFilters.projectTypes[params.value.toString()] ?? this.$t('unknown'),
            },
            {
                field: this.COLUMN_KEYS.AERO_CLASSIFICATION,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.aeroClassification'),
                valueFormatter: (params): string => this.scenarioFilters.aeroClassification[params.value.toString()] ?? this.$t('unknown'),
            },
            {
                field: this.COLUMN_KEYS.OUTAGE_ID,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.outageId'),
                valueFormatter: (params): string => params.value ? params.value : '-',
            },
            {
                field: this.COLUMN_KEYS.PLANNING_AND_CONTROLLING_CLASSIFICATION,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.planningAndControllingClassification'),
                valueFormatter: (params): string => this.scenarioFilters.planningControllingClassification[params.value.toString()] ?? this.$t('unknown'),
            },
            {
                field: this.COLUMN_KEYS.FORECAST,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.forecast', { year: this.scenarioMtpYear }),
            },
            {
                field: this.COLUMN_KEYS.NEW1,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.new1', { year: this.scenarioMtpYear + 1 }),
            },
            {
                field: this.COLUMN_KEYS.NEW2,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.new2', { year: this.scenarioMtpYear + 2 }),
            },
            {
                field: this.COLUMN_KEYS.NEW3,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.new3', { year: this.scenarioMtpYear + 3 }),
            },
            {
                field: this.COLUMN_KEYS.CURRENCY,
                headerName: this.$t('setup.scenario.ceForm.tables.all.headers.currency'),
            },
        ],
        defaultColDef: {
            menuTabs: [],
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        serverSideInfiniteScroll: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        getRowId: (params): string => params.data.globalId,
        isRowSelectable: (node): boolean => !node.data?.isFlagged && (!!node.data?.globalId ?? false),
        onSelectionChanged: this.onSelectionChanged,
        onModelUpdated: this.onModelUpdated,
        onGridSizeChanged: (event) => {
            event.api.sizeColumnsToFit({ defaultMinWidth: 250, columnLimits: this.columnLimits });
        },
        onGridReady: this.onGridReady,
    };

    private onGridReady(event: GridReadyEvent<AllCostsTableRow>): void {
        event.api.sizeColumnsToFit({ defaultMinWidth: 250, columnLimits: this.columnLimits });

        this.gridApi = event.api;
    }

    private onSelectionChanged(event: SelectionChangedEvent<AllCostsTableRow>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.isSelected()) {
                if (!this.selectedGlobalIds.includes(node.data.globalId)) {
                    this.selectedGlobalIds = [...this.selectedGlobalIds, node.data.globalId];
                }
            } else {
                this.selectedGlobalIds = this.selectedGlobalIds.filter(e => e !== node.data?.globalId);
            }
        });
    }

    private onModelUpdated(event: ModelUpdatedEvent<AllCostsTableRow>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (this.selectedGlobalIds.includes(node.data.globalId)) {
                node.setSelected(true, false, true);
            } else {
                node.setSelected(false, false, true);
            }
        });
    }

    private onShowOnlyWithValuesChanged(newValue: boolean): void {
        this.showOnlyWithValues = newValue;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableHeaderFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableHeaderFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private async submitMarkAsFlagged(): Promise<void> {
        if (this.selectedGlobalIds.length === 0) {
            return;
        }

        const payload = new AddBudgetsRequest({
            scenarioId: this.scenarioId,
            saveAsDraft: false,
            globalIds: [...this.selectedGlobalIds],
        });

        try {
            this.isFormSubmitting = true;
            const response = await this.budgetService.addBudget(payload);
            if (response.result.globalIds && response.result.globalIds.length > 0) {
                const list = response.result.globalIds.join(', ');
                this.$pui.toast({
                    type: 'success',
                    title: this.$t('setup.scenario.ceForm.tables.all.toastMessages.markSuccess.title'),
                    copy: this.$t('setup.scenario.ceForm.tables.all.toastMessages.markSuccess.copy', { list }),
                });
            }
            this.selectedGlobalIds = [];
            this.gridApi?.refreshServerSide({ purge: true });
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
        }

        this.isFormSubmitting = false;
    }

    private getPaginationForServerRequestParams(params: IServerSideGetRowsParams): FetchPaginationPartial {
        return {
            pageNumber: Math.floor((params.request.endRow ?? this.tableHeaderFilters.pageSize) / this.tableHeaderFilters.pageSize),
            pageSize: this.tableHeaderFilters.pageSize,
        };
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): FetchAllCostsFilters {
        return {
            plants: params.request.filterModel?.[this.COLUMN_KEYS.PLANT_NAME]?.values?.map((e: string) => Number(e)),
            countries: params.request.filterModel?.[this.COLUMN_KEYS.COUNTRY_NAME]?.values?.map((e: string) => Number(e)),
            technologies: params.request.filterModel?.[this.COLUMN_KEYS.TECHNOLOGY_NAME]?.values?.map((e: string) => Number(e)),
            searchTerm: this.tableHeaderFilters.searchTerm,
            showOnlyWithValues: this.showOnlyWithValues ? true : undefined,
        };
    }
}
</script>

<style scoped lang="scss">
.ce-all-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    &__actions {
        @include rem(padding-top, pui-spacing(s));

        display: flex;
        align-items: flex-end;

        &__left-content {
            display: flex;
            align-items: center;
            gap: 3rem;

            justify-self: flex-start;
        }

        &__spacer {
            flex-grow: 1;
        }

        &__right-content {
            display: flex;
            align-items: center;
            gap: 3rem;

            justify-self: flex-end;
        }

        &__text {
            font-size: 1.4rem;
            font-weight: bold;
            color: $dark-blue-lighter;
        }
    }

    &__table {
        width: 100%;
        @include pui-box();
    }
}

</style>
