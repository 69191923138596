<template>
    <div class="primary-data-table">
        <div
            v-show="!disableExport && isYearDataLoaded"
            class="primary-data-table__year-filter"
        >
            <pui-chip
                v-for="year in yearRange"
                :key="year.value.toString()"
                :label="year.value.toString()"
                :border-color="selectedYearColor(year.value)"
                class="primary-data-table__year-filter__chip"
                @click.native="handleSelectYear(year.value)"
            />
        </div>

        <div class="primary-data-table__table">
            <spinner
                :promise="filtersPromise"
                size="60px"
            >
                <div class="primary-data-table__table__extra-header">
                    <pui-toggle @change="handleShowOnlyMissingValues">
                        <template #puiToggleRight>
                            {{ $t('setup.scenario.primaryKpiStep.table.showOnlyMissingValues') }}
                        </template>
                    </pui-toggle>
                    <pui-button
                        small
                        icon="download"
                        state="secondary"
                        :disabled="disableExport"
                        @click="handleExportPrimaryDataClick()"
                    >
                        {{ $t('setup.scenario.primaryKpiStep.table.exportToExcel') }}
                    </pui-button>
                </div>
                <table-header
                    :search-term="tableExtraFilters.searchTerm"
                    :page-size="tableExtraFilters.pageSize"
                    @page-size-changed="onPageSizeChanged"
                    @search-triggered="onTriggerSearch"
                    @clear-search-term="onClearSearchTerm"
                />
                <ag-grid-vue
                    class="ag-theme-alpine"
                    :grid-options="gridOptions"
                    @grid-ready="onGridReady"
                />
            </spinner>
            <a
                v-show="false"
                :ref="PRIMARY_DATA_FILE"
                :href="primaryDataUrl"
                :download="primaryDataFile.fileName"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridApi, GridOptions, IServerSideGetRowsParams, ITooltipParams, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import {
    BadRequestResponse,
    EditPrimaryKpiRequest,
    FileParameter,
    IPrimaryDataKpiEditDto,
    ListSortDirection,
    PrimaryDataKpiEditDto,
    PrimaryDataServiceProxy,
    SortablePrimaryDataField
} from '@/service-proxies/service-proxies.g';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { FilterParams } from '@/models/interfaces';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import Spinner from '@/components/spinner/spinner.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import NoRowsOverlay from '@/components/benchmarking-table/overlay-types/no-rows-overlay.vue';
import { PUICOLOR_UNIPER_BLUE, PUICOLOR_WARM_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { ScenarioFilters } from '@/store/modules/scenario/steps/filters.module';
import { formatNumberForTableDisplay } from '@/utils/formatters';

type PrimaryDataRow = {
    kpiType: string,
    kpiId: string,
    countryName: string,
    plantName: string,
    unitName: string,
    technologyName: string,
    isAllMissing: boolean,
    [key: string]: any,
};

type PrimaryDataKpiSetFilters = {
    countries: number[],
    plants: number[],
    kpiTypes: number[],
    technologies: number[],
    units: number[],
};

const PRIMARY_DATA_FILE = 'primaryDataFile' as const;

@Component({
    components: {
        AgGridVue,
        SimpleTooltip,
        TableHeader,
        Spinner,
        NoRowsOverlay,
    }
})
export default class PrimaryKpiTableComponent extends Vue {
    private readonly PRIMARY_DATA_FILE = PRIMARY_DATA_FILE;
    $refs!: {
        [PRIMARY_DATA_FILE]: (Vue | Element | Vue[] | Element[]) & {
            click: () => void;
        }
    };
    private filtersPromise: Promise<any> | null = null;
    private primaryDataServiceProxy = new PrimaryDataServiceProxy();
    private gridApi: GridApi<PrimaryDataRow> | null = null;
    private tableExtraFilters = {
        showOnlyMissingValues: false,
        pageSize: 20,
        searchTerm: '',
    }
    private yearRange: {
        value: number;
        isSelected: boolean;
    }[] = [];
    private yearKeys: string[] = [];
    private isYearDataLoaded = false;
    private modifiedValues = new Map<string, IPrimaryDataKpiEditDto>();
    private filterParams: FilterParams = {} as FilterParams;
    private primaryDataUrl = '';
    private disableExport = false;
    private isFirstLoad = true;

    private gridOptions: GridOptions<PrimaryDataRow> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const { plants, countries, kpiTypes, technologies, units } = this.getSelectedFiltersForServerRequestParams(params);
                const years = this.yearRange.map(y => y.value);
                const scenarioId = this.scenarioId;
                const sortField = this.sortField(params.request.sortModel[0]?.colId);
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort);
                const pageNumber = this.pageNumber(params.request.endRow);
                const { showOnlyMissingValues, pageSize, searchTerm } = this.tableExtraFilters;

                const filterParams: FilterParams = {
                    scenarioId,
                    sortField,
                    sortDirection,
                    showOnlyMissingValues,
                    searchTerm,
                    plants: plants ?? [],
                    countries: countries ?? [],
                    kpiTypes: kpiTypes ?? [],
                    years: [],
                    technologies: technologies ?? [],
                    units: units ?? [],
                }
                this.updateFilters(filterParams);

                params.api.hideOverlay();

                this.primaryDataServiceProxy.list3(scenarioId, showOnlyMissingValues, kpiTypes, technologies, units, plants, years, countries, sortField, sortDirection, pageNumber, pageSize, searchTerm, undefined)
                    .then((response) => {
                        const rowData: PrimaryDataRow[] = [];
                        const years = new Set<number>();

                        response.result.items?.forEach(value => {
                            const row: PrimaryDataRow = {
                                kpiType: value.kpiType ?? '',
                                kpiId: value.kpiId ?? '',
                                kpiDesc: value.kpiDesc ?? '',
                                countryName: value.countryName ?? '',
                                plantName: value.plantName ?? '',
                                unitName: value.unitName ?? '',
                                technologyName: value.technologyName ?? '',
                                isAllMissing: value.isAllMissing,
                            };

                            this.yearRange.forEach(year => {
                                years.add(year.value);
                                const primId = value.years?.find(y => y.year === year.value)?.primId;
                                const yearValue = value.years?.find(y => y.year === year.value)?.yearValue;
                                const idExists = value.years?.find(y => y.year === year.value)?.idExists;
                                row[year.value.toString()] = primId ? yearValue ?? '-' : undefined;
                                row[this.computeIdKeyForYear(year.value)] = {
                                    primId,
                                    idExists,
                                };
                            })

                            rowData.push(row);
                        });

                        if (!response.result.isEtlPipelineFinished) {
                            this.showEtlNoRowsOverlay();
                            params.success({ rowData: [], rowCount: 0 });
                            return;
                        }

                        this.configureYearColumns(years);

                        if (response.result.total === 0 && this.getIsFirstLoad()) {
                            this.showEtlErrorOverlay();
                        } else {
                            this.setIsFirstLoad(false);
                        }

                        params.success({ rowData, rowCount: response.result.total });
                    })
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.kpiType'),
                field: 'kpiType',
                pinned: 'left',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.scenarioFilters.kpiTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.scenarioFilters.kpiTypes[params.value];
                    }
                },
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.kpiId'),
                field: 'kpiId',
                tooltipField: 'kpiId',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<PrimaryDataRow>): { content?: string } => ({
                    content: params.data?.kpiDesc,
                }),
                pinned: 'left',
                sortable: true,
                cellClass: (params): string | undefined => {
                    if (params.data?.isAllMissing) {
                        return 'primary-data-table__table__cell--empty';
                    }
                },
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.country'),
                field: 'countryName',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.countries.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const countrySid = Number(params.value);
                        return this.scenarioFilters.countries.find(e => e.sid === countrySid)?.name ?? this.$t('unknown');
                    },
                },
                minWidth: 150,
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.plant'),
                field: 'plantName',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.plants.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const plantSid = Number(params.value);
                        return this.scenarioFilters.plants.find(e => e.sid === plantSid)?.name ?? this.$t('unknown');
                    },
                },
                minWidth: 150,
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.unit'),
                field: 'unitName',
                minWidth: 200,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.units.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const unitSid = Number(params.value);
                        return this.scenarioFilters.units.find(e => e.sid === unitSid)?.name ?? this.$t('unknown');
                    },
                },
            },
            {
                headerName: this.$t('setup.scenario.primaryKpiStep.table.headers.technology'),
                field: 'technologyName',
                minWidth: 100,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(this.scenarioFilters.technologies.map(e => e.sid.toString()));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        const technologySid = Number(params.value);
                        return this.scenarioFilters.technologies.find(e => e.sid === technologySid)?.name ?? this.$t('unknown');
                    },
                },
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 100,
            cellClass: 'primary-data-table__table__cell--non-editable',
        },
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        noRowsOverlayComponent: 'NoRowsOverlay',
        onCellValueChanged: (event) => {
            event.api.refreshCells();
        },
        onModelUpdated: (event) => {
            event.api.forEachNode(node => {
                this.applyLocalChangesToRowId(node.id ?? '');
            });
        },
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    private getIsFirstLoad(): boolean {
        return this.isFirstLoad;
    }

    private setIsFirstLoad(value: boolean): void {
        this.isFirstLoad = value;
    }

    private showEtlErrorOverlay(): void {
        this.gridOptions.noRowsOverlayComponentParams = {
            message: this.$t('setup.scenario.primaryKpiStep.table.etlError'),
        };

        this.gridApi?.showNoRowsOverlay();
    }

    private showEtlNoRowsOverlay(): void {
        this.gridOptions.noRowsOverlayComponentParams = {
            message: this.$t('setup.scenario.primaryKpiStep.table.noRows'),
        };

        this.gridApi?.showNoRowsOverlay();
    }

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get primaryDataFile(): FileParameter {
        return this.$store.getters['scenario/primaryKpiStep/getFile'];
    }

    private get scenarioFilters(): ScenarioFilters {
        return this.$store.getters['scenario/filters/getFilters'];
    }

    private get selectedYears(): number[] {
        return this.yearRange
            .filter(y => y.isSelected)
            .map(y => y.value);
    }

    private mounted(): void {
        this.filtersPromise = this.$store.dispatch('scenario/filters/ensureFiltersAreLoaded')
            .then(() => {
                const startYear = this.scenarioFilters.years.startYear;
                const endYear = this.scenarioFilters.years.endYear;
                for (let i = startYear; i <= endYear; i++) {
                    this.yearRange.push({
                        value: i,
                        isSelected: true,
                    });
                }
            });
    }

    private handleExportPrimaryDataClick(): void {
        this.filtersPromise = this.exportPrimaryData();
    }

    private selectedYearColor(value: number): string {
        return this.yearRange.find(e => e.value === value)?.isSelected ? PUICOLOR_UNIPER_BLUE : PUICOLOR_WARM_GREY;
    }

    private handleSelectYear(value: number): void {
        const yearIndex = this.yearRange.findIndex(e => e.value === value);
        if (yearIndex !== -1) {
            const year = this.yearRange[yearIndex]
            Vue.set(this.yearRange, yearIndex, {
                value: year.value,
                isSelected: !year.isSelected,
            })
            this.gridOptions.columnApi?.setColumnVisible(year.value.toString(), !year.isSelected);
            this.gridApi?.sizeColumnsToFit();
        }
    }

    private handleShowOnlyMissingValues(value: boolean): void {
        this.tableExtraFilters.showOnlyMissingValues = value;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onGridReady(params: AgGridCommon<PrimaryDataRow>): void {
        this.gridApi = params.api;
    }

    public refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if (!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortablePrimaryDataField | undefined {
        if (!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortablePrimaryDataField> = {
            'kpiId': SortablePrimaryDataField.KPI_ID,
            'unitName': SortablePrimaryDataField.NAME,
        };

        return fieldKey[colId];
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): PrimaryDataKpiSetFilters {
        return {
            plants: params.request.filterModel?.plantName?.values?.map((e: string) => Number(e)),
            countries: params.request.filterModel?.countryName?.values?.map((e: string) => Number(e)),
            kpiTypes: params.request.filterModel?.kpiType?.values?.map((e: string) => Number(e)),
            technologies: params.request.filterModel?.technologyName?.values?.map((e: string) => Number(e)),
            units: params.request.filterModel?.unitName?.values?.map((e: string) => Number(e)),
        };
    }

    private configureYearColumns(years: Set<number>): void {
        if (this.isYearDataLoaded || !this.gridApi) {
            return;
        }

        const columnDefinitions = this.gridApi.getColumnDefs() ?? [];
        const sortedYears = Array.from(years).sort((a, b) => (a - b));

        sortedYears.forEach(year => {
            this.yearKeys.push(year.toString());
            const isYearSelected = this.selectedYears.find(y => y === year);

            columnDefinitions.push({
                field: year.toString(),
                headerName: year.toString(),
                hide: !isYearSelected,
                editable: (params) => {
                    const year = Number(params.column.getColId());
                    const primId = params.data?.[this.computeIdKeyForYear(year)];
                    return primId !== undefined;
                },
                cellClass: (params) => {
                    if (params.value === '-') {
                        return 'primary-data-table__table__cell--empty';
                    }
                },
                valueFormatter: (params): string => {
                    const value: number = params.data?.[params.column.getColId()] ?? '';
                    return formatNumberForTableDisplay(value);
                },
                valueSetter: (params): boolean => {
                    if (params.oldValue === params.newValue || params.newValue === ' ' || !params.data) {
                        return false;
                    }

                    const value = this.parsePrimaryDataKpiValue(params.newValue);
                    const year = Number(params.column.getColId());
                    const idExists = params.data[this.computeIdKeyForYear(year)].idExists;
                    const primId = Number(params.data[this.computeIdKeyForYear(year)].primId);
                    const cellId = this.computeIdKeyForCell(primId, year);

                    // Update modified value in this.modifiedValues
                    if (value) {
                        const primaryDataKpiEditDto: IPrimaryDataKpiEditDto = { primId, value, year, idExists };
                        this.modifiedValues.set(cellId, primaryDataKpiEditDto);

                        // Update modified value in table data
                        params.data[params.column.getColId()] = value;
                        return true;
                    }

                    return false;
                }
            });
        });

        this.isYearDataLoaded = true;
        this.gridApi.setColumnDefs(columnDefinitions);
        this.gridApi.sizeColumnsToFit();
    }

    private computeIdKeyForYear(year: number): string {
        return `${year}_ID`;
    }

    private computeIdKeyForCell(primId: number, year: number): string {
        return `${primId}_${year}`;
    }

    private parsePrimaryDataKpiValue(value: string): number | null {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? null : parsedValue;
    }

    private applyLocalChangesToRowId(rowId: string): void {
        const node = this.gridApi?.getRowNode(rowId);

        if (!node || !node.data) {
            return;
        }

        const data: PrimaryDataRow = node.data;

        this.yearKeys.forEach(yearKey => {
            const yearIdKey = this.computeIdKeyForYear(Number(yearKey));
            const cellId = this.computeIdKeyForCell(Number(data[yearIdKey].primId), Number(yearKey));

            if (!data[yearIdKey] || !this.modifiedValues.has(cellId)) {
                return;
            }

            // Non-null guaranteed by previous Map#has() call.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const modifiedValue = this.modifiedValues.get(cellId)!;
            node.setDataValue(yearKey, modifiedValue.value);
        });
    }

    private updateFilters(filterParams: FilterParams): void {
        this.filterParams = filterParams;
    }

    public async submitChanges(saveAsDraft = true): Promise<boolean> {
        if (this.modifiedValues.size === 0 && saveAsDraft) {
            return true;
        }

        const primaryDataKpis: PrimaryDataKpiEditDto[] = [];
        this.modifiedValues.forEach(value => primaryDataKpis.push(new PrimaryDataKpiEditDto(value)));

        const payload: EditPrimaryKpiRequest = new EditPrimaryKpiRequest({
            scenarioId: this.scenarioId,
            saveAsDraft,
            primaryDataKpis,
        });

        try {
            await this.$store.dispatch('scenario/primaryKpiStep/editPrimaryData', payload);
            this.modifiedValues.clear();
            this.gridApi?.refreshServerSide({ purge: true });
            return true;
        } catch (err) {
            let copy = this.$t('setup.scenario.primaryKpiStep.table.toastMessages.edit.copy');

            if (err instanceof BadRequestResponse) {
                copy = err.message ?? '';
            }

            this.$pui.toast({
                type: 'error',
                title: this.$t('setup.scenario.primaryKpiStep.table.toastMessages.edit.title'),
                copy,
            });

            return false;
        }
    }

    private async exportPrimaryData(): Promise<void> {
        this.disableExport = true;
        this.filterParams.years = this.selectedYears;

        await this.submitChanges();

        try {
            await this.$store.dispatch('scenario/primaryKpiStep/exportPrimaryData', this.filterParams);
            this.downloadFile(this.primaryDataFile);
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('setup.scenario.primaryKpiStep.table.toastMessages.exportFile.title'),
                copy: this.$t('setup.scenario.primaryKpiStep.table.toastMessages.exportFile.copy'),
            });
        } finally {
            this.isYearDataLoaded = false;
            this.disableExport = false;
        }
    }

    private downloadFile(file: FileParameter): void {
        this.primaryDataUrl = window.URL.createObjectURL(new Blob([file.data]));
        this.$nextTick(() => {
            this.$refs[PRIMARY_DATA_FILE].click();
        });
    }
}
</script>

<style lang="scss" scoped>
.primary-data-table {
    @include rem(margin, pui-spacing(l) 0);

    &__year-filter {
        display: flex;
        flex-wrap: wrap;
        @include rem(gap, pui-spacing(xxs));
        @include rem(margin, pui-spacing(xs) 0);

        &__chip {
            cursor: pointer;
        }
    }

    &__table {
        width: 100%;
        background-color: $white;
        @include pui-box();

        &__extra-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $warm-grey-25;
            @include rem(padding, pui-spacing(xs) pui-spacing(s));
        }

        ::v-deep &__cell {
            &--non-editable {
                background-color: $non-editable-cell-color;
            }

            &--empty {
                color: $empty-cell-color;
            }
        }
    }
}
</style>
