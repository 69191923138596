<template>
    <div class="unit-step">
        <div class="unit-step__selection-counts">
            <div class="unit-step__selection-counts__text">
                {{ $t('setup.scenario.selectUnitForm.counters.sites', { count: selectedSitesCount }) }}
            </div>
            <div class="unit-step__selection-counts__text">
                {{ $t('setup.scenario.selectUnitForm.counters.units', { count: selectedUnitsCount }) }}
            </div>
        </div>
        <div
            v-show="!isFormSubmitting"
            class="unit-step__table-wrapper"
        >
            <table-header
                :page-size="tableHeaderFilters.pageSize"
                @page-size-changed="onPageSizeChanged"
                @search-triggered="onTriggerSearch"
                @clear-search-term="onClearSearchTerm"
            />
            <ag-grid-vue
                :grid-options="gridOptions"
                class="ag-theme-alpine"
            />
        </div>
        <pui-loader
            v-if="isFormSubmitting"
            :promise="infinitePromise"
        />
        <div class="unit-step__buttons">
            <pui-button
                :disabled="isFormSubmitting"
                state="secondary"
                @click="backButtonClicked"
            >
                {{ $t('setup.scenario.buttons.back') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting || !isEditable"
                state="secondary"
                @click="saveAsDraftButtonClicked"
            >
                {{ $t('setup.scenario.buttons.saveAsDraft') }}
            </pui-button>
            <pui-button
                :disabled="isFormSubmitting"
                state="primary"
                @click="nextButtonClicked"
            >
                {{ $t('setup.scenario.buttons.next') }}
            </pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import {
    GridApi,
    GridOptions,
    GridReadyEvent, IAggFuncParams,
    RowNode,
    RowSelectedEvent, ValueGetterParams
} from 'ag-grid-community';
import {
    AddUnitsRequest,
    UnitAddDeleteDto,
    UnitServiceProxy
} from '@/service-proxies/service-proxies.g';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import { SCENARIO_FORM_STEP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';
import InnerUnitGroupCell from '@/components/unit-step/inner-unit-group-cell.vue';
import LoadingOverlay from '@/components/benchmarking-table/overlay-types/loading-overlay.vue';

export type UnitRowModel = {
    plantSid: number;
    plantName: string;
    isPlantInitiallySelected: boolean;
    unitSid?: number;
    unitName?: string;
    isUnitInitiallySelected?: boolean;
    countryName: string;
    technologyName: string;
    isRowSelectable: boolean;
};

@Component({
    components: {
        AgGridVue,
        TableHeader,
        InnerUnitGroupCell,
        LoadingOverlay,
    },
    beforeRouteLeave(to, from, next) {
        if ((this as UnitScenarioStep).shouldSaveOnRouteLeave) {
            (this as UnitScenarioStep).submitForm(true)
        }

        next();
    }
})
export default class UnitScenarioStep extends mixins(ScenarioStepNavigation) {
    private readonly unitService = new UnitServiceProxy();
    private readonly COLUMN_KEYS = {
        GROUP_KEY: 'ag-Grid-AutoColumn',
        PLANT_SID: 'plantSid',
        COUNTRY_NAME: 'countryName',
        TECHNOLOGY_NAME: 'technologyName',
    } as const;

    private tableHeaderFilters = {
        pageSize: 20,
        searchTerm: '',
    };

    private gridApi: GridApi<UnitRowModel> | null = null;
    private isFormSubmitting = false;
    private isFormSubmitted = false;

    private isEditable = false;

    private modifiedUnits: Record<string, boolean> = {};

    private selectedSitesCount = 0;
    private selectedUnitsCount = 0;

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    private gridOptions: GridOptions<UnitRowModel> = {
        rowModelType: 'clientSide',
        domLayout: 'autoHeight',
        columnDefs: [
            {
                field: this.COLUMN_KEYS.PLANT_SID,
                rowGroup: true,
                hide: true,
                valueGetter: (params: ValueGetterParams<UnitRowModel>): string => params.data?.plantName ?? '',
            },
            {
                headerName: this.$t('setup.scenario.selectUnitForm.headers.countryName'),
                field: this.COLUMN_KEYS.COUNTRY_NAME,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                },
                aggFunc: (params: IAggFuncParams<UnitRowModel>): string => {
                    if (params.values.length > 0) {
                        return params.values[0];
                    }

                    return '';
                }
            },
            {
                headerName: this.$t('setup.scenario.selectUnitForm.headers.technologyName'),
                field: this.COLUMN_KEYS.TECHNOLOGY_NAME,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    refreshValuesOnOpen: true,
                },
                aggFunc: (params: IAggFuncParams<UnitRowModel>): any => {
                    if (params.values.length > 0) {
                        return {
                            toString(): string {
                                return params.values[0];
                            }
                        }
                    }
                }
            },
        ],
        autoGroupColumnDef: {
            headerCheckboxSelection: true,
            headerName: this.$t('setup.scenario.selectUnitForm.headers.groupColumn'),
            cellRenderer: 'agGroupCellRenderer',
            valueGetter: (params): string => (params.node?.level === 0 ? params.data?.plantName : params.data?.unitName) ?? '',
            menuTabs: [],
            cellRendererParams: {
                suppressCount: true,
                checkbox: true,
                innerRenderer: 'InnerUnitGroupCell'
            },
        },
        defaultColDef: {
            menuTabs: ['filterMenuTab'],
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        groupSelectsChildren: true,
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        paginateChildRows: false,
        suppressAggFuncInHeader: true,
        loadingOverlayComponent: 'LoadingOverlay',
        getRowId: (params) => params.data.unitSid ? params.data.unitSid.toString() : params.data.plantSid.toString(),
        isRowSelectable: (node: RowNode<UnitRowModel>) => node.data?.isRowSelectable ?? false,
        onRowSelected: (event: RowSelectedEvent<UnitRowModel>) => {
            if (event.node.level === 1 && event.node.data?.unitSid) {
                this.setSelectionOnUnitLocally(event.node.data.unitSid, event.node.isSelected() ?? false);
            }

            this.computeModifiedCounts();
        },
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
    }

    private get currentScenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private get shouldSaveOnRouteLeave(): boolean {
        return !this.isFormSubmitted && this.isEditable;
    }

    private async fetchData(): Promise<void> {
        try {
            const result = (await this.unitService.list(this.currentScenarioId, undefined, undefined, 1, 100, undefined, undefined)).result;
            const plants = result.plants ?? [];

            this.isEditable = result.isEditable;
            this.selectedSitesCount = result.sitesCount;
            this.selectedUnitsCount = result.unitsCount;

            const rows: UnitRowModel[] = [];

            plants.forEach(plant => {
                const plantUnits = plant.units ?? [];
                rows.push(...plantUnits.map(unit => ({
                    plantSid: plant.plantSid,
                    plantName: plant.plantName ?? '',
                    isPlantInitiallySelected: plant.isSelected,
                    countryName: plant.countryName ?? '',
                    technologyName: plant.technologyName ?? '',
                    unitSid: unit.machineSid,
                    unitName: unit.unitName ?? '',
                    isUnitInitiallySelected: unit.isSelected,
                    isRowSelectable: result.isEditable,
                })));
            });

            this.gridApi?.setRowData(rows);
            this.reconcilePreselectedRows();
            this.computeModifiedCounts();
        } catch (err) {
            this.gridApi?.showNoRowsOverlay();
        }
    }

    private computeModifiedCounts(): void {
        if (!this.gridApi || !this.isEditable) {
            return;
        }

        let fullySelectedSitesCount = 0;
        let selectedUnitsCount = 0;

        this.gridApi.forEachNode(node => {
            if (node.level === 0 && node.isSelected()) {
                fullySelectedSitesCount += 1;
            }

            if (node.level === 1 && node.isSelected()) {
                selectedUnitsCount += 1;
            }
        });

        this.selectedSitesCount = fullySelectedSitesCount;
        this.selectedUnitsCount = selectedUnitsCount;
    }

    private onGridReady(event: GridReadyEvent<UnitRowModel>): void {
        event.api.sizeColumnsToFit();

        this.gridApi = event.api;

        this.gridApi.showLoadingOverlay();
        this.fetchData().then(() => {
            event.api.hideOverlay();
        });
    }

    private setModifiedUnit(unitSid: number, selected: boolean): void {
        this.$set(this.modifiedUnits, unitSid.toString(), selected);
    }

    private getModifiedUnit(unitSid: number): boolean | undefined {
        return this.modifiedUnits[unitSid.toString()];
    }

    private setSelectionOnUnitLocally(unitSid: number, selected: boolean): void {
        this.setModifiedUnit(unitSid, selected);
    }

    private reconcilePreselectedRows(): void {
        if (!this.gridApi) {
            return;
        }

        this.gridApi.forEachNode((node) => {
            if (!node.data) {
                return;
            }

            if (node.data.isUnitInitiallySelected) {
                node.setSelected(true);
            }
        });
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableHeaderFilters.searchTerm = searchTerm;
        this.gridApi?.setQuickFilter(searchTerm);
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
    }

    private onClearSearchTerm(): void {
        this.tableHeaderFilters.searchTerm = '';
        this.gridApi?.setQuickFilter('');
    }

    private async submitForm(saveAsDraft = false): Promise<boolean> {
        this.isFormSubmitting = true;

        const units: UnitAddDeleteDto[] = [];
        Object.keys(this.modifiedUnits).forEach(key => {
            const machineSid = Number(key);
            const isSelected = this.modifiedUnits[key];

            units.push(new UnitAddDeleteDto({ isSelected, machineSid }));
        });

        if (saveAsDraft && units.length === 0) {
            this.isFormSubmitting = false;
            return true;
        }

        const payload = new AddUnitsRequest({
            scenarioId: this.currentScenarioId,
            saveAsDraft,
            units
        });

        try {
            await this.unitService.addUnit(payload);
            this.isFormSubmitting = false;
            this.isFormSubmitted = true;
            return true;
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
        } finally {
            this.isFormSubmitting = false;
        }

        return false;
    }

    private async backButtonClicked(): Promise<void> {
        if (this.isEditable && !(await this.submitForm(true))) {
            return;
        }

        await this.pushToStep(SCENARIO_FORM_STEP.SETUP);
    }

    private async saveAsDraftButtonClicked(): Promise<void> {
        if (this.isEditable && !(await this.submitForm(true))) {
            return;
        }

        await this.pushToScenarioOverview();
    }

    private async nextButtonClicked(): Promise<void> {
        if (this.isEditable && !(await this.submitForm())) {
            return;
        }

        await this.$store.dispatch('scenario/increaseStepIfNeeded', SCENARIO_FORM_STEP.FORMULA);
        await this.pushToStep(SCENARIO_FORM_STEP.FORMULA);
    }
}
</script>

<style lang="scss" scoped>
.unit-step {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    &__selection-counts {
        display: flex;
        flex-direction: row;
        gap: 1.6rem;
        justify-content: flex-end;

        &__text {
            font-size: 1.4rem;
            font-weight: bold;
            color: $dark-blue-lighter;
        }
    }

    &__table-wrapper {
        width: 100%;
        @include pui-box();
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
    }
}
</style>
