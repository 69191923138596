var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inflation-table-wrapper"
  }, [_c('table-header', {
    attrs: {
      "hide-search-bar": true,
      "page-size": _vm.tableHeaderFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }