var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner",
    style: [{
      'min-height': _vm.minHeight
    }]
  }, [_vm.isLoading ? _c('pui-loader-spinner', {
    attrs: {
      "size": _vm.size,
      "title": _vm.title,
      "min-height": _vm.minHeight
    }
  }) : [_vm.isResolved ? _vm._t("default") : _vm._e(), _vm.isError ? _vm._t("error-state", function () {
    return [_c('div', {
      class: ['spinner__error-state', {
        'spinner__error-state--inline': _vm.isInlineError
      }]
    }, [_c('div', {
      class: ['spinner__error-icon', {
        'spinner__error-icon--inline': _vm.isInlineError
      }]
    }, [_c('pui-icon', {
      attrs: {
        "icon-color": _vm.ERROR_STATE_ICON_COLOR,
        "icon-name": "data-unavailable",
        "size": "24px"
      }
    })], 1), _c('div', {
      class: ['spinner__error-message', {
        'spinner__error-icon--inline': _vm.isInlineError
      }]
    }, [_vm._v(" " + _vm._s(_vm.$t('dataUnavailable')) + " ")])])];
  }) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }