var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-tabs"
  }, [_vm.hasNoScenario ? _c('pui-loader-error', {
    staticClass: "sc-tabs__loader-error",
    attrs: {
      "title": _vm.$t('results.scenarioCompare.errors.twoScenarios.title'),
      "message": _vm.$t('results.scenarioCompare.errors.twoScenarios.message'),
      "icon": "error-empty-data"
    }
  }) : [_c('pui-tabs', {
    staticClass: "sc-tabs__tabs",
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('results.scenarioCompare.tabs.primaryKpi')
    }
  }, [_c('div', {
    staticClass: "sc-tabs__tabs__content"
  }, [_c('scenario-compare-table', {
    attrs: {
      "is-primary-kpi-data": true
    }
  })], 1)]), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('results.scenarioCompare.tabs.secondaryKpi')
    }
  }, [_c('div', {
    staticClass: "sc-tabs__tabs__content"
  }, [_c('scenario-compare-table', {
    attrs: {
      "is-primary-kpi-data": false
    }
  })], 1)])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }