<template>
    <div class="fc-form-exclusions">
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.exclusions.title') }}
        </pui-headline>
        <template v-for="exclusion in exclusions">
            <div :key="exclusion.internalId">
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionFrom')"
                        :is-valid="!exclusion.errorMessage"
                        v-pui-form-grid-column="3"
                    >
                        <pui-form-select
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionFrom')"
                            :options="mtpYearOptions"
                            :is-valid="!exclusion.errorMessage"
                            :value="exclusion.fromYear"
                            @change="onFromYearChange($event, exclusion)"
                        />
                        <template #error-message>
                            {{ exclusion.errorMessage ?? '' }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionPercentage')"
                        v-pui-form-grid-column="2"
                    >
                        <pui-form-input-field
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionPercentage')"
                            :value="exclusion.percentage.toString()"
                            @input="onPercentageChange($event, exclusion)"
                        />
                    </pui-form-group>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionAmount')"
                        v-pui-form-grid-column="2"
                    >
                        <pui-form-input-field
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.exclusions.labels.exclusionAmount')"
                            :is-disabled="true"
                            :value="formatExclusionAmount(exclusion.amount)"
                        />
                    </pui-form-group>
                    <pui-button
                        :small="true"
                        icon="delete"
                        class="fc-form-exclusions__remove-button"
                        @click="emitExclusionRemoval(exclusion.internalId)"
                    >
                        {{ $t('setup.scenario.ceForm.tables.flagged.form.exclusions.buttons.removeExclusion') }}
                    </pui-button>
                </div>
            </div>
        </template>
        <pui-button
            :small="true"
            icon="add"
            state="primary"
            class="fc-form-exclusions__add-button"
            @click="addNewExclusion"
        >
            {{ $t('setup.scenario.ceForm.tables.flagged.form.exclusions.buttons.addExclusion') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFormCostModification, OperationType } from '@/models/steps/cost-correction';
import { ViewResponse } from '@/service-proxies/service-proxies.g';
import { PuiSelectOption } from '@/models/pebble-ui';
import {
    computeExclusionAmount
} from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-computations';
import Decimal from 'decimal.js';

@Component({})
export default class FlaggedCostsFormExclusions extends Vue {
    @Prop({ required: true })
    private budgetRequest!: ViewResponse;

    @Prop({ required: true, default: () => [] })
    private costModifications!: IFormCostModification[];

    private hasTriggeredValidation = false;

    private get exclusions(): IFormCostModification[] {
        return this.costModifications.filter(e => e.costModificationType === OperationType.EXCLUSION);
    }

    private get mtpYear(): number {
        return this.$store.getters['scenario/getScenario']?.mtpYear ?? 0;
    }

    private get years(): number[] {
        return [this.mtpYear, this.mtpYear + 1, this.mtpYear + 2, this.mtpYear + 3];
    }

    private get mtpYearOptions(): PuiSelectOption<number>[] {
        return this.years.map(e => ({
            label: e.toString(),
            value: e,
        }));
    }

    private get yearBudgetKeyMap(): Record<number, 'forecast' | 'new1' | 'new2' | 'new3'> {
        return {
            [this.mtpYear]: 'forecast',
            [this.mtpYear + 1]: 'new1',
            [this.mtpYear + 2]: 'new2',
            [this.mtpYear + 3]: 'new3'
        };
    }

    private mounted(): void {
        this.updateExclusionAmounts();
    }

    private validateExclusion(exclusion: IFormCostModification): void {
        if (!exclusion.fromYear) {
            this.emitExclusionChange({
                ...exclusion,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.error.fromYearRequired'),
            });
            return;
        }

        if (this.exclusions.filter(e => e.fromYear === exclusion.fromYear).length > 1) {
            this.emitExclusionChange({
                ...exclusion,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.exclusions.error.multipleExclusions'),
            });
            return;
        }

        this.emitExclusionChange({
            ...exclusion,
            errorMessage: undefined,
        });
    }

    public async validateForm(): Promise<boolean> {
        return new Promise((resolve) => {
            this.hasTriggeredValidation = true;
            this.exclusions.forEach(this.validateExclusion);

            this.$nextTick(() => {
                resolve(this.exclusions.filter(e => !!e.errorMessage).length === 0);
            });
        });
    }

    private onFromYearChange(newFromYear: number, exclusion: IFormCostModification): void {
        this.emitExclusionChange({
            ...exclusion,
            fromYear: newFromYear,
        });

        this.$nextTick(() => {
            this.updateExclusionAmounts();
        });
    }

    private onPercentageChange(newPercentage: string, exclusion: IFormCostModification): void {
        const parsedValue = parseFloat(newPercentage);

        this.emitExclusionChange({
            ...exclusion,
            percentage: isNaN(parsedValue) ? 0 : parsedValue,
        });

        this.$nextTick(() => {
            this.updateExclusionAmounts();
        });
    }

    private updateExclusionAmounts(): void {
        this.exclusions
            .filter(e => !!this.yearBudgetKeyMap[e.fromYear])
            .forEach(e => {
                const amount = computeExclusionAmount(new Decimal(this.budgetRequest[this.yearBudgetKeyMap[e.fromYear]]), e.percentage);

                this.emitExclusionChange({
                    ...e,
                    amount,
                });
            });

        this.$nextTick(() => {
            if (this.hasTriggeredValidation) {
                this.exclusions.forEach(this.validateExclusion);
            }
        });
    }

    private formatExclusionAmount(amount: number): string {
        return amount.toString();
    }

    private getNewCostModificationId(): number {
        const maxId = Math.max(...this.costModifications.map(e => e.internalId), 1);
        return maxId + 1;
    }

    private addNewExclusion(): void {
        this.emitExclusionChange({
            id: -1,
            internalId: this.getNewCostModificationId(),
            fromYear: 0,
            toYear: undefined,
            percentage: 0,
            amount: new Decimal(0),
            costModificationType: OperationType.EXCLUSION,
            budgetRequestExclusionsId: this.budgetRequest.id,
        });
    }

    private emitExclusionChange(newFormModification: IFormCostModification): void {
        this.$emit('change:form-modification', newFormModification);
    }

    private emitExclusionRemoval(internalId: number): void {
        this.$emit('remove:form-modification', internalId);
    }
}
</script>

<style lang="scss">
.fc-form-exclusions {
    &__error-notification {
        @include rem(margin-top, pui-spacing(s));
    }

    &__add-button {
        @include rem(margin-top, pui-spacing(s));
    }

    &__remove-button {
        @include rem(margin-top, pui-spacing(xxl));
    }

    .pui-form-grid-row {
        padding: 0 !important;
    }
}
</style>
