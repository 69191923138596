var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin-overview"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('dataManagement.overview.title')))]), _c('div', {
    staticClass: "admin-overview__header"
  }, [_c('pui-tabs', {
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": _vm.$t('admin.tabs.scenario')
    }
  }, [_c('admin-scenario-tab')], 1), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('admin.tabs.kpiType')
    }
  }, [_c('admin-kpi-type-tab', {
    ref: _vm.REF_CONSTANTS.KPI_TYPE
  })], 1), _c('pui-tab', {
    attrs: {
      "title": _vm.$t('admin.tabs.uom')
    }
  }, [_c('admin-uom-tab', {
    ref: _vm.REF_CONSTANTS.UOM
  })], 1), _c('div', {
    attrs: {
      "slot": "action-bar"
    },
    slot: "action-bar"
  }, [_c('pui-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.ADMIN_OVERVIEW_TAB.KPI_TYPE,
      expression: "selectedTabIndex === ADMIN_OVERVIEW_TAB.KPI_TYPE"
    }],
    attrs: {
      "state": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleOpenConstantsLightbox(_vm.AdminModalMode.KPI_TYPE);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('admin.actions.addNewKpiType')) + " ")]), _c('pui-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTabIndex === _vm.ADMIN_OVERVIEW_TAB.UOM,
      expression: "selectedTabIndex === ADMIN_OVERVIEW_TAB.UOM"
    }],
    attrs: {
      "state": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleOpenConstantsLightbox(_vm.AdminModalMode.UOM);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('admin.actions.addNewUOM')) + " ")])], 1)], 1)], 1), _c('constants-lightbox', {
    attrs: {
      "current-mode": _vm.currentModalMode,
      "lightbox-open": _vm.isConstantsLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleCloseConstantsLightbox
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }