<template>
    <div class="kpi-analysis-scenario-selector">
        <pui-form-group
            :label="$t('results.kpiAnalysis.selector.selectScenario')"
            :cta-label="$t('form.clear')"
            :has-cta="true"
            :cta-callback="clearScenario"
            :show-required-label="true"
            :has-label-padding="false"
            label-for="selectScenario"
        >
            <benchmarking-type-ahead
                :ref="REF_CONSTANTS.TYPEAHEAD"
                :value="selectedScenarioOption"
                :placeholder="$t('results.kpiAnalysis.selector.selectPlaceholder')"
                :request-promise-generator="scenariosRequestPromiseGenerator"
                @input="onSelectedScenarioChange"
            />
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { EngineRunServiceProxy, IScenarioRunDto } from '@/service-proxies/service-proxies.g';
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import BenchmarkingTypeAhead from '@/components/inputs/benchmarking-type-ahead/benchmarking-type-ahead.vue';
import { SCENARIO_STATUS } from '@/config/status';

const REF_CONSTANTS = {
    TYPEAHEAD: 'typeahead',
} as const;

@Component({
    components: {BenchmarkingTypeAhead}
})
export default class KpiAnalysisScenarioSelector extends Vue {
    private readonly engineRunService = new EngineRunServiceProxy();
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.TYPEAHEAD]?: BenchmarkingTypeAhead,
    };

    mounted(): void {
        this.onSelectedScenarioChange(this.selectedScenarioOption ?? undefined);
    }

    private get selectedScenarioOption(): PuiSelectOption<number> | null {
        const selectedScenario: IScenarioRunDto = this.$store.getters['results/getSelectedScenario'];

        return selectedScenario ? {
            label: selectedScenario.name ?? '-',
            value: selectedScenario.id,
            extraData: selectedScenario,
        } : null;
    }

    private onSelectedScenarioChange(newSelectedScenario: PuiSelectOption<number> | undefined): void {
        this.$store.commit('results/setSelectedScenario', newSelectedScenario?.extraData);

        this.$emit('change:scenario-id', newSelectedScenario?.value);
        this.$emit('change:years', [newSelectedScenario?.extraData.startYear, newSelectedScenario?.extraData.endYear])
    }

    private async scenariosRequestPromiseGenerator(query: string, signal: AbortSignal): Promise<PuiSelectOptions<number>> {
        const statuses = [
            Number(SCENARIO_STATUS.ENGINE_RUN_COMPLETED),
            Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)
        ];

        const response = await this.engineRunService.list7(undefined, undefined, statuses, 1, 20, query, undefined, signal);
        return response.result.items?.map(e => ({
            label: e.name ?? '',
            value: e.id,
            extraData: e,
        })) ?? [];
    }

    private clearScenario(): void {
        this.$refs.typeahead?.clearInput();
    }
}
</script>
