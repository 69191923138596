var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-analysis-display"
  }, [_vm.isKpiAnalysisLoading ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise,
      "title": _vm.$t('results.kpiAnalysis.loader.title'),
      "message": _vm.$t('results.kpiAnalysis.loader.message')
    }
  }) : _vm.noDataLoaded || !_vm.hasAllParametersForKpiAnalysis ? _c('pui-loader-error', {
    attrs: {
      "title": _vm.$t('results.kpiAnalysis.errors.title'),
      "message": _vm.$t('results.kpiAnalysis.errors.message'),
      "icon": "error-empty-data"
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.noDataLoaded && _vm.hasAllParametersForKpiAnalysis && !_vm.isKpiAnalysisLoading,
      expression: "!noDataLoaded && hasAllParametersForKpiAnalysis && !isKpiAnalysisLoading"
    }],
    ref: _vm.REF_CONSTANTS.CHART,
    staticClass: "kpi-analysis-display__chart"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }