<template>
    <pui-loader :promise="initialPromise">
        <div class="fc-form">
            <flagged-costs-form-overview
                :budget-request="budgetRequest"
                :cost-modifications="costModifications"
            />
            <pui-form-group
                :label="$t('setup.scenario.ceForm.tables.flagged.form.justification')"
                :has-label-padding="false"
                v-pui-form-grid-column="11"
            >
                <pui-form-textarea
                    v-model="justification"
                />
            </pui-form-group>
            <flagged-costs-form-exclusions
                :ref="REF_CONSTANTS.EXCLUSIONS_SECTION"
                :budget-request="budgetRequest"
                :cost-modifications="costModifications"
                @change:form-modification="handleFormModificationChange"
                @remove:form-modification="handleFormModificationRemove"
            />
            <flagged-costs-form-corrections
                :ref="REF_CONSTANTS.CORRECTIONS_SECTION"
                :budget-request="budgetRequest"
                :cost-modifications="costModifications"
                @change:form-modification="handleFormModificationChange"
                @remove:form-modification="handleFormModificationRemove"
            />
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    BudgetRequestServiceProxy,
    CostModificationDto,
    EditBudgetsRequest,
    ViewResponse
} from '@/service-proxies/service-proxies.g';
import FlaggedCostsFormOverview
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-overview.vue';
import FlaggedCostsFormExclusions
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-exclusions.vue';
import FlaggedCostsFormCorrections
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-corrections.vue';
import { IFormCostModification } from '@/models/steps/cost-correction';
import Decimal from 'decimal.js';

const REF_CONSTANTS = {
    EXCLUSIONS_SECTION: 'exclusions',
    CORRECTIONS_SECTION: 'corrections',
} as const;

@Component({
    components: {
        FlaggedCostsFormOverview,
        FlaggedCostsFormExclusions,
        FlaggedCostsFormCorrections,
    }
})
export default class FlaggedCostsForm extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly budgetRequestService = new BudgetRequestServiceProxy();

    @Prop({ required: true })
    private budgetRequestId!: number;

    private initialPromise: Promise<any> | null = null;

    private budgetRequest: ViewResponse | null = null;
    private costModifications: IFormCostModification[] = [];

    private justification = '';

    $refs!: {
        [REF_CONSTANTS.EXCLUSIONS_SECTION]: FlaggedCostsFormExclusions,
        [REF_CONSTANTS.CORRECTIONS_SECTION]: FlaggedCostsFormCorrections,
    };

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private mounted(): void {
        this.initialPromise = Promise.all([
            this.budgetRequestService.viewBudget(this.budgetRequestId)
                .then(response => {
                    this.budgetRequest = response.result;
                    this.justification = response.result.justification ?? '';
                    this.emitNewGlobalId(this.budgetRequest.globalId ?? '');

                    const costModificationDtos = this.budgetRequest.costModifications ?? [];
                    this.costModifications = [];
                    for (let i = 0; i < costModificationDtos.length; i++) {
                        this.costModifications.push({
                            ...costModificationDtos[i],
                            internalId: i,
                            amount: new Decimal(costModificationDtos[i].amount),
                        });
                    }
                })
        ]);
    }

    private emitNewGlobalId(globalId: string): void {
        this.$emit('change:global-id', globalId)
    }

    private handleFormModificationChange(changedFormModification: IFormCostModification): void {
        if (this.costModifications.find(e => e.internalId === changedFormModification.internalId)) {
            this.costModifications = [...this.costModifications.filter(e => e.internalId !== changedFormModification.internalId), changedFormModification];
        } else {
            this.costModifications = [...this.costModifications, changedFormModification];
        }

        this.costModifications.sort((a, b) => a.internalId - b.internalId);
    }

    private handleFormModificationRemove(removedInternalId: number): void {
        this.costModifications = this.costModifications.filter(e => e.internalId !== removedInternalId);
    }

    public async submitForm(): Promise<any> {
        if (!(await this.$refs.exclusions.validateForm()) || !(await this.$refs.corrections.validateForm())) {
            return false;
        }

        const costModifications = this.costModifications.map(e => new CostModificationDto({
            ...e,
            amount: e.amount.toNumber(),
        }));

        this.initialPromise = this.budgetRequestService.editBudget(new EditBudgetsRequest({
            scenarioId: this.scenarioId,
            budgetRequestExclusionId: this.budgetRequestId,
            justification: this.justification,
            costModifications,
        })).then(() => {
            this.$emit('close:form', true);
            return Promise.resolve(true);
        }).catch(() => {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
            return Promise.resolve(false);
        });

        return this.initialPromise;
    }
}
</script>

<style scoped lang="scss">
.fc-form {
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    padding-bottom: 15rem;
}
</style>
