<template>
    <div class="fc-form-year-display">
        <template v-for="(value, key, index) in values">
            <div
                :key="`${key}_display`"
                :class="[
                    {
                        'fc-form-year-display__cell--empty': value === 0,
                        'fc-form-year-display__cell--negative': value < 0,
                        'fc-form-year-display__cell--positive': value > 0
                    },
                    'fc-form-year-display__cell'
                ]"
            >
                <pui-headline
                    type="h5"
                    class="fc-form-year-display__cell__year"
                >
                    {{ key }}
                </pui-headline>
                <pui-headline type="h5">
                    {{ value | formatBudgetValue }}
                </pui-headline>
            </div>
            <div
                v-if="index !== Object.keys(values).length - 1"
                :key="`${key}_separator`"
                class="fc-form-year-display__spacer"
            />
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Decimal from 'decimal.js';

@Component({
    filters: {
        formatBudgetValue(input: Decimal): string {
            return input.toString();
        }
    }
})
export default class FlaggedCostsFormOverviewYearDisplay extends Vue {
    @Prop({ required: true, default: {} })
    private values!: Record<number, Decimal>;
}
</script>

<style scoped lang="scss">
.fc-form-year-display {
    @include rem(margin-top, pui-spacing(s));
    @include rem(gap, pui-spacing(s));

    display: flex;

    &__cell {
        margin-right: 2.5rem;

        &__year {
            color: $grey-light;
        }

        &--empty {
            color: $grey-light;
        }

        &--positive {
            color: $black;
        }

        &--negative {
            color: $red-darker;
        }
    }

    &__spacer {
        flex-grow: 1;
        max-width: 5rem;
    }
}
</style>
