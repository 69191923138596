import { Module } from 'vuex';
import { IScenarioRunDto, IYearComparisonDto, RunResultServiceProxy } from '@/service-proxies/service-proxies.g';

const runResultService = new RunResultServiceProxy();

type ScenarioCompareDetailsModuleState = {
    items: IYearComparisonDto[];
};

const initialState: ScenarioCompareDetailsModuleState = {
    items: [],
};

const ScenarioCompareDetailsModule: Module<ScenarioCompareDetailsModuleState, any> = {
    state: initialState,

    mutations: {
        setItems(state, payload: IYearComparisonDto[]): void {
            state.items = payload;
        },
        resetItems(state): void {
            state.items = [];
        },
    },

    actions: {
        async fetchScenarioCompareDetails({ rootGetters, commit }, { machineSid, kpi }: { machineSid: number, kpi: string }): Promise<void> {
            if (rootGetters['results/scenarioCompare/areScenariosNotSelected']) {
                return;
            }

            try {
                const firstScenario: IScenarioRunDto = rootGetters['results/scenarioCompare/getFirstScenario'];
                const secondScenario: IScenarioRunDto = rootGetters['results/scenarioCompare/getSecondScenario'];

                const response = await runResultService.scenarioCompareDetails(firstScenario.id, secondScenario.id, machineSid, kpi);
                commit('setItems', response.result.items ?? []);
            } catch (err) {
                commit('resetItems');

                throw err;
            }
        },
    },

    getters: {
        getItems(state): IYearComparisonDto[] {
            return state.items;
        },
    },


    namespaced: true,
};

export default ScenarioCompareDetailsModule;

