import { render, staticRenderFns } from "./flagged-costs-no-rows-overlay.vue?vue&type=template&id=3900f5ef&scoped=true"
import script from "./flagged-costs-no-rows-overlay.vue?vue&type=script&lang=ts"
export * from "./flagged-costs-no-rows-overlay.vue?vue&type=script&lang=ts"
import style0 from "./flagged-costs-no-rows-overlay.vue?vue&type=style&index=0&id=3900f5ef&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3900f5ef",
  null
  
)

export default component.exports