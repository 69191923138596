import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=34481a42&scoped=true"
import script from "./loader.vue?vue&type=script&lang=ts"
export * from "./loader.vue?vue&type=script&lang=ts"
import style0 from "./loader.vue?vue&type=style&index=0&id=34481a42&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34481a42",
  null
  
)

export default component.exports