<template>
    <div class="setup-create-scenario">
        <pui-breadcrumb
            :links="breadcrumbLinks"
            @changed:active-route="onActiveRouteChanged"
        />
        <div class="setup-create-scenario__card">
            <template v-if="!isCurrentLoaderStep">
                <div class="setup-create-scenario__card__step-display">
                    <step-display :steps="steps" />
                </div>
                <div class="setup-create-scenario__card__separator" />
            </template>
            <pui-loader :promise="initialPromise">
                <router-view />
            </pui-loader>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import StepDisplay, { StepItem } from '@/components/step-display/step-display.vue';
import { PuiBreadcrumbLinkItem, PuiNavigationToolbarClickEvent } from '@/models/pebble-ui';
import { IGetScenarioResponse } from '@/service-proxies/service-proxies.g';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import ScenarioStepNavigation from '@/mixins/scenario-step-navigation';

export type StepConfiguration = {
    [key: string]: {
        step: SCENARIO_FORM_STEP;
        title?: string;
        isLoaderStep?: boolean;
    }
}

@Component({
    components: {
        StepDisplay
    }
})
export default class SetupCreateScenarioPage extends mixins(ManagedAbortControllers, ScenarioStepNavigation) {
    private readonly STEP_CONFIGURATION: StepConfiguration = {
        'INITIAL_LOADER': {
            isLoaderStep: true,
            step: SCENARIO_FORM_STEP.INITIAL_LOADER,
        },
        'SELECT_SETUP': {
            title: this.$t('setup.scenario.steps.selectSetup'),
            step: SCENARIO_FORM_STEP.SETUP,
        },
        'SELECT_UNIT': {
            title: this.$t('setup.scenario.steps.selectUnit'),
            step: SCENARIO_FORM_STEP.UNIT,
        },
        'SELECT_FORMULA': {
            title: this.$t('setup.scenario.steps.selectFormula'),
            step: SCENARIO_FORM_STEP.FORMULA,
        },
        'INFLATION': {
            title: this.$t('setup.scenario.steps.inflation'),
            step: SCENARIO_FORM_STEP.INFLATION,
        },
        'SELECT_MTC': {
            title: this.$t('setup.scenario.steps.selectMtc'),
            step: SCENARIO_FORM_STEP.MTC,
        },
        'COST_EXCLUSION': {
            title: this.$t('setup.scenario.steps.costExclusion'),
            step: SCENARIO_FORM_STEP.COST_EXCLUSION,
        },
        'PRIMARY_KPI': {
            title: this.$t('setup.scenario.steps.primaryKpi'),
            step: SCENARIO_FORM_STEP.PRIMARY_KPI,
        },
        'CONFIRMATION_SCREEN': {
            isLoaderStep: true,
            step: SCENARIO_FORM_STEP.CONFIRMATION,
        },
    } as const;

    @Prop()
    private id!: string;

    private initialPromise: Promise<any> | null = null;

    private get existingScenario(): IGetScenarioResponse | undefined {
        return this.$store.getters['scenario/getScenario'];
    }

    private get breadcrumbLinks(): PuiBreadcrumbLinkItem[] {
        const links: PuiBreadcrumbLinkItem[] = [
            { label: this.$t('setup.scenario.breadcrumbs.setup'), href: 'SetupOverview' },
        ];

        if (this.existingScenario) {
            links.push({
                label: this.$t('setup.scenario.breadcrumbs.editExistingScenario', { scenarioName: this.existingScenario.name ?? '' }),
                href: '',
            });
        } else {
            links.push({
                label: this.$t('setup.scenario.breadcrumbs.addNewScenario'),
                href: '',
            });
        }

        return links;
    }

    private get steps(): StepItem[] {
        return Object.keys(this.STEP_CONFIGURATION)
            .filter(e => !this.STEP_CONFIGURATION[e].isLoaderStep)
            .map(e => this.STEP_CONFIGURATION[e])
            .map(e => {
                const currentRouteName = SCENARIO_FORM_STEP_ROUTE_MAP[e.step];

                return ({
                    text: e.title ?? '',
                    isActive: this.$route.name === currentRouteName,
                    href: currentRouteName,
                    isClickable: this.isClickable(e.step ?? 0, this.existingScenario?.step ?? 0),
                });
            });
    }

    private get isCurrentLoaderStep(): boolean {
        return this.$route.name === SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER];
    }

    private get isDestinationRouteAllowedForNewStep(): boolean {
        const allowedRoutes: string[] = [
            SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
            SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.SETUP],
        ];

        return allowedRoutes.includes(this.$route.name ?? '');
    }

    private get scenarioId(): number | undefined {
        const parsedScenarioId = Number(this.id);
        return isNaN(parsedScenarioId) ? undefined : parsedScenarioId;
    }

    private mounted(): void {
        this.initialPromise = this.$store.dispatch('constants/fetchAllConstants');

        if (this.scenarioId) {
            this.initialPromise = this.initialPromise.then(() => this.$store.dispatch('scenario/clearScenario'))
                .then(() => this.$store.dispatch('scenario/fetchScenario', {
                    id: this.scenarioId,
                    isDuplicating: !!this.$route.query.isDuplicating,
                    signal: this.getSignal('initialLoaderScenario'),
                }));

            return;
        }

        this.initialPromise.then(() => {
            if (!this.isDestinationRouteAllowedForNewStep) {
                this.pushToStep(SCENARIO_FORM_STEP.SETUP);
            }
        });
    }

    private isClickable(step: number, scenarioStep: number): boolean {
        return step <= scenarioStep;
    }

    private onActiveRouteChanged(event: PuiNavigationToolbarClickEvent): void {
        if (!event.href) {
            return;
        }

        this.$router.push({ name: event.href });
    }
}
</script>

<style scoped lang="scss">
.setup-create-scenario {
    @include rem(gap, pui-spacing(s));

    display: flex;
    flex-direction: column;
    padding: pui-spacing(s) pui-spacing(l) 15rem;

    &__heading {
        display: flex;
        justify-content: space-between;
    }

    &__card {
        @include pui-box();
        background-color: $white;

        &__step-display {
            padding: pui-spacing(m) pui-spacing(l) 0;
        }

        &__separator {
            margin: 0 pui-spacing(l);
            height: 0.1rem;
            background-color: $warm-grey;
        }
    }
}
</style>
