var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "er-units-list-wrapper"
  }, [_vm.filteredUnitsCount !== 0 ? _vm._l(_vm.filteredUnits, function (unit, index) {
    return _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.tooltipContent(unit),
        expression: "tooltipContent(unit)"
      }],
      key: unit.unitSid
    }, [index !== 0 ? _c('span', [_vm._v(", ")]) : _vm._e(), _c('span', {
      class: ['er-units-list-wrapper__unit-sid', {
        'er-units-list-wrapper__unit-sid--clickable': _vm.hasCompleteKpiDataForAnalysis
      }],
      on: {
        "click": function ($event) {
          return _vm.unitClicked(unit);
        }
      }
    }, [_vm._v(_vm._s(unit.unitSid))])]);
  }) : [_vm._v(" - ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }