var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-data-cell",
    style: _vm.dataCellStyle,
    on: {
      "mouseenter": function ($event) {
        _vm.isHovering = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovering = false;
      },
      "click": _vm.onCellClicked
    }
  }, [_vm.isHovering ? _c('div', {
    staticClass: "sc-data-cell__text"
  }, [_vm._v(" " + _vm._s(_vm.formattedPercentageValue) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }