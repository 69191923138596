<template>
    <div class="tooltip-wrapper">
        <pui-link
            :title="title"
            variant="primary"
            @click="sendToLink"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface LinkCell extends ICellRendererParams {
    title?: string,
    id?: number,
    routerName: string,
}

@Component({})
export default class LinkCellComponent extends Vue {
    private params?: LinkCell;

    private get title(): string {
        return this.params?.title ?? '-';
    }

    private get id(): number | undefined {
        return this.params?.id;
    }

    private get routerName(): string | undefined {
        return this.params?.routerName;
    }

    private sendToLink(): void {
        if (!this.id) {
            return;
        }

        this.$router.push({
            name: this.routerName,
            params: {
                id: this.id.toString(),
            },
        });
    }
}
</script>

<style scoped lang="scss">
</style>
