var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-wrapper"
  }, [_c('pui-navigation-toolbar', {
    attrs: {
      "link-items": _vm.navbarLinks
    },
    on: {
      "clicked:tab": _vm.handleTabClick
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }