var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fc-form-overview"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.overview.before')) + " ")]), _c('flagged-costs-form-overview-year-display', {
    attrs: {
      "values": _vm.beforeValues
    }
  }), _c('div', {
    staticClass: "fc-form-overview__separator"
  }), _c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.overview.afterExclusion')) + " ")]), _c('flagged-costs-form-overview-year-display', {
    attrs: {
      "values": _vm.afterExclusionValues
    }
  }), _c('div', {
    staticClass: "fc-form-overview__separator"
  }), _c('pui-headline', {
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tables.flagged.form.overview.afterCorrection')) + " ")]), _c('flagged-costs-form-overview-year-display', {
    attrs: {
      "values": _vm.afterCorrectionValues
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }