<template>
    <div class="sc-filters">
        <pui-filter
            v-if="filters"
            :use-router="false"
            :config="filterConfiguration"
            :vuex-namespace="namespace"
            @changed:applied-values="filtersChanged"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PuiFilterAppliedValues, PuiFilterConfig, PuiFilterOption } from '@/models/pebble-ui';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { IGetFiltersResponse, ScenarioCompareResponse } from '@/service-proxies/service-proxies.g';
import { ScenarioCompareFilterOptions } from '@/store/modules/results/scenario-compare.module';
import { getNameForScenarioCompateStatus, ScenarioCompareStatus } from '@/config/scenario-compare';

@Component({})
export default class ScenarioCompareFilters extends mixins(ManagedAbortControllers) {
    @Prop()
    private namespace!: string;

    @Prop({ default: () => true })
    private isPrimaryKpi!: boolean;

    private filterConfiguration: PuiFilterConfig = { filters: [] };

    private get filters(): IGetFiltersResponse | undefined {
        return this.$store.getters['results/scenarioCompare/getFilters'];
    }

    private get filterOptions(): ScenarioCompareFilterOptions {
        return this.$store.getters['results/scenarioCompare/getFilterOptions'];
    }

    private get kpiData(): ScenarioCompareResponse | undefined {
        return this.isPrimaryKpi
            ? this.$store.getters['results/scenarioCompare/getPrimaryKpiData']
            : this.$store.getters['results/scenarioCompare/getSecondaryKpiData'];
    }

    private get kpiIdFilterOptions(): PuiFilterOption[] {
        return this.kpiData?.kpis?.map(e => ({
            displayName: e.kpiId ?? '',
            value: e.kpiId ?? '',
        })) ?? [];
    }

    private get reportedUnits(): number[] {
        if (!this.filters) {
            return [];
        }

        return this.filterOptions.units.length > 0
            ? this.filterOptions.units
            : this.unitsFilterOptions.map(e => Number(e.value));
    }

    private get countriesFilterOptions(): PuiFilterOption[] {
        return this.filters?.countries?.map(e => ({
            displayName: e.countryName ?? '',
            value: e.countryId,
        })) ?? [];
    }

    private get plantsFilterOptions(): PuiFilterOption[] {
        let filteredCountries = this.filters?.countries ?? [];

        if (this.filterOptions.countries.length > 0) {
            filteredCountries = filteredCountries.filter(e => this.filterOptions.countries.includes(e.countryId));
        }

        const plants = filteredCountries.flatMap(e => e.plants ?? []);
        return plants.map(e => ({
            displayName: e.plantName ?? '',
            value: e.plantSid,
        }));
    }

    private get unitsFilterOptions(): PuiFilterOption[] {
        let filteredCountries = this.filters?.countries ?? [];

        if (this.filterOptions.countries.length > 0) {
            filteredCountries = filteredCountries.filter(e => this.filterOptions.countries.includes(e.countryId));
        }

        let filteredPlants = filteredCountries.flatMap(e => e.plants ?? []);

        if (this.filterOptions.plants.length > 0) {
            filteredPlants = filteredPlants.filter(e => this.filterOptions.plants.includes(e.plantSid));
        }

        const units = filteredPlants.flatMap(e => e.units ?? []);
        return units.map(e => ({
            displayName: e.unitName ?? '',
            value: e.unitSid,
        }));
    }

    private get kpiTypesFilterOptions(): PuiFilterOption[] {
        if (!this.filters?.kpiTypes) {
            return [];
        }

        return Object.keys(this.filters.kpiTypes).map(key => ({
            displayName: this.filters?.kpiTypes?.[key] ?? '',
            value: Number(key),
        })) ?? [];
    }

    private get statusFilterOptions(): PuiFilterOption[] {
        const scenarioCompareStatuses = [ScenarioCompareStatus.GREEN, ScenarioCompareStatus.RED, ScenarioCompareStatus.ORANGE, ScenarioCompareStatus.GREY];

        return scenarioCompareStatuses.map(e => ({
            displayName: this.$t(getNameForScenarioCompateStatus(e)),
            value: e
        }));
    }

    @Watch('kpiData')
    private onKpiDataChange(): void {
        this.configureFilters();
    }

    @Watch('filters', { immediate: true })
    private onFiltersDataChange(): void {
        this.configureFilters();
        this.$store.dispatch('results/scenarioCompare/setReportedUnits', this.reportedUnits);
    }

    @Watch('filterOptions')
    private onFilterOptionsChange(): void {
        this.configureFilters();
    }

    private hasSameSelectedOptions(appliedValues: any[], selectedOptions: any[]): boolean {
        return appliedValues.every(item => selectedOptions.includes(item))
            && selectedOptions.every(item => appliedValues.includes(item));
    }

    private filtersChanged(appliedValues: PuiFilterAppliedValues): void {
        const hasDifferentCountries = !this.hasSameSelectedOptions(appliedValues['countries'] as number[], this.filterOptions.countries);
        const hasDifferentPlants = !this.hasSameSelectedOptions(appliedValues['plants'] as number[], this.filterOptions.plants);

        const filterOptions: ScenarioCompareFilterOptions = {
            countries: appliedValues['countries'] as number[],
            plants: appliedValues['plants'] as number[],
            units: appliedValues['units'] as number[],
            kpiTypes: appliedValues['kpiTypes'] as number[],
            kpiIds: appliedValues['kpiIds'] as string[],
            status: appliedValues['status'] as number[],
        };

        if (hasDifferentCountries) {
            filterOptions.plants = [];
            filterOptions.units = [];
        }

        if (hasDifferentPlants) {
            filterOptions.units = [];
        }

        this.$store.dispatch('results/scenarioCompare/setFilterOptions', filterOptions)
            .then(() => this.$store.dispatch('results/scenarioCompare/setReportedUnits', this.reportedUnits));
    }

    private configureFilters(): void {
        this.filterConfiguration = {
            filters: [
                {
                    name: 'countries',
                    displayName: this.$t('results.siteView.selector.countries'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.countriesFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.countries,
                    },
                    appliedValues: {
                        options: this.filterOptions.countries,
                    }
                },
                {
                    name: 'plants',
                    displayName: this.$t('results.siteView.selector.plants'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.plantsFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.plants,
                    },
                    appliedValues: {
                        options: this.filterOptions.plants,
                    }
                },
                {
                    name: 'units',
                    displayName: this.$t('results.siteView.selector.units'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.unitsFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.units,
                    },
                    appliedValues: {
                        options: this.filterOptions.units,
                    }
                },
                {
                    name: 'kpiTypes',
                    displayName: this.$t('results.siteView.selector.kpiTypes'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.kpiTypesFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.kpiTypes,
                    },
                    appliedValues: {
                        options: this.filterOptions.kpiTypes,
                    }
                },
                {
                    name: 'kpiIds',
                    displayName: this.$t('results.siteView.selector.kpiId'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: true,
                        searchInputPlaceholder: '',
                        options: this.kpiIdFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.kpiIds,
                    },
                    appliedValues: {
                        options: this.filterOptions.kpiIds,
                    }
                },
                {
                    name: 'status',
                    displayName: this.$t('results.scenarioCompare.filters.status'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: this.statusFilterOptions,
                    },
                    selectedValues: {
                        options: this.filterOptions.status,
                    },
                    appliedValues: {
                        options: this.filterOptions.status,
                    }
                }
            ]
        };
    }
}
</script>

<style scoped lang="scss">
.sc-filters {
    @include rem(padding-left, pui-spacing(s));

    flex-grow: 1;

    ::v-deep .pui-filter {
        margin-bottom: 0;
        align-items: center;
    }
}
</style>
