var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['header-wrapper', {
      'header-wrapper--dev': _vm.currentEnvironment === 'dev' || _vm.currentEnvironment === 'development',
      'header-wrapper--uat': _vm.currentEnvironment === 'uat',
      'header-wrapper--prd': _vm.currentEnvironment === 'prd'
    }]
  }, [_c('adam-header-bar', {
    attrs: {
      "username": _vm.user.name,
      "emailaddress": _vm.user.email
    },
    domProps: {
      "showNotifications": false
    },
    on: {
      "apps-click": _vm.clickDrawer,
      "mouseover": function ($event) {
        _vm.isMouseOverHeader = true;
      },
      "mouseleave": function ($event) {
        _vm.isMouseOverHeader = false;
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "headerContent"
    },
    slot: "headerContent"
  }, [_c('h4', [_vm._v(_vm._s(_vm.isMouseOverHeader ? _vm.$t('appWorkingTitle') : _vm.$t('appTitle')))])]), _c('div', {
    attrs: {
      "slot": "profileDetails"
    },
    slot: "profileDetails"
  }, [_c('user-profile')], 1)]), _c('app-drawer', {
    ref: "appDrawer",
    domProps: {
      "data": _vm.applications,
      "labels": _vm.getAppDrawerLabels,
      "onClick": _vm.handleAppClick
    }
  }, [_c('span', {
    staticClass: "app-logo",
    attrs: {
      "slot": "appLogo"
    },
    slot: "appLogo"
  }, [_c('img', {
    attrs: {
      "alt": "COODE Logo",
      "src": require("adam.ui-core/assets/brand/logo-coode-rgb.svg")
    }
  }), _c('img', {
    attrs: {
      "alt": "Enerlytics Logo",
      "src": require("adam.ui-core/assets/brand/logo-enerlytics-rgb.svg")
    }
  })])]), _c('snackbar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }