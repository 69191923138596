<template>
    <div 
        :class="[
            'header-wrapper',
            {
                'header-wrapper--dev': currentEnvironment === 'dev' || currentEnvironment === 'development',
                'header-wrapper--uat': currentEnvironment === 'uat',
                'header-wrapper--prd': currentEnvironment === 'prd',
            }
        ]"
    >
        <adam-header-bar
            :username="user.name"
            :emailaddress="user.email"
            :show-notifications.prop="false"
            @apps-click="clickDrawer"
            @mouseover="isMouseOverHeader = true"
            @mouseleave="isMouseOverHeader = false"
        >
            <span slot="headerContent">
                <h4>{{ isMouseOverHeader ? $t('appWorkingTitle') : $t('appTitle') }}</h4>
            </span>
            <div slot="profileDetails">
                <user-profile />
            </div>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-logo"
            >
                <img
                    alt="COODE Logo"
                    src="~adam.ui-core/assets/brand/logo-coode-rgb.svg"
                >
                <img
                    alt="Enerlytics Logo"
                    src="~adam.ui-core/assets/brand/logo-enerlytics-rgb.svg"
                >
            </span>
        </app-drawer>
        <snackbar />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application } from '@/models';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { EventBus } from '@/utils';
import { LocalStorageHelper } from '@coode/fe-sdk';

@Component({
    name: 'header-wrapper',
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class HeaderWrapper extends Vue {
    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];

    private isMouseOverHeader = false;

    private user = {
        name: '',
        email: '',
    };

    private get currentEnvironment(): string {
        return process.env.VUE_APP_ENVIRONMENT;
    }

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
        await this.loadApps();
    }

    private async setProfileData(): Promise<void> {
        const profile = await LocalStorageHelper.getPermissionsLocalStorage();

        if (profile) {
            this.user.name = profile.name;
            this.user.email = profile.email;
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }
}
</script>

<style lang="scss">
.header-wrapper {
    &--dev {
        .adam-header {
            --header-bar-bg: #{$coode-header-dev};

            &__separator {
                background-color: $coode-header-dev-contrast;
            }

            &__logo.adam-header-bar:hover {
                --fill: #{$coode-header-dev-contrast};
            }

            .adam-header-user {
                &__icon {
                    &:hover, &--focused {
                        background-color: $coode-header-dev-contrast;
                        --stroke: white;
                    }
                }
            }
            
            ::selection {
                background: $coode-header-dev-contrast;
            }
        }
    }

    &--uat {
        .adam-header {
            --header-bar-bg: #{$coode-header-uat};

            &__separator {
                background-color: $coode-header-uat-contrast;
            }

            &__logo.adam-header-bar:hover {
                --fill: #{$coode-header-uat-contrast};
            }

            .adam-header-user {
                &__icon {
                    &:hover, &--focused {
                        background-color: $coode-header-uat-contrast;
                        --stroke: white;
                    }
                }
            }

            ::selection {
                background: $coode-header-uat-contrast;
            }
        }
    }
}

.app-logo {
    img {
        height: 100%;
        margin-right: 1rem;
    }
}

.logout-button {
    height: 3.5rem;
    width: 100%;
    color: $dark-grey;
    background: $light-grey;
    transition: 0.2s ease-in;
    border-radius: 0;

    &:hover {
        transition: 0.2s ease-in;
        background: #E0E0E0;
    }
}
</style>
