import { render, staticRenderFns } from "./flagged-costs-form-overview-year-display.vue?vue&type=template&id=a8ccb0da&scoped=true"
import script from "./flagged-costs-form-overview-year-display.vue?vue&type=script&lang=ts"
export * from "./flagged-costs-form-overview-year-display.vue?vue&type=script&lang=ts"
import style0 from "./flagged-costs-form-overview-year-display.vue?vue&type=style&index=0&id=a8ccb0da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8ccb0da",
  null
  
)

export default component.exports