var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step-display"
  }, [_vm._l(_vm.steps, function (step, index) {
    return [_c('step-display-item', {
      key: `step_${index}`,
      attrs: {
        "circle-text": (index + 1).toString(),
        "text": step.text,
        "href": step.href,
        "is-active": step.isActive,
        "is-clickable": step.isClickable
      }
    }), _vm.steps.length - 1 !== index ? _c('div', {
      key: `separator_${index}`,
      staticClass: "step-display__separator"
    }) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }