import { EngineRunServiceProxy, IEngineRunSummaryResponse, IScenarioList, MarkErrorRequest, ScenarioServiceProxy } from '@/service-proxies/service-proxies.g';
import { Module } from 'vuex';

const scenarioService = new ScenarioServiceProxy();
const engineRunService = new EngineRunServiceProxy();

type EngineRunSummaryState = {
    scenario?: IScenarioList;
    summary?: IEngineRunSummaryResponse,
};

const initialState: EngineRunSummaryState = {
    scenario: undefined,
    summary: undefined,
};

const EngineRunSummaryModule: Module<EngineRunSummaryState, any> = {
    state: initialState,

    mutations: {
        setScenario(state, payload: IScenarioList): void {
            state.scenario = payload;
        },
        resetScenario(state): void {
            state.scenario = initialState.scenario;
        },
        setSummary(state, payload: IEngineRunSummaryResponse): void {
            state.summary = payload;
        },
        resetSummary(state): void {
            state.summary = initialState.summary;
        },
    },

    actions: {
        async fetchScenario({commit}, { id }: { id: number }): Promise<void> {
            try {
                const response = await scenarioService.get(id);
                commit('setScenario', response.result);
            } catch(err) {
                commit('resetScenario');
                throw err;
            }
        },
        async fetchEngineRunSummary({commit}, { id }: { id: number }): Promise<void> {
            try {
                const response = await engineRunService.summary3(id);
                commit('setSummary', response.result);
            } catch(err) {
                commit('resetSummary');
                throw err;
            }
        },
        async markErrorsAsAcceptable(context, payload: MarkErrorRequest): Promise<void> {
            await engineRunService.markError(payload);
        }
    },

    getters: {
        getScenario(state): IScenarioList | undefined {
            return state.scenario;
        },
        getEngineRunSummary(state): IEngineRunSummaryResponse | undefined {
            return state.summary;
        },
    },

    namespaced: true,
};

export default EngineRunSummaryModule;
