var render = function render(){
  var _vm$params$data$name, _vm$params$data, _vm$params$data$forec, _vm$params$data2, _vm$params$data$new, _vm$params$data3, _vm$params$data$new2, _vm$params$data4, _vm$params$data$new3, _vm$params$data5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip",
    attrs: {
      "role": "tooltip"
    }
  }, [_c('div', {
    staticClass: "tooltip-inner"
  }, [_c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tooltips.nameLocalLanguage', {
    value: (_vm$params$data$name = (_vm$params$data = _vm.params.data) === null || _vm$params$data === void 0 ? void 0 : _vm$params$data.name) !== null && _vm$params$data$name !== void 0 ? _vm$params$data$name : '-'
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tooltips.forecast', {
    value: (_vm$params$data$forec = (_vm$params$data2 = _vm.params.data) === null || _vm$params$data2 === void 0 ? void 0 : _vm$params$data2.forecast) !== null && _vm$params$data$forec !== void 0 ? _vm$params$data$forec : '-'
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tooltips.new1', {
    value: (_vm$params$data$new = (_vm$params$data3 = _vm.params.data) === null || _vm$params$data3 === void 0 ? void 0 : _vm$params$data3.new1) !== null && _vm$params$data$new !== void 0 ? _vm$params$data$new : '-'
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tooltips.new2', {
    value: (_vm$params$data$new2 = (_vm$params$data4 = _vm.params.data) === null || _vm$params$data4 === void 0 ? void 0 : _vm$params$data4.new2) !== null && _vm$params$data$new2 !== void 0 ? _vm$params$data$new2 : '-'
  })) + " ")]), _c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.ceForm.tooltips.new3', {
    value: (_vm$params$data$new3 = (_vm$params$data5 = _vm.params.data) === null || _vm$params$data5 === void 0 ? void 0 : _vm$params$data5.new3) !== null && _vm$params$data$new3 !== void 0 ? _vm$params$data$new3 : '-'
  })) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }