<template>
    <div class="secondary-kpi-form-wrapper">
        <pui-loader
            v-if="isFormSubmitting"
            :promise="infinitePromise"
        />
        <div v-else>
            <div v-pui-form-grid-row>
                <pui-form-group
                    :is-valid="formData.validation.kpiType"
                    :show-required-label="true"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiType')"
                    label-for="kpiType"
                    v-pui-form-grid-column="4"
                >
                    <pui-form-select
                        id="kpiType"
                        v-model="formData.values.kpiType"
                        :options="kpiTypeSelectorOptions"
                        :is-valid="formData.validation.kpiType"
                        :label="$t('setup.scenario.formulaStepForm.labels.kpiType')"
                        :search-input-placeholder="$t('setup.scenario.formulaStepForm.placeholders.kpiType')"
                        @change="validateField('kpiType')"
                    />
                    <template #error-message>
                        {{ formData.errorMessages.kpiType }}
                    </template>
                </pui-form-group>

                <pui-form-group
                    :is-valid="formData.validation.kpiId"
                    :show-required-label="true"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiId')"
                    label-for="kpiId"
                    v-pui-form-grid-column="4"
                >
                    <pui-form-input-field
                        id="kpiId"
                        v-model="formData.values.kpiId"
                        :is-valid="formData.validation.kpiId"
                        :max-length="MAX_LENGTH_CONFIG.kpiId"
                        :placeholder-text="$t('setup.scenario.formulaStepForm.placeholders.kpiId')"
                        @input="validateField('kpiId', true)"
                    />
                    <template #helper-text>
                        <span v-show="formData.values.kpiId.length > (MAX_LENGTH_CONFIG.kpiId / 2)">
                            {{ $t('form.characterCounter', { current: formData.values.kpiId.length, max: MAX_LENGTH_CONFIG.kpiId }) }}
                        </span>
                    </template>
                    <template #error-message>
                        <span class="secondary-kpi-form-wrapper__highlighted-text"> {{ $t('setup.scenario.formulaStepForm.errors.notValidKpiId') }} </span> {{ formData.errorMessages.kpiId }}
                    </template>
                </pui-form-group>

                <pui-form-group
                    :is-valid="formData.validation.kpiUom"
                    :show-required-label="true"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiUom')"
                    label-for="kpiUom"
                    v-pui-form-grid-column="4"
                >
                    <pui-form-select
                        id="kpiUom"
                        v-model="formData.values.kpiUom"
                        :options="kpiUomSelectorOptions"
                        :is-valid="formData.validation.kpiUom"
                        :label="$t('setup.scenario.formulaStepForm.labels.kpiUom')"
                        :search-input-placeholder="$t('setup.scenario.formulaStepForm.placeholders.kpiUom')"
                        @change="validateField('kpiUom')"
                    />
                    <template #error-message>
                        {{ formData.errorMessages.kpiUom }}
                    </template>
                </pui-form-group>
            </div>

            <div v-pui-form-grid-row>
                <pui-form-group
                    :is-valid="formData.validation.kpiDesc"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiDesc')"
                    label-for="kpiDesc"
                    v-pui-form-grid-column="12"
                >
                    <pui-form-input-field
                        id="kpiDesc"
                        v-model="formData.values.kpiDesc"
                        :is-valid="formData.validation.kpiDesc"
                        :max-length="MAX_LENGTH_CONFIG.kpiDesc"
                        :placeholder-text="$t('setup.scenario.formulaStepForm.placeholders.kpiDesc')"
                        @input="validateField('kpiDesc')"
                    />
                    <template #helper-text>
                        <span v-show="formData.values.kpiDesc.length > (MAX_LENGTH_CONFIG.kpiDesc / 2)">
                            {{ $t('form.characterCounter', { current: formData.values.kpiDesc.length, max: MAX_LENGTH_CONFIG.kpiDesc }) }}
                        </span>
                    </template>
                    <template #error-message>
                        {{ formData.errorMessages.kpiDesc }}
                    </template>
                </pui-form-group>
            </div>

            <div v-pui-form-grid-row>
                <pui-form-group
                    :is-valid="formData.validation.selectKpi"
                    :show-required-label="!secondaryKpi"
                    :label="$t('setup.scenario.formulaStepForm.labels.selectKpi')"
                    label-for="selectKpi"
                    v-pui-form-grid-column="12"
                >
                    <div
                        v-if="formData.values.selectKpi.length > 0"
                        class="secondary-kpi-form-wrapper__kpi-list"
                    >
                        <pui-chip
                            v-for="kpi in formData.values.selectKpi"
                            :key="kpi.value"
                            :label="kpi.value"
                            :border-color="usedKpiColor(kpi.value)"
                            :draggable="true"
                            @dragstart.native="kpiDragStart($event, kpi.value)"
                        />
                    </div>
                    <pui-form-type-ahead
                        class="secondary-kpi-form-wrapper__type-ahead-input"
                        id="selectKpi"
                        v-model="formData.values.selectKpi"
                        :is-loading="kpiIsLoading"
                        :is-valid="formData.validation.selectKpi"
                        :options="kpiSearchResults"
                        :use-local-cache="false"
                        :label="$t('setup.scenario.formulaStepForm.labels.selectKpi')"
                        :search-input-placeholder="$t('setup.scenario.formulaStepForm.placeholders.selectKpi')"
                        :multiple="true"
                        @abort="clearSearchResults"
                        @search="fetchKpiList"
                        @change="validateField('selectKpi')"
                    />
                    <template #error-message>
                        {{ formData.errorMessages.selectKpi }}
                    </template>
                </pui-form-group>
            </div>

            <div v-pui-form-grid-row>
                <pui-form-group
                    :is-valid="formData.validation.kpiFormula"
                    :show-required-label="true"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiFormula')"
                    label-for="kpiFormula"
                    v-pui-form-grid-column="12"
                >
                    <pui-form-input-field
                        id="kpiFormula"
                        v-model="formData.values.kpiFormula"
                        :is-valid="formData.validation.kpiFormula"
                        :max-length="MAX_LENGTH_CONFIG.kpiFormula"
                        :placeholder-text="$t('setup.scenario.formulaStepForm.placeholders.kpiFormula')"
                        @dragover.native="textFieldDragOver"
                        @drop.native="textFieldDrop($event, handleDropForKpiFormula)"
                        @input="validateField('kpiFormula')"
                    />
                    <template #helper-text>
                        <span v-if="formData.values.kpiFormula.length > (MAX_LENGTH_CONFIG.kpiFormula / 2)">
                            {{ $t('form.characterCounter', { current: formData.values.kpiFormula.length, max: MAX_LENGTH_CONFIG.kpiFormula }) }}
                        </span>
                        <span v-else>
                            {{ $t('setup.scenario.formulaStepForm.helpers.dragAndDropFrom', { field: $t('setup.scenario.formulaStepForm.labels.selectKpi')}) }}
                        </span>
                    </template>
                    <template #error-message>
                        {{ formData.errorMessages.kpiFormula }}
                    </template>
                </pui-form-group>
            </div>

            <div v-pui-form-grid-row>
                <pui-form-group
                    :is-valid="formData.validation.kpiActive"
                    :show-required-label="true"
                    :label="$t('setup.scenario.formulaStepForm.labels.kpiActive')"
                    label-for="kpiActive"
                    v-pui-form-grid-column="12"
                >
                    <pui-form-input-selector
                        class="secondary-kpi-form-wrapper__input-selector"
                        id="kpiActive"
                        name="kpiActive"
                        :options="INPUT_SELECTOR_OPTIONS"
                        v-model="formData.values.kpiActive"
                        :selected-value.sync="formData.values.kpiActive"
                        @input="validateField('kpiActive')"
                    />
                    <template #error-message>
                        {{ formData.errorMessages.kpiActive }}
                    </template>
                </pui-form-group>
            </div>

            <div v-pui-form-grid-row>
                <pui-form-group
                    label
                    v-pui-form-grid-column="12"
                    :is-valid="formData.validation.unit"
                >
                    <pui-form-checkbox
                        id="unit"
                        v-model="formData.values.unit"
                        :is-valid="formData.validation.unit"
                        :label="$t('setup.scenario.formulaStepForm.labels.unit')"
                        @change="validateField('unit')"
                    />
                    <pui-form-checkbox
                        id="unitZero"
                        v-model="formData.values.unitZero"
                        :is-valid="formData.validation.unit"
                        :label="$t('setup.scenario.formulaStepForm.labels.unitZero')"
                        @change="validateField('unit')"
                    />
                    <template #error-message>
                        {{ formData.errorMessages.unit }}
                    </template>
                </pui-form-group>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFormData } from '@/models/form';
import { IAddKpiRequest, KpiDto, FormulaKpiDto, IEditKpiRequest, EditFormulaRequestDto, KpiResponseDto } from '@/service-proxies/service-proxies.g';
import {
    arrayNotEmptyValidationFn,
    combineValidationFns,
    createStringLengthValidationFn,
    objectNotNullOrUndefinedValidationFn,
    stringNotEmptyValidationFn
} from '@/utils/form-validation/form-validation-utils';
import { INPUT_SELECTOR_OPTIONS, MAX_LENGTH_CONFIG, SecondaryKpi } from '@/config/formulaStep';
import { PUICOLOR_UNIPER_BLUE, PUICOLOR_WARM_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js'
import { PuiSelectOptions } from '@/models/pebble-ui';
import { ConstantEntry } from '@/store/modules/constants.module';

const SecondaryKpiFormKeys = ['kpiType', 'kpiId', 'kpiUom', 'kpiDesc', 'selectKpi', 'kpiFormula', 'kpiActive', 'unit', 'unitZero'] as const;
type SecondaryKpiFormKeysType = typeof SecondaryKpiFormKeys[number];

type KpiSelectOption = {
    label: string;
    secondaryLabel?: string;
    value: string;
    benchKpiFormulaId: number;
    isPrimaryKpi: boolean;
}

type FormulaFormValuesType = {
    kpiType: number | undefined;
    kpiId: string;
    kpiUom: number | undefined;
    kpiDesc: string;
    selectKpi: KpiSelectOption[];
    kpiFormula: string;
    kpiActive: string;
    unit: boolean;
    unitZero: boolean;
};

@Component({})
export default class SecondaryKpiFormComponent extends Vue {
    private readonly KPI_DATA_MIME_TYPE = 'application/benchmarking';
    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
    private isFormSubmitting = false;

    private readonly INPUT_SELECTOR_OPTIONS = INPUT_SELECTOR_OPTIONS;

    private readonly MAX_LENGTH_CONFIG = MAX_LENGTH_CONFIG;
    private readonly MIN_LENGTH_KPI_ID = 5;
    private readonly MIN_NUMBER_OF_LETTERS_AT_START_OF_KPI_ID = 4;

    private readonly MAX_NUMBER_OF_RESULTS = 20;
    private kpiSearchResults: KpiSelectOption[] = [];
    private kpiIsLoading = false;

    @Prop()
    private secondaryKpi!: SecondaryKpi | null;

    private get kpiTypeSelectorOptions(): PuiSelectOptions<number> {
        const kpiTypes: ConstantEntry[] = this.$store.getters['constants/kpiTypes'];
        return kpiTypes.map(e => ({
            label: e.value,
            value: e.id,
        }));
    }

    private get kpiUomSelectorOptions(): PuiSelectOptions<number> {
        const unitsOfMeasure: ConstantEntry[] = this.$store.getters['constants/unitsOfMeasure'];
        return unitsOfMeasure.map(e => ({
            label: e.value,
            value: e.id,
        }));
    }

    private formData: IFormData<SecondaryKpiFormKeysType, FormulaFormValuesType> = {
        initialValues: {
            kpiType: undefined,
            kpiId: '',
            kpiUom: undefined,
            kpiDesc: '',
            selectKpi: [],
            kpiFormula: '',
            kpiActive: 'yes',
            unit: false,
            unitZero: false,
        },
        values: {
            kpiType: undefined,
            kpiId: '',
            kpiUom: undefined,
            kpiDesc: '',
            selectKpi: [],
            kpiFormula: '',
            kpiActive: 'yes',
            unit: false,
            unitZero: false,
        },
        validation: {
            kpiType: true,
            kpiId: true,
            kpiUom: true,
            kpiDesc: true,
            selectKpi: true,
            kpiFormula: true,
            kpiActive: true,
            unit: true,
            unitZero: true,
        },
        validators: {
            kpiType: objectNotNullOrUndefinedValidationFn,
            kpiId: combineValidationFns (
                stringNotEmptyValidationFn,
                (input) => {
                    if (input !== undefined && this.kpiIdMinLengthValidationFn(input)) {
                        return this.$t('setup.scenario.formulaStepForm.errors.kpiIdMinimumLength', { length: this.MIN_LENGTH_KPI_ID });
                    } else if (input !== undefined && this.kpiIdFirstNCharactersNaNValidationFn(input)) {
                        return this.$t('setup.scenario.formulaStepForm.errors.kpiIdCannotBeNumber', { count: this.MIN_NUMBER_OF_LETTERS_AT_START_OF_KPI_ID });
                    }
                }
            ),
            kpiUom: objectNotNullOrUndefinedValidationFn,
            kpiDesc: createStringLengthValidationFn(0, this.MAX_LENGTH_CONFIG.kpiDesc),
            selectKpi: this.secondaryKpi ? (): undefined => undefined : arrayNotEmptyValidationFn,
            kpiFormula: combineValidationFns(
                stringNotEmptyValidationFn,
                createStringLengthValidationFn(1, this.MAX_LENGTH_CONFIG.kpiFormula),
                (input, context) => {
                    const selectedKpis = context.selectKpi.map(k => k.value);
                    const kpisInInput = this.extractKpiTokensFromString(input);
                    if(input !== undefined && !selectedKpis.every(e => kpisInInput.includes(e))) {
                        return this.$t('setup.scenario.formulaStepForm.errors.notAllSelectedKpisIncluded');
                    }
                }
            ),
            kpiActive: () => undefined,
            unit: (input, context) => {
                if (!(input || context.unitZero)) {
                    return this.$t('setup.scenario.formulaStepForm.errors.unitValidation');
                }
            },
            unitZero: () => undefined,
        },
        errorMessages: {
            kpiType: undefined,
            kpiId: undefined,
            kpiUom: undefined,
            kpiDesc: undefined,
            selectKpi: undefined,
            kpiFormula: undefined,
            kpiActive: undefined,
            unit: undefined,
            unitZero: undefined,
        },
    }

    private get currentScenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }

    private mounted(): void {
        this.loadDataFromExistingScenario();
    }

    private loadDataFromExistingScenario(): void {
        if (!this.secondaryKpi) {
            return;
        }

        this.formData.values.kpiType = this.secondaryKpi.kpiTypeId;
        this.formData.values.kpiId = this.secondaryKpi.kpiId ?? '';
        this.formData.values.kpiUom = this.secondaryKpi.unitOfMeasureId;
        this.formData.values.kpiDesc = this.secondaryKpi.kpiDesc ?? '';
        this.formData.values.kpiFormula = this.secondaryKpi.kpiFormula ?? '';
        this.formData.values.kpiActive = this.secondaryKpi.kpiActive ? 'yes' : 'no';
        this.formData.values.unit = this.secondaryKpi.unit;
        this.formData.values.unitZero = this.secondaryKpi.unitZero;

        if (this.secondaryKpi.kpis) {
            this.formData.values.selectKpi = this.secondaryKpi.kpis.map(kpi => ({
                label: kpi.kpiId ?? '',
                value: kpi.kpiId ?? '',
                benchKpiFormulaId: kpi.benchKpiFormulaId,
                isPrimaryKpi: kpi.isPrimaryKpi,
            }));
        }

        this.setCurrentValuesAsInitialValues();
    }

    private setCurrentValuesAsInitialValues(): void {
        SecondaryKpiFormKeys.forEach(key => {
            this.formData.initialValues[key] = this.formData.values[key] as never;
        });
    }

    private validateField(key: SecondaryKpiFormKeysType, convertToUpperCase = false): boolean {
        if (convertToUpperCase) {
            (this.formData.values[key] as string) = (this.formData.values[key] as string).toUpperCase();
        }

        if (key === 'selectKpi') {
            this.validateField('kpiFormula');
        }

        this.formData.errorMessages[key] = this.formData.validators[key](this.formData.values[key] as never, this.formData.values);
        this.formData.validation[key] = !this.formData.errorMessages[key];
        return this.formData.validation[key];
    }

    private kpiIdMinLengthValidationFn(kpiId: string): boolean {
        return kpiId.length < this.MIN_LENGTH_KPI_ID;
    }

    private kpiIdFirstNCharactersNaNValidationFn(kpiId: string): boolean {
        return !new RegExp(`^[A-Z]{${this.MIN_NUMBER_OF_LETTERS_AT_START_OF_KPI_ID}}`).test(kpiId);
    }

    private async fetchKpiList(searchQuery: string): Promise<void> {
        this.kpiIsLoading = true;
        this.clearSearchResults();

        const payload = {
            searchQuery,
            scenarioId: this.currentScenarioId,
        }

        try {
            const kpiList = await this.$store.dispatch('scenario/formulaStep/fetchKpiList', payload);
            this.kpiSearchResults = kpiList
                .slice(0, this.MAX_NUMBER_OF_RESULTS)
                .map((kpi: KpiResponseDto) => ({ ...kpi.kpiFormula }))
                .map((kpi: KpiDto) => ({
                    label: kpi.kpiId,
                    secondaryLabel: kpi.kpiDesc,
                    value: kpi.kpiId,
                    benchKpiFormulaId: kpi.kpiNumber,
                    isPrimaryKpi: kpi.kpiPrimary,
                }));
            if (this.secondaryKpi?.kpiId) {
                this.kpiSearchResults = this.kpiSearchResults
                    .filter((kpi: KpiSelectOption) => kpi.value !== this.secondaryKpi?.kpiId);
            }
        } finally {
            this.kpiIsLoading = false;
        }
    }

    private clearSearchResults(): void {
        this.kpiSearchResults = [];
    }

    private kpiDragStart(event: DragEvent, kpiContent: string): void {
        if (!event.dataTransfer) {
            return;
        }

        event.dataTransfer.setData(this.KPI_DATA_MIME_TYPE, kpiContent);
        event.dataTransfer.effectAllowed = 'copy';
    }

    private textFieldDragOver(event: DragEvent): void {
        if (event.dataTransfer?.types.includes(this.KPI_DATA_MIME_TYPE)) {
            event.preventDefault();
            event.dataTransfer.effectAllowed = 'copy';
        }
    }

    private textFieldDrop(event: DragEvent, callback: (arg0: string) => void): void {
        if (!event.dataTransfer || !event.dataTransfer.types.includes(this.KPI_DATA_MIME_TYPE)) {
            return;
        }

        event.preventDefault();
        const kpiData = event.dataTransfer.getData(this.KPI_DATA_MIME_TYPE);
        callback(kpiData);
    }

    private handleDropForKpiFormula(kpiData: string): void {
        kpiData = !this.isLastCharacterWhitespaceOrEmpty(this.formData.values.kpiFormula) ? ` ${kpiData} ` : `${kpiData} `;
        this.formData.values.kpiFormula += kpiData;
        this.validateField('kpiFormula');
    }

    private isLastCharacterWhitespaceOrEmpty = (text?: string): boolean => {
        return !text || text.charAt(text.length - 1) === ' ';
    };

    private get isFormValid(): boolean {
        return SecondaryKpiFormKeys.every(key => this.formData.validation[key]);
    }

    private clearFieldValidation(key: SecondaryKpiFormKeysType): void {
        this.formData.errorMessages[key] = undefined;
        this.formData.validation[key] = true;
    }

    private clearFormValidation(): void {
        SecondaryKpiFormKeys.forEach(this.clearFieldValidation);
    }

    private validateForm(): boolean {
        SecondaryKpiFormKeys.forEach(key => this.validateField(key));
        return this.isFormValid;
    }

    private usedKpiColor(value: string): string | undefined {
        return this.extractKpiTokensFromString(this.formData.values.kpiFormula).includes(value) ? PUICOLOR_WARM_GREY : PUICOLOR_UNIPER_BLUE;
    }

    private extractKpiTokensFromString(value: string): string[] {
        const regex = /[A-Za-z]{2}[A-Za-z0-9]*/g;
        return value.match(regex) ?? [];
    }

    public async submitForm(): Promise<void> {
        this.clearFormValidation();

        if (!this.validateForm()) {
            return;
        }

        const kpisList: FormulaKpiDto[] = this.formData.values.selectKpi.map(kpi => new FormulaKpiDto({
            id: undefined,
            kpiId: kpi.value,
            benchKpiFormulaId: kpi.benchKpiFormulaId,
            isPrimaryKpi: kpi.isPrimaryKpi,
        }));

        const payload: IEditKpiRequest | IAddKpiRequest = this.secondaryKpi ?
            {
                scenarioId: this.currentScenarioId,
                kpiNumber: this.secondaryKpi.kpiNumber,
                kpiFormula: new EditFormulaRequestDto({
                    kpiTypeId: this.formData.values.kpiType,
                    kpiId: this.formData.values.kpiId,
                    kpiDesc: this.formData.values.kpiDesc,
                    unitOfMeasureId: this.formData.values.kpiUom,
                    unit: this.formData.values.unit,
                    unitZero: this.formData.values.unitZero,
                    kpiFormula: this.formData.values.kpiFormula,
                    kpiActive: this.formData.values.kpiActive === 'yes',
                }),
                kpis: kpisList.length > 0 ? kpisList : undefined,
            } : {
                scenarioId: this.currentScenarioId,
                kpiTypeId: this.formData.values.kpiType,
                kpiId: this.formData.values.kpiId,
                unitOfMeasureId: this.formData.values.kpiUom,
                kpiDescription: this.formData.values.kpiDesc,
                kpisList: kpisList,
                kpiFormula: this.formData.values.kpiFormula,
                kpiActive: this.formData.values.kpiActive === 'yes',
                unit: this.formData.values.unit,
                unit0: this.formData.values.unitZero,
            };

        try {
            this.isFormSubmitting = true;
            const action = this.secondaryKpi ? 'editKpi' : 'addKpi';
            await this.$store.dispatch(`scenario/formulaStep/${action}`, payload);
            this.$pui.toast({
                type: 'success',
                title: this.$t('setup.scenario.formulaStepForm.toastMessages.formSubmitSuccess.title'),
                copy: this.$t('setup.scenario.formulaStepForm.toastMessages.formSubmitSuccess.copy'),
            });
            this.$emit('close-lightbox', true);
        } catch(e: any) {
            let errorCopy = '';

            if (e.errors?.length > 0) {
                errorCopy = e.errors[0];
            }

            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: errorCopy,
            });
        } finally {
            this.isFormSubmitting = false;
        }
    }

}
</script>

<style lang="scss">
  .secondary-kpi-form-wrapper {
    &__input-selector {
      .pui-form-input-selector-option {
        width: auto !important;
      }
    }

    &__highlighted-text {
      font-weight: bold;
    }

    &__kpi-list {
      display: flex;
      @include rem(gap, pui-spacing(xxxs));
      @include rem(margin-bottom, pui-spacing(xs));
    }

    &__type-ahead-input {
        .pui-form-type-ahead-input-async-multi-select {
            &__search-input-wrap {
                display: flex;
            }
        }

        .pui-form-type-ahead__overlay {
            max-height: 250px;
            overflow-y: auto;
        }
    }
  }
</style>
