import BaseService from '@/utils/base-service';
import { ApiResponse, Application, ApiResponseList } from '@/models';
import { Api } from '@coode/fe-sdk/dist/networking';
import { Vue } from 'vue-property-decorator';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService extends BaseService {
    constructor() {
        super('use-cases');
    }

    protected get api(): Api {
        return Vue.prototype.$sdk.$http?.$core.metaData.request.api;
    }

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return {
            result: {
                items: [],
            },
            statusCode: 200,
            message: ''
        };

        // return (await this.api.get<ApiResponse<ApiResponseList<Application>>>(`${this.resourcePath}`)).data;
    }
}
