<template>
    <div class="step-display">
        <template v-for="(step, index) in steps">
            <step-display-item
                :key="`step_${index}`"
                :circle-text="(index + 1).toString()"
                :text="step.text"
                :href="step.href"
                :is-active="step.isActive"
                :is-clickable="step.isClickable"
            />
            <div
                v-if="steps.length - 1 !== index"
                :key="`separator_${index}`"
                class="step-display__separator"
            />
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import StepDisplayItem from '@/components/step-display/step-display-item.vue';

export type StepItem = {
    text: string;
    href: string;
    isActive: boolean;
    isClickable: boolean;
};

@Component({
    components: {
        StepDisplayItem,
    }
})
export default class StepDisplay extends Vue {
    @Prop({ required: true, default: () => [] })
    private steps!: StepItem[];
}
</script>

<style scoped lang="scss">
.step-display {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &__separator {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 2rem;
        flex-grow: 1;
        height: 0.2rem;
        background-color: $warm-grey;
    }
}
</style>
