var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ag-cell-label-container"
  }, [_vm.enableMenu ? _c('div', {
    ref: _vm.REF_CONSTANTS.MENU_BUTTON,
    on: {
      "click": _vm.onMenuClicked
    }
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "content"
  }, [_c('pui-form-checkbox', {
    staticClass: "content__checkbox",
    attrs: {
      "label": "",
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.onChange
    }
  }), _vm._v(" " + _vm._s(_vm.displayName) + " ")], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show"
  }, [_c('span', {
    staticClass: "ag-icon ag-icon-menu"
  })]);

}]

export { render, staticRenderFns }