<template>
    <div class="engine-run-start-card">
        <pui-grid-row>
            <pui-grid-column
                :cols="4"
                class="engine-run-start-card__select-scenario-column"
            >
                <div class="engine-run-start-card__select-scenario-column__content">
                    <pui-form-group
                        :label="$t('engineRun.engineControl.selectScenario')"
                        :cta-label="$t('form.clear')"
                        :has-cta="true"
                        :cta-callback="clearRunnableScenarios"
                        :has-label-padding="false"
                        label-for="selectScenario"
                    >
                        <benchmarking-type-ahead
                            :ref="REF_CONSTANTS.TYPEAHEAD"
                            v-model="selectedRunnableScenarioOption"
                            :placeholder="$t('engineRun.engineControl.selectScenarioPlaceholder')"
                            :request-promise-generator="scenariosRequestPromiseGenerator"
                        />
                    </pui-form-group>
                </div>
            </pui-grid-column>
            <pui-grid-column
                :cols="4"
                class="engine-run-start-card__summary-column"
            >
                <div
                    v-if="selectedRunnableScenario"
                    class="engine-run-start-card__summary-column__content"
                >
                    <pui-headline
                        type="h5"
                        class="engine-run-start-card__summary-column__content__headline"
                    >
                        {{ $t('engineRun.engineControl.summary') }}
                    </pui-headline>
                    <pui-headline
                        type="h6"
                        class="engine-run-start-card__summary-column__content__item"
                    >
                        {{ $t('engineRun.engineControl.selectedKpis', { count: selectedRunnableScenario.kpisCalculated }) }}
                    </pui-headline>
                    <pui-headline
                        type="h6"
                        class="engine-run-start-card__summary-column__content__item"
                    >
                        {{ $t('engineRun.engineControl.sites', { count: selectedRunnableScenario.sites }) }}
                    </pui-headline>
                    <pui-headline
                        type="h6"
                        class="engine-run-start-card__summary-column__content__item"
                    >
                        {{ $t('engineRun.engineControl.units', { count: selectedRunnableScenario.units }) }}
                    </pui-headline>
                </div>
            </pui-grid-column>
            <pui-grid-column
                :cols="4"
                class="engine-run-start-card__start-engine-column"
            >
                <pui-button
                    :disabled="!isRunnableScenarioValid"
                    @click="startEngineRunClicked"
                >
                    {{ $t('engineRun.engineControl.startEngine') }}
                </pui-button>
            </pui-grid-column>
        </pui-grid-row>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IScenarioRunDto } from '@/service-proxies/service-proxies.g';
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import BenchmarkingTypeAhead from '@/components/inputs/benchmarking-type-ahead/benchmarking-type-ahead.vue';

const REF_CONSTANTS = {
    TYPEAHEAD: 'typeahead',
} as const;

@Component({
    components: {
        BenchmarkingTypeAhead
    }
})
export default class EngineRunStartCard extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private isStartingEngine = false;
    private selectedRunnableScenarioOption: PuiSelectOption<number> | null = null;

    $refs!: {
        [REF_CONSTANTS.TYPEAHEAD]: BenchmarkingTypeAhead,
    };

    private scenariosRequestPromiseGenerator = async (query: string, signal: AbortSignal): Promise<PuiSelectOptions<number>> => {
        await this.$store.dispatch('engineRun/fetchRunnableScenarios', { query, signal });
        return this.runnableScenariosOptions;
    }

    private get runnableScenarios(): IScenarioRunDto[] {
        return this.$store.getters['engineRun/runnableScenarios'];
    }

    private get runnableScenariosOptions(): PuiSelectOptions<number> {
        return this.runnableScenarios.map(e => ({
            label: e.name ?? '',
            value: e.id,
        }));
    }

    private get selectedRunnableScenario(): IScenarioRunDto | undefined {
        if (!this.selectedRunnableScenarioOption) {
            return undefined;
        }

        return this.runnableScenarios.find(e => e.id === this.selectedRunnableScenarioOption?.value);
    }

    private get isRunnableScenarioValid(): boolean {
        return !!this.selectedRunnableScenario && !this.isStartingEngine;
    }

    private clearRunnableScenarios(): void {
        this.$refs.typeahead.clearInput();
    }

    private async startEngineRunClicked(): Promise<void> {
        if (!this.selectedRunnableScenario?.id) {
            return;
        }

        try {
            this.isStartingEngine = true;
            await this.$store.dispatch('engineRun/startEngineRun', {
                scenarioId: this.selectedRunnableScenario.id,
            });
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('engineRun.engineControl.toast.startError.title'),
                copy: this.$t('engineRun.engineControl.toast.startError.copy'),
            });
        }

        this.isStartingEngine = false;
    }
}
</script>

<style scoped lang="scss">
.engine-run-start-card {
    flex-grow: 1;

    &__select-scenario-column {
        display: flex;
        align-items: center;

        &__content {
            flex-grow: 1;
        }
    }

    &__summary-column {
        display: flex;
        justify-content: center;

        &__content {
            @include rem(padding-left, pui-spacing(xl));
            @include rem(gap, pui-spacing(xs));
            border-left: 1px solid $warm-grey-25;

            display: flex;
            flex-direction: column;
            justify-content: center;

            &__headline {
                color: $warm-grey;
                font-weight: normal;
            }

            &__item {
                color: $dark-grey;
                font-weight: bold;
            }
        }
    }

    &__start-engine-column {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
