<template>
    <div
        v-if="shouldDisplayTooltip"
        class="tooltip"
        role="tooltip"
    >
        <div class="tooltip-inner">
            <div class="tooltip-inner__text">
                {{ params?.value }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ITooltipParams } from 'ag-grid-community';

@Component({})
export default class ScenarioCompareKpiHeaderTooltip extends Vue {
    private params?: ITooltipParams;

    private get shouldDisplayTooltip(): boolean {
        return !this.params?.rowIndex;
    }
}
</script>

<style scoped lang="scss">
.tooltip {
    .tooltip-inner {
        @include rem(gap, pui-spacing(xxxs));

        display: flex;
        flex-direction: column;

        &__text {
            color: $white;
            font-size: 1.4rem;
        }
    }
}
</style>
