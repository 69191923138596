<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :default-header-label="headerLabel"
        :close-on-e-s-c="false"
        :default-footer-confirm-label="$t('setup.scenario.formulaStepForm.buttons.save')"
        :default-footer-cancel-label="$t('setup.scenario.formulaStepForm.buttons.cancel')"
        :on-confirm-callback="triggerSubmitForm"
        @puilightbox:close="emitClose()"
    >
        <secondary-kpi-form
            :ref="REF_CONSTANTS.FORM"
            :secondary-kpi="secondaryKpi"
            @close-lightbox="(refresh) => emitClose(refresh)"
        />
    </pui-lightbox>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';
import SecondaryKpiForm from '@/components/formula-step/secondary-kpi-tab/secondary-kpi-lightbox/secondary-kpi-form/secondary-kpi-form.vue';
import { SecondaryKpi } from '@/config/formulaStep';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
    FORM: 'form',
} as const;

@Component({
    components: {
        SecondaryKpiForm,
    }
})
export default class SecondaryKpiLightboxComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
        [REF_CONSTANTS.FORM]: SecondaryKpiForm;
    };
    
    @Prop()
    private secondaryKpi!: SecondaryKpi | null;
    
    @Prop()
    private lightboxOpen!: boolean;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private get headerLabel(): string {
        if (this.secondaryKpi) {
            return this.$t('setup.scenario.formulaStepForm.lightboxHeader.editSecondaryKpi', { kpiId: this.secondaryKpi.kpiId });
        }

        return this.$t('setup.scenario.formulaStepForm.lightboxHeader.addNewSecondaryKpi');
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }
    
    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(refresh = false): void {
        this.$emit('close-lightbox', refresh);
    }

    private triggerSubmitForm(): void {
        this.$refs.form.submitForm();
    }
}
</script>
