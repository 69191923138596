<template>
    <div class="wrapper">
        <div class="wrapper__heading">
            <h3>{{ $t('engineRun.overview.title') }}</h3>
        </div>
        <engine-run-control-card />
        <engine-run-table :ref="REF_CONSTANTS.ENGINE_RUN_TABLE" />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import EngineRunControlCard from '@/components/engine-run-overview/engine-run-control-card.vue';
import EngineRunTable from '@/components/engine-run-overview/engine-run-table/engine-run-table.vue';
import {
    EngineRunTrackingData,
    EngineRunTrackingState
} from '@/store/modules/engine-run/engine-run.module';

const REF_CONSTANTS = {
    ENGINE_RUN_TABLE: 'engineRunTable',
} as const;

@Component({
    components: {
        EngineRunControlCard,
        EngineRunTable,
    }
})
export default class EngineRunOverviewPage extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.ENGINE_RUN_TABLE]: EngineRunTable,
    };

    private get trackedScenarios(): Record<number, EngineRunTrackingData> {
        return this.$store.getters['engineRun/trackedScenarios'];
    }

    private filterEngineRunTrackingForState(trackedScenarios: Record<number, EngineRunTrackingData>, state: EngineRunTrackingState): number[] {
        return Object.keys(trackedScenarios)
            .map(key => Number(key))
            .filter(key => trackedScenarios[Number(key)].state === state);
    }

    @Watch('trackedScenarios', { deep: true })
    private onTrackedScenariosChange(newValue: Record<number, EngineRunTrackingData>, oldValue: Record<number, EngineRunTrackingData>): void {
        let notifyScenarioCompletion = false;
        let notifyScenarioFailure = false;
        let notifyScenarioStop = false;

        const completedNewScenarioIds = this.filterEngineRunTrackingForState(newValue, EngineRunTrackingState.COMPLETED);
        const failedNewScenarioIds = this.filterEngineRunTrackingForState(newValue, EngineRunTrackingState.FAILED);
        const stoppedNewScenarioIds = this.filterEngineRunTrackingForState(newValue, EngineRunTrackingState.STOPPED);

        this.filterEngineRunTrackingForState(oldValue, EngineRunTrackingState.STARTED)
            .forEach(scenarioId => {
                notifyScenarioCompletion = completedNewScenarioIds.includes(scenarioId);
                notifyScenarioFailure ||= failedNewScenarioIds.includes(scenarioId);
                notifyScenarioStop ||= stoppedNewScenarioIds.includes(scenarioId);
            });

        if (notifyScenarioCompletion) {
            this.$pui.toast({
                type: 'success',
                title: this.$t('engineRun.engineControl.toast.newData.title'),
                copy: this.$t('engineRun.engineControl.toast.newData.copy'),
            });
        }

        if (notifyScenarioFailure) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('engineRun.engineControl.toast.engineFailure.title'),
                copy: this.$t('engineRun.engineControl.toast.engineFailure.copy'),
            });
        }

        if (notifyScenarioStop) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('engineRun.engineControl.toast.engineStopped.title'),
                copy: this.$t('engineRun.engineControl.toast.engineStopped.copy'),
            });
        }

        if (notifyScenarioCompletion || notifyScenarioFailure) {
            this.$refs.engineRunTable.refreshData();
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: column;

    &__heading {
        display: flex;
        justify-content: space-between;
    }
}
</style>
