var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inner-unit-cell"
  }, [_vm.isNotSelectable ? _c('div', {
    staticClass: "inner-unit-cell__checkbox"
  }, [_c('pui-form-checkbox', {
    attrs: {
      "label": "",
      "is-disabled": true,
      "checked": true
    }
  })], 1) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.textValue))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }