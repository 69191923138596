<template>
    <div class="engine-run-running-card">
        <div class="engine-run-running-card__indicator">
            <pui-headline
                type="h5"
                class="engine-run-running-card__indicator__text"
            >
                {{ $t('engineRun.engineControl.engineRunning') }}
            </pui-headline>
            <loading-bar class="engine-run-running-card__indicator__loading-bar" />
        </div>
        <div class="engine-run-running-card__horizontal-separator" />
        <div class="engine-run-running-card__actions">
            <div class="engine-run-running-card__actions__button">
                <pui-button
                    :disabled="!isEngineRunStoppable"
                    state="secondary"
                    @click="stopEngineRunClicked"
                >
                    {{ engineRunCount === 1 ? $t('engineRun.engineControl.stopEngineSingle') : $t('engineRun.engineControl.stopEngineMultiple') }}
                </pui-button>
            </div>
            <template v-if="engineRunCount">
                <div class="engine-run-running-card__actions__vertical-separator" />
                <div class="engine-run-running-card__actions__text-content">
                    <pui-headline
                        type="h6"
                        class="engine-run-running-card__actions__text-content__title"
                    >
                        {{ $t('engineRun.engineControl.numberOfEngines') }}
                    </pui-headline>
                    <pui-headline
                        type="h6"
                        class="engine-run-running-card__actions__text-content__text"
                    >
                        {{ engineRunCount }}
                    </pui-headline>
                </div>
            </template>
            <template v-if="latestStartedAt">
                <div class="engine-run-running-card__actions__vertical-separator" />
                <div class="engine-run-running-card__actions__text-content">
                    <pui-headline
                        type="h6"
                        class="engine-run-running-card__actions__text-content__title"
                    >
                        {{ $t('engineRun.engineControl.started') }}
                    </pui-headline>
                    <pui-headline
                        type="h6"
                        class="engine-run-running-card__actions__text-content__text"
                    >
                        {{ latestStartedAt | formatDate }}
                    </pui-headline>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LoadingBar from '@/components/loading-bar/loading-bar.vue';
import { getFormattedTime } from '@/utils/formatters';

@Component({
    components: {
        LoadingBar,
    },
    filters: {
        formatDate(input: Date): string {
            return getFormattedTime(input);
        }
    }
})
export default class EngineRunRunningCard extends Vue {
    private readonly CHECK_TIMEOUT = 20 * 1000;
    private timeoutId: number | null = null;
    private isStoppingEngine = false;

    private get latestStartedAt(): Date | undefined {
        return this.$store.getters['engineRun/latestStartedAt'];
    }

    private get engineRunCount(): number {
        return this.$store.getters['engineRun/runningCount'];
    }

    private get isEngineRunStoppable(): boolean {
        return this.engineRunCount !== 0 && !this.isStoppingEngine;
    }

    private mounted(): void {
        this.checkEngineRunStatus();
    }

    private beforeDestroy(): void {
        this.clearCheckTimeout();
    }

    private scheduleEngineStatusCheck(): void {
        this.clearCheckTimeout();

        if (this.engineRunCount === 0) {
            return;
        }

        this.timeoutId = setTimeout(this.checkEngineRunStatus, this.CHECK_TIMEOUT);
    }

    private async checkEngineRunStatus(): Promise<void> {
        await this.$store.dispatch('engineRun/checkEngineRunState', {});

        if (this.engineRunCount !== 0) {
            this.scheduleEngineStatusCheck();
        }
    }

    private clearCheckTimeout(): void {
        clearTimeout(this.timeoutId ?? undefined);
    }

    private async stopEngineRunClicked(): Promise<void> {
        if (!this.isEngineRunStoppable) {
            return;
        }

        try {
            this.isStoppingEngine = true;
            await this.$store.dispatch('engineRun/stopCurrentEngineRun');
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('engineRun.engineControl.toast.stopError.title'),
                copy: this.$t('engineRun.engineControl.toast.stopError.copy'),
            });
        }

        this.isStoppingEngine = false;
    }
}
</script>

<style scoped lang="scss">
.engine-run-running-card {
    @include rem(gap, pui-spacing(m));

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__indicator {
        @include rem(gap, pui-spacing(m));
        display: flex;

        &__text {
            font-weight: bold;
        }

        &__loading-bar {
            flex-grow: 1;
            height: 18px;
        }
    }

    &__horizontal-separator {
        height: 1px;
        width: 100%;
        background-color: $warm-grey-25;
    }

    &__actions {
        @include rem(gap, pui-spacing(m));
        display: flex;

        &__button {
            display: flex;
            align-items: center;
        }

        &__vertical-separator {
            min-height: 100%;
            width: 1px;
            background-color: $warm-grey-25;
        }

        &__text-content {
            @include rem(gap, pui-spacing(xxs));
            display: flex;
            flex-direction: column;

            &__title {
                color: $warm-grey;
            }

            &__text {
                font-weight: bold;
            }
        }
    }
}
</style>
