var render = function render(){
  var _vm$params, _vm$params$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-action-cell"
  }, [_c('pui-button', {
    attrs: {
      "icon": "delete",
      "state": "secondary",
      "small": true,
      "disabled": !((_vm$params = _vm.params) !== null && _vm$params !== void 0 && (_vm$params$data = _vm$params.data) !== null && _vm$params$data !== void 0 && _vm$params$data.canBeDeleted)
    },
    on: {
      "click": _vm.onDeleteClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('admin.actions.delete')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }