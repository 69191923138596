var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "secondary-kpi-scenario-import"
  }, [_c('pui-form-group', {
    class: ['secondary-kpi-scenario-import__dropdown', {
      'secondary-kpi-scenario-import__dropdown--enabled': !_vm.hasImportedScenario
    }],
    attrs: {
      "label": _vm.$t('setup.scenario.formulaStep.import.label'),
      "cta-label": _vm.$t('form.clear'),
      "has-cta": !_vm.inputDisabled,
      "cta-callback": _vm.clearScenario,
      "has-label-padding": false
    }
  }, [_c('benchmarking-type-ahead', {
    ref: _vm.REF_CONSTANTS.TYPEAHEAD,
    attrs: {
      "placeholder": _vm.$t('setup.scenario.formulaStep.import.placeholder'),
      "request-promise-generator": _vm.scenariosRequestPromiseGenerator,
      "is-disabled": _vm.inputDisabled
    },
    model: {
      value: _vm.selectedScenario,
      callback: function ($$v) {
        _vm.selectedScenario = $$v;
      },
      expression: "selectedScenario"
    }
  })], 1), _c('div', {
    staticClass: "secondary-kpi-scenario-import__button"
  }, [!_vm.hasImportedScenario ? _c('pui-button', {
    attrs: {
      "icon": "imported",
      "state": "primary",
      "icon-only": "",
      "disabled": _vm.isImportScenarioDisabled
    },
    on: {
      "click": _vm.importScenarioClicked
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }