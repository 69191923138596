<template>
    <div class="sv-scenario-selector">
        <div class="sv-scenario-selector__scenario">
            <pui-form-group
                :label="$t('results.siteView.selector.selectScenario')"
                :cta-label="$t('form.clear')"
                :has-cta="true"
                :cta-callback="clearScenario"
                :has-label-padding="false"
                label-for="selectScenario"
            >
                <benchmarking-type-ahead
                    :ref="REF_CONSTANTS.TYPEAHEAD"
                    :value="selectedScenarioOption"
                    :placeholder="$t('results.siteView.selector.selectPlaceholder')"
                    :request-promise-generator="scenariosRequestPromiseGenerator"
                    @input="onSelectedScenarioChange"
                />
            </pui-form-group>
        </div>
        <div class="sv-scenario-selector__year">
            <pui-form-group
                :show-required-label="true"
                :label="$t('results.siteView.selector.selectStartYear')"
                :has-label-padding="false"
                label-for="startYear"
            >
                <pui-form-select
                    :value="startYear"
                    :options="yearSelectorOptions"
                    :label="$t('results.siteView.selector.selectStartYear')"
                    :is-disabled="yearSelectorOptions.length === 0"
                    :search-input-placeholder="$t('results.siteView.selector.selectPlaceholder')"
                    search-input-id="startYear"
                    @change="onSelectedStartYearChange"
                />
            </pui-form-group>
        </div>
        <div class="sv-scenario-selector__year">
            <pui-form-group
                :show-required-label="true"
                :is-valid="!isEndYearInvalid"
                :label="$t('results.siteView.selector.selectEndYear')"
                :has-label-padding="false"
                label-for="endYear"
            >
                <pui-form-select
                    :value="endYear"
                    :options="yearSelectorOptions"
                    :label="$t('results.siteView.selector.selectEndYear')"
                    :is-valid="!isEndYearInvalid"
                    :is-disabled="yearSelectorOptions.length === 0"
                    :search-input-placeholder="$t('results.siteView.selector.selectPlaceholder')"
                    search-input-id="endYear"
                    @change="onSelectedEndYearChange"
                />
                <template #error-message>
                    {{ $t('results.siteView.errors.invalidEndYear') }}
                </template>
            </pui-form-group>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { PuiSelectOption, PuiSelectOptions } from '@/models/pebble-ui';
import { EngineRunServiceProxy, IScenarioRunDto } from '@/service-proxies/service-proxies.g';
import BenchmarkingTypeAhead from '@/components/inputs/benchmarking-type-ahead/benchmarking-type-ahead.vue';
import { SCENARIO_STATUS } from '@/config/status';

const REF_CONSTANTS = {
    TYPEAHEAD: 'typeahead',
} as const;

@Component({
    components: {BenchmarkingTypeAhead}
})
export default class SiteViewScenarioSelector extends mixins(ManagedAbortControllers) {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly engineRunService = new EngineRunServiceProxy();

    @Prop({ required: true })
    private startYear: number | null = null;

    @Prop({ required: true })
    private endYear: number | null = null;

    $refs!: {
        [REF_CONSTANTS.TYPEAHEAD]?: BenchmarkingTypeAhead,
    };

    private get selectedScenarioOption(): PuiSelectOption<number> | null {
        const selectedScenario: IScenarioRunDto = this.$store.getters['results/getSelectedScenario'];

        return selectedScenario ? {
            label: selectedScenario.name ?? '-',
            value: selectedScenario.id,
            extraData: selectedScenario,
        } : null;
    }

    private get yearRange(): number[] {
        if (!this.selectedScenarioOption?.extraData?.startYear || !this.selectedScenarioOption?.extraData?.endYear) {
            return [];
        }

        const years: number[] = [];
        for (let i = this.selectedScenarioOption.extraData.startYear; i <= this.selectedScenarioOption.extraData.endYear; i++) {
            years.push(i);
        }

        return years;
    }

    private get yearSelectorOptions(): PuiSelectOptions<number> {
        return this.yearRange.map(e => ({
            label: e.toString(),
            value: e,
        }));
    }

    private get isEndYearInvalid(): boolean {
        if (!this.startYear || !this.endYear) {
            return false;
        }

        return this.endYear < this.startYear;
    }

    private onSelectedScenarioChange(newScenario: PuiSelectOption<number> | null): void {
        this.$store.commit('results/setSelectedScenario', newScenario?.extraData);

        this.onSelectedStartYearChange(null);
        this.onSelectedEndYearChange(null);
    }

    private onSelectedStartYearChange(newStartYear: number | null): void {
        this.$emit('change:start-year', newStartYear);
    }

    private onSelectedEndYearChange(newEndYear: number | null): void {
        this.$emit('change:end-year', this.isEndYearInvalid ? undefined : newEndYear);
    }

    private async scenariosRequestPromiseGenerator(query: string, signal: AbortSignal): Promise<PuiSelectOptions<number>> {
        const statuses = [
            Number(SCENARIO_STATUS.ENGINE_RUN_COMPLETED),
            Number(SCENARIO_STATUS.ENGINE_RUN_FAILED)
        ];

        const response = await this.engineRunService.list7(undefined, undefined, statuses, 1, 20, query, undefined, signal);
        return response.result.items?.map(e => ({
            label: e.name ?? '',
            value: e.id,
            extraData: e,
        })) ?? [];
    }

    private clearScenario(): void {
        this.$refs.typeahead?.clearInput();
        this.onSelectedStartYearChange(null);
        this.onSelectedEndYearChange(null);
    }
}
</script>

<style scoped lang="scss">
.sv-scenario-selector {
    @include rem(gap, pui-spacing(m));

    display: flex;

    &__scenario {
        min-width: 400px;
    }

    &__year {
        min-width: 200px;
    }
}
</style>
