var render = function render(){
  var _vm$params;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldDisplayTooltip ? _c('div', {
    staticClass: "tooltip",
    attrs: {
      "role": "tooltip"
    }
  }, [_c('div', {
    staticClass: "tooltip-inner"
  }, [_c('div', {
    staticClass: "tooltip-inner__text"
  }, [_vm._v(" " + _vm._s((_vm$params = _vm.params) === null || _vm$params === void 0 ? void 0 : _vm$params.value) + " ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }