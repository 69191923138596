import { Module } from 'vuex';
import { AddKpiTypeRequest, EditKpiTypeRequest, KpiTypeServiceProxy, UnitOfMeasureUpdateDto, UomServiceProxy } from '@/service-proxies/service-proxies.g';

const kpiTypeService = new KpiTypeServiceProxy();
const unitsOfMeasureService = new UomServiceProxy();

export type ConstantEntry = {
    id: number,
    value: string,
};

type ConstantsState = {
    kpiTypes: ConstantEntry[],
    unitsOfMeasure: ConstantEntry[],
};

const initialState: ConstantsState = {
    kpiTypes: [],
    unitsOfMeasure: [],
};

const ConstantsModule: Module<ConstantsState, any> = {
    state: initialState,

    mutations: {
        setKpiTypes(state, payload: ConstantEntry[]): void {
            state.kpiTypes = payload;
        },
        resetKpiTypes(state): void {
            state.kpiTypes = [];
        },
        setUnitsOfMeasure(state, payload: ConstantEntry[]): void {
            state.unitsOfMeasure = payload;
        },
        resetUnitsOfMeasure(state): void {
            state.unitsOfMeasure = [];
        },
    },

    actions: {
        async fetchAllConstants({ dispatch }): Promise<void> {
            await Promise.all([
                dispatch('fetchKpiTypes'),
                dispatch('fetchUnitsOfMeasure'),
            ]);
        },
        async fetchKpiTypes({ commit }): Promise<void> {
            try {
                const response = await kpiTypeService.listKpiTypes(0, 0, undefined, undefined);

                const kpiTypes: ConstantEntry[] = response.result.kpiTypes.items?.map(e => ({
                    id: e.id,
                    value: e.name ?? '',
                })) ?? [];

                commit('setKpiTypes', kpiTypes);
            } catch {
                commit('resetKpiTypes');
            }
        },
        async addKpiType(context, payload: AddKpiTypeRequest): Promise<void> {
            await kpiTypeService.addKpiType(payload);
        },
        async editKpiType(context, payload: EditKpiTypeRequest): Promise<void> {
            await kpiTypeService.editKpiType(payload);
        },
        async fetchUnitsOfMeasure({ commit }): Promise<void> {
            try {
                const response = await unitsOfMeasureService.listUnitsOfMeasure(0, 0, undefined, undefined);

                const unitsOfMeasure: ConstantEntry[] = response.result.items?.map(e => ({
                    id: e.id,
                    value: e.name ?? '',
                })) ?? [];

                commit('setUnitsOfMeasure', unitsOfMeasure);
            } catch {
                commit('resetUnitsOfMeasure');
            }
        },
        async addUOM(context, payload: string[]): Promise<void> {
            await unitsOfMeasureService.addUnitsOfMeasure(payload);
        },
        async editUOM(context, payload: UnitOfMeasureUpdateDto[]): Promise<void> {
            await unitsOfMeasureService.editUnitsOfMeasure(payload);
        },
    },

    getters: {
        kpiTypes(state): ConstantEntry[] {
            return state.kpiTypes;
        },
        unitsOfMeasure(state): ConstantEntry[] {
            return state.unitsOfMeasure;
        }
    },

    namespaced: true,
};

export default ConstantsModule;
