import { Module } from 'vuex';
import { SCENARIO_STATUS } from '@/config/status';
import {
    IStatusDistributionResponse,
    ScenarioServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { PayloadWithSignal, StatusDistribution } from '@/models/interfaces';
import { NonNullableProps } from '@/types/helper'

const scenarioService = new ScenarioServiceProxy();

type StatusState = {
    total: number;
    statusDistribution: StatusDistribution;
};

const initialState: StatusState = {
    total: 0,
    statusDistribution: {
        [SCENARIO_STATUS.DRAFT]: 0,
        [SCENARIO_STATUS.IN_REVIEW]: 0,
        [SCENARIO_STATUS.READY_FOR_ENGINE_RUN]: 0,
        [SCENARIO_STATUS.ENGINE_RUN_STARTED]: 0,
        [SCENARIO_STATUS.ENGINE_RUN_COMPLETED]: 0,
        [SCENARIO_STATUS.ENGINE_RUN_FAILED]: 0,
    },
};

const StatusModule: Module<StatusState, any> = {
    state: initialState,

    mutations: {
        setStatusDistribution(state, payload: NonNullableProps<IStatusDistributionResponse>): void {
            state.total = payload.total;
            state.statusDistribution = payload.statusDistribution;
        },
        resetStatusDistribution(state): void {
            state.total = 0;
            state.statusDistribution = {
                [SCENARIO_STATUS.DRAFT]: 0,
                [SCENARIO_STATUS.IN_REVIEW]: 0,
                [SCENARIO_STATUS.READY_FOR_ENGINE_RUN]: 0,
                [SCENARIO_STATUS.ENGINE_RUN_STARTED]: 0,
                [SCENARIO_STATUS.ENGINE_RUN_COMPLETED]: 0,
                [SCENARIO_STATUS.ENGINE_RUN_FAILED]: 0,
            };
        }
    },

    actions: {
        async fetchStatusDistribution({ commit }, { signal }: PayloadWithSignal): Promise<void> {
            try {
                const response = await scenarioService.statusDistribution(undefined, signal);
                if (response.result?.statusDistribution && response.result?.total) {
                    commit('setStatusDistribution', response.result);
                } else {
                    return Promise.reject(206);
                }
            } catch (err) {
                commit('resetStatusDistribution');

                if (!signal?.aborted) {
                    throw err;
                }
            }
        },
    },

    getters: {
        getStatusDistribution(state): StatusDistribution {
            return state.statusDistribution;
        },
        getTotalCount(state): number {
            return state.total;
        },
    },

    namespaced: true
}

export default StatusModule;
