var render = function render(){
  var _vm$firstScenario$nam, _vm$secondScenario$na, _vm$secondScenario$ye;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-summary"
  }, [_vm.hasNoScenario ? _c('pui-loader-error', {
    attrs: {
      "title": _vm.$t('results.scenarioCompare.errors.atLeastOne.title'),
      "message": _vm.$t('results.scenarioCompare.errors.atLeastOne.message'),
      "icon": "error-empty-data"
    }
  }) : _c('div', {
    staticClass: "sc-summary__section"
  }, [_c('div', {
    staticClass: "sc-summary__section__content"
  }, [_vm.firstScenario ? [_c('div', {
    staticClass: "sc-summary__section__content__title"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.title', {
    name: (_vm$firstScenario$nam = _vm.firstScenario.name) !== null && _vm$firstScenario$nam !== void 0 ? _vm$firstScenario$nam : ''
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.kpis', {
    count: _vm.firstScenario.kpisCalculated
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.sites', {
    count: _vm.firstScenario.sites
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.years', {
    count: _vm.firstScenario.years
  })) + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "sc-summary__section__separator"
  }), _c('div', {
    staticClass: "sc-summary__section__content sc-summary__section__content--second"
  }, [_vm.secondScenario ? [_c('div', {
    staticClass: "sc-summary__section__content__title"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.title', {
    name: (_vm$secondScenario$na = _vm.secondScenario.name) !== null && _vm$secondScenario$na !== void 0 ? _vm$secondScenario$na : ''
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.kpis', {
    count: _vm.secondScenario.kpisCalculated
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.sites', {
    count: _vm.secondScenario.sites
  })) + " ")]), _c('div', {
    staticClass: "sc-summary__section__content__item"
  }, [_vm._v(" " + _vm._s(_vm.$t('results.scenarioCompare.summary.years', {
    count: (_vm$secondScenario$ye = _vm.secondScenario.years) !== null && _vm$secondScenario$ye !== void 0 ? _vm$secondScenario$ye : 0
  })) + " ")])] : _vm._e()], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }