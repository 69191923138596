<template>
    <div class="kpi-analysis">
        <kpi-analysis-selectors
            @change:scenario-id="scenarioId = $event"
            @change:kpi-id="kpiId = $event"
            @change:year="year = $event"
            @change:unit="unitSid = $event"
        />
        <kpi-analysis-display
            :scenario-id="scenarioId"
            :kpi-id="kpiId"
            :year="year"
            :unit-sid="unitSid"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import KpiAnalysisSelectors from '@/components/results/kpi-analysis/kpi-analysis-selectors.vue';
import KpiAnalysisDisplay from '@/components/results/kpi-analysis/kpi-analysis-display.vue';

@Component({
    components: {
        KpiAnalysisDisplay,
        KpiAnalysisSelectors
    },
})
export default class ResultsKpiAnalysis extends Vue {
    private scenarioId: number | null = null;
    private kpiId: string | null = null;
    private year: number | null = null;
    private unitSid: number | null = null;

    @Watch('scenarioId')
    private clearKpiSelection(): void {
        this.kpiId = null;
        this.year = null;
        this.unitSid = null;
    }
}
</script>

<style scoped lang="scss">
.kpi-analysis {
    @include rem(padding, pui-spacing(m) 0);
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: column;
}
</style>
