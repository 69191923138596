<template>
    <div class="dm-table">
        <div class="dm-table__actions">
            <div class="dm-table__actions__text">
                {{ $t('dataManagement.overview.scenarioTable.selectedCount', { count: selectedScenariosCount }) }}
            </div>
            <pui-button
                state="primary"
                :disabled="isDeleteDisabled"
                @click="openConfirmationLightbox"
            >
                {{ $t('dataManagement.overview.scenarioTable.actions.delete') }}
            </pui-button>
        </div>

        <div class="dm-table__table">
            <spinner :promise="promise">
                <table-header
                    :search-term="tableExtraFilters.searchTerm"
                    :page-size="tableExtraFilters.pageSize"
                    @page-size-changed="onPageSizeChanged"
                    @search-triggered="onTriggerSearch"
                    @clear-search-term="onClearSearchTerm"
                />
                <ag-grid-vue
                    class="ag-theme-alpine"
                    :grid-options="gridOptions"
                    @grid-ready="onGridReady"
                />
            </spinner>
        </div>
        <confirmation-lightbox
            :list="selectedScenarioNameList"
            :lightbox-open="isConfirmationLightboxOpen"
            @close="closeConfirmationLightbox"
            @confirm="handleBulkDelete"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, ITooltipParams, ModelUpdatedEvent, SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { IScenarioList, ListSortDirection, ScenarioServiceProxy, SortableScenarioField } from '@/service-proxies/service-proxies.g';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { formatMtpYear, getFormattedTime } from '@/utils/formatters';
import LinkCell from '@/components/benchmarking-table/cell-types/link-cell.vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import DMCheckboxCell from '@/components/data-management-overview/dm-checkbox-cell.vue';
import SimpleTooltip from '@/components/benchmarking-table/tooltip-types/simple-tooltip.vue';
import Spinner from '@/components/spinner/spinner.vue';
import ScenarioStatusCell from '@/components/benchmarking-table/cell-types/scenario-status-cell.vue';
import ConfirmationLightbox from '@/components/data-management-overview/dm-confirmation-lightbox.vue';
import { LinkCellParams } from '@/models/interfaces';
import { SCENARIO_FORM_STEP, SCENARIO_FORM_STEP_ROUTE_MAP } from '@/config/steps';

enum MARKED_STATE {
    MARKED = 'marked',
    NOT_MARKED = 'notmarked'
}

@Component({
    components: {
        AgGridVue,
        Spinner,
        DMCheckboxCell,
        LinkCell,
        TableHeader,
        SimpleTooltip,
        ScenarioStatusCell,
        ConfirmationLightbox,
    }
})
export default class DataManagementTableComponent extends Vue {
    private scenarioService = new ScenarioServiceProxy();
    private gridApi: GridApi<IScenarioList> | null = null;
    private promise: Promise<any> = Promise.resolve();
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }
    private selectedScenarios: {
        id: number;
        name?: string;
    }[] = [];
    private isDeleteInProgress = false;
    private isConfirmationLightboxOpen = false;

    /**
     * This prop is required to know the selected status.
     */
    @Prop({ default: '0' })
    private selectedStatus!: string;

    private gridOptions: GridOptions<IScenarioList> = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                const isMarkedFilter = this.isMarkedFilter(params.request.filterModel?.isMarked?.values);
                const sortField = this.sortField(params.request.sortModel[0]?.colId);
                const sortDirection = this.sortDirection(params.request.sortModel[0]?.sort);
                const pageNumber = this.pageNumber(params.request.endRow);
                const { pageSize, searchTerm } = this.tableExtraFilters;

                this.$store.dispatch('dataManagement/saveShowInPowerBiChangelist')
                    .then(() => this.scenarioService.list2(this.statusFilter, isMarkedFilter, sortField, sortDirection, pageNumber, pageSize, searchTerm, undefined))
                    .then((response) => params.success({ rowData: response.result.items?.map((e: IScenarioList) => ({ ...e, markAsDeleteChecked: false })) ?? [], rowCount: response.result.count }))
                    .catch(() => params.fail());
            }
        },
        domLayout: 'autoHeight',
        columnDefs: [
            {
                headerName: '',
                field: 'markAsDeleteChecked',
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                valueFormatter: (): string => '',
                minWidth: 50,
                maxWidth: 50,
                pinned: 'left',
                sortable: false,
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.name'),
                field: 'name',
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams<IScenarioList>): LinkCellParams => {
                    return {
                        title: params.data?.name,
                        id: params.data?.id,
                        routerName: SCENARIO_FORM_STEP_ROUTE_MAP[SCENARIO_FORM_STEP.INITIAL_LOADER],
                    }
                },
                tooltipField: 'name',
                tooltipComponent: 'SimpleTooltip',
                tooltipComponentParams: (params: ITooltipParams<IScenarioList>): { content?: string } => ({
                    content: params.data?.description,
                }),
                pinned: 'left',
                resizable: true,
                minWidth: 250,
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.referenceYear'),
                field: 'referenceYear',
                valueFormatter: (params): string => params.value ?? '-',
                minWidth: 150,
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.mtpYear'),
                field: 'mtpYear',
                valueFormatter: (params): string => {
                    if (params.value === 0) {
                        return this.$t('setup.scenario.setupStepForm.noMtpYearOption');
                    }

                    return params.value ? formatMtpYear(params.value) : '-';
                }
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.createdBy'),
                field: 'createdByName',
                valueFormatter: (params): string => params.value ?? '-',
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.createdAt'),
                field: 'createdAt',
                sort: 'desc',
                valueFormatter: (params): string => params.value ? getFormattedTime(params.value) : '-',
                minWidth: 250,
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.status'),
                field: 'status',
                cellRenderer: 'ScenarioStatusCell',
                minWidth: 300,
            },
            {
                headerName: this.$t('dataManagement.overview.scenarioTable.headers.showInPowerBI'),
                field: 'isMarked',
                cellRenderer: 'DMCheckboxCell',
                sortable: false,
                minWidth: 200,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: [MARKED_STATE.MARKED, MARKED_STATE.NOT_MARKED],
                    valueFormatter: (params: ValueFormatterParams): string => params.value === MARKED_STATE.MARKED
                        ? this.$t('dataManagement.overview.scenarioTable.filter.marked')
                        : this.$t('dataManagement.overview.scenarioTable.filter.notmarked')
                },
                onCellValueChanged: (params): void => {
                    this.$store.dispatch('dataManagement/addShowInPowerBiToChangelist', {
                        scenarioId: params.data.id,
                        showInPowerBi: params.newValue,
                    });
                },
            },
        ],
        defaultColDef: {
            lockPosition: 'left',
            sortable: true,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
            maxWidth: 600,
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        tooltipShowDelay: 0,
        suppressMultiSort: true,
        suppressMenuHide: true,
        serverSideFilterOnServer: true,
        serverSideSortOnServer: true,
        serverSideInfiniteScroll: true,
        getRowId: (params) => params.data.id.toString(),
        isRowSelectable: (node): boolean => !!node.data?.canBeDeleted && (!!node.data?.id ?? false),
        onSelectionChanged: this.onSelectionChanged,
        onModelUpdated: this.onModelUpdated,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        }
    }

    @Watch('selectedStatus')
    onSelectedStatusChanged(): void {
        this.gridApi?.onFilterChanged();
    }

    private get statusFilter(): number | undefined {
        const status = Number(this.selectedStatus);
        if(this.selectedStatus && status !== 0) {
            return status;
        }

        return undefined;
    }

    private get selectedScenariosCount(): number {
        return this.selectedScenarios.length;
    }

    private get selectedScenarioNameList(): string {
        return this.selectedScenarios.map(e => e.name).join(', ');
    }

    private get isDeleteDisabled(): boolean {
        return this.isDeleteInProgress || !this.selectedScenarios.length;
    }

    private onGridReady(params: AgGridCommon<IScenarioList>): void {
        this.gridApi = params.api;
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private onClearSearchTerm(): void {
        this.tableExtraFilters.searchTerm = '';
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sortDirection(sort: string | undefined): ListSortDirection | undefined {
        if(!sort) {
            return undefined;
        }

        const directionKey: Record<string, ListSortDirection> = {
            'desc': ListSortDirection.Descending,
            'asc': ListSortDirection.Ascending,
        }

        return directionKey[sort];
    }

    private sortField(colId: string | undefined): SortableScenarioField | undefined {
        if(!colId) {
            return undefined;
        }

        const fieldKey: Record<string, SortableScenarioField> = {
            'mtpYear': SortableScenarioField.MTP_YEAR,
            'referenceYear': SortableScenarioField.REFERENCE_YEAR,
            'name': SortableScenarioField.NAME,
            'status': SortableScenarioField.STATUS,
            'createdByKid': SortableScenarioField.CREATED_BY_KID,
            'createdByName': SortableScenarioField.CREATED_BY_NAME,
            'createdAt': SortableScenarioField.CREATED_AT,
            'updatedAt': SortableScenarioField.UPDATED_AT,
        };

        return fieldKey[colId];
    }

    private isMarkedFilter(options?: string[]): boolean | null {
        if (!options || options.length !== 1) {
            return null;
        }
        return options[0] === MARKED_STATE.MARKED;
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private onSelectionChanged(event: SelectionChangedEvent<IScenarioList>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.isSelected()) {
                if (!this.selectedScenarios.find(e => e.id === node.data?.id)) {
                    this.selectedScenarios = [...this.selectedScenarios, {
                        id: node.data.id,
                        name: node.data.name,
                    }];
                }
            } else {
                this.selectedScenarios = this.selectedScenarios.filter(e => e.id !== node.data?.id);
            }
        });
    }

    private onModelUpdated(event: ModelUpdatedEvent<IScenarioList>): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (this.selectedScenarios.find(e => e.id === node.data?.id)) {
                node.setSelected(true, false, true);
            } else {
                node.setSelected(false, false, true);
            }
        });
    }

    private async handleBulkDelete(): Promise<void> {
        this.promise = this.triggerBulkDelete();
        this.closeConfirmationLightbox();
    }

    private async triggerBulkDelete(): Promise<void> {
        const scenarioIds = this.selectedScenarios.map(e => e.id);
        const payload = {
            scenarioIds,
        };

        try {
            this.isDeleteInProgress = true;
            await this.$store.dispatch('dataManagement/deleteScenarios', payload);
            this.$pui.toast({
                type: 'success',
                title: this.$t('dataManagement.overview.scenarioTable.toastMessages.deleteSuccess.title'),
                copy: this.$t('dataManagement.overview.scenarioTable.toastMessages.deleteSuccess.copy'),
            });
            this.selectedScenarios = [];
        } catch {
            this.$pui.toast({
                type: 'error',
                title: this.$t('dataManagement.overview.scenarioTable.toastMessages.deleteFail.title'),
                copy: this.$t('dataManagement.overview.scenarioTable.toastMessages.deleteFail.copy'),
            });
        } finally {
            this.isDeleteInProgress = false;
        }
    }

    public openConfirmationLightbox(): void {
        this.isConfirmationLightboxOpen = true;
    }

    public closeConfirmationLightbox(): void {
        this.isConfirmationLightboxOpen = false;
    }
}
</script>

<style lang="scss" scoped>
.dm-table {
  @include rem(margin-top, pui-spacing(m));

  &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 3rem;

      &__text {
          font-size: 1.4rem;
          font-weight: bold;
          color: $dark-blue-lighter;
      }
  }

  &__table {
    width: 100%;
    background-color: $white;
    @include pui-box();
    @include rem(margin, pui-spacing(l) 0);
  }
}
</style>
