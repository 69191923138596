<template>
    <div class="wrapper">
        <kpi-analysis-lightbox :ref="REF_CONSTANTS.KPI_ANALYSIS_LIGHTBOX" />
        <pui-loader :promise="initialPromise">
            <pui-breadcrumb
                :links="breadcrumbLinks"
                @changed:active-route="onActiveRouteChanged"
            />
            <engine-run-summary-card :promise="summaryPromise" />
            <engine-run-summary-message-cards
                :promise="summaryPromise"
                :is-card-hoverable="isCardHoverable"
                @on-selected-message-changed="selectedMessageChange"
            />
            <engine-run-summary-table
                :selected-message="selectedMessage"
                @reload-summary="loadSummary"
                @open:kpi-analysis-lightbox="openKpiAnalysisLightbox"
            />
        </pui-loader>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PuiBreadcrumbLinkItem, PuiNavigationToolbarClickEvent } from '@/models/pebble-ui';
import EngineRunSummaryCard from '@/components/engine-run-summary/engine-run-summary-card/engine-run-summary-card.vue';
import EngineRunSummaryMessageCards from '@/components/engine-run-summary/engine-run-message-cards/engine-run-message-cards.vue';
import EngineRunSummaryTable from '@/components/engine-run-summary/engine-run-summary-table/engine-run-summary-table.vue';
import { IScenarioList } from '@/service-proxies/service-proxies.g';
import KpiAnalysisLightbox from '@/components/kpi-analysis-lightbox/kpi-analysis-lightbox.vue';
import { KpiAnalysisLightboxPayload } from '@/models/kpi-analysis/kpi-analysis-lightbox';

const REF_CONSTANTS = {
    KPI_ANALYSIS_LIGHTBOX: 'kpiAnalysisLightbox',
} as const;

@Component({
    components: {
        KpiAnalysisLightbox,
        EngineRunSummaryCard,
        EngineRunSummaryMessageCards,
        EngineRunSummaryTable,
    }
})
export default class EngineRunSummaryPage extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private initialPromise: Promise<any> | null = null;
    private summaryPromise: Promise<any> | null = null;
    private isCardHoverable = false;
    private selectedMessage: string | null = null;

    $refs!: {
        [REF_CONSTANTS.KPI_ANALYSIS_LIGHTBOX]?: KpiAnalysisLightbox,
    };

    @Prop()
    private id!: string;

    private get scenario(): IScenarioList | undefined {
        return this.$store.getters['engineRun/summary/getScenario'];
    }

    private get breadcrumbLinks(): PuiBreadcrumbLinkItem[] {
        const links: PuiBreadcrumbLinkItem[] = [
            { label: this.$t('engineRun.navigationTitle'), href: 'EngineRunOverview' },
        ];

        if (this.scenario?.name) {
            links.push({
                label: this.scenario.name,
                href: '',
            });
        }

        return links;
    }

    private mounted(): void {
        this.loadScenario();
        this.loadSummary();
    }

    private openKpiAnalysisLightbox(payload: KpiAnalysisLightboxPayload): void {
        this.$refs.kpiAnalysisLightbox?.open(payload);
    }

    private loadScenario(): void {
        const payload = {
            id: Number(this.id),
        }
        this.initialPromise = this.$store.dispatch('engineRun/summary/fetchScenario', payload);
    }

    private loadSummary(): void {
        const payload = {
            id: Number(this.id),
        }
        this.summaryPromise = this.$store.dispatch('engineRun/summary/fetchEngineRunSummary', payload)
            .then(() => {
                this.isCardHoverable = true;
            });
    }

    private selectedMessageChange(id: string | null): void {
        this.selectedMessage = id;
    }

    private onActiveRouteChanged(event: PuiNavigationToolbarClickEvent): void {
        if (!event.href) {
            return;
        }

        this.$router.push({ name: event.href });
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    @include rem(padding, pui-spacing(l));

    &__heading {
        display: flex;
        justify-content: space-between;
    }
}
</style>

