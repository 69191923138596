<template>
    <div class="ag-cell-label-container">
        <div
            v-if="enableMenu"
            :ref="REF_CONSTANTS.MENU_BUTTON"
            @click="onMenuClicked"
        >
            <span class="ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show">
                <span class="ag-icon ag-icon-menu" />
            </span>
        </div>
        <div class="content">
            <pui-form-checkbox
                class="content__checkbox"
                label
                :checked="isChecked"
                @change="onChange"
            />
            {{ displayName }}
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IHeaderParams } from 'ag-grid-community';

interface CheckboxHeaderParams extends IHeaderParams {
  isChecked?: boolean;
}

const REF_CONSTANTS = {
    MENU_BUTTON: 'menuButton',
} as const;

@Component({})
export default class CheckboxHeaderCellComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private params?: CheckboxHeaderParams;

    $refs!: {
        [REF_CONSTANTS.MENU_BUTTON]: (HTMLElement);
    };

    private get displayName(): string | undefined {
        return this.params?.displayName;
    }

    private get enableMenu(): boolean | undefined {
        return this.params?.enableMenu;
    }

    private get isChecked(): boolean {
        return this.params?.isChecked ?? false
    }

    private onChange(value: boolean): void {
        this.params?.context.bulkHandleKpiActiveArray(value);
    }

    private onMenuClicked(): void {
        this.params?.showColumnMenu(this.$refs.menuButton);
    }
}
</script>

<style scoped lang="scss">
.content {
  &__checkbox {
    @include rem(height, 18px);
    @include rem(margin, 0px);
  }
}
</style>
