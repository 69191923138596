var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scenario-actions-cell-wrapper"
  }, [_c('pui-button', {
    attrs: {
      "icon": "edit",
      "state": "secondary",
      "small": true
    },
    on: {
      "click": _vm.openSecondaryKpiLightbox
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.actions.edit')) + " ")]), _c('pui-button', {
    attrs: {
      "icon": "delete",
      "state": "secondary",
      "small": true
    },
    on: {
      "click": _vm.deleteClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.actions.delete')) + " ")]), _c('secondary-kpi-delete-lightbox', {
    attrs: {
      "kpi-details": _vm.kpiDetails,
      "lightbox-open": _vm.isDeleteLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleCloseDeleteLightbox,
      "trigger-delete": _vm.deleteSecondaryKpi
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }