var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-summary-card"
  }, [_c('spinner', {
    attrs: {
      "promise": _vm.summaryPromise,
      "is-inline-error": false,
      "min-height": "60px"
    }
  }, [_c('pui-headline', {
    staticClass: "kpi-summary-card__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.title')) + " ")]), _c('pui-headline', {
    staticClass: "kpi-summary-card__content kpi-summary-card__content__item kpi-summary-card__content__item--bold",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.kpisSelected', {
    count: _vm.summary.selected
  })) + " ")]), _c('div', {
    class: ['kpi-summary-card__content--inline', {
      'kpi-summary-card__content': !_vm.isPrimary
    }]
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.unitKpis', {
    count: _vm.summary.unit
  })) + " ")]), _c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.unit0Kpis', {
    count: _vm.summary.unit0
  })) + " ")]), _c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.unitPlusUnit0Kpis', {
    count: _vm.summary.unitPlusUnit0
  })) + " ")])], 1), !_vm.isPrimary ? _c('pui-headline', {
    staticClass: "kpi-summary-card__content__item--highlight",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.scenario.formulaStep.summary.activeKpis', {
    count: _vm.summary.active
  })) + " ")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }