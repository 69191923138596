<template>
    <div class="sc-details">
        <table-header
            :hide-search-bar="true"
            :page-size="tableHeaderFilters.pageSize"
            @page-size-changed="onPageSizeChanged"
        />
        <ag-grid-vue
            :grid-options="gridOptions"
            class="ag-theme-alpine"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IYearComparisonDto } from '@/service-proxies/service-proxies.g';
import {
    CellRendererSelectorResult,
    CellStyle,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    ValueFormatterParams
} from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import ScenarioCompareDetailsNotCalculatedCell
    from '@/components/results/scenario-compare/cells/scenario-compare-details-not-calculated-cell.vue';
import ScenarioCompareDetailsValueCell
    from '@/components/results/scenario-compare/cells/scenario-compare-details-value-cell.vue';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { getColorForScenarioCompareStatus, ScenarioCompareStatus } from '@/config/scenario-compare';

export type ScenarioCompareDetailsRow = {
    year: number;
    firstScenarioValue?: number;
    firstScenarioIsCalculated: boolean;
    secondScenarioValue?: number;
    secondScenarioIsCalculated: boolean;
    percentageChange?: number;
    status: number;
};

@Component({
    components: {
        AgGridVue,
        TableHeader,
        ScenarioCompareDetailsNotCalculatedCell,
        ScenarioCompareDetailsValueCell,
    }
})
export default class ScenarioCompareDetailsTable extends Vue {
    private readonly COLUMN_KEYS = {
        YEAR: 'year',
        FIRST_SCENARIO: 'firstScenario',
        SECOND_SCENARIO: 'secondScenario',
        PERCENTAGE_CHANGE: 'percentageChange',
    } as const;

    private tableHeaderFilters = {
        pageSize: 20,
    };

    private gridApi: GridApi<ScenarioCompareDetailsRow> | null = null;

    private gridOptions: GridOptions<ScenarioCompareDetailsRow> = {
        rowModelType: 'clientSide',
        domLayout: 'autoHeight',
        columnDefs: [
            {
                field: this.COLUMN_KEYS.YEAR,
                headerName: this.$t('results.scenarioCompare.details.table.headers.year'),
                pinned: 'left',
            },
            {
                field: this.COLUMN_KEYS.FIRST_SCENARIO,
                headerValueGetter: (): string => this.firstScenarioName,
                cellRendererSelector: this.scenarioFieldCellRendererSelector,
            },
            {
                field: this.COLUMN_KEYS.SECOND_SCENARIO,
                headerValueGetter: (): string => this.secondScenarioName,
                cellRendererSelector: this.scenarioFieldCellRendererSelector,
            },
            {
                field: this.COLUMN_KEYS.PERCENTAGE_CHANGE,
                headerName: this.$t('results.scenarioCompare.details.table.headers.percentageChange'),
                valueFormatter: this.formatPercentageChange,
                cellStyle: this.percentageChangeCellStyleSelector
            }
        ],
        defaultColDef: {
            menuTabs: [],
        },
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
    }

    private get comparisonItems(): IYearComparisonDto[] {
        return this.$store.getters['results/scenarioCompare/details/getItems'];
    }

    private get firstScenarioName(): string {
        return this.$store.getters['results/scenarioCompare/getFirstScenario']?.name ?? this.$t('results.scenarioCompare.details.table.headers.firstScenario');
    }

    private get secondScenarioName(): string {
        return this.$store.getters['results/scenarioCompare/getSecondScenario']?.name ?? this.$t('results.scenarioCompare.details.table.headers.secondScenario');
    }

    private get rowData(): ScenarioCompareDetailsRow[] {
        return this.comparisonItems.map(e => ({
            year: e.year,
            status: e.status,
            percentageChange: e.percentageChange,
            firstScenarioValue: e.firstKpiData.value,
            firstScenarioIsCalculated: e.firstKpiData.isCalculated,
            secondScenarioValue: e.secondKpiData.value,
            secondScenarioIsCalculated: e.secondKpiData.isCalculated,
        }));
    }

    private onGridReady(event: GridReadyEvent<ScenarioCompareDetailsRow>): void {
        event.api.sizeColumnsToFit();
        event.api.setRowData(this.rowData);

        this.gridApi = event.api;
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
    }

    @Watch('rowData', { immediate: true })
    private onDataChanged(): void {
        this.gridApi?.setRowData(this.rowData);
    }

    private formatPercentageChange(params: ValueFormatterParams<ScenarioCompareDetailsRow>): string {
        if (params.data?.percentageChange === undefined) {
            return '-';
        }

        return `${params.data.percentageChange}%`;
    }

    private scenarioFieldCellRendererSelector(params: ICellRendererParams<ScenarioCompareDetailsRow>): CellRendererSelectorResult | undefined {
        if (!params.data || !params.colDef?.field) {
            return undefined;
        }

        let isSelected: boolean;
        let value: number | undefined;

        if (params.colDef.field === this.COLUMN_KEYS.FIRST_SCENARIO) {
            isSelected = params.data.firstScenarioIsCalculated;
            value = params.data.firstScenarioValue;
        } else if (params.colDef.field === this.COLUMN_KEYS.SECOND_SCENARIO) {
            isSelected = params.data.secondScenarioIsCalculated;
            value = params.data.secondScenarioValue;
        } else {
            return undefined;
        }

        if (!isSelected) {
            return {
                component: 'ScenarioCompareDetailsNotCalculatedCell',
            }
        } else {
            return {
                component: 'ScenarioCompareDetailsValueCell',
                params: { value }
            }
        }
    }

    private percentageChangeCellStyleSelector(params: CellClassParams<ScenarioCompareDetailsRow>): CellStyle | undefined {
        const highlightedStatuses: ScenarioCompareStatus[] = [ScenarioCompareStatus.RED, ScenarioCompareStatus.GREEN];

        if (!params.data?.status || !highlightedStatuses.includes(params.data.status)) {
            return undefined;
        }

        return {
            color: getColorForScenarioCompareStatus(params.data.status)
        };
    }
}
</script>

<style scoped lang="scss">
.sc-details {
    width: 100%;
    background-color: $white;
    @include pui-box();
}
</style>
