<template>
    <div class="delete-lightbox-wrapper">
        <pui-lightbox
            :ref="REF_CONSTANTS.LIGHTBOX"
            :default-header-label="$t('setup.scenario.formulaStep.toastMessages.confirmDeleteToastMessages.title')"
            :default-footer-confirm-label="$t('setup.scenario.formulaStep.actions.delete')"
            :default-footer-cancel-label="$t('setup.scenario.formulaStep.actions.cancel')"
            :on-confirm-callback="triggerDeleteKpi"
            :close-on-e-s-c="false"
            fit-content
            @puilightbox:close="emitClose"
        >
            <div class="delete-lightbox-wrapper__content">
                {{ $t('setup.scenario.formulaStep.toastMessages.confirmDeleteToastMessages.copy') }}
            </div>
        </pui-lightbox>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '@/models/pebble-ui';

const REF_CONSTANTS = {
    LIGHTBOX: 'delete-lightbox',
} as const;

@Component({})
export default class SecondaryKpiDeleteLightboxComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    @Prop()
    private lightboxOpen!: boolean;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }
    
    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(): void {
        this.$emit('close-lightbox');
    }

    private triggerDeleteKpi(): void {
        this.$emit('trigger-delete');
    }
}
</script>

<style lang="scss" scoped>
  .delete-lightbox-wrapper {
    &__content {
      @include rem(padding, pui-spacing(s) 0);
    }
  }
</style>
