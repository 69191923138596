var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "setup-overview"
  }, [_c('div', {
    staticClass: "setup-overview__heading"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('setup.overview.title')))]), _c('pui-button', {
    on: {
      "click": _vm.newScenarioClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('setup.overview.newScenario')) + " ")])], 1), _c('scenario-status-cards', {
    on: {
      "on-selected-status-changed": _vm.selectedStatusChanged
    }
  }), _c('scenario-table', {
    attrs: {
      "selected-status": _vm.selectedStatus
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }