import { Module } from 'vuex';
import {
    BudgetRequestServiceProxy,
    IBudgetFlaggedDto,
    IBudgetRequestGetDto
} from '@/service-proxies/service-proxies.g';
import Vue from 'vue';
import { FetchCostCorrectionValuesPayload } from '@/models/steps/cost-correction';

const budgetRequestService = new BudgetRequestServiceProxy();

type CostCorrectionStepModuleState = {
    budgetRequests: IBudgetRequestGetDto[];
    budgetRequestsTotalCount: number;
    modifications: Record<string, IBudgetFlaggedDto[]>;
};

const initialState: CostCorrectionStepModuleState = {
    budgetRequests: [],
    budgetRequestsTotalCount: 0,
    modifications: {},
};

const CostCorrectionStepModule: Module<CostCorrectionStepModuleState, any> = {
    state: initialState,

    mutations: {
        setBudgetRequests(state, payload: IBudgetRequestGetDto[]): void {
            state.budgetRequests = payload;
        },
        resetBudgetRequests(state): void {
            state.budgetRequests = [];
        },
        setBudgetRequestsTotalCount(state, payload: number): void {
            state.budgetRequestsTotalCount = payload;
        },
        resetBudgetRequestsTotalCount(state): void {
            state.budgetRequestsTotalCount = 0;
        },
        addModifications(state, payload: { globalId: string, modifications: IBudgetFlaggedDto[] }): void {
            Vue.set(state.modifications, payload.globalId, payload.modifications);
        },
        resetModifications(state): void {
            state.modifications = {};
        },
    },

    actions: {
        async fetchBudgetRequests({ commit }, payload: FetchCostCorrectionValuesPayload): Promise<IBudgetRequestGetDto[]> {
            const result = (await budgetRequestService.getBudget(payload.scenarioId, payload.plantSids, payload.countrySids, payload.technologySids, payload.operationTypes, payload.pageNumber, payload.pageSize, payload.searchTerm, undefined)).result;
            const budgetRequests = result.budgets ?? [];

            commit('setBudgetRequests', budgetRequests);
            commit('setBudgetRequestsTotalCount', result.count);

            budgetRequests.forEach(request => {
                commit('addModifications', { globalId: request.globalId ?? '', modifications: request.modifications ?? [] });
            });

            return budgetRequests;
        }
    },

    getters: {
        getBudgetRequestsTotalCount(state): number {
            return state.budgetRequestsTotalCount;
        },
        getModifications(state): Record<string, IBudgetFlaggedDto[]> {
            return state.modifications;
        },
    },

    namespaced: true,
};

export default CostCorrectionStepModule;

