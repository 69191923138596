<template>
    <div class="checkbox-wrapper">
        <pui-form-checkbox
            label
            :checked="isChecked"
            @change="onChange"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

@Component({})
export default class DMCheckboxCellComponent extends Vue {
    private params?: ICellRendererParams;

    private get isChecked(): boolean | undefined {
        return this.params?.value;
    }

    private onChange(value: boolean): void {
        const colId = this.params?.column?.getColId();
        if(colId) {
            this.params?.node?.setDataValue(colId, value);
        }
    }
}
</script>

<style scoped lang="scss">
  .checkbox-wrapper {
    height: 100%;
    display: flex;
    @include rem(margin-top, pui-spacing(xxxs));
  }
</style>
