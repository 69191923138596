<template>
    <div class="kpi-analysis-selector">
        <kpi-analysis-scenario-selector
            class="kpi-analysis-selector__scenario"
            @change:scenario-id="onScenarioIdChange"
            @change:years="onYearRangeChange"
        />
        <div
            v-if="scenarioId && scenarioFilters"
            class="kpi-analysis-selector__opt-selectors"
        >
            <kpi-analysis-kpi-selectors
                :scenario-id="scenarioId"
                :scenario-filters="scenarioFilters"
                @change:kpi-id="onKpiIdChange"
            />
            <div class="kpi-analysis-selector__opt-selectors__spacer" />
            <kpi-analysis-unit-selectors
                :start-year="startYear"
                :end-year="endYear"
                :scenario-filters="scenarioFilters"
                @change:unit="onUnitChange"
                @change:year="onYearChange"
            />
        </div>
        <div
            v-else-if="scenarioId && !scenarioFilters"
            class="kpi-analysis-selector__loader"
        >
            <pui-loader-spinner
                size="24px"
                min-height="0"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import KpiAnalysisScenarioSelector from '@/components/results/kpi-analysis/selectors/kpi-analysis-scenario-selector.vue';
import KpiAnalysisKpiSelectors from '@/components/results/kpi-analysis/selectors/kpi-analysis-kpi-selectors.vue';
import KpiAnalysisUnitSelectors from '@/components/results/kpi-analysis/selectors/kpi-analysis-unit-selectors.vue';
import { ManagedAbortControllers } from '@/mixins/managed-abort-controllers';
import { GetFiltersResponse, ResultServiceProxy } from '@/service-proxies/service-proxies.g';

@Component({
    components: { KpiAnalysisUnitSelectors, KpiAnalysisKpiSelectors, KpiAnalysisScenarioSelector }
})
export default class KpiAnalysisSelectors extends mixins(ManagedAbortControllers) {
    private readonly resultService = new ResultServiceProxy();

    private scenarioId: number | null = null;
    private scenarioFilters: GetFiltersResponse | null = null;

    private startYear: number | null = null;
    private endYear: number | null = null;

    private onScenarioIdChange(newScenarioId: number | undefined): void {
        this.scenarioId = newScenarioId ?? null;
        this.scenarioFilters = null;

        this.fetchScenarioFilters();

        this.$emit('change:scenario-id', newScenarioId);
    }

    private onYearRangeChange([startYear, endYear]: [number | undefined, number | undefined]): void {
        this.startYear = startYear ?? null;
        this.endYear = endYear ?? null;
    }

    private onKpiIdChange(newKpiId: string | undefined): void {
        this.$emit('change:kpi-id', newKpiId);
    }

    private onYearChange(newYear: number | undefined): void {
        this.$emit('change:year', newYear);
    }

    private onUnitChange(newUnit: number | undefined): void {
        this.$emit('change:unit', newUnit);
    }

    private async fetchScenarioFilters(): Promise<void> {
        if (!this.scenarioId) {
            this.scenarioFilters = null;
            return;
        }

        const response = await this.resultService.filter([this.scenarioId], true, this.getSignal('filters', true));
        this.scenarioFilters = response.result;
    }
}
</script>

<style scoped lang="scss">
.kpi-analysis-selector {
    @include rem(gap, pui-spacing(m));

    display: flex;
    flex-direction: row;

    &__opt-selectors {
        flex-grow: 1;

        display: flex;
        flex-direction: row;

        &__spacer {
            flex-grow: 1;
        }
    }

    &__scenario {
        min-width: 400px;
    }

    &__loader {
        margin-top: 25px;
        align-self: center;

        :deep(.pui-loader-spinner) {
            padding: 0;
            margin: 0;
        }
    }
}
</style>
