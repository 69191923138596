import { format } from '@enerlytics/time-helper/dist/date-fns';
import { i18n } from '@/utils/i18n';

export const getFormattedShortDate = (date: Date): string => format(new Date(date), 'dd/MM/yyyy');

export const getFormattedTime = (date: Date): string => format(new Date(date), 'MMMM do yyyy, HH:mm:ss');

export const getFormattedCreatedBy = (kid: string, name?: string): string => name ? `${name} (${kid})` : kid;

export const formatMtpYear = (mtpYear: number): string => {
    return i18n.t('pml.mtpYear', {
        mtpYear,
        mtpYear1: mtpYear + 1,
        mtpYear2: mtpYear + 3
    });
}

const LONG_FRACTION_DIGITS = 3 as const;
const SHORT_FRACTION_DIGITS = 1 as const;

export const formatNumberForTableDisplay = (value: number): string => {
    if (isNaN(value)) {
        return '-';
    }

    const roundedAbsoluteValue = Math.abs(Math.round(value));

    return Intl.NumberFormat('en-EN', {
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: roundedAbsoluteValue >= 10 ? SHORT_FRACTION_DIGITS : LONG_FRACTION_DIGITS,
    }).format(value);
}
