<template>
    <div class="overlay">
        <pui-headline type="h2">
            {{ $t('setup.scenario.ceForm.tables.flagged.empty.title') }}
        </pui-headline>
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.empty.subtitle') }}
        </pui-headline>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class FlaggedCostsNoRowsOverlay extends Vue {

}
</script>

<style scoped lang="scss">
.overlay {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
