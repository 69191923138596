<template>
    <div
        :class="[{
            'step-display-item--active': isActive,
            'step-display-item--clickable': isClickable,
        }, 'step-display-item']"
        @click="onItemClicked"
    >
        <div
            :class="[{
                'step-display-item__circle--active': isActive || isClickable,
            }, 'step-display-item__circle']"
        >
            {{ circleText }}
        </div>
        <div
            :class="[{
                'step-display-item__text--active': isActive || isClickable,
            }, 'step-display-item__text']"
        >
            {{ text }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class StepDisplayItem extends Vue {
    @Prop({ required: true, default: () => '' })
    private circleText!: string;

    @Prop({ required: true, default: () => '' })
    private text!: string;

    @Prop({ required: true, default: () => '' })
    private href!: string;

    @Prop({ required: true, default: () => false })
    private isActive!: boolean;

    @Prop({ required: true, default: () => false })
    private isClickable!: boolean;

    private onItemClicked(): void {
        if (!this.isClickable || this.isActive) {
            return;
        }

        this.$router.push({
            name: this.href,
        });
    }
}
</script>

<style lang="scss" scoped>
.step-display-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
    padding-bottom: 2rem;

    &--active {
        border-bottom: 0.2rem solid $uniper-blue;
    }

    &--clickable {
        cursor: pointer;
        user-select: none;
    }

    &__circle {
        @include rem(height, pui-spacing(m));
        @include rem(width, pui-spacing(m));

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;

        color: $white;
        background-color: $warm-grey;

        &--active {
            background-color: $uniper-blue;
        }
    }

    &__text {
        color: $warm-grey;

        &--active {
            color: $uniper-blue;
        }
    }
}
</style>
