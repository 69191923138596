<template>
    <div class="admin-overview">
        <h3>{{ $t('dataManagement.overview.title') }}</h3>
        <div class="admin-overview__header">
            <pui-tabs
                :selected="selectedTabIndex"
                @changed="onTabChanged"
            >
                <pui-tab :title="$t('admin.tabs.scenario')">
                    <admin-scenario-tab />
                </pui-tab>
                <pui-tab :title="$t('admin.tabs.kpiType')">
                    <admin-kpi-type-tab :ref="REF_CONSTANTS.KPI_TYPE" />
                </pui-tab>
                <pui-tab :title="$t('admin.tabs.uom')">
                    <admin-uom-tab :ref="REF_CONSTANTS.UOM" />
                </pui-tab>
                <div slot="action-bar">
                    <pui-button
                        v-show="selectedTabIndex === ADMIN_OVERVIEW_TAB.KPI_TYPE"
                        state="primary"
                        @click="handleOpenConstantsLightbox(AdminModalMode.KPI_TYPE)"
                    >
                        {{ $t('admin.actions.addNewKpiType') }}
                    </pui-button>
                    <pui-button
                        v-show="selectedTabIndex === ADMIN_OVERVIEW_TAB.UOM"
                        state="primary"
                        @click="handleOpenConstantsLightbox(AdminModalMode.UOM)"
                    >
                        {{ $t('admin.actions.addNewUOM') }}
                    </pui-button>
                </div>
            </pui-tabs>
        </div>
        <constants-lightbox
            :current-mode="currentModalMode"
            :lightbox-open="isConstantsLightboxOpen"
            @close-lightbox="handleCloseConstantsLightbox"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AdminKpiTypeTab from '@/pages/data-management/tabs/admin-kpi-type-tab.vue';
import AdminUomTab from '@/pages/data-management/tabs/admin-uom-tab.vue';
import ConstantsLightbox from '@/components/admin/modals/constants-lightbox.vue';
import { AdminModalMode } from '@/models/admin/constants';
import AdminScenarioTab from '@/pages/data-management/tabs/admin-scenario-tab.vue';

enum ADMIN_OVERVIEW_TAB {
    SCENARIO = 0,
    KPI_TYPE = 1,
    UOM = 2,
}

const REF_CONSTANTS = {
    KPI_TYPE: 'kpi-type',
    UOM: 'uom',
} as const;

@Component({
    components: {
        AdminScenarioTab,
        AdminUomTab,
        AdminKpiTypeTab,
        ConstantsLightbox,
    }
})
export default class AdminOverview extends Vue {
    private ADMIN_OVERVIEW_TAB = ADMIN_OVERVIEW_TAB;
    private selectedTabIndex: ADMIN_OVERVIEW_TAB = ADMIN_OVERVIEW_TAB.SCENARIO;
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.KPI_TYPE]: AdminKpiTypeTab;
        [REF_CONSTANTS.UOM]: AdminUomTab;
    };
    private AdminModalMode = AdminModalMode;
    private currentModalMode: AdminModalMode = AdminModalMode.KPI_TYPE;
    private isConstantsLightboxOpen = false;

    private async onTabChanged(tabIndex: number): Promise<void> {
        this.selectedTabIndex = tabIndex;
    }

    public async handleOpenConstantsLightbox(modalMode: AdminModalMode): Promise<void> {
        this.currentModalMode = modalMode;
        this.isConstantsLightboxOpen = true;
    }

    private handleCloseConstantsLightbox(params: { refresh: boolean, currentModalMode: AdminModalMode }): void {
        this.isConstantsLightboxOpen = false;
        if (params.refresh) {
            if (this.currentModalMode === AdminModalMode.KPI_TYPE) {
                this.$refs[REF_CONSTANTS.KPI_TYPE].onRefreshTable();
            } else if (this.currentModalMode === AdminModalMode.UOM) {
                this.$refs[REF_CONSTANTS.UOM].onRefreshTable();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.admin-overview {
    @include rem(padding, pui-spacing(l));
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;
}
</style>
