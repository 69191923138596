<template>
    <div class="tooltip">
        <div
            v-if="show"
            class="tooltip-inner"
        >
            <div v-if="content">
                {{ content }}
            </div>
            <div v-if="additionalContent">
                {{ additionalContent }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ITooltipParams } from 'ag-grid-community';

interface TooltipContent extends ITooltipParams {
    show?: boolean;
    content?: string;
    additionalContent?: string;
}

@Component({})
export default class SimpleTooltipComponent extends Vue {
    private params?: TooltipContent;

    private get show(): boolean {
        return this.params?.show ?? true;
    }

    private get content(): string | undefined {
        return this.params?.content;
    }

    private get additionalContent(): string | undefined {
        return this.params?.additionalContent;
    }
}
</script>

<style scoped lang="scss">
</style>
