var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [{
      'step-display-item--active': _vm.isActive,
      'step-display-item--clickable': _vm.isClickable
    }, 'step-display-item'],
    on: {
      "click": _vm.onItemClicked
    }
  }, [_c('div', {
    class: [{
      'step-display-item__circle--active': _vm.isActive || _vm.isClickable
    }, 'step-display-item__circle']
  }, [_vm._v(" " + _vm._s(_vm.circleText) + " ")]), _c('div', {
    class: [{
      'step-display-item__text--active': _vm.isActive || _vm.isClickable
    }, 'step-display-item__text']
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }