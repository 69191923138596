import { PUICOLOR_WARM_GREY, PUICOLOR_APPLICATION_BLACK, PUICOLOR_CONTEXTUAL_GREEN, PUICOLOR_UNIPER_BLUE, PUICOLOR_DATA_AMBER } from '@enerlytics/pebble-ui/dist/constants/colors.js'

export enum SCENARIO_STATUS {
    DRAFT = '1',
    IN_REVIEW = '2',
    READY_FOR_ENGINE_RUN = '3',
    ENGINE_RUN_STARTED = '4',
    ENGINE_RUN_COMPLETED = '5',
    ENGINE_RUN_FAILED = '6',
    ENGINE_RUN_STOPPED = '7',
}

interface ScenarioDetails {
    [key: string]: {
        COLOR: string;
        NAME: string;
    }
}

const ENGINE_RUN_COMPLETED_COLOR = '#28A745';
const ENGINE_RUN_FAILED_COLOR = '#FF0000';

export const SCENARIO_STATUS_DETAILS: ScenarioDetails = {
    [SCENARIO_STATUS.DRAFT]: {
        COLOR: PUICOLOR_WARM_GREY,
        NAME: 'setup.overview.status.draft',
    },
    [SCENARIO_STATUS.IN_REVIEW]: {
        COLOR: PUICOLOR_CONTEXTUAL_GREEN,
        NAME: 'setup.overview.status.inReview',
    },
    [SCENARIO_STATUS.READY_FOR_ENGINE_RUN]: {
        COLOR: PUICOLOR_UNIPER_BLUE,
        NAME: 'setup.overview.status.readyForEngineRun',
    },
    [SCENARIO_STATUS.ENGINE_RUN_STARTED]: {
        COLOR: PUICOLOR_DATA_AMBER,
        NAME: 'setup.overview.status.engineRunStarted',
    },
    [SCENARIO_STATUS.ENGINE_RUN_COMPLETED]: {
        COLOR: ENGINE_RUN_COMPLETED_COLOR,
        NAME: 'setup.overview.status.engineRunCompleted',
    },
    [SCENARIO_STATUS.ENGINE_RUN_FAILED]: {
        COLOR: ENGINE_RUN_FAILED_COLOR,
        NAME: 'setup.overview.status.engineRunFailed',
    },
    [SCENARIO_STATUS.ENGINE_RUN_STOPPED]: {
        COLOR: PUICOLOR_APPLICATION_BLACK,
        NAME: 'setup.overview.status.engineRunStopped',
    },
}
