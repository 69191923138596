<template>
    <div>
        <div
            v-if="kpiData"
            class="sc-table"
        >
            <div class="sc-table__header">
                <div class="sc-table__header__section">
                    <scenario-compare-filters
                        :namespace="isPrimaryKpiData ? 'primKpi' : 'secondKpi'"
                        :is-primary-kpi="isPrimaryKpiData"
                    />
                    <scenario-compare-table-overview :overview-data="kpiData?.overview" />
                </div>
                <table-header
                    :hide-search-bar="true"
                    :page-size="tableHeaderFilters.pageSize"
                    @page-size-changed="onPageSizeChanged"
                />
            </div>
            <ag-grid-vue
                v-if="hasReportedUnits"
                :grid-options="gridOptions"
                class="ag-theme-alpine"
            />
        </div>
        <pui-loader
            v-else
            :promise="infinitePromise"
            :title="$t('results.scenarioCompare.loader.title')"
            :message="$t('results.scenarioCompare.loader.message')"
            class="sc-table__loader"
        />
        <scenario-compare-details-lightbox :ref="REF_CONSTANTS.DETAILS_LIGHTBOX" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
    IKpiColumnDto,
    IKpiDataComparisonDto,
    IUnitDataComparisonDto,
    ScenarioCompareResponse
} from '@/service-proxies/service-proxies.g';
import { AgGridVue } from 'ag-grid-vue';
import TableHeader from '@/components/benchmarking-table/header-types/table-header.vue';
import ScenarioCompareDataCell from '@/components/results/scenario-compare/cells/scenario-compare-data-cell.vue';
import ScenarioCompareKpiHeaderTooltip
    from '@/components/results/scenario-compare/cells/scenario-compare-kpi-header-tooltip.vue';
import ScenarioCompareTableOverview
    from '@/components/results/scenario-compare/sections/scenario-compare-table-overview.vue';
import ScenarioCompareFilters from '@/components/results/scenario-compare/sections/scenario-compare-filters.vue';
import ScenarioCompareDetailsLightbox
    from '@/components/results/scenario-compare/lightbox/scenario-compare-details-lightbox.vue';
import { OpenScenarioCompareDetailsPayload } from '@/models/results/scenario-compare';
import { ScenarioCompareFilterOptions } from '@/store/modules/results/scenario-compare.module';
import NoRowsOverlay from '@/components/benchmarking-table/overlay-types/no-rows-overlay.vue';

export type ScenarioCompareRow = {
    machineSid: number;
    unitName: string;
    kpiData: Record<string, IKpiDataComparisonDto>;
};

const REF_CONSTANTS = {
    DETAILS_LIGHTBOX: 'detailsLightbox',
} as const;

@Component({
    components: {
        ScenarioCompareDetailsLightbox,
        ScenarioCompareFilters,
        AgGridVue,
        TableHeader,
        ScenarioCompareTableOverview,
        ScenarioCompareDataCell,
        ScenarioCompareKpiHeaderTooltip,
        NoRowsOverlay,
    }
})
export default class ScenarioCompareTable extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    @Prop({ default: () => false })
    private isPrimaryKpiData!: boolean;

    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    private isKpiDataSetInitially = false;

    private readonly COLUMN_KEYS = {
        UNIT_NAME: 'unitName',
    } as const;

    private tableHeaderFilters = {
        pageSize: 20,
    };

    private gridApi: GridApi<ScenarioCompareRow> | null = null;

    private gridOptions: GridOptions<ScenarioCompareRow> = {
        rowModelType: 'clientSide',
        domLayout: 'autoHeight',
        columnDefs: [
            {
                field: this.COLUMN_KEYS.UNIT_NAME,
                minWidth: 300,
                pinned: 'left',
                resizable: true,
            }
        ],
        defaultColDef: {
            menuTabs: [],
        },
        pagination: true,
        paginationPageSize: this.tableHeaderFilters.pageSize,
        cacheBlockSize: this.tableHeaderFilters.pageSize,
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        noRowsOverlayComponent: 'NoRowsOverlay',
        context: {
            openDetails: this.openDetails,
        },
        onGridSizeChanged(event) {
            event.api.sizeColumnsToFit();
        },
        onGridReady: this.onGridReady,
    };

    $refs!: {
        [REF_CONSTANTS.DETAILS_LIGHTBOX]: ScenarioCompareDetailsLightbox,
    }

    private get hasReportedUnits(): boolean {
        return this.$store.getters['results/scenarioCompare/getReportedUnits'].length !== 0;
    }

    private get filterOptions(): ScenarioCompareFilterOptions {
        return this.$store.getters['results/scenarioCompare/getFilterOptions'];
    }

    private get isFiltered(): boolean {
        return Object.values(this.filterOptions).some(e => e.length !== 0);
    }

    private get kpiData(): ScenarioCompareResponse | undefined {
        return this.$store.getters[this.isPrimaryKpiData ? 'results/scenarioCompare/getPrimaryKpiData' : 'results/scenarioCompare/getSecondaryKpiData'];
    }

    @Watch('isFiltered')
    private onIsFilteredChange(newIsFiltered: boolean): void {
        this.gridOptions.noRowsOverlayComponentParams = {
            message: newIsFiltered ? this.$t('results.scenarioCompare.noRows.withFilter') : this.$t('results.scenarioCompare.noRows.withoutFilter'),
        };
    }

    @Watch('filterOptions.kpiIds')
    private onFilterOptionsChange(): void {
        this.applyKpiIdColumnVisibility();
    }

    private applyKpiIdColumnVisibility(): void {
        if (!this.gridApi || !this.gridOptions.columnApi) {
            return;
        }

        const kpiColumnIds = this.gridOptions.columnApi.getColumns()
            ?.map(e => e.getId())
            .slice(1) ?? [];

        if (kpiColumnIds.length === 0) {
            return;
        }

        if (this.filterOptions.kpiIds.some(e => !kpiColumnIds.includes(e))) {
            this.$store.dispatch('results/scenarioCompare/setFilterOptions', {
                ...this.filterOptions,
                kpiIds: [],
            });

            return;
        }

        if (this.filterOptions.kpiIds.length === 0) {
            this.gridOptions.columnApi.setColumnsVisible(kpiColumnIds, true);
            this.gridApi.sizeColumnsToFit();
            return;
        }

        const filteredColumnIds = kpiColumnIds.filter(e => this.filterOptions.kpiIds.includes(e));
        const hiddenColumnIds = kpiColumnIds.filter(e => !this.filterOptions.kpiIds.includes(e));

        this.gridOptions.columnApi.setColumnsVisible(hiddenColumnIds, false);
        this.gridOptions.columnApi.setColumnsVisible(filteredColumnIds, true);

        this.gridApi.sizeColumnsToFit();
    }

    private onGridReady(event: GridReadyEvent<ScenarioCompareRow>): void {
        event.api.sizeColumnsToFit();

        this.gridApi = event.api;

        if (this.kpiData) {
            this.configureKpiColumns(this.kpiData.kpis ?? []);
            this.gridApi.setRowData(this.computeRowData(this.kpiData.data ?? []));
            this.isKpiDataSetInitially = true;

            this.applyKpiIdColumnVisibility();
        }
    }

    private openDetails(payload: OpenScenarioCompareDetailsPayload): void {
        this.$refs.detailsLightbox.open(payload);
    }

    private computeRowData(unitData: IUnitDataComparisonDto[]): ScenarioCompareRow[] {
        return unitData.map(data => ({
            machineSid: data.machineSid,
            unitName: data.unitName ?? '',
            kpiData: data.kpiDataDictionary ?? {}
        }));
    }

    private configureKpiColumns(kpiData: IKpiColumnDto[]): void {
        if (!this.gridApi) {
            return;
        }

        const columnDefinitions: ColDef<ScenarioCompareRow>[] = [];
        columnDefinitions.push(...this.gridOptions.columnDefs ?? []);

        kpiData.forEach(e => {
            if (!e.kpiId) {
                return;
            }

            columnDefinitions.push({
                field: e.kpiId,
                headerName: e.kpiId,
                minWidth: 125,
                maxWidth: 125,
                valueGetter: (): string => '',
                tooltipComponent: 'ScenarioCompareKpiHeaderTooltip',
                headerTooltip: e.kpiDescription ?? '',
                cellRenderer: 'ScenarioCompareDataCell',
                cellStyle: {
                    padding: 0,
                },
            });
        });

        this.gridApi.setColumnDefs(columnDefinitions);
        this.gridApi.sizeColumnsToFit();
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableHeaderFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableHeaderFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableHeaderFilters.pageSize);
    }
}
</script>

<style scoped lang="scss">
.sc-table {
    @include pui-box();

    background-color: $white;

    :deep(.ag-body-viewport) {
        max-height: 400px;
        overflow: auto;
    }

    &__header {
        display: flex;
        flex-direction: column;

        &__section {
            @include rem(padding-top, pui-spacing(s));

            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__loader {
        @include pui-box();
    }
}
</style>
