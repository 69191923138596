var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kpi-report"
  }, [_c('pui-collapsible', {
    staticClass: "kpi-report__collapsible",
    attrs: {
      "headline": _vm.$t('results.siteView.kpiReport.title')
    }
  }, [_c('div', {
    staticClass: "kpi-report__collapsible__inputs"
  }, [_c('kpi-analysis-kpi-selectors', {
    attrs: {
      "is-full-width": true,
      "scenario-id": _vm.scenarioId,
      "scenario-filters": _vm.scenarioFilters,
      "is-disabled": !_vm.scenarioFilters
    },
    on: {
      "change:kpi-id": function ($event) {
        _vm.selectedKpiId = $event;
      }
    }
  })], 1), _vm.shouldShowErrorMessage ? _c('pui-loader-error', {
    attrs: {
      "title": _vm.$t('results.siteView.noData.title'),
      "message": _vm.isTrendReportOptionsIncomplete ? _vm.$t('results.siteView.noData.message') : _vm.$t('results.siteView.noData.kpiMessage'),
      "icon": "error-empty-data"
    }
  }) : _vm._e(), _c('pui-loader', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shouldShowErrorMessage,
      expression: "!shouldShowErrorMessage"
    }],
    attrs: {
      "promise": _vm.kpiReportPromise
    }
  }, [_vm.kpiReport.length > 0 ? _c('site-view-trend-reports-chart', {
    attrs: {
      "chart-data": _vm.kpiReport,
      "scenario-filters": _vm.scenarioFilters
    }
  }) : _c('pui-loader-error', {
    attrs: {
      "title": _vm.$t('results.siteView.noData.title'),
      "icon": "error-empty-data"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }