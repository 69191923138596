import { FetchPaginationPartial } from '@/models/steps/common';
import { ICostModificationDto } from '@/service-proxies/service-proxies.g';
import Decimal from 'decimal.js';

export type FetchCostCorrectionValuesPayload = { scenarioId: number }
    & FetchPaginationPartial
    & FetchCostCorrectionFilters;

export type FetchCostCorrectionFilters = {
    plantSids?: number[];
    countrySids?: number[];
    technologySids?: number[];
    operationTypes?: number[];
    searchTerm?: string;
};

export type FetchAllCostsFilters = {
    plants?: number[],
    countries?: number[],
    technologies?: number[],
    searchTerm?: string,
    showOnlyWithValues?: boolean,
};

export enum OperationType {
    UNKNOWN,
    CORRECTION,
    EXCLUSION,
}

export const OPERATION_TYPE_PRINT_KEY_MAP = {
    [OperationType.EXCLUSION]: 'operationTypes.exclusion',
    [OperationType.CORRECTION]: 'operationTypes.correction'
} as const;

export type IFormCostModification = { internalId: number, errorMessage?: string, amount: Decimal } & Omit<ICostModificationDto, 'amount'>;
